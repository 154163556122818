import { useFiltersStyles } from "./useFiltersStyles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Chevron } from "uiKit/icons";
import { Dispatch, useCallback } from "react";
import { NO_FILTER_SPECIFIED } from "../../../../../common/const";
import { NetworkCode } from "../../../../../common/types";
import { IAction } from "../../reducer";
import { t } from "modules/utils/intl";
import { IFiltersState } from "../../../Games/types";
import { chainsMap } from "../../../../../common/utils/filters";

interface IFilters {
  areFiltersOpen: boolean;
  filtersState: [IFiltersState, Dispatch<IAction>];
}

const Filters = ({
  areFiltersOpen,
  filtersState: [{ chain }, setActiveFilters],
}: IFilters) => {
  const { classes } = useFiltersStyles({
    activeFilter: chain || NO_FILTER_SPECIFIED,
    areFiltersOpen,
  });

  const handleChainFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (value === NO_FILTER_SPECIFIED) {
        setActiveFilters({ type: "CLEAR_FILTER", payload: "chain" });
        return;
      }

      setActiveFilters({ type: "SET_CHAIN", payload: value });
    },
    [setActiveFilters],
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <Typography className={classes.heading} variant="h4" component="h4">
          {t("plaza.collections.filters.title")}
        </Typography>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<Chevron />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body2Demibold">
              {t("plaza.collections.filters.chain")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <FormControl>
              <RadioGroup
                className={classes.radioGroup}
                aria-labelledby="demo-radio-buttons-group-label"
                value={chain || NO_FILTER_SPECIFIED}
                name="radio-buttons-group"
                onChange={handleChainFilterChange}
              >
                <FormControlLabel
                  value={NO_FILTER_SPECIFIED}
                  className={classes.formControlLabel}
                  control={
                    <Radio className={classes.radio} id={"All chains"} />
                  }
                  label="All chains"
                />
                {Object.entries(chainsMap).map(([key, value]) => (
                  <FormControlLabel
                    key={key}
                    value={key as NetworkCode}
                    className={classes.formControlLabel}
                    control={<Radio className={classes.radio} id={value} />}
                    label={value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default Filters;
