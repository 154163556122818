import { Box, Typography } from "@mui/material";
import { Network } from "modules/common/types";
import { NetworkOrTokenIcon } from "uiKit/components/Icon";
import { ReactComponent as CheckIcon } from "uiKit/icons/check.svg";
import { useNetworkButtonStyles } from "./useNetworkButtonStyles";

interface INetworkItemProps {
  network: Network;
  name: string;
  isActive: boolean;
}

export const NetworkItem = ({
  network,
  name,
  isActive,
}: INetworkItemProps): JSX.Element => {
  const { classes } = useNetworkButtonStyles();

  return (
    <Box className={classes.networkRoot}>
      <Box display="flex" alignItems="center">
        <NetworkOrTokenIcon name={network} className={classes.itemIcon} />
        <Typography variant="body2Demibold">{name}</Typography>
      </Box>
      {isActive && <CheckIcon className={classes.checkIcon} />}
    </Box>
  );
};
