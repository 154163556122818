import { makeStyles } from "tss-react/mui";

export const useCoinsListStyles = makeStyles()(theme => ({
  root: {},
  header: {
    display: "grid",
    gridTemplateColumns: "1.5fr 1fr 0.5fr",
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    color: theme.palette.grey[300],
    padding: theme.spacing(3, 5),
    marginBottom: theme.spacing(7),
  },
  itemRoot: {
    display: "grid",
    gridTemplateColumns: "1.5fr 1fr 0.5fr",
    marginBottom: theme.spacing(7),
  },
}));
