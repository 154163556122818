import { makeStyles } from "tss-react/mui";
import { selectClasses, outlinedInputClasses } from "@mui/material";

export const useSelectStyles = makeStyles()((theme) => ({
  root: {},
  select: {
    maxHeight: 40,
    borderRadius: 6,
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(3.25, 3.5, 2.75, 5),
    fontSize: 15,
    lineHeight: 16,
    fontWeight: 600,
    cursor: "pointer",
    width: "100%",
    maxWidth: 200,
    border: "none !important",

    [`& .${selectClasses.icon}`]: {
      color: "white",
    },

    [`& .${selectClasses.select}`]: {
      borderColor: "transparent !important",
    },

    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "transparent !important",
    },
  },
  option: {},
}));
