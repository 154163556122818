import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useSidebarItemStyles } from "./useSidebarItemStyles";
import { cloneElement } from "react";

interface ISidebarItemProps {
  iconSlot: JSX.Element;
  title: string;
  link: string;
  isActive: boolean;
  className?: string;
}

export const SidebarItem = ({
  iconSlot,
  title,
  link,
  isActive,
  className,
}: ISidebarItemProps): JSX.Element => {
  const { classes, cx } = useSidebarItemStyles();

  return (
    <NavLink
      to={link}
      className={cx(classes.item, isActive && classes.active, className)}>
      {cloneElement(iconSlot, { className: classes.icon })}
      <Typography variant="h3">{title}</Typography>
    </NavLink>
  );
};
