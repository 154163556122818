import { configFromEnv, web3Api } from "modules/api";
import { GET_POST_COLLECTION } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { ICollectionListItem } from "modules/plaza/types";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IGetCollectionArgs {
  id?: string;
}

export const { useGetCollectionQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    getCollection: build.query<ICollectionListItem, IGetCollectionArgs>({
      queryFn: queryFnNotifyWrapper<
        IGetCollectionArgs,
        never,
        ICollectionListItem
      >(async ({ id }: IGetCollectionArgs) => {
        try {
          const response = await fetch(
            nftManagerUrl + GET_POST_COLLECTION + id,
            {
              method: "GET",
              credentials: "include",
            },
          );

          const data = await processApiResponse(response);
          return { data };
        } catch (err) {
          console.error("Error in `useGetCollectionQuery.ts`", err);
          throw err;
        }
      }),
    }),
  }),
});
