import { makeStyles } from "tss-react/mui";

export const useUnauthorizedWalletDialogStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.text.secondary,
  },
  icon: {
    marginBottom: theme.spacing(9.5),
    width: 84,
    height: 62,
  },
}));
