import { EEthereumNetworkId, EWalletId } from "@ankr.com/provider";
import { web3Api } from "modules/api";
import { getProviderManager } from "modules/api/getProviderManager";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { setChainId } from "store/authSlice";

interface ISwitchNetworkArgs {
  wallet?: EWalletId;
  chainId: EEthereumNetworkId;
}

type TSwitchNetwork = boolean;

// TODO: fix provider current chain constantly equal 11155111
export const {
  useSwitchNetworkMutation,
  endpoints: { switchNetwork },
} = web3Api.injectEndpoints({
  endpoints: (build) => {
    return {
      switchNetwork: build.mutation<TSwitchNetwork, ISwitchNetworkArgs>({
        queryFn: queryFnNotifyWrapper<
          ISwitchNetworkArgs,
          never,
          TSwitchNetwork
        >(async ({ wallet, chainId }) => {
          const providerManager = getProviderManager();
          const provider = await providerManager.getETHWriteProvider(wallet);

          if (provider.currentChain !== chainId) {
            await provider.switchNetwork(chainId);
          }

          return {
            data: false,
          };
        }),
        async onQueryStarted(
          { chainId, wallet },
          { dispatch, queryFulfilled },
        ) {
          return queryFulfilled.then(() => {
            dispatch(
              setChainId({
                isActive: true,
                chainId,
              }),
            );
          });
        },
      }),
    };
  },
});
