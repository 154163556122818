import { Box, ButtonBase, Typography } from "@mui/material";
import { useCopyHook } from "modules/common/hooks/useCopyHook";
import CopyToClipboard from "react-copy-to-clipboard";
import { ReactComponent as CopyIcon } from "uiKit/icons/copy.svg";

interface ICopyWrapperProps {
  shortValue: string;
  fullValue: string;
}

export const CopyWrapper = ({
  shortValue,
  fullValue,
}: ICopyWrapperProps): JSX.Element => {
  const { handleCopy } = useCopyHook();

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1Demibold" mr={1}>
        {shortValue}
      </Typography>
      <CopyToClipboard text={fullValue} onCopy={handleCopy}>
        <ButtonBase>
          <CopyIcon />
        </ButtonBase>
      </CopyToClipboard>
    </Box>
  );
};
