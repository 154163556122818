import { makeStyles } from "tss-react/mui";

export const useTableStyles = makeStyles()(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  headerCell: {
    color: theme.palette.grey[300],
  },
}));
