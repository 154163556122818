import { ButtonBase, Menu, Typography } from "@mui/material";
import { cloneElement, useEffect, useState } from "react";
import { ReactComponent as ChevronSmallIcon } from "uiKit/icons/chevron-small.svg";
import { AccountInfo } from "../AccountInfo";
import { useAccountButtonStyles } from "./useAccountButtonStyles";

interface IAccountButtonProps {
  icon: JSX.Element;
  name: string;
}

export const AccountButton = ({
  icon,
  name,
}: IAccountButtonProps): JSX.Element => {
  const { classes, cx } = useAccountButtonStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <>
      <ButtonBase className={classes.root} onClick={handleClick}>
        {!!icon &&
          cloneElement(icon, {
            className: classes.userLogo,
          })}
        <Typography variant="body1Demibold" className={classes.userName}>
          {name}
        </Typography>
        <ChevronSmallIcon className={cx(open && classes.openedChevron)} />
      </ButtonBase>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: 0,
            maxHeight: 500,
            width: 302,
            transform: "translateY(12px)",
          },
        }}
      >
        <AccountInfo icon={icon} name={name} />
      </Menu>
    </>
  );
};
