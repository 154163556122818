import { makeStyles } from "tss-react/mui";

export const usePopularWalletsStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 384,
    borderRight: `1px solid ${theme.palette.grey[500]}`,
  },
  buttonWrapper: {
    width: 222,
    height: 44,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.grey[500],
    borderRadius: 6,
    padding: theme.spacing(2),
  },
  loader: {
    color: theme.palette.yellow.light,
  },
  popularWalletIcon: {
    height: 28,
    width: 28,
    marginRight: theme.spacing(2),
  },
}));
