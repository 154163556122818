import { Route, Switch } from "react-router-dom";
import { getRoutes as getAccountRoutes } from "modules/account/Routes";
import { getRoutes as getPlazaRoutes } from "modules/plaza/Routes";
import { PageNotFound } from "modules/common/components/PageNotFound";
import { Layout } from "modules/common/components/Layout";
import { Sidebar } from "modules/plaza/components/Sidebar/Sidebar";

export function Routes(): JSX.Element {
  return (
    <Switch>
      {getAccountRoutes()}

      {getPlazaRoutes()}

      <Route path="*">
        <Layout sidebarSlot={<Sidebar />}>
          <PageNotFound />
        </Layout>
      </Route>
    </Switch>
  );
}
