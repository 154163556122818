import React, { ForwardedRef, forwardRef } from 'react';
import { Button as ButtonComponent, ButtonProps } from '@mui/material';
import { Spinner } from '../Spinner';

type ButtonsVariant = 'contained' | 'outlined' | 'text';

export interface IButtonProps extends ButtonProps {
  variant?: ButtonsVariant;
  submit?: boolean;
  style?: React.CSSProperties;
  isLoading?: boolean;
}

export const Button = forwardRef(
  (
    {
      variant = 'contained',
      submit,
      style,
      isLoading,
      endIcon,
      ...props
    }: IButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <ButtonComponent
        ref={ref}
        component="button"
        style={style}
        type={submit ? 'submit' : 'button'}
        variant={variant}
        {...props}
      >
        {isLoading ? <Spinner /> : props.children}
      </ButtonComponent>
    );
  },
);
