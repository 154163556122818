import { Box, Typography } from "@mui/material";

interface IEmptyProps {
  text: string;
}

export const Empty = ({ text }: IEmptyProps): JSX.Element => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={1}
      width={1}
    >
      <Typography variant="body1Demibold">{text}</Typography>
    </Box>
  );
};
