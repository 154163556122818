import { Button } from "@mui/material";
import { oryLoginAsync } from "modules/auth";
import { t } from "modules/utils";
import { useCallback } from "react";

export const LoginButton = (): JSX.Element => {
  const handleClickLogin = useCallback(async () => {
    await oryLoginAsync();
  }, []);

  return (
    <Button variant="outlined" onClick={handleClickLogin}>
      {t("layout.header.login")}
    </Button>
  );
};
