import { useBigImageStyles } from "./useBigImageStyles";
import { StatusBadge } from "uiKit/components/StatusBadge/StatusBadge";
import { ENftTokenStatus } from "modules/common/types";

interface IBigImageProps {
  imageSrc: string;
  isMyCollectible: boolean;
}

export const BigImage = ({
  imageSrc,
  isMyCollectible,
}: IBigImageProps): JSX.Element => {
  const { classes } = useBigImageStyles();

  return (
    <div className={classes.root}>
      <img alt="" className={classes.root} src={imageSrc} />

      {isMyCollectible && (
        <div className={classes.owned}>
          <StatusBadge type={ENftTokenStatus.OWNED} />
        </div>
      )}
    </div>
  );
};
