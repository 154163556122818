import { Box, Button, Typography } from "@mui/material";
import { t } from "modules/utils/intl";
import { useNoResultsStyles } from "./useNoResultsStyles";
import { Dispatch, useCallback } from "react";
import { IAction } from "../../../plaza/screens/Collections/reducer";

type ClearSearch = (value: string) => void;

interface INoResultsProps {
  emptyDataName: string;
  onClearFilters: Dispatch<IAction>;
  clearSearch: [ClearSearch, ClearSearch];
}
export const NoResults = ({
  emptyDataName,
  onClearFilters,
  clearSearch: [clearLocalSearchQuery, clearSearchQuery],
}: INoResultsProps) => {
  const { classes } = useNoResultsStyles();

  const handleClearFilters = useCallback(() => {
    onClearFilters({ type: "CLEAR_FILTERS" });
    clearLocalSearchQuery("");
    clearSearchQuery("");
  }, [clearLocalSearchQuery, clearSearchQuery, onClearFilters]);

  return (
    <Box className={classes.root}>
      <Typography className={classes.heading} variant="body1Demibold">
        {t("empty-states.no-results.heading", { value: emptyDataName })}
      </Typography>
      <Typography className={classes.message} variant="body2">
        {t("empty-states.no-results.message")}
      </Typography>
      <Button variant="outlined" onClick={handleClearFilters}>
        {t("empty-states.no-results.button")}
      </Button>
    </Box>
  );
};
