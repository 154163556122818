import { configFromEnv, web3Api } from "modules/api";

import { GET_ORGANIZATIONS } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { IOrganizationData } from "modules/plaza/types";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { mirageIdUrl },
} = configFromEnv();

type IResponse = IOrganizationData[];

export const { useFetchOrganizationsQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    fetchOrganizations: build.query<IResponse, unknown>({
      queryFn: queryFnNotifyWrapper<unknown, never, IResponse>(async () => {
        try {
          const url = new URL(mirageIdUrl + GET_ORGANIZATIONS).toString();
          const response = await fetch(url, {
            method: "GET",
            headers: new Headers({
              credentials: "include",
            }),
          });

          const data = await processApiResponse(response);

          return { data };
        } catch (err) {
          console.error("Error in `useFetchOrganizations.ts`", err);
          throw err;
        }
      }),
    }),
  }),
});
