import { ITEM_TILE_WIDHT } from "modules/common/const";
import { makeStyles } from "tss-react/mui";

export const useCollectibleItemStyles = makeStyles()((theme) => ({
  root: {
    background: "none",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),

    "&:last-child": {
      marginBottom: 0,
    },
  },
  media: {
    position: "relative",
    width: ITEM_TILE_WIDHT,
    height: ITEM_TILE_WIDHT,
    borderRadius: 12,
    backgroundColor: theme.palette.grey[700],
  },
  collectibleName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 120,
  },
  iconWrapper: {
    display: "block",
    position: "absolute",
    top: 9,
    left: 12,
    borderRadius: 6,
    width: 20,
    height: 20,

    "& div": {
      maxHeight: 20,
      minHeight: 20,
      backgroundColor: theme.palette.grey[700],
    },
  },
  icon: {
    width: 12,
    height: 12,
  },
  statusBadge: {
    display: "block",
    position: "absolute",
    top: 9,
    left: 36,
    width: "100%",

    "& div": {
      minHeight: 20,

      "& div": {
        minHeight: 8,
      },
    },
  },
  transfrormStatusBadge: {
    left: 12,
  },
  content: {
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3.25, 0, 0),
  },
  collectionName: {
    color: theme.palette.yellow.main,
  },
}));
