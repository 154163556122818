import { Box } from "@mui/material";

import { GameTile } from "modules/common/components/GameTile";
import { RoutesConfig } from "modules/plaza/Routes";
import { IGamesListItem } from "modules/plaza/types";

interface IGamesGridProps {
  data: IGamesListItem[];
}

export const GamesGrid = ({ data }: IGamesGridProps): JSX.Element => {
  return (
    <Box display="flex" flexWrap="wrap" gap="40px 20px">
      {!!data.length &&
        data.map(
          ({
            id,
            projectName,
            image,
            gameProjectTypes,
            platformTypes,
            score,
            chainCodes,
          }) => (
            <GameTile
              key={id}
              image={image?.fileUri || ""}
              name={projectName}
              developerName={projectName}
              platformTypes={platformTypes}
              gameProjectTypes={gameProjectTypes}
              link={RoutesConfig.game.generatePath(id)}
              reviewScore={score}
              networks={
                !!chainCodes && chainCodes.length > 0 ? chainCodes : undefined
              }
            />
          ),
        )}
    </Box>
  );
};
