import { makeStyles } from "tss-react/mui";

export const useDialogStyles = makeStyles()(theme => ({
  root: {
    justifyContent: "center",
    margin: theme.spacing(4, 0),
    padding: theme.spacing(10, 8, 15, 8),
    backgroundColor: theme.palette.grey[700],
    borderRadius: 12,

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(8, 8, 15),
    },
  },

  default: {
    width: 332,
    minWidth: 332,
  },

  big: {
    width: 508,
    minWidth: 508,
  },

  large: {
    width: 720,
    minWidth: 720,
    paddingBottom: theme.spacing(8),
  },

  title: {
    position: "relative",
    fontWeight: 600,
    fontSize: 40,
    lineHeight: "44px",
    textAlign: "start",
    padding: 0,
    marginBottom: theme.spacing(8),
  },

  closeBtn: {
    position: "absolute",
    top: theme.spacing(-4),
    right: theme.spacing(-4.5),

    [theme.breakpoints.up("sm")]: {
      top: theme.spacing(-4),
      right: theme.spacing(-4.5),
    },

    width: 40,
    minWidth: 0,
    height: 40,
    padding: 0,

    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[500]}`,
    color: theme.palette.text.primary,
  },

  content: {
    padding: 0,
  },
}));
