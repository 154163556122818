import { makeStyles } from "tss-react/mui";

export const useCollectionsListStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(8),
  },
  tableRow: {
    maxHeight: 72,
    cursor: "pointer",
  },
  tableColumn: {
    color: "rgba(255, 255, 255, 0.5)",
  },
  indicatorIcon: {
    width: 8,
    height: 8,
    marginRight: theme.spacing(1),
  },
  indicatorGreen: {
    color: theme.palette.success.main,
    "& svg": {
      transform: "rotate(180)",
    },
  },
  indicatorRed: {
    color: theme.palette.error.light,
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  logo: {
    width: 52,
    height: 52,
    borderRadius: 12,
    marginRight: theme.spacing(3),
  },
  collectionName: {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    whiteSpace: "pre-wrap",
  },
  gameProjectName: {
    color: theme.palette.yellow.main,
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    whiteSpace: "pre-wrap",
  },
}));
