import { makeStyles } from "tss-react/esm/mui";

export const useFilterTagsStyles = makeStyles<{ areTagsEmpty: boolean }>()(
  (theme, { areTagsEmpty }) => ({
    root: {
      display: "flex",
      flexFlow: "wrap",
      gap: theme.spacing(2),
      marginBottom: areTagsEmpty ? 0 : theme.spacing(5),
    },
    tag: {
      padding: theme.spacing(1.5, 1, 1.25, 2),
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 6,

      "& button": {
        padding: 0,
      },
    },
    filterText: {
      marginRight: theme.spacing(2),
    },
  }),
);
