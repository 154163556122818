import * as reservoir from "@reservoir0x/reservoir-sdk";
import { ethers } from "ethers";
import { web3Api } from "modules/api";
import { EWalletId } from "modules/api/const";
import { notify } from "uiKit/components/Notifications";
import {
  RESERVOIR_ETH_API,
  RESERVOIR_SEPOLIA_API,
  RESERVOIR_POLYGON_API,
  RESERVOIR_ARBITRUM_NOVA_API,
} from "modules/api/urls";
import { EWalletErrorCode } from "modules/common/types";
import {
  ARBITRUM_NOVA_MAINNET_CHAIN_ID,
  COMMON_ERROR_MESSAGE,
  POLYGON_MAINNET_CHAIN_ID,
  REJECTED_ERROR_MESSAGE,
  RESERVOIR_API_KEY,
  SEPOLIA_CHAIN_ID,
} from "modules/common/const";
import { push } from "@lagunovsky/redux-react-router";
import { RoutesConfig } from "modules/account/Routes";
import { getProviderManager } from "modules/api/getProviderManager";

interface IResponse {
  txHash: string;
}

interface IBuyArgs {
  wallet?: EWalletId;
  chainId: number;
  contractAddress: string;
  tokenId: string;
  price: string;
}

export const { useBuyCollectibleMutation } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    buyCollectible: build.mutation<IResponse, IBuyArgs>({
      queryFn: async ({
        wallet,
        chainId,
        contractAddress,
        tokenId,
        price,
      }: IBuyArgs) => {
        const providerManager = getProviderManager();
        try {
          let txHash = "";
          const provider = await providerManager.getETHWriteProvider(wallet);

          const etherProvider = new ethers.providers.Web3Provider(
            window.ethereum,
            chainId,
          );

          if (provider.currentChain !== chainId) {
            await provider.switchNetwork(chainId);
          }

          let baseUrl = RESERVOIR_ETH_API;
          if (chainId === SEPOLIA_CHAIN_ID) baseUrl = RESERVOIR_SEPOLIA_API;
          if (chainId === POLYGON_MAINNET_CHAIN_ID)
            baseUrl = RESERVOIR_POLYGON_API;
          if (chainId === ARBITRUM_NOVA_MAINNET_CHAIN_ID)
            baseUrl = RESERVOIR_ARBITRUM_NOVA_API;

          const reservoirClient = reservoir.createClient({
            chains: [
              {
                id: chainId,
                baseApiUrl: baseUrl,
                default: true,
                apiKey: RESERVOIR_API_KEY,
              },
            ],
            source: "localhost",
          });

          const signer = etherProvider.getSigner();

          await reservoirClient.actions.buyToken({
            items: [
              {
                token: `${contractAddress}:${tokenId}`,
                quantity: 1,
              },
            ],
            signer,
            onProgress: (steps) => {
              steps.forEach((step) => {
                step.items?.forEach((item) => {
                  if (!!item.txHash) {
                    txHash = item.txHash;
                  }
                });
              });
            },
          });

          return {
            data: { txHash },
          };
        } catch (e) {
          if ((e as any)?.code === EWalletErrorCode.ACTION_REJECTED) {
            throw new Error(REJECTED_ERROR_MESSAGE);
          } else {
            throw new Error(COMMON_ERROR_MESSAGE);
          }
        }
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        return queryFulfilled.then(() => {
          dispatch(push(RoutesConfig.inventory.generatePath()));
          notify({
            message: "Purchase completed!",
            type: "success",
            key: "success",
          });
        });
      },
      // invalidatesTags: [CollectibleCacheTags.main],
    }),
  }),
});
