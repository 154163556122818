import { configFromEnv, web3Api } from "modules/api";
import { getProviderManager } from "modules/api/getProviderManager";
import { GET_WALLET_VERIFICATION } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { mirageIdUrl },
} = configFromEnv();

export interface IVerifyWalletResponse {
  id: string;
}

interface ILazyVerifyWalletQueryArgs {
  wallet: string;
}

const MOCK_MESSAGE = "verify me";

export const { useVerifyWalletMutation } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    verifyWallet: build.mutation<
      IVerifyWalletResponse,
      ILazyVerifyWalletQueryArgs
    >({
      queryFn: queryFnNotifyWrapper<
        ILazyVerifyWalletQueryArgs,
        never,
        IVerifyWalletResponse
      >(async ({ wallet }) => {
        try {
          const providerManager = getProviderManager();
          const provider = await providerManager.getETHWriteProvider(wallet);

          const address = provider.currentAccount;
          const web3 = provider.getWeb3();
          const signedMessage = await web3.eth.personal.sign(
            MOCK_MESSAGE,
            address,
            "",
          );

          const response = await fetch(
            mirageIdUrl +
              GET_WALLET_VERIFICATION +
              "?" +
              new URLSearchParams({
                Message: MOCK_MESSAGE,
                Address: address,
                Signature: signedMessage,
              }),
            {
              method: "GET",
              credentials: "include",
            },
          );

          const data = await processApiResponse(response);

          return { data };
        } catch (err) {
          console.error("Error in `useVerifyWalletMutation.ts`", err);
          throw err;
        }
      }),
    }),
  }),
});
