import { makeStyles } from "tss-react/mui";

export const useSearchInputStyles = makeStyles()(theme => ({
  root: {
    width: 296,
    height: 40,
    color: theme.palette.grey[300],
    border: "none",
    backgroundColor: theme.palette.grey[700],
    borderRadius: 6,
    transition: "all 0.3s",

    "&:after, &:before": {
      display: "none",
    },

    "> input": {
      height: 24,
      color: theme.palette.common.white,
      padding: "4px 0 12px",

      [`&.MuiInputBase-input`]: {},

      [`&.MuiInputBase-disabled`]: {
        color: theme.palette.grey[900],
        WebkitTextFillColor: theme.palette.grey[900],
        opacity: 1,
      },
    },
  },
  searchIcon: {
    marginRight: theme.spacing(3),
  },
  default: {
    color: theme.palette.grey[300],
  },
  white: {
    color: theme.palette.common.white,
  },
  clearIcon: {
    color: theme.palette.error.main,
  },
}));
