import { configFromEnv, web3Api } from "modules/api";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { GET_POST_NFT_TOKEN_FILTERS } from "modules/api/urls";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { processApiResponse } from "modules/utils/processApiResponse";
import { ICollectibleTileData } from "modules/common/components/CollectibleTile";
import { transformCollectiblesData } from "modules/common/utils/transformCollectiblesData";
import { GAMES_PAGE_SIZE } from "../../../../common/const";
import { NetworkCode } from "../../../../common/types";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

type IRequestParams = NetworkCode | null;

interface IResponse {
  items: ICollectibleTileData[];
  pageNumber: number;
  totalPages: number;
  totalCount: number;
}

export const { useFetchCollectiblesByChainQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    fetchCollectiblesByChain: build.query<IResponse, IRequestParams>({
      queryFn: queryFnNotifyWrapper<IRequestParams, never, IResponse>(
        async (
          chainCode: IRequestParams,
        ): Promise<QueryReturnValue<IResponse, never, unknown>> => {
          const requestUrl = new URL(
            nftManagerUrl + GET_POST_NFT_TOKEN_FILTERS,
          );
          requestUrl.search = new URLSearchParams({
            PageNumber: "1",
            PageSize: GAMES_PAGE_SIZE.toString(),
          }).toString();

          if (chainCode !== null) {
            requestUrl.searchParams.append("ChainCode", chainCode);
          }

          try {
            const response = await fetch(requestUrl, {
              method: "GET",
              credentials: "include",
            });

            const data = await processApiResponse(response);

            return {
              data: {
                ...data,
                items: transformCollectiblesData(data.items),
              },
            };
          } catch (err) {
            console.error("Error in `useFetchCollectiblesByChain.ts`", err);
            throw err;
          }
        },
      ),
    }),
  }),
});
