// MOSAIC GAMING section
export const GET_PROJECTS_PLAZA_LIST_URL = "GameProjectPlaza/";

// NFT MANAGER API
// nft-manager section
export const GET_POST_COLLECTION = "NftCollection/";
export const GET_POST_NFT_TOKEN = "NftToken/";
export const GET_POST_NFT_LISTING = "NftListing";
export const GET_IS_WALLET_CONNECTED = "Wallet/isWalletConnected";
export const GET_POST_NFT_CONTRACT = "NftContract/";
export const GET_NFT_TEMPLATE = "NftTemplate/";
export const GET_POST_NFT_TOKEN_FILTERS = `${GET_POST_NFT_TOKEN}GetByFiltering`;
export const GET_NFT_TOKEN_BY_GAME_FILTERS = `${GET_POST_NFT_TOKEN}gameProject/`;
export const GET_INVENTORY = `${GET_POST_NFT_TOKEN}GetUserInventory`;

// external section
export const COINGECKO_API = "https://api.coingecko.com/api/";
export const GET_COINGECKO_ETH_DATA = `${COINGECKO_API}v3/coins/ethereum`;
export const GET_COINGECKO_BNB_DATA = `${COINGECKO_API}v3/coins/binancecoin`;
export const GET_COINGECKO_MATIC_DATA = `${COINGECKO_API}v3/coins/matic-network`;
export const RESERVOIR_ETH_API = "https://api.reservoir.tools";
export const RESERVOIR_SEPOLIA_API = "https://api-sepolia.reservoir.tools";
export const RESERVOIR_POLYGON_API = "https://api-polygon.reservoir.tools";
export const RESERVOIR_ARBITRUM_NOVA_API =
  "https://api-arbitrum-nova.reservoir.tools";

// mirage-id section
export const GET_WALLET_VERIFICATION = "Wallet/VerifySignature";
export const GET_ORGANIZATIONS = "OrganizationsAnonymous/";

export const SUPPORT_EMAIL = "info@mirage.xyz";
export const DOCS_LINK = "https://mirage.xyz/docs/";
