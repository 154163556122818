import { Box, Skeleton } from "@mui/material";
import { useGameTileSkeletonStyles } from "./useGameTileSkeletonStyles";

export const GameTileSkeleton = (): JSX.Element => {
  const { classes, cx } = useGameTileSkeletonStyles();

  return (
    <Box display="flex" flexDirection="column">
      <Skeleton className={classes.sliderRoot} />
      <Skeleton width={124} height={20} className={classes.small} />
      <Skeleton width={72} height={16} className={classes.small} />
      <Box display="flex" marginTop={0.5}>
        <Skeleton height={16} width={16} className={classes.small} />
        <Skeleton
          height={16}
          width={36}
          className={cx(classes.small, classes.leftMargin)}
        />
      </Box>
    </Box>
  );
};
