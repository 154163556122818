import { uid } from "react-uid";
import { Box, Typography } from "@mui/material";
import { t } from "modules/utils/intl";
import { ICoinBalanceItem } from "../../useBalanceData";
import { useCoinsListStyles } from "./useCoinsListStyles";
import { Item } from "./Item";

interface ICoinsListProps {
  coins: ICoinBalanceItem[];
}

export const CoinsList = ({ coins }: ICoinsListProps): JSX.Element => {
  const { classes } = useCoinsListStyles();

  return (
    <Box mb={15}>
      <div className={classes.header}>
        <Typography variant="caption">{t("account.balance.coin")}</Typography>
        <Typography variant="caption">
          {t("account.balance.coin-value")}
        </Typography>
        <Typography variant="caption">{t("account.balance.action")}</Typography>
      </div>
      <div>
        {coins.map(coin => (
          <Item key={uid(coin)} {...coin} />
        ))}
      </div>
    </Box>
  );
};
