import { SvgIcon } from "@mui/material"
import { ReactNode } from "react"

interface ICustomSvgIconProps {
  width?: number | string
  height?: number | string
  style?: React.CSSProperties
  children: ReactNode
  viewBox?: string
  fill?: string
}

export const CustomSvgIcon = ({width, height, viewBox, fill, style, children}: ICustomSvgIconProps) => (
  <SvgIcon width={width} height={height} viewBox={viewBox} fill={fill} style={style}>
    {children}
  </SvgIcon>
)
