import {
  ARBITRUM_NOVA_MAINNET_CHAIN_ID,
  BSC_MAINNET_CHAIN_ID,
  BSC_TESTNET_CHAIN_ID,
  ETH_MAINNET_CHAIN_ID,
  GOERLI_CHAIN_ID,
  POLYGON_MAINNET_CHAIN_ID,
  SEPOLIA_CHAIN_ID,
} from "../const";

export function getScanNameByNetwork(chainId: number): string {
  switch (chainId) {
    case ETH_MAINNET_CHAIN_ID:
    case GOERLI_CHAIN_ID:
    case SEPOLIA_CHAIN_ID:
    default:
      return "Etherscan";
    case BSC_MAINNET_CHAIN_ID:
    case BSC_TESTNET_CHAIN_ID:
      return "BscScan";
    case POLYGON_MAINNET_CHAIN_ID:
      return "PolygonScan";
    case ARBITRUM_NOVA_MAINNET_CHAIN_ID:
      return "ArbiScan";
  }
}
