import { makeStyles } from "tss-react/mui";

export const useCollectionFilterStyles = makeStyles<{
  viewMode: string;
  areFiltersOpen: boolean;
}>()((theme, { viewMode, areFiltersOpen }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(8, 0),
  },
  btnFilters: {
    transition: "all 0.3s ease",
    height: 40,
    width: 40,
    backgroundColor: areFiltersOpen
      ? theme.palette.yellow.main
      : theme.palette.grey[700],

    "& svg path": {
      fill: areFiltersOpen ? "black" : "white",
    },

    "&:hover": {
      backgroundColor: theme.palette.yellow.main,

      "& svg path": {
        fill: "black",
      },
    },
  },
  btnToggle: {
    transition: "all 0.3s ease",
    height: 40,
    width: 40,
    "&:hover": {
      svg: {
        color: "black",
      },
    },
  },
  gridIcon: {
    pointerEvents: "none",
    color:
      viewMode === "grid"
        ? theme.palette.grey[300]
        : theme.palette.background.paper,
  },
  listIcon: {
    pointerEvents: "none",
    color:
      viewMode === "list"
        ? theme.palette.grey[300]
        : theme.palette.background.paper,
  },
}));
