import { Box, Stack, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { Network } from "modules/common/types";
import { RoutesConfig } from "modules/plaza/Routes";
import { t } from "modules/utils/intl";
import { NavLink } from "react-router-dom";
import { IconBadge, SimpleNetworkIcon } from "uiKit/components/Icon";
import { ReactComponent as DividerIcon } from "uiKit/icons/dot-divider.svg";
import { useCollectionTileStyles } from "./useCollectionTileStyles";
import { featuresConfig } from "modules/common/featuresConfig";

interface ICollectionTileProps {
  id: string;
  name: string;
  projectName: string | null;
  network?: Network;
  nftItemsNumber: number;
  capitalization?: BigNumber;
  image: string;
}

export const CollectionTile = ({
  id,
  name,
  projectName,
  network,
  nftItemsNumber,
  capitalization,
  image,
}: ICollectionTileProps): JSX.Element => {
  const { classes } = useCollectionTileStyles({ imageUrl: image });

  return (
    <Box className={classes.root}>
      <Box className={classes.bg} />
      <NavLink
        className={classes.content}
        to={RoutesConfig.collection.generatePath(id)}
      >
        <Box className={classes.contentWrapper}>
          {network && (
            <Box
              display="flex"
              width={1}
              justifyContent="flex-end"
              className={classes.icon}
            >
              {
                <IconBadge
                  iconSlot={
                    <SimpleNetworkIcon height={12} width={12} name={network} />
                  }
                />
              }
            </Box>
          )}
          <Box>
            <Typography variant="h3Demibold" className={classes.text}>
              {name}
            </Typography>
            <Stack
              gap={2}
              direction="row"
              alignItems="center"
              divider={<DividerIcon />}
              className={classes.text}
            >
              {projectName && (
                <Typography variant="body2" className={classes.projectName}>
                  {projectName}
                </Typography>
              )}
              {featuresConfig.isCollectibleCounterActive && (
                <Typography variant="body2" whiteSpace="nowrap">
                  {t("plaza.collections.items.items", {
                    value: nftItemsNumber,
                  })}
                </Typography>
              )}
            </Stack>
          </Box>
        </Box>
      </NavLink>
    </Box>
  );
};
