import { Box, Button, Typography } from "@mui/material";
import { t, tHTML } from "modules/utils/intl";
import { ReactComponent as CanceledIcon } from "uiKit/icons/cancelled.svg";
import { usePurchasedCancelledDialogStyles } from "./usePurchasedCancelledDialogStyles";

interface IPurchasedCancelledDialogProps {
  name: string;
  tokenId: number;
  onClose: () => void;
}

export const PurchasedCancelledDialog = ({
  name,
  tokenId,
  onClose,
}: IPurchasedCancelledDialogProps): JSX.Element => {
  const { classes } = usePurchasedCancelledDialogStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width={1}>
      <CanceledIcon className={classes.icon} />
      <Typography variant="h3Demibold" mb={1}>
        {t("plaza.collectible.purchase-cancelled.title")}
      </Typography>
      <Typography variant="body2" color="textSecondary" mb={8}>
        {tHTML("plaza.collectible.purchase-cancelled.description", {
          name,
          tokenId,
        })}
      </Typography>

      <Button
        fullWidth
        variant="outlined"
        className={classes.button}
        onClick={onClose}
      >
        <Typography variant="body1Demibold" mr={1.5}>
          {t("plaza.collectible.purchase-cancelled.button")}
        </Typography>
      </Button>
    </Box>
  );
};
