import { makeStyles } from "tss-react/mui";

export const useAccountButtonStyles = makeStyles()((theme) => ({
  root: {
    height: 40,
    width: 190,
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[500]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userLogo: {
    height: 24,
    width: 24,
    borderRadius: "50%",
    marginRight: theme.spacing(2),
  },
  userName: {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    whiteSpace: "pre-wrap",
    maxWidth: 120,
    marginRight: theme.spacing(1),
  },
  openedChevron: {
    transform: "rotate(180deg)",
  },
}));
