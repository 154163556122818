import { makeStyles } from "tss-react/mui";
import { ViewType } from "modules/common/types";

export const useGamesListStyles = makeStyles()((theme) => ({
  [ViewType.GRID]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    gap: 6,
  },
  [ViewType.LIST]: {
    display: "flex",
    gap: theme.spacing(5),
  },
}));
