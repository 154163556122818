import { LoaderCentered } from "modules/common/components/LoaderCentered";
import { AdditionalInfo } from "./components/AdditionalInfo";
import { BigImage } from "./components/BigImage";
import { Info } from "./components/Info";
import { useCollectibleData } from "./useCollectibleData";
import { useCollectibleStyles } from "./useCollectibleStyles";
import { Box } from "@mui/material";
import { EditListingDialog } from "./components/EditListingDialog";
import { BuyDialog } from "./components/BuyDialog";
import { Dialog } from "modules/common/components/Dialog";
import { t } from "modules/utils/intl";
import { BuyInProgressDialog } from "./components/BuyInProgressDialog";
import { PurchasedCancelledDialog } from "./components/PurchasedCancelledDialog";

export const Collectible = (): JSX.Element => {
  const { classes } = useCollectibleStyles();

  const {
    collection,
    name,
    game,
    tokenStandart,
    createdBy,
    price,
    usdPrice,
    token,
    network,
    description,
    attributes,
    image,
    orderId,
    listingStatus,
    orderStatus,
    contractAddress,
    isLoading,
    isMyCollectible,
    isBuyLoading,
    isSellLoading,
    isOpenEditing,
    handleClickOpenEdit,
    handleClickCloseEdit,
    handleClickSaveEdit,
    handleClickStopSale,
    isOpenBuyDialog,
    collectibleForPurchase,
    buyTxHash,
    isOpenBuyInProgressDialog,
    chainId,
    tokenId,
    royalty,
    isOpenCancelledDialog,
    organizationName,
    organizationLogoUri,
    isAuthorized,
    isStopSaleLoading,
    handleCloseBuyInProgressDialog,
    handleCloseBuyDialog,
    handleClickBuyCollectible,
    handleClickCheckoutCollectible,
    handleSellCollectible,
    handleCloseCancelledDialog,
    handleClickLogin,
  } = useCollectibleData();

  if (isLoading) return <LoaderCentered />;

  return (
    <>
      <Box display="flex" flexDirection="column" mb={25} width={1}>
        <Box display="flex" gap={5} mb={10}>
          <div className={classes.mainSection}>
            <BigImage imageSrc={image} isMyCollectible={isMyCollectible} />
          </div>
          <div className={classes.mainSection}>
            <Info
              collection={collection}
              name={name}
              game={game}
              tokenStandart={tokenStandart}
              createdBy={createdBy}
              price={price}
              usdPrice={usdPrice}
              token={token}
              network={network}
              listingStatus={listingStatus}
              orderStatus={orderStatus}
              orderId={orderId}
              organizationName={organizationName}
              organizationLogoUri={organizationLogoUri}
              isMyCollectible={isMyCollectible}
              isBuyLoading={isBuyLoading}
              isSellLoading={isSellLoading}
              isAuthorized={isAuthorized}
              isStopSaleLoading={isStopSaleLoading}
              handleClickLogin={handleClickLogin}
              handleClickOpenEdit={handleClickOpenEdit}
              handleClickStopSale={handleClickStopSale}
              handleClickBuyCollectible={handleClickBuyCollectible}
              handleSellCollectible={handleSellCollectible}
            />
          </div>
        </Box>
        <AdditionalInfo
          description={description}
          attributes={attributes}
          details={{
            contractAddress: contractAddress,
            tokenId: tokenId,
            network,
            tokenStandart,
            royalty,
          }}
        />
      </Box>

      <Dialog
        size="big"
        title={t("plaza.collectible.edit-dialog.title")}
        open={isOpenEditing}
        onClose={handleClickCloseEdit}
      >
        <EditListingDialog
          initPrice={price}
          token={token}
          handleSaveChanges={handleClickSaveEdit}
        />
      </Dialog>
      {!!collectibleForPurchase && (
        <Dialog
          size="big"
          title={t("plaza.collectible.buy-dialog.title")}
          open={isOpenBuyDialog}
          onClose={handleCloseBuyDialog}
        >
          <BuyDialog
            image={collectibleForPurchase.image}
            name={collectibleForPurchase.name}
            tokenId={collectibleForPurchase.tokenId}
            fromAddress={collectibleForPurchase.fromAddress}
            tokenStandart={collectibleForPurchase.tokenStandart}
            price={collectibleForPurchase.price}
            usdPrice={collectibleForPurchase.usdPrice}
            gasFee={collectibleForPurchase.gasFee}
            usdGasFee={collectibleForPurchase.usdGasFee}
            network={collectibleForPurchase.network}
            isLoading={isBuyLoading}
            handleClickCheckout={handleClickCheckoutCollectible}
          />
        </Dialog>
      )}

      {!!collectibleForPurchase && !!buyTxHash && (
        <Dialog
          size="big"
          open={isOpenBuyInProgressDialog}
          onClose={handleCloseBuyInProgressDialog}
        >
          <BuyInProgressDialog
            image={collectibleForPurchase.image}
            name={collectibleForPurchase.name}
            tokenId={collectibleForPurchase.tokenId}
            chainId={chainId}
            txHash={buyTxHash}
          />
        </Dialog>
      )}

      {!!collectibleForPurchase && (
        <Dialog
          size="big"
          open={isOpenCancelledDialog}
          onClose={handleCloseCancelledDialog}
        >
          <PurchasedCancelledDialog
            name={collectibleForPurchase.name}
            tokenId={collectibleForPurchase.tokenId}
            onClose={handleCloseCancelledDialog}
          />
        </Dialog>
      )}
    </>
  );
};
