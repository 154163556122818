import { configFromEnv, web3Api } from "modules/api";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { GET_PROJECTS_PLAZA_LIST_URL } from "modules/api/urls";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { processApiResponse } from "modules/utils/processApiResponse";
import { IGamesListItem } from "../../../types";
import { EPlatformType, GAMES_PAGE_SIZE } from "../../../../common/const";
import { NetworkCode } from "../../../../common/types";

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

interface IRequestParams {
  chainCode?: NetworkCode | null;
  platforms?: EPlatformType[] | null;
  developer?: string[] | null;
  genre?: string[] | null;
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}

interface IResponse {
  items: IGamesListItem[];
  pageNumber: number;
  totalPages: number;
  totalCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export const { useFetchGameProjectsQuery, useLazyFetchGameProjectsQuery } =
  web3Api.injectEndpoints({
    endpoints: (build) => ({
      fetchGameProjects: build.query<IResponse, IRequestParams>({
        queryFn: queryFnNotifyWrapper<IRequestParams, never, IResponse>(
          async ({
            chainCode,
            platforms,
            developer,
            genre,
            query,
            pageNumber,
            pageSize = GAMES_PAGE_SIZE,
          }): Promise<QueryReturnValue<IResponse, never, unknown>> => {
            const requestUrl = new URL(gamingUrl + GET_PROJECTS_PLAZA_LIST_URL);

            if (chainCode) {
              requestUrl.searchParams.append("ChainCode", chainCode);
            }

            if (platforms) {
              for (let i: EPlatformType = 0; i < platforms?.length; i++) {
                requestUrl.searchParams.append(
                  "Platforms",
                  platforms[i].toString(),
                );
              }
            }

            if (developer) {
              for (let i = 0; i < developer?.length; i++) {
                const developerId = developer[i].split("::")[0];
                requestUrl.searchParams.append("Developer", developerId);
              }
            }

            if (genre) {
              for (let i = 0; i < genre?.length; i++) {
                const gameProjectType = genre[i].split("::")[0];
                requestUrl.searchParams.append(
                  "GameProjectTypes",
                  gameProjectType,
                );
              }
            }

            if (query) {
              requestUrl.searchParams.append("Name", query);
            }

            if (pageNumber) {
              requestUrl.searchParams.append(
                "PageNumber",
                pageNumber.toString(),
              );
            }

            if (pageSize) {
              requestUrl.searchParams.append("PageSize", pageSize.toString());
            }

            try {
              const response = await fetch(requestUrl, {
                method: "GET",
                credentials: "include",
              });

              const data = await processApiResponse(response);

              return {
                data,
              };
            } catch (err) {
              console.error("Error in `useFetchGameProjectsQuery.ts`", err);
              throw err;
            }
          },
        ),
      }),
    }),
  });
