import { EEthereumNetworkId, EWalletId } from "@ankr.com/provider";
import { useSwitchNetworkMutation } from "modules/common/actions/useSwitchNetworkMutation";
import { useCallback } from "react";
import { useConnectedData } from 'modules/common/hooks/useConnectedData'

interface INetworkGuardData {
  isLoading: boolean;
  handleSwitchNetwork: (network: EEthereumNetworkId) => Promise<void>;
}

export const useNetworkGuardData = (): INetworkGuardData => {
  const { walletId } = useConnectedData();
  const [switchNetwork, { isLoading }] = useSwitchNetworkMutation();

  const handleSwitchNetwork = useCallback(
    async (network: EEthereumNetworkId) => {
      await switchNetwork({ wallet: walletId as EWalletId || EWalletId.injected, chainId: network });
    },
    [switchNetwork, walletId]
  );

  return {
    isLoading,
    handleSwitchNetwork,
  };
};
