import { Box } from "@mui/material";
import { ReactNode } from "react";

interface IContentFullWrapperProps {
  children: ReactNode;
}

export const ContentFullWrapper = ({
  children,
}: IContentFullWrapperProps): JSX.Element => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="calc(100vh - 422px)"
      width={1}
    >
      {children}
    </Box>
  );
};
