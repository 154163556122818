import { EEthereumNetworkId } from "@ankr.com/provider";
import BigNumber from "bignumber.js";
import { getUniqueId } from "modules/utils/getUniqueId";
import { t } from "modules/utils/intl";

export const UNAUTHORIZED_ERROR = new Error("Unauthorized request");
export const COMMON_ERROR = new Error("Something went wrong...");

export type Milliseconds = number;

export const GAME_TILE_WIDHT = 244;
export const COLLECTION_TILE_WIDHT = 508;
export const ITEM_TILE_WIDHT = 244;

export const SIDEBAR_WIDTH = 300;
export const PAGE_CONTENT_WIDTH = window.innerWidth - SIDEBAR_WIDTH - 100;
export const GAMES_PAGE_SIZE =
  Math.floor(PAGE_CONTENT_WIDTH / (GAME_TILE_WIDHT + 20)) * 2;
export const COLLECTIBLES_PAGE_SIZE =
  Math.floor(PAGE_CONTENT_WIDTH / (ITEM_TILE_WIDHT + 20)) * 2;
export const COLLECTIONS_PAGE_SIZE =
  Math.floor(PAGE_CONTENT_WIDTH / (COLLECTION_TILE_WIDHT + 20)) * 2;

export const HEADER_HEIGHT = 122;

export const SEARCH_DEBOUNCE_TIME: Milliseconds = 300;

export const ACTION_CACHE_SEC = 600;

export const DEFAULT_DATE_FORMAT = "dd.MM.yyyy, hh:mma";

export const ZERO = new BigNumber(0);

export const NO_FILTER_SPECIFIED = "All";

export const PAGE_SIZE = 10;

export const USD_DECIMALS = 2;

export enum EProjectType {
  Games,
  Action,
  Adventure,
  Board,
  Card,
  Family,
  Music,
  Puzzle,
  Role,
  Racing,
  Simulation,
  Sports,
  Strategy,
  SocialNetworking,
  NonGaming,
  Metaverse,
  Casual,
  Education,
  Kids,
  Trivia,
  Word,
  Arcade,
  FPS,
  Moba,
  MMO,
}

export const projectTypesMap: Record<EProjectType, string> = {
  [EProjectType.Action]: "Action",
  [EProjectType.Adventure]: "Adventure",
  [EProjectType.Arcade]: "Arcade",
  [EProjectType.Board]: "Board",
  [EProjectType.Card]: "Card",
  [EProjectType.Casual]: "Casual",
  [EProjectType.Education]: "Education",
  [EProjectType.Family]: "Family",
  [EProjectType.FPS]: "FPS",
  [EProjectType.Games]: "Games",
  [EProjectType.Kids]: "Kids",
  [EProjectType.Metaverse]: "Metaverse",
  [EProjectType.MMO]: "MMO",
  [EProjectType.Moba]: "MOBA",
  [EProjectType.Music]: "Music",
  [EProjectType.NonGaming]: "Non-gaming",
  [EProjectType.Puzzle]: "Puzzle",
  [EProjectType.Racing]: "Racing",
  [EProjectType.Role]: "Role",
  [EProjectType.Simulation]: "Simulation",
  [EProjectType.SocialNetworking]: "Social Networking",
  [EProjectType.Sports]: "Sports",
  [EProjectType.Strategy]: "Strategy",
  [EProjectType.Trivia]: "Trivia",
  [EProjectType.Word]: "Word",
};

export enum EPlatformType {
  PC,
  Mac,
  Linux,
  IOS,
  Android,
  Web,
}

export const platformTypesMap: Record<EPlatformType, string> = {
  [EPlatformType.PC]: t("pc"),
  [EPlatformType.Mac]: t("mac"),
  [EPlatformType.Linux]: t("linux"),
  [EPlatformType.IOS]: t("ios"),
  [EPlatformType.Android]: t("android"),
  [EPlatformType.Web]: t("web"),
};

export enum SupportedChainIDS {
  MAINNET = EEthereumNetworkId.mainnet,
  GOERLI = EEthereumNetworkId.goerli,
  SEPOLIA = EEthereumNetworkId.sepolia,
  BSC = EEthereumNetworkId.smartchain,
  BSC_TESTNET = EEthereumNetworkId.smartchainTestnet,
  POLYGON = EEthereumNetworkId.polygon,
  POLYGON_MUMBAI_TESTNET = EEthereumNetworkId.mumbai,
  ARBITRUM_NOVA = EEthereumNetworkId.arbitrumNova,
}

export const EXPLORER_URLS: Record<SupportedChainIDS, string> = {
  [SupportedChainIDS.MAINNET]: "https://etherscan.io",
  [SupportedChainIDS.GOERLI]: "https://goerli.etherscan.io",
  [SupportedChainIDS.SEPOLIA]: "https://sepolia.etherscan.io",
  [SupportedChainIDS.BSC]: "https://bscscan.com",
  [SupportedChainIDS.BSC_TESTNET]: "https://testnet.bscscan.com",
  [SupportedChainIDS.POLYGON]: "https://polygonscan.com",
  [SupportedChainIDS.POLYGON_MUMBAI_TESTNET]: "https://mumbai.polygonscan.com",
  [SupportedChainIDS.ARBITRUM_NOVA]: "https://nova.arbiscan.io",
};

export const RESERVOIR_API_KEY = "062cafa7-d7ec-56b8-8905-d85b95193ea7";

export const REJECTED_ERROR_MESSAGE = "rejected";
export const COMMON_ERROR_MESSAGE = "common";

export const CommonDataCacheTags = {
  balanceData: getUniqueId(),
};

export const DOWNLOAD_METAMASK = "https://metamask.io/download/";
export const DOWNLOAD_MIRAGE =
  "https://chrome.google.com/webstore/detail/mrg-wallet/hdbmjkeggmnegmdcojfpdfmjplmojlkg";
export const DOWNLOAD_COINBASE =
  "https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad";

export const ETH_MAINNET_CHAIN_ID = 1;
export const GOERLI_CHAIN_ID = 5;
export const SEPOLIA_CHAIN_ID = 11155111;
export const BSC_MAINNET_CHAIN_ID = 56;
export const BSC_TESTNET_CHAIN_ID = 97;
export const POLYGON_MAINNET_CHAIN_ID = 137;
export const ARBITRUM_NOVA_MAINNET_CHAIN_ID = 42170;
