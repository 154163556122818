import { Controller, useForm } from "react-hook-form";
import { ReactComponent as PencilIcon } from "uiKit/icons/pencil.svg";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  InputLabel,
} from "@mui/material";
import { t } from "modules/utils/intl";
import { mainPalette } from "uiKit/mainPalette";
import { useInfoFormStyles } from "./useInfoFormStyles";
import { useCallback, useState } from "react";

interface IInfoFormData {
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
}

const inputStyles = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: mainPalette.text.primary,
  },
};

export const InfoForm = ({
  userName,
  email,
  firstName,
  lastName,
}: IInfoFormData): JSX.Element => {
  const { classes } = useInfoFormStyles();

  const { control } = useForm<IInfoFormData>({
    defaultValues: {
      userName: userName,
      email: email,
      firstName: firstName,
      lastName: lastName,
    },
  });

  const [isEnabledUsernameChange, setIsEnabledUsernameChange] = useState(false);
  const [isEnabledFirstNameChange, setIsEnabledFirstNameChange] =
    useState(false);
  const [isEnabledLastNameChange, setIsEnabledLastNameChange] = useState(false);

  const handleChangeUsername = useCallback((newUserName: string) => {}, []);
  const handeSetToEnabledUsernameChange = useCallback(
    () => setIsEnabledUsernameChange(true),
    []
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" mb={8}>
        <Controller
          name="userName"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => {
            return (
              <>
                <FormControl>
                  <InputLabel>{t("account.info.username")}</InputLabel>
                  <Input
                    {...field}
                    fullWidth
                    disabled={!isEnabledUsernameChange}
                    className={classes.input}
                    sx={inputStyles}
                    error={!!fieldState.error}
                    endAdornment={
                      !isEnabledUsernameChange && (
                        <IconButton onClick={handeSetToEnabledUsernameChange}>
                          <PencilIcon className={classes.icon} />
                        </IconButton>
                      )
                    }
                    onChange={field.onChange}
                  />
                </FormControl>
                {field.value !== userName && (
                  <Button onClick={e => handleChangeUsername(field.value)}>
                    change
                  </Button>
                )}
              </>
            );
          }}
        />
      </Box>

      <Box display="flex" alignItems="center" mb={8}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl>
                <InputLabel>{t("account.info.email")}</InputLabel>
                <Input
                  {...field}
                  disabled
                  fullWidth
                  className={classes.input}
                  sx={inputStyles}
                  error={!!fieldState.error}
                  onChange={field.onChange}
                />
              </FormControl>
            );
          }}
        />
      </Box>

      <Box display="flex" alignItems="center" mb={8}>
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl>
                <InputLabel>{t("account.info.firstName")}</InputLabel>
                <Input
                  {...field}
                  fullWidth
                  disabled={!isEnabledFirstNameChange}
                  className={classes.input}
                  sx={inputStyles}
                  error={!!fieldState.error}
                  endAdornment={
                    !isEnabledUsernameChange && (
                      <IconButton
                        onClick={() => setIsEnabledFirstNameChange(true)}
                      >
                        <PencilIcon className={classes.icon} />
                      </IconButton>
                    )
                  }
                  onChange={field.onChange}
                />
              </FormControl>
            );
          }}
        />
      </Box>

      <Box display="flex" alignItems="center" mb={8}>
        <Controller
          name="lastName"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl>
                <InputLabel>{t("account.info.lastName")}</InputLabel>
                <Input
                  {...field}
                  fullWidth
                  disabled={!isEnabledLastNameChange}
                  className={classes.input}
                  sx={inputStyles}
                  error={!!fieldState.error}
                  endAdornment={
                    !isEnabledUsernameChange && (
                      <IconButton
                        onClick={() => setIsEnabledLastNameChange(true)}
                      >
                        <PencilIcon className={classes.icon} />
                      </IconButton>
                    )
                  }
                  onChange={field.onChange}
                />
              </FormControl>
            );
          }}
        />
      </Box>
    </Box>
  );
};
