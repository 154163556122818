import { useCallback, useState, useRef, memo } from "react";
import { Box, Link, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { IHero } from "./types";
import { Button } from "uiKit/components/Button";
import { GameDescriptionModal } from "./components/GameDescriptionModal/GameDescriptionModal";
import { useHeroStyles } from "./useHeroStyles";
import {
  Screenshots,
  Share,
  Trailer,
  SocialMedia,
  Platform,
} from "uiKit/icons";
import { GameLinks } from "./components/GameLinks/GameLinks";
import { TLightboxContent } from "../../types";
import { t } from "modules/utils/intl";
import { Lightbox } from "../Lightbox";
import { SimpleNetworkIcon } from "uiKit/components/Icon";

export const Hero = memo(
  ({
    gameName,
    bannerUrl = "",
    logoUrl,
    chains,
    platforms,
    tags,
    screenshots,
    videos,
    shortDescription,
    gameDeveloperDescription,
    fullDescription,
    gameProjectLinks: { socialLinks, platformLinks },
  }: IHero) => {
    const { classes } = useHeroStyles({ bannerUrl });
    const [extendedDescriptionOpen, setExtendedDescriptionOpen] =
      useState(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxContentType, setLightboxContentType] =
      useState<TLightboxContent>("image");
    const [showPlayNowContent, setShowPlayNowContent] = useState(false);
    const [showShareContent, setShowShareContent] = useState(false);
    let timerRef = useRef<number | null>(null);
    const shouldShowGameDeveloperBlock =
      !isEmpty(gameDeveloperDescription) || !isEmpty(socialLinks);

    const handleOpenDescription = useCallback(
      () => setExtendedDescriptionOpen(true),
      [],
    );
    const handleCloseDescription = useCallback(
      () => setExtendedDescriptionOpen(false),
      [],
    );
    const handleOpenLightboxWithVideo = useCallback(() => {
      setLightboxContentType("video");
      setLightboxOpen(true);
    }, []);

    const handleOpenLightboxWithImages = useCallback(() => {
      setLightboxContentType("image");
      setLightboxOpen(true);
    }, []);

    const handleCloseLightbox = useCallback(() => setLightboxOpen(false), []);

    const handlePlayMouseEnter = useCallback(() => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
      setShowShareContent(false);
      setShowPlayNowContent(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePlayMouseLeave = useCallback(() => {
      timerRef.current = window.setTimeout(() => {
        setShowPlayNowContent(false);
      }, 150);
    }, []);

    const handleShareMouseEnter = useCallback(() => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
      setShowPlayNowContent(false);
      setShowShareContent(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleShareMouseLeave = useCallback(() => {
      timerRef.current = window.setTimeout(() => {
        setShowShareContent(false);
      }, 150);
    }, []);

    return (
      <>
        <Box className={classes.root}>
          <Box className={classes.banner} />
          <Box className={classes.content}>
            <Box className={classes.gameInfo}>
              <Box className={classes.badges}>
                {!!chains?.length &&
                  chains?.map((chain) => (
                    <Link
                      className={classes.chainBadge}
                      component="button"
                      key={chain}
                    >
                      <SimpleNetworkIcon name={chain} />
                    </Link>
                  ))}
                {platforms.map((platform) => (
                  <Link
                    className={classes.chainBadge}
                    component="button"
                    key={platform}
                  >
                    <Platform platform={platform} />
                  </Link>
                ))}
                {!!tags.length &&
                  tags.slice(0, 3).map((tag) => (
                    <Typography
                      component="span"
                      key={tag}
                      className={classes.tag}
                    >
                      {tag}
                    </Typography>
                  ))}
              </Box>
              <Box className={classes.brand}>
                <Box className={classes.logo}>
                  <img width="52px" src={logoUrl} alt="game logo" />
                </Box>
                <Typography className={classes.heading} variant="h1">
                  {gameName}
                </Typography>
              </Box>
              <Box className={classes.assets}>
                {!!videos?.length && (
                  <Button
                    className={classes.btnAsset}
                    startIcon={<Trailer />}
                    onClick={handleOpenLightboxWithVideo}
                  >
                    {t("plaza.games.trailers")}
                  </Button>
                )}
                {!!screenshots?.length && (
                  <Button
                    className={classes.btnAsset}
                    startIcon={<Screenshots />}
                    onClick={handleOpenLightboxWithImages}
                  >
                    {t("plaza.games.screenshots")} ({screenshots.length})
                  </Button>
                )}
              </Box>
              <Box className={classes.descriptionWrapper}>
                <Typography className={classes.descriptionText}>
                  {shortDescription.length > 200
                    ? shortDescription.substring(0, 200) + "..."
                    : shortDescription}
                  <Typography
                    className={classes.descriptionLearnMore}
                    component="span"
                    onClick={handleOpenDescription}
                  >
                    {t("plaza.games.actions.learn-more")}
                  </Typography>
                </Typography>
              </Box>
              <Box display="flex">
                {!isEmpty(platformLinks) && (
                  <Box
                    position="relative"
                    onMouseEnter={handlePlayMouseEnter}
                    onMouseLeave={handlePlayMouseLeave}
                  >
                    <Button className={classes.btnPlay}>Play now</Button>
                    {showPlayNowContent && !isEmpty(platformLinks) && (
                      <GameLinks links={platformLinks} metaType="play" />
                    )}
                  </Box>
                )}
                <Box
                  position="relative"
                  onMouseEnter={handleShareMouseEnter}
                  onMouseLeave={handleShareMouseLeave}
                >
                  <Button className={classes.btnShare} variant="outlined">
                    {t("plaza.games.actions.share")} <Share />
                  </Button>
                  {showShareContent && (
                    <GameLinks
                      links={{
                        twitter: "https://twitter.com",
                        facebook: "https://www.facebook.com/",
                      }}
                      metaType="share"
                    />
                  )}
                </Box>
              </Box>
            </Box>

            {shouldShowGameDeveloperBlock && (
              <Box className={classes.developerInfo}>
                <Box className={classes.developerInfoWrapper}>
                  <Typography
                    className={classes.developerName}
                    component={"h5"}
                    variant="body1Demibold"
                  >
                    {t("plaza.games.about", { value: gameName })}
                  </Typography>
                  <Typography
                    className={classes.developerDescription}
                    variant="notes"
                  >
                    {gameDeveloperDescription}
                  </Typography>
                  <Box className={classes.socialMediaWrapper}>
                    {!isEmpty(socialLinks) &&
                      Object.entries(socialLinks).map(([key, value]) => (
                        <Link
                          className={classes.btnSocialMedia}
                          key={key}
                          href={value}
                          target="_blank"
                        >
                          <SocialMedia socialMedia={key} />
                        </Link>
                      ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <GameDescriptionModal
          gameData={{ gameName, fullDescription, logoUrl }}
          open={extendedDescriptionOpen}
          onClose={handleCloseDescription}
          aria-labelledby="Game extended description"
        />
        {(!!screenshots?.length || !!videos?.length) && (
          <Lightbox
            mediaData={lightboxContentType === "image" ? screenshots! : videos!}
            contentType={lightboxContentType}
            open={lightboxOpen}
            handleClose={handleCloseLightbox}
          />
        )}
      </>
    );
  },
);
