import { makeStyles } from "tss-react/esm/mui";
import {
  accordionClasses,
  accordionDetailsClasses,
  accordionSummaryClasses,
  radioClasses,
  svgIconClasses,
} from "@mui/material";

export const useFiltersStyles = makeStyles<{
  activeFilter: string;
  areFiltersOpen: boolean;
}>()((theme, { activeFilter, areFiltersOpen }) => ({
  root: {
    overflow: "hidden",
    transition: "width 0.3s ease",
    position: "relative",
    height: "100%",
    width: areFiltersOpen ? 250 : 0,
    marginRight: areFiltersOpen ? theme.spacing(5) : theme.spacing(0),
  },
  wrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    transition: "opacity 0.3s ease",
    opacity: areFiltersOpen ? 1 : 0,
  },
  heading: {
    marginBottom: theme.spacing(5),
  },
  accordion: {
    [`&.${accordionClasses.root}`]: {
      border: "none",
    },
  },
  accordionSummary: {
    margin: theme.spacing(3.25, 0),
    [`&.${accordionSummaryClasses.root}`]: {
      margin: 0,
      borderBottom: "none",
    },
    [`&.${accordionSummaryClasses.content}`]: {
      margin: theme.spacing(3.25, 0),
    },
  },
  accordionDetails: {
    [`&.${accordionDetailsClasses.root}`]: {
      margin: 0,
    },
  },
  formControlLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: 0,
    marginRight: 0,
  },
  radioGroup: {
    gap: "12px 0",
  },
  radio: {
    padding: 0,
    color: theme.palette.yellow.main,
    marginRight: theme.spacing(2),

    [`&.${radioClasses.checked}`]: {
      color: theme.palette.yellow.main,
    },

    [`&.${accordionClasses.expanded}`]: {
      color: theme.palette.yellow.main,
    },

    [`& .${svgIconClasses.root}`]: {
      width: 20,
      height: 20,
    },
  },
}));
