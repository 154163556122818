import { Box, Divider, Stack, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import {
  EListingStatus,
  EOrderListingStatus,
  NetworkCode,
  Token,
} from "modules/common/types";
import { t } from "modules/utils/intl";
import { SimpleNetworkIcon } from "uiKit/components/Icon/SimpleNetworkIcon";
import { useInfoStyles } from "./useInfoStyles";
import { Badge } from "uiKit/components/Badge";
import { Button } from "uiKit/components/Button";
import { IconBadge } from "uiKit/components/Icon";
import { StatusBadge } from "uiKit/components/StatusBadge/StatusBadge";

export interface IInfoProps {
  collection: string;
  name: string;
  game: string;
  tokenStandart: string;
  createdBy: string;
  price: BigNumber;
  usdPrice: BigNumber;
  token: Token;
  listingStatus: EListingStatus;
  network: NetworkCode;
  // TODO: remove it and replace with listingStatus
  orderStatus?: EOrderListingStatus;
  orderId?: string | null;
  organizationName?: string;
  organizationLogoUri?: string;
  isMyCollectible: boolean;
  isBuyLoading: boolean;
  isSellLoading: boolean;
  isStopSaleLoading: boolean;
  isAuthorized: boolean;
  handleClickLogin: () => void;
  handleClickOpenEdit: () => void;
  handleClickStopSale: () => void;
  handleClickBuyCollectible: () => void;
  handleSellCollectible: () => void;
}

export const Info = ({
  collection,
  name,
  tokenStandart,
  // game,
  // createdBy,
  price,
  usdPrice,
  token,
  listingStatus,
  network,
  orderStatus,
  orderId,
  organizationLogoUri,
  organizationName,
  isMyCollectible,
  isBuyLoading,
  isSellLoading,
  isAuthorized,
  isStopSaleLoading,
  handleClickOpenEdit,
  handleClickLogin,
  handleClickStopSale,
  handleClickBuyCollectible,
  handleSellCollectible,
}: IInfoProps): JSX.Element => {
  const { classes } = useInfoStyles();

  return (
    <div>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1Demibold" className={classes.collection}>
          {collection}
        </Typography>
        <Box
          gap={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconBadge
            small
            darkBg
            className={classes.networkWrapper}
            iconSlot={
              <SimpleNetworkIcon height={15} width={15} name={network} />
            }
          />
          <Badge text={tokenStandart} />
          <StatusBadge lightBg type={listingStatus} />
        </Box>
      </Box>
      <Typography variant="h2" mb={3}>
        {name}
      </Typography>
      <Divider className={classes.divider} />

      {(!!organizationLogoUri || !!organizationName) && (
        <Box marginBottom={8}>
          <Typography variant="caption" color="textSecondary">
            {t("plaza.collectible.created-by")}
          </Typography>

          <Stack mt={3} gap={3} direction="row" alignItems="center">
            {!!organizationLogoUri && (
              <img
                src={organizationLogoUri}
                className={classes.organizationLogo}
                alt=""
              />
            )}
            <Typography>{organizationName}</Typography>
          </Stack>
        </Box>
      )}

      <Box mb={6} display="flex" flexDirection="column">
        <Typography color="textSecondary" variant="caption" mb={3}>
          {t("plaza.collectible.price")}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box mr={3}>
            <IconBadge
              darkBg
              iconSlot={
                <SimpleNetworkIcon name={token} height={27.5} width={27.5} />
              }
              className={classes.tokenPriceIconWrapper}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h3Demibold">
              {t("plaza.collectible.price-value", {
                price: price.toFormat(),
                token,
              })}
            </Typography>
            <Typography color="textSecondary" variant="h4Numbers">
              {t("formats.estimated-usd-value", {
                value: usdPrice.toFormat(2),
              })}
            </Typography>
          </Box>
        </Box>
      </Box>

      {!isAuthorized && (
        <Button
          fullWidth
          variant="outlined"
          className={classes.button}
          onClick={handleClickLogin}
        >
          {t("plaza.collectible.login")}
        </Button>
      )}

      {isAuthorized &&
        isMyCollectible &&
        ((!orderStatus && !orderId) ||
          listingStatus === EListingStatus.SOLD) && (
          <Button
            fullWidth
            variant="contained"
            disabled={isSellLoading}
            isLoading={isSellLoading}
            className={classes.button}
            onClick={handleSellCollectible}
          >
            {t("plaza.collectible.sell")}
          </Button>
        )}
      {isAuthorized &&
        isMyCollectible &&
        (orderStatus === EOrderListingStatus.ACTIVE || !!orderId) &&
        listingStatus !== EListingStatus.SOLD && (
          <Button
            fullWidth
            variant="outlined"
            isLoading={isStopSaleLoading}
            className={classes.button}
            onClick={handleClickStopSale}
          >
            {t("plaza.collectible.stop-sale")}
          </Button>
        )}
      {isAuthorized &&
        !isMyCollectible &&
        orderStatus === EOrderListingStatus.ACTIVE && (
          <Button
            fullWidth
            variant="contained"
            isLoading={isBuyLoading}
            className={classes.button}
            onClick={handleClickBuyCollectible}
          >
            {t("plaza.collectible.buy")}
          </Button>
        )}
      {!isMyCollectible && orderStatus === EOrderListingStatus.FILLED && (
        <Typography variant="body1Demibold" className={classes.status}>
          {t("plaza.collectible.sold")}
        </Typography>
      )}
    </div>
  );
};
