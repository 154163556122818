import { makeStyles } from "tss-react/mui";

export const useEmptyDataStyles = makeStyles()((theme) => ({
  wrapper: {
    width: "100%",
    height: 136,
    padding: theme.spacing(10, 0),
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 6,
    marginBottom: theme.spacing(17),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: 28,
    height: 28,
    marginBottom: theme.spacing(3),
  },
  text: {
    color: theme.palette.grey[300],
  },
}));
