import { EEthereumNetworkId } from "@ankr.com/provider";
import { Box, ButtonBase, Menu, MenuItem, Typography } from "@mui/material";
import { Network } from "modules/common/types";
import { getChainId } from "modules/common/utils/getChainId";
import { t } from "modules/utils/intl";
import { useCallback, useState } from "react";
import { NetworkOrTokenIcon } from "uiKit/components/Icon";
import { ReactComponent as ChevronSmallIcon } from "uiKit/icons/chevron-small.svg";
import { NetworkItem } from "./NetworkItem";
import { useNetworkButtonData } from "./useNetworkButtonData";
import { useNetworkButtonStyles } from "./useNetworkButtonStyles";
import { Dialog } from "modules/common/components/Dialog";
import { useDialog } from "modules/common/hooks/useDialog";
import { SwitchDialog } from "../../../SwitchDialog";

export const NetworkButton = (): JSX.Element => {
  const { classes, cx } = useNetworkButtonStyles();
  const { network, handleSwitchNetwork } = useNetworkButtonData();

  const { isOpened, onOpen, onClose } = useDialog();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [switchingChainId, setSwitchingChainId] =
    useState<EEthereumNetworkId | null>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNetwork = useCallback(
    (chainId: EEthereumNetworkId) => {
      setSwitchingChainId(chainId);
      onOpen();
    },
    [onOpen],
  );

  const handleConfirmSwitch = useCallback(async () => {
    if (switchingChainId) {
      handleSwitchNetwork(switchingChainId).then(() => {
        onClose();
        handleClose();
      });
    }
  }, [handleSwitchNetwork, onClose, switchingChainId]);

  const handleSwitchEth = useCallback(() => {
    handleClickNetwork(getChainId(Network.Eth));
  }, [handleClickNetwork]);

  const handleSwitchBsc = useCallback(() => {
    handleClickNetwork(getChainId(Network.Bsc));
  }, [handleClickNetwork]);

  const handleSwitchPolygon = useCallback(() => {
    handleClickNetwork(getChainId(Network.Matic));
  }, [handleClickNetwork]);

  const handleSwitchArbitrumNova = useCallback(() => {
    handleClickNetwork(getChainId(Network.ArbitrumNova));
  }, [handleClickNetwork]);

  return (
    <>
      <ButtonBase className={classes.root} onClick={handleClick}>
        <NetworkOrTokenIcon name={network} className={classes.icon} />
        <ChevronSmallIcon className={cx(open && classes.openedChevron)} />
      </ButtonBase>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            maxHeight: 242,
            width: 260,
            transform: "translateY(12px)",
          },
        }}
      >
        <Box mb={5}>
          <Typography color="textSecondary" variant="caption" ml={2}>
            {t("layout.header.switch")}
          </Typography>
        </Box>
        <MenuItem key={Network.Eth} onClick={handleSwitchEth}>
          <NetworkItem
            isActive={network === Network.Eth}
            network={Network.Eth}
            name={t("layout.header.ethereum")}
          />
        </MenuItem>
        <MenuItem key={Network.Bsc} onClick={handleSwitchBsc}>
          <NetworkItem
            isActive={network === Network.Bsc}
            network={Network.Bsc}
            name={t("layout.header.bsc")}
          />
        </MenuItem>
        <MenuItem key={Network.Matic} onClick={handleSwitchPolygon}>
          <NetworkItem
            isActive={network === Network.Matic}
            network={Network.Matic}
            name={t("layout.header.polygon")}
          />
        </MenuItem>
        <MenuItem key={Network.ArbitrumNova} onClick={handleSwitchArbitrumNova}>
          <NetworkItem
            isActive={network === Network.ArbitrumNova}
            network={Network.ArbitrumNova}
            name={t("layout.header.arbitrum-nova")}
          />
        </MenuItem>
      </Menu>

      <Dialog open={isOpened} onClose={onClose}>
        <SwitchDialog
          handleClickCancel={onClose}
          handleClickConfirm={handleConfirmSwitch}
        />
      </Dialog>
    </>
  );
};
