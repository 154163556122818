import { EEthereumNetworkId, EWalletId } from "@ankr.com/provider";
import { useSwitchNetworkMutation } from "modules/common/actions/useSwitchNetworkMutation";
import { useConnectedData } from "modules/common/hooks/useConnectedData";
import { Network } from "modules/common/types";
import { getNetworkByChainId } from "modules/common/utils/getNetworkByChainId";
import { useCallback } from "react";

interface INetworkButtonData {
  network: Network;
  handleSwitchNetwork: (network: EEthereumNetworkId) => Promise<void>;
}

export const useNetworkButtonData = (): INetworkButtonData => {
  const { chainId, walletId } = useConnectedData();
  const [switchNetwork] = useSwitchNetworkMutation();

  const handleSwitchNetwork = useCallback(
    async (network: EEthereumNetworkId) => {
      await switchNetwork({ wallet: walletId as EWalletId || EWalletId.injected, chainId: network });
    },
    [switchNetwork, walletId]
  );

  return {
    network: getNetworkByChainId(chainId ?? 1),
    handleSwitchNetwork,
  };
};
