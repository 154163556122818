import { useMemo, useRef } from "react";
import { Stack, Typography } from "@mui/material";
import { Network, Token } from "../../../modules/common/types";
import { ReactComponent as BscIcon } from "uiKit/icons/bsc_network.svg";
import { ReactComponent as BnbIcon } from "uiKit/icons/bnb.svg";
import { ReactComponent as ArbitrumNovaIcon } from "uiKit/icons/arbitrum-nova.svg";
import { ReactComponent as EthIcon } from "uiKit/icons/eth_network.svg";
import { ReactComponent as PolygonIcon } from "uiKit/icons/matic_network.svg";

interface INetworkIconProps {
  name: Network | Token;
  className?: string;
  withText?: boolean;
}

export const NetworkOrTokenIcon = ({
  className,
  name,
  withText,
}: INetworkIconProps): JSX.Element | null => {
  let networkName = useRef("");

  const iconOrToken = useMemo(() => {
    switch (name) {
      case Network.Eth:
      case Token.ETH:
        networkName.current = Token.ETH;
        return <EthIcon className={className} />;
      case Network.Bsc:
        networkName.current = Token.BNB;
        return <BscIcon className={className} />;
      case Network.ArbitrumNova:
      case Token.ARB:
        networkName.current = Token.ARB;
        return <ArbitrumNovaIcon className={className} />;
      case Network.Matic:
      case Token.MATIC:
        networkName.current = Token.MATIC;
        return <PolygonIcon className={className} />;
      case Token.BNB:
        networkName.current = Token.BNB;
        return <BnbIcon className={className} />;
      default:
        return null;
    }
  }, [name, className, networkName]);

  return withText ? (
    <Stack direction="row" alignItems="center" gap={2}>
      <>
        {iconOrToken}
        <Typography variant="body1Demibold">{networkName.current}</Typography>
      </>
    </Stack>
  ) : (
    iconOrToken
  );
};
