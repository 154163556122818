import { useFiltersStyles } from "./useFiltersStyles";
import { Box, Typography } from "@mui/material";
import { t } from "modules/utils/intl";
import {
  ChainsFilter,
  DevelopersFilter,
  GenresFilter,
  PlatformsFilter,
} from "./components";

interface IFilters {
  areFiltersOpen: boolean;
}

export const Filters = ({ areFiltersOpen }: IFilters) => {
  const { classes } = useFiltersStyles({
    areFiltersOpen,
  });

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <Typography className={classes.heading} variant="h4" component="h4">
          {t("plaza.collections.filters.title")}
        </Typography>
        <PlatformsFilter />
        <ChainsFilter />
        <DevelopersFilter />
        <GenresFilter />
      </Box>
    </Box>
  );
};
