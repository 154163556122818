import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface IConnectionStatus {
  address?: string;
  isActive?: boolean;
  chainId?: number;
  walletId?: string;
  walletIcon?: string;
  walletName?: string;
}

const initialState: IConnectionStatus = {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setConnectionStatus: (state, action: PayloadAction<IConnectionStatus>) => {
      Object.keys(action.payload).forEach(key => {
        const objKey = key as keyof IConnectionStatus;

        // @ts-ignore
        state[objKey] = action.payload[objKey];
      });
    },
    setChainId: (state, action: PayloadAction<IConnectionStatus>) => {
      Object.keys(action.payload).forEach(key => {
        if (key === "chainId") {
          state[key] = action.payload.chainId;
        }
      });
    },
    setAddress: (
      state,
      action: PayloadAction<Omit<IConnectionStatus, "isActive">>
    ) => {
      Object.keys(action.payload).forEach(key => {
        if (key === "address") {
          state[key] = action.payload.address;
        }
      });
    },
    resetStatus: state => {
      state.isActive = false;
    },
  },
});

const selectAuth = (state: RootState) => state.auth;

export const selectConnectionData = createSelector(selectAuth, state => state);

export const { setAddress, setConnectionStatus, setChainId, resetStatus } =
  authSlice.actions;
