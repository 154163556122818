import { Components, Palette } from "@mui/material";

export function getMuiTableCell(palette: Palette): Components["MuiTableCell"] {
  return {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${palette.grey[500]}`,
      },
    },
  };
}
