import { web3Api } from "modules/api";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { ZERO, CommonDataCacheTags } from "modules/common/const";
import {
  AvailableWriteProviders,
  Web3KeyReadProvider,
} from "@ankr.com/provider";
import BigNumber from "bignumber.js";
import { selectConnectionData } from "store/authSlice";
import { RootState } from "store";
import { getProviderManager } from "modules/api/getProviderManager";

export const { useGetNativeTokenBalanceQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    getNativeTokenBalance: build.query<BigNumber, void>({
      queryFn: queryFnNotifyWrapper<void, never, BigNumber>(
        async (args, { getState }) => {
          const providerManager = getProviderManager();
          const { address, walletId } = selectConnectionData(
            getState() as RootState,
          );

          const provider = await providerManager.getProvider(
            AvailableWriteProviders.ethCompatible,
            walletId,
          );

          if (!(provider instanceof Web3KeyReadProvider)) {
            return {
              data: ZERO,
            };
          }
          const web3 = provider.getWeb3();

          const weiBalance = await web3.eth.getBalance(address ?? "");

          return { data: new BigNumber(web3.utils.fromWei(weiBalance)) };
        },
      ),
      providesTags: [CommonDataCacheTags.balanceData],
    }),
  }),
});
