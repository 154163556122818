import { makeStyles } from 'tss-react/mui';

export const useNotificationsStyles = makeStyles()(theme => ({
  root: {
    '&&': {
      marginRight: 20,
      maxWidth: 344,
      background: 'none',
    },
  },

  toast: {
    '&&': {
      color: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      width: 344,
      padding: 0,
      background: 'none',
      cursor: 'default',
      marginBottom: 0,
      '& + &': {
        marginTop: theme.spacing(2),
      },
    },
  },

  toastBody: {
    wordBreak: 'break-word',
  },
}));
