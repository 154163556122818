import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CollectibleCacheTags } from "modules/plaza/screens/Collectible/const";

export const web3Api = createApi({
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: "",
  }),
  tagTypes: [...Object.values(CollectibleCacheTags)],
  endpoints: builder => ({}),
});
