import { NetworkCode } from "../../../common/types";
import { IFiltersState } from "../Games/types";

export interface IAction {
  type: string;
  payload?: string;
}

export const collectionsFilterInitialState: IFiltersState = {
  chain: null,
};

export function collectionsFilterReducer(
  state: IFiltersState,
  action: IAction,
) {
  switch (action.type) {
    case "SET_CHAIN":
      return { ...state, chain: action.payload as NetworkCode };
    case "CLEAR_FILTERS":
      return collectionsFilterInitialState;
    case "CLEAR_FILTER": {
      return { ...state, [action.payload as string]: null };
    }
    default:
      throw new Error();
  }
}
