import { configFromEnv, web3Api } from "modules/api";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { GET_POST_NFT_LISTING } from "modules/api/urls";
import { processApiResponse } from "modules/utils/processApiResponse";
import { CollectibleCacheTags } from "../const";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IUpdateOwnerArgs {
  id: string;
  soldToAddress: string;
}

export const { useUpdateCollectibleOwnerMutation } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    updateCollectibleOwner: build.mutation<boolean, IUpdateOwnerArgs>({
      queryFn: queryFnNotifyWrapper<IUpdateOwnerArgs, never, boolean>(
        async (args: IUpdateOwnerArgs) => {
          try {
          } catch (err) {
            console.error(
              "Error in `useUpdateCollectibleOwnerMutation.ts`",
              err,
            );
            throw err;
          }
          const response = await fetch(
            nftManagerUrl + GET_POST_NFT_LISTING + "/" + args.id + "/sell",
            {
              method: "PUT",
              body: JSON.stringify({ soldToAddress: args.soldToAddress }),
              credentials: "include",
              headers: new Headers({
                "Content-Type": "application/json",
              }),
            },
          );

          const data = await processApiResponse(response);
          return { data };
        },
      ),
      invalidatesTags: [CollectibleCacheTags.main],
    }),
  }),
});
