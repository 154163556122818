import "./assets/styles.css";
import { AppBase } from "AppBase";
import { persistor, store } from "store";
import { Provider } from "react-redux";
import { Routes } from "Routes";
import { PersistGate } from "redux-persist/integration/react";
import { LoaderCentered } from "modules/common/components/LoaderCentered";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoaderCentered />} persistor={persistor}>
        <AppBase>
          <Routes />
        </AppBase>
      </PersistGate>
    </Provider>
  );
}

export default App;
