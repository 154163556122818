import { Box } from "@mui/material";
import { useHeaderStyles } from "./useHeaderStyles";

interface IHeaderProps {
  bgImage: string;
  logoImage: string;
}

export const Header = ({ bgImage, logoImage }: IHeaderProps): JSX.Element => {
  const { classes } = useHeaderStyles({ bg: bgImage });

  return (
    <Box className={classes.root}>
      <Box className={classes.img} />
      <img className={classes.icon} src={logoImage} alt="" />
    </Box>
  );
};
