import { makeStyles } from "tss-react/mui";

export const useBuyInProgressDialogStyles = makeStyles()(theme => ({
  image: {
    height: 130,
    width: 130,
    borderRadius: 12,
    marginBottom: theme.spacing(6),
  },
  link: {
    textDecoration: "unset",
    width: "100%",
  },
  button: {
    display: "flex",
    alignItems: "center",
  },
}));
