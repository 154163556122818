import { Box, ButtonBase, Typography } from "@mui/material";
import { useCopyHook } from "modules/common/hooks/useCopyHook";
import { getShortId } from "modules/common/utils/getShortId";
import CopyToClipboard from "react-copy-to-clipboard";
import { ReactComponent as CopyIcon } from "uiKit/icons/copy.svg";

interface IDestinationProps {
  address: string;
}

export const Destination = ({ address }: IDestinationProps): JSX.Element => {
  const { handleCopy } = useCopyHook();

  return (
    <Box display="flex" alignItems="center">
      <Typography mr={2}>{getShortId(address)}</Typography>
      <CopyToClipboard text={address} onCopy={handleCopy}>
        <ButtonBase>
          <CopyIcon />
        </ButtonBase>
      </CopyToClipboard>
    </Box>
  );
};
