import { Box, Stack, Typography } from "@mui/material";
import { t } from "modules/utils/intl";
import { IGameVendorItem } from "../../types";

import { ReactComponent as DividerIcon } from "uiKit/icons/dot-divider.svg";
import { SimpleIcon, SimpleNetworkIcon } from "uiKit/components/Icon";
import { Network } from "modules/common/types";

interface IInfoBlockProps {
  description?: string;
  itemsCount: number;
  network?: Network;
  createdBy?: IGameVendorItem;
}

export const InfoBlock = ({
  description,
  itemsCount,
  network,
  createdBy,
}: IInfoBlockProps): JSX.Element => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Stack
          gap={2}
          direction="row"
          alignItems="center"
          mb={4}
          divider={<DividerIcon />}
        >
          {!!network && (
            <SimpleIcon icon={<SimpleNetworkIcon name={network} />} />
          )}
          <Typography variant="body2">
            {itemsCount !== 1
              ? t("plaza.collection.items", {
                  value: itemsCount,
                })
              : t("plaza.collection.item", {
                  value: itemsCount,
                })}
          </Typography>
        </Stack>

        <Typography
          variant="body1"
          color="textSecondary"
          maxWidth={508}
          marginBottom={6}
        >
          {description}
        </Typography>
      </Box>

      {!!createdBy && (
        <Box mt={10} mr={20}>
          <Typography variant="caption" color="textSecondary">
            {t("plaza.collection.created-by")}
          </Typography>

          <Stack mt={3} gap={3} direction="row" alignItems="center">
            <img src={createdBy?.icon} alt="" />
            <Typography>{createdBy?.title}</Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
