import { createRoot, hydrateRoot } from "react-dom/client";
import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement as HTMLElement);

if (rootElement?.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  root.render(<App />);
}
