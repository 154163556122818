import { makeStyles } from "tss-react/mui";

interface IHeroProps {
  bannerUrl: string;
}

export const useHeroStyles = makeStyles<IHeroProps>()(
  (theme, { bannerUrl }) => ({
    skeleton: {
      transform: "scale(1, 1)",
    },
    root: {
      position: "relative",
      height: 420,
      maxHeight: 420,
      width: "100%",
      borderRadius: 20,
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
      marginBottom: theme.spacing(15),
    },
    banner: {
      position: "absolute",
      height: "100%",
      width: "100%",
      opacity: 0.4,
      zIndex: 0,
      borderRadius: 20,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url(${bannerUrl})`,
    },
    content: {
      position: "absolute",
      display: "flex",
      height: "100%",
      width: "100%",
      padding: theme.spacing(8, 10),
      zIndex: 1,
    },
    gameInfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      flex: 0.7,
    },
    badges: {
      display: "flex",
      alignItems: "center",
    },
    chainBadge: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.grey[700],
      borderRadius: 6,
    },
    tag: {
      height: 24,
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.grey[700],
      borderRadius: 6,
      padding: theme.spacing(0.5, 2, 0.75),
      fontSize: 13,
      fontWeight: 600,
      textAlign: "center",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    },
    brand: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(4, 0),
    },
    heading: {},
    descriptionWrapper: {
      maxWidth: 380,
      marginBottom: theme.spacing(8),
    },
    descriptionText: {
      fontWeight: 400,
      lineHeight: "16px",
      fontSize: 15,
    },
    btnExplore: {
      width: 102,
      minWidth: 102,
      marginRight: theme.spacing(3),
    },
    controlsWrapper: {
      flex: 0.3,
      alignSelf: "flex-end",
    },
    controls: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    pageButton: {
      height: 40,
      width: 40,
      minWidth: 40,
      padding: 0,
      transform: "rotate(-180deg)",

      "& + &": {
        marginLeft: theme.spacing(2),
        transform: "rotate(0deg)",
      },
    },
  }),
);
