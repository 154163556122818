import { useCallback, useState } from "react";
import { WalletDescription } from "../WalletDescription";
import { WalletsList } from "../WalletsList";

export const MainRightBlock = (): JSX.Element => {
  const [isWalletsListShown, setIsWalletsListShown] = useState(false);

  const handleClickGoBack = useCallback(() => setIsWalletsListShown(false), []);
  const handleClickGetWallet = useCallback(
    () => setIsWalletsListShown(true),
    []
  );

  if (isWalletsListShown)
    return <WalletsList handleClickGoBack={handleClickGoBack} />;

  return <WalletDescription handleClickGetWallet={handleClickGetWallet} />;
};
