import {
  ARBITRUM_NOVA_MAINNET_CHAIN_ID,
  BSC_MAINNET_CHAIN_ID,
  BSC_TESTNET_CHAIN_ID,
  ETH_MAINNET_CHAIN_ID,
  GOERLI_CHAIN_ID,
  POLYGON_MAINNET_CHAIN_ID,
  SEPOLIA_CHAIN_ID,
} from "../const";
import { isProd } from "../env";
import { Network, NetworkCode } from "../types";

export const getChainId = (network: Network | NetworkCode): number => {
  switch (network) {
    case Network.Eth:
    case NetworkCode.SEPOLIA:
      return isProd ? ETH_MAINNET_CHAIN_ID : SEPOLIA_CHAIN_ID;
    case NetworkCode.GOERLI:
      return isProd ? ETH_MAINNET_CHAIN_ID : GOERLI_CHAIN_ID;
    case Network.Bsc:
      return isProd ? BSC_MAINNET_CHAIN_ID : BSC_TESTNET_CHAIN_ID;
    case Network.Matic:
    case NetworkCode.MATIC:
      return POLYGON_MAINNET_CHAIN_ID;
    case Network.ArbitrumNova:
    case NetworkCode.ARB:
      return ARBITRUM_NOVA_MAINNET_CHAIN_ID;
    default:
      return ETH_MAINNET_CHAIN_ID;
  }
};
