import { makeStyles } from "tss-react/mui";

export const useTxStatusStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: "rgba(61, 61, 61, 0.5)",
    borderRadius: 6,
    height: 24,
    width: "fit-content",
    display: "flex",
    padding: theme.spacing(0, 2),
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  orange: {
    backgroundColor: theme.palette.orange.main,
  },
  green: {
    backgroundColor: theme.palette.success.main,
  },
}));
