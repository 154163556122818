import { t } from "modules/utils/intl";
import { ReactComponent as HomeIcon } from "uiKit/icons/home.svg";
import { ReactComponent as GamesIcon } from "uiKit/icons/games.svg";
import { ReactComponent as ItemsIcon } from "uiKit/icons/items.svg";
import { ReactComponent as CollectionsIcon } from "uiKit/icons/collections.svg";
import { RoutesConfig } from "modules/plaza/Routes";
import { SidebarItem } from "modules/common/components/SidebarItem";
import {
  checkParentPathMatch,
  checkSimplePathMatch,
} from "modules/common/utils/checkPathMatch";
import { useLocation } from "react-router-dom";

interface ISidebarProps {
  className?: string;
}

// TODO: make active menu switcher with color highlight
export const Sidebar = ({ className }: ISidebarProps): JSX.Element => {
  const { pathname } = useLocation();

  return (
    <div className={className}>
      <SidebarItem
        iconSlot={<HomeIcon />}
        title={t("layout.plaza-sidebar.home")}
        link={RoutesConfig.home.generatePath()}
        isActive={checkSimplePathMatch(pathname, RoutesConfig.home.path)}
      />
      <SidebarItem
        iconSlot={<GamesIcon />}
        title={t("layout.plaza-sidebar.games")}
        link={RoutesConfig.games.path}
        isActive={checkParentPathMatch(pathname, RoutesConfig.games.path)}
      />
      <SidebarItem
        iconSlot={<ItemsIcon />}
        title={t("layout.plaza-sidebar.items")}
        link={RoutesConfig.collectibles.path}
        isActive={checkParentPathMatch(
          pathname,
          RoutesConfig.collectibles.path,
        )}
      />
      <SidebarItem
        iconSlot={<CollectionsIcon />}
        title={t("layout.plaza-sidebar.collections")}
        link={RoutesConfig.collections.path}
        isActive={checkParentPathMatch(pathname, RoutesConfig.collections.path)}
      />
    </div>
  );
};
