import { Box } from "@mui/material";
import { Header } from "./components/Header";
import { InfoForm } from "./components/InfoForm";
import { useAccountData } from "./useAccountData";

export const Account = (): JSX.Element => {
  const { userName, email, firstName, lastName } = useAccountData();

  return (
    <Box display="flex" flexDirection="column" mb={5}>
      <Header />
      <InfoForm
        userName={userName}
        email={email}
        firstName={firstName}
        lastName={lastName}
      />
    </Box>
  );
};
