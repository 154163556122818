import { ButtonBase, Input } from "@mui/material";
import { t } from "modules/utils/intl";
import { ReactComponent as CloseIcon } from "uiKit/icons/close.svg";
import { ReactComponent as SearchIcon } from "uiKit/icons/search.svg";
import { useSearchInputStyles } from "./useSearchInputStyles";

interface ISearchInputProps {
  value: string;
  disabled?: boolean;
  setSearch: (value: string) => void;
}

export const SearchInput = ({
  value,
  disabled = false,
  setSearch,
}: ISearchInputProps): JSX.Element => {
  const { classes, cx } = useSearchInputStyles();

  return (
    <Input
      disableUnderline
      className={classes.root}
      placeholder={t("ui-kit.search-placeholder")}
      value={value}
      inputProps={{ maxLength: 30 }}
      onChange={(e) => setSearch(e.target.value)}
      startAdornment={
        <SearchIcon
          className={cx(
            classes.searchIcon,
            !!value ? classes.white : classes.default,
          )}
        />
      }
      endAdornment={
        value && (
          <ButtonBase onClick={() => setSearch("")}>
            <CloseIcon className={classes.clearIcon} />
          </ButtonBase>
        )
      }
    />
  );
};
