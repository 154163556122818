import { Components } from "@mui/material";

export function getMuiInput(): Components["MuiInput"] {
  return {
    defaultProps: {
      disableUnderline: true,
    },

    styleOverrides: {
      root: {
        padding: "10px 12px",
      },
    },
  };
}
