import { COLLECTION_TILE_WIDHT } from "modules/common/const";
import { makeStyles } from "tss-react/mui";

export const useCollectionTileSkeletonStyles = makeStyles()((theme) => ({
  root: {
    width: COLLECTION_TILE_WIDHT,
    maxWidth: COLLECTION_TILE_WIDHT,
    height: 244,
    maxHeight: 244,
    position: "relative",
  },
  sliderRoot: {
    objectFit: "contain",
    width: "inherit",
    maxWidth: "inherit",
    height: "inherit",
    maxHeight: 244,
    borderRadius: 16,
    position: "absolute",
    transform: "unset",
  },
  light: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: 4,
  },
}));
