import { Box, ButtonBase, Skeleton, Tooltip, Typography } from "@mui/material";
import { cloneElement } from "react";
import { ReactComponent as InventoryIcon } from "uiKit/icons/simple-inventory.svg";
import { ReactComponent as ActivityIcon } from "uiKit/icons/simple-activity.svg";
import { ReactComponent as CopyIcon } from "uiKit/icons/copy.svg";
import { ReactComponent as CopiedIcon } from "uiKit/icons/copied.svg";
import { ReactComponent as DisconnectIcon } from "uiKit/icons/disconnect.svg";
import { useAccountInfoData } from "./useAccountInfoData";
import { useAccountInfoStyles } from "./useAccountInfoStyles";
import { t } from "modules/utils/intl";
import { NavLink } from "react-router-dom";
import { getShortId } from "modules/common/utils/getShortId";
import CopyToClipboard from "react-copy-to-clipboard";
import { RoutesConfig } from "modules/account/Routes";
import { NetworkOrTokenIcon } from "uiKit/components/Icon";
import { USD_DECIMALS } from "modules/common/const";
import { featuresConfig } from "modules/common/featuresConfig";

interface IAccountInfoProps {
  icon: JSX.Element;
  name: string;
}

export const AccountInfo = ({ icon, name }: IAccountInfoProps): JSX.Element => {
  const { classes } = useAccountInfoStyles();

  const {
    address,
    walletIcon,
    token,
    balance,
    usdBalance,
    isBalanceLoading,
    isCopiedAddress,
    isConnected,
    handleCopyAddress,
    handleClickDisconnect,
  } = useAccountInfoData();

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" className={classes.mainRoot}>
        <Box display="flex" alignItems="center" mb={7}>
          {cloneElement(icon, {
            className: classes.userLogo,
          })}
          <Box display="flex" flexDirection="column">
            <Typography
              variant="body1Demibold"
              marginBottom={featuresConfig.isProfilePageEnabled ? 1 : 0}
            >
              {name}
            </Typography>
            {featuresConfig.isProfilePageEnabled && (
              <NavLink to={RoutesConfig.account.generatePath()}>
                <Typography variant="body1Demibold">
                  {t("layout.header.account.view-profile")}
                </Typography>
              </NavLink>
            )}
          </Box>
        </Box>
        <NavLink to={RoutesConfig.inventory.generatePath()}>
          <Box display="flex" alignItems="center" position="relative" mb={5}>
            <InventoryIcon className={classes.mainIcons} />
            <Typography
              variant="body1Demibold"
              className={classes.menuItemText}
            >
              {t("layout.header.account.inventory")}
            </Typography>
          </Box>
        </NavLink>
        <Box display="flex" alignItems="center" position="relative">
          <ActivityIcon className={classes.mainIcons} />
          <Typography variant="body1Demibold" className={classes.menuItemText}>
            {t("layout.header.account.activity")}
          </Typography>
          <div className={classes.comingSoonRoot}>
            <Typography className={classes.comingSoon}>
              {t("layout.header.account.coming-soon")}
            </Typography>
          </div>
        </Box>
      </Box>

      {isConnected && (
        <Box
          display="flex"
          flexDirection="column"
          className={classes.walletRoot}
        >
          <Typography color="textSecondary" variant="caption" mb={3.5}>
            {t("layout.header.account.wallet")}
          </Typography>

          <Box display="flex" justifyContent="space-between" mb={3}>
            <Box display="flex" alignItems="center">
              <img alt="" src={walletIcon} className={classes.walletIcon} />
              <Typography variant="body1Demibold">
                {getShortId(address)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <CopyToClipboard text={address} onCopy={handleCopyAddress}>
                <ButtonBase className={classes.smallButton}>
                  {isCopiedAddress ? (
                    <Tooltip
                      arrow
                      open
                      placement="top"
                      title={t("layout.header.account.copied")}
                    >
                      <CopiedIcon />
                    </Tooltip>
                  ) : (
                    <CopyIcon />
                  )}
                </ButtonBase>
              </CopyToClipboard>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.balanceRoot}
          >
            <Box display="flex" alignItems="center">
              <NetworkOrTokenIcon name={token} className={classes.token} />
              <Typography variant="body1Demibold">{token}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              {isBalanceLoading ? (
                <Skeleton height={25} width={75} />
              ) : (
                <Typography variant="body1Demibold">
                  {balance.toFormat(4)}
                </Typography>
              )}
              {isBalanceLoading ? (
                <Skeleton height={25} width={75} />
              ) : (
                <Typography variant="body1" color="textSecondary">
                  {t("formats.estimated-usd-value", {
                    value: usdBalance.toFormat(USD_DECIMALS),
                  })}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}

      <ButtonBase
        className={classes.logoutButton}
        onClick={handleClickDisconnect}
      >
        <DisconnectIcon />
        <Typography ml={3} variant="body1Demibold">
          {isConnected
            ? t("layout.header.account.log-out-with-wallet")
            : t("layout.header.account.log-out")}
        </Typography>
      </ButtonBase>
    </Box>
  );
};
