import { Button } from "@mui/material";
import { t } from "modules/utils/intl";
import { NavLink } from "react-router-dom";
import { useViewAllButtonStyles } from "./useViewAllButtonStyles";

interface IViewAllButtonProps {
  link: string;
}

export const ViewAllButton = ({ link }: IViewAllButtonProps): JSX.Element => {
  const { classes } = useViewAllButtonStyles();

  return (
    <NavLink to={link}>
      <Button variant="outlined" className={classes.root}>
        {t("ui-kit.view-all")}
      </Button>
    </NavLink>
  );
};
