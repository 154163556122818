import { Box, Typography, Icon } from "@mui/material";
import { t } from "modules/utils/intl";
import { useEmptyDataStyles } from "./useEmptyDataStyles";
import { ReactComponent as GamesIcon } from "uiKit/icons/games.svg";
import { ReactComponent as CollectionsIcon } from "uiKit/icons/collections.svg";
import { ReactComponent as CollectiblesIcon } from "uiKit/icons/items.svg";
import { ReactComponent as ReviewsIcon } from "uiKit/icons/reviews.svg";

type DataType = "games" | "collections" | "collectibles" | "reviews";

interface EmptyDataProps {
  dataType: DataType;
}

export const EmptyData = ({ dataType }: EmptyDataProps) => {
  const { classes } = useEmptyDataStyles();
  const emptyDataIcon = (dataType: string) => {
    switch (dataType) {
      case "games":
        return <Icon className={classes.icon} component={GamesIcon} />;
      case "collections":
        return <Icon className={classes.icon} component={CollectionsIcon} />;
      case "collectibles":
        return <Icon className={classes.icon} component={CollectiblesIcon} />;
      case "reviews":
        return <Icon className={classes.icon} component={ReviewsIcon} />;
      default:
        return <Icon className={classes.icon} component={GamesIcon} />;
    }
  };
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        {emptyDataIcon(dataType)}
        <Typography className={classes.text} variant="body2">
          {t(`empty-states.${dataType}`)}
        </Typography>
      </Box>
    </Box>
  );
};
