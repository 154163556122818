import { configFromEnv, web3Api } from "modules/api";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { GET_POST_NFT_LISTING } from "modules/api/urls";
import { CollectibleCacheTags } from "../const";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IUpdateCanceledListingArgs {
  id: string;
}

export const { useUpdateCanceledListingMutation } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    updateCanceledListing: build.mutation<boolean, IUpdateCanceledListingArgs>({
      queryFn: queryFnNotifyWrapper<IUpdateCanceledListingArgs, never, boolean>(
        async ({ id }: IUpdateCanceledListingArgs) => {
          try {
            const response = await fetch(
              nftManagerUrl + GET_POST_NFT_LISTING + "/" + id + "/cancel",
              {
                method: "PUT",
                credentials: "include",
                headers: new Headers({
                  "Content-Type": "application/json",
                }),
              },
            );

            const data = await processApiResponse(response);
            return { data };
          } catch (err) {
            console.error(
              "Error in `useUpdateCanceledListingMutation.ts`",
              err,
            );
            throw err;
          }
        },
      ),
      invalidatesTags: [CollectibleCacheTags.main],
    }),
  }),
});
