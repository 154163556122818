import {
  EListingStatus,
  ENftTokenStatus,
  ETxStatus,
} from "modules/common/types";
import { makeStyles } from "tss-react/mui";
import { mainTheme } from "uiKit/mainTheme";
import { TStatusBadge } from "./StatusBadge";

interface IStatusBadgeStyles {
  type: TStatusBadge;
}

const DOT_BG_COLOR_MAP: Record<TStatusBadge, string> = {
  [EListingStatus.LISTED]: mainTheme.palette.success.main,
  [EListingStatus.SOLD]: mainTheme.palette.orange.main,
  [EListingStatus.NotListed]: mainTheme.palette.grey[300],
  [ETxStatus.COMPLETED]: mainTheme.palette.success.main,
  [ETxStatus.PENDING]: mainTheme.palette.orange.main,
  [ENftTokenStatus.NotSupported]: mainTheme.palette.yellow.main,
  [ENftTokenStatus.OWNED]: mainTheme.palette.orange.main,
};

export const useStatusBadgeStyles = makeStyles<IStatusBadgeStyles>()(
  (theme, { type }) => ({
    root: {
      minHeight: 24,
      width: "fit-content",
      backdropFilter: "blur(8px)",
      borderRadius: 6,
      backgroundColor: theme.palette.grey[700],
      padding: theme.spacing(0, 2),
    },
    orangeRoot: {
      border: `1px solid ${theme.palette.orange.main}`,
    },
    orangeText: {
      color: theme.palette.orange.main,
    },
    lightBg: {
      backgroundColor: theme.palette.grey[500],
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: DOT_BG_COLOR_MAP[type],
      display: type === ENftTokenStatus.OWNED ? "none" : "block",
    },
  }),
);
