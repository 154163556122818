import { AvailableWriteProviders } from "@ankr.com/provider";
import { useCallback } from "react";
import { useDisconnectMutation } from "../actions/useDisconnectMutation";

const PROVIDERS_ARRAY = Object.values(
  AvailableWriteProviders
) as AvailableWriteProviders[];

type TDisconnectAll = () => void;

export const useDisconnectAll = (): TDisconnectAll => {
  const [disconnect] = useDisconnectMutation();

  return useCallback(() => {
    PROVIDERS_ARRAY.forEach(x => disconnect(x));
  }, [disconnect]);
};
