import { Divider, Link, Typography } from "@mui/material";
import { t } from "modules/utils/intl";
import { ReactComponent as PlazaLogo } from "uiKit/icons/plaza-logo.svg";
import { useHeaderData } from "./useHeaderData";
import { useHeaderStyles } from "./useHeaderStyles";
import { ConnectWallet } from "../ConnectWallet";
import { NetworkButton } from "./components/NetworkButton";
import { AccountButton } from "./components/AccountButton";
import { NavLink } from "react-router-dom";
import { RoutesConfig } from "modules/plaza/Routes";
import { useOrySession } from "../OryLifecycleProvider/context";
import { LoginButton } from "./components/LoginButton";

interface IHeaderProps {
  className?: string;
}

// TODO: make active menu switcher with color highlight
export const Header = ({ className }: IHeaderProps): JSX.Element => {
  const { classes, cx } = useHeaderStyles();
  const { session } = useOrySession();

  const { userName, isConnected, developersUrl, companyUrl, userLogo } =
    useHeaderData();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.menu}>
        <NavLink
          to={RoutesConfig.home.generatePath()}
          className={classes.titleWrapper}
        >
          <PlazaLogo className={classes.icon} />
        </NavLink>

        <Divider orientation="vertical" className={classes.divider} />

        <Link
          href={developersUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <Typography variant="body2Demibold" color="textSecondary">
            {t("layout.header.developers")}
          </Typography>
        </Link>

        <Link
          href={companyUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <Typography variant="body2Demibold" color="textSecondary">
            {t("layout.header.company")}
          </Typography>
        </Link>
      </div>

      <div className={classes.secondActions}>
        {!!session?.active &&
          (isConnected ? <NetworkButton /> : <ConnectWallet />)}

        {!!session?.active ? (
          <AccountButton icon={userLogo} name={userName} />
        ) : (
          <LoginButton />
        )}
      </div>
    </div>
  );
};
