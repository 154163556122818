import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { uid } from "react-uid";
import { useGamesListStyles } from "./useGamesListStyles";
import { t } from "modules/utils/intl";
import { TableHeadCell } from "uiKit/components/Table/TableHeadCell";
import { Network } from "modules/common/types";
import { SimpleNetworkIcon } from "uiKit/components/Icon/SimpleNetworkIcon";
import { ReactComponent as ArrowIcon } from "uiKit/icons/arrow-small-down.svg";
import { ReactComponent as MobileIcon } from "uiKit/icons/mobile.svg";
import { SimpleIcon } from "uiKit/components/Icon";
import { NavLink } from "react-router-dom";
import { RoutesConfig } from "modules/plaza/Routes";
import { IGamesListItem } from "modules/plaza/types";
import { EProjectType, projectTypesMap } from "modules/common/const";
import { useDispatch } from "react-redux";
import { push } from "@lagunovsky/redux-react-router";
import { featuresConfig } from "modules/common/featuresConfig";
import { useCallback } from "react";

interface IGamesListProps {
  data: IGamesListItem[];
}

export const GamesList = ({ data }: IGamesListProps): JSX.Element => {
  const { classes, cx } = useGamesListStyles();
  const { palette } = useTheme();

  const dispatch = useDispatch();

  const handleClickGameLink = useCallback(
    (link: string) => {
      dispatch(push(link));
    },
    [dispatch],
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell title={"#"} />
            <TableHeadCell title={t("plaza.games.top-games.games")} />
            <TableHeadCell title={t("plaza.games.top-games.network")} />
            {featuresConfig.isAnalyticsDataEnabled && (
              <>
                <TableHeadCell
                  title={t("plaza.games.top-games.volume-total")}
                />
                <TableHeadCell title={t("plaza.games.top-games.volume-24h")} />
              </>
            )}

            <TableHeadCell title={t("plaza.games.top-games.genres")} />
            <TableHeadCell title={t("plaza.games.top-games.platforms")} />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0
            ? data.map((row, index) => (
                <TableRow
                  key={uid(row)}
                  className={classes.tableRow}
                  onClick={() =>
                    handleClickGameLink(RoutesConfig.game.generatePath(row.id))
                  }
                >
                  <TableCell width={20}>
                    <Typography>{++index}</Typography>
                  </TableCell>

                  <TableCell width={400}>
                    <Box display="flex" alignItems="center">
                      <img
                        src={!!row.image ? row.image.fileUri : ""}
                        alt=""
                        className={classes.icon}
                      />
                      <Box display="flex" flexDirection="column" ml={4}>
                        <Typography variant={"body1Demibold"}>
                          {row.projectName}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell width={40}>
                    <SimpleNetworkIcon
                      height={15}
                      width={15}
                      name={Network.Eth}
                    />
                  </TableCell>

                  {featuresConfig.isAnalyticsDataEnabled && (
                    <>
                      <TableCell>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body1Demibold">
                            {t("plaza.games.top-games.volume", {
                              value: 0, // TODO: change it with real data
                            })}
                          </Typography>

                          <Typography
                            variant="caption"
                            color={palette.success.main}
                          >
                            {t("plaza.games.top-games.trend", {
                              value: 0, // TODO: change it with real data
                              icon: "",
                            })}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell width={200}>
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography variant="body1Demibold">
                            {t("plaza.games.top-games.volume", {
                              value: 0, // TODO: change it with real data
                              icon: "",
                            })}
                          </Typography>

                          <Typography
                            variant="caption"
                            color={palette.error.main}
                          >
                            {t("plaza.games.top-games.trend", {
                              value: 0, // TODO: change it with real data
                              icon: "",
                            })}
                          </Typography>
                        </Box>
                      </TableCell>
                    </>
                  )}

                  <TableCell width={150}>
                    <Typography variant="body1Demibold">
                      {projectTypesMap[row.gameProjectTypes[0] as EProjectType]}
                    </Typography>
                  </TableCell>

                  <TableCell width={150}>
                    <SimpleIcon
                      icon={<MobileIcon />}
                      className={cx(classes.iconText, "platform-icon")}
                    />

                    <NavLink to={RoutesConfig.game.generatePath(row.id)}>
                      <Typography
                        className={cx(classes.viewGame, "view-game")}
                        variant="body1Demibold"
                        color={palette.yellow.main}
                      >
                        {t("plaza.games.top-games.view-game")}
                        <ArrowIcon className={classes.arrowIcon} />
                      </Typography>
                    </NavLink>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
