import BigNumber from "bignumber.js";
import { NetworkCode, Platforms, Token } from "modules/common/types";
import { IGamesListItem } from "modules/plaza/types";
import { EPlatformType } from "../../../common/const";

export interface IFiltersState extends Record<string, any> {
  chain?: NetworkCode | null;
  platforms?: EPlatformType[] | null;
  developer?: string[] | null;
  genre?: string[] | null;
}

export enum ViewMode {
  GRID = "grid",
  LIST = "list",
}

export interface FilterParams {
  type: "viewType" | "toggleFilters" | "searchChange";
  value?: any;
}

export enum IGameFilterEnum {
  ALL_CHAINS = "All",
  ETH = "Goerli",
  MATIC = "MATIC",
  BNB = "BNB",
}

export interface IFilterParams {
  network?: IGameFilterEnum;
  viewType: ViewMode;
  filterToggle: boolean;
}

export interface IGameItem {
  id: string;
  name: string;
  developerName?: string;
  token: Token;
  volumeTotal?: BigNumber;
  volumeTrend?: number;
  volume24hTrend?: number;
  volume24h?: number;
  type?: string;
  platforms?: Platforms[];
}

export interface IGameData {
  data: IGamesListItem[];
  filter: {
    network?: IGameFilterEnum;
    filterToggle: boolean;
  };
  isLoading: boolean;
  hasNextPage: boolean;
  searchValue: string;
  searchDelay: boolean;
  viewMoreDelay: boolean;
  handleSetFilter: (name: IGameFilterEnum) => void;
  handleSetFilterToggle: (toggle: boolean) => void;
  handleClickViewMore: () => void;
  setSearch: (value: string) => void;
}
