import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";

import { t } from "modules/utils/intl";
import { useChainFilterStyles } from "./useChainFilterStyles";
import { useCallback, useState } from "react";
import { NetworkCode } from "modules/common/types";
import { SimpleNetworkIcon } from "uiKit/components/Icon";
import { NO_FILTER_SPECIFIED } from "../../../../../common/const";
import { TChainCode } from "../../../../types";
import { chainsMap } from "../../../../../common/utils/filters";
import { uid } from "react-uid";

interface ITopGamesFilterProps {
  onChange: (chain: TChainCode) => void;
}

export const ChainFilter = ({
  onChange,
}: ITopGamesFilterProps): JSX.Element => {
  const { classes } = useChainFilterStyles();
  const { palette } = useTheme();
  const [activeTab, setActiveTab] = useState(NO_FILTER_SPECIFIED);

  const handleFilterChange = useCallback(
    (_: React.MouseEvent<HTMLElement, MouseEvent>, value: TChainCode) => {
      if (!value) return;
      onChange(value);
      setActiveTab(value);
    },
    [onChange],
  );

  return (
    <Box display={"flex"} marginY={6}>
      <ToggleButtonGroup
        className={classes.root}
        value={activeTab}
        exclusive
        onChange={handleFilterChange}
      >
        <ToggleButton
          className={classes.btnAllChains}
          size="small"
          value={"All"}
        >
          <Typography variant="body2Demibold">
            {t("plaza.games.top-games.filter.all-chains")}
          </Typography>
        </ToggleButton>
        {Object.entries(chainsMap).map(([key, value]) => {
          return (
            <ToggleButton
              className={classes.btnChain}
              size="small"
              value={key}
              key={uid(key)}
            >
              <SimpleNetworkIcon
                name={key as NetworkCode}
                width={20}
                height={20}
                color={palette.grey["300"]}
              />
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
};
