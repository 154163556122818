import {
  MenuItem,
  Select as SelectBase,
  SelectChangeEvent,
} from "@mui/material";
import { useCallback } from "react";
import { useSelectStyles } from "./useSelectStyles";
import { uid } from "react-uid";

export interface IOption {
  label: string;
  value: string;
}

interface ISelectProps {
  defaultValue: IOption;
  options: readonly IOption[];
  onChange: (param: string) => void;
}

export const Select = ({ defaultValue, options, onChange }: ISelectProps) => {
  const { classes } = useSelectStyles();
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value as string);
    },
    [onChange],
  );

  return (
    <SelectBase
      className={classes.select}
      value={defaultValue.value}
      label={defaultValue.label}
      onChange={handleChange}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={uid(value)} className={classes.option} value={value}>
          {label}
        </MenuItem>
      ))}
    </SelectBase>
  );
};
