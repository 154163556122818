import { persistReducer } from "redux-persist";
import { PersistState } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";

import { authSlice, IConnectionStatus } from "./authSlice";

export type TAuthState = IConnectionStatus & {
  _persist: PersistState;
};

const persistConfig = {
  key: authSlice.name,
  storage,
};

export const authPersistReducer = persistReducer(
  persistConfig,
  authSlice.reducer
);
