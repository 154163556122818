import { RESPONSE_CODES } from "../common/types";
import { COMMON_ERROR, UNAUTHORIZED_ERROR } from "../common/const";
import { oryLoginAsync } from "modules/auth";

/**
 * Processes the API response.
 * If the response status is "unauthorized", it performs a logout operation and throws an unauthorized error.
 * If the response is not okay, it throws a common error.
 * Otherwise, it returns the JSON content of the response.
 *
 * @async
 * @param {Response} response - The API response to process.
 * @returns {Promise<any>} The JSON content of the response.
 * @throws {UNAUTHORIZED_ERROR} If the response status is "unauthorized".
 * @throws {COMMON_ERROR} If the response is not okay.
 */
export const processApiResponse = async (response: Response) => {
  if (response?.status === RESPONSE_CODES.UNAUTHORIZED) {
    oryLoginAsync(); // TODO: move this side effect to onQueryStarted param
    throw UNAUTHORIZED_ERROR;
  }

  if (!response.ok) {
    throw COMMON_ERROR;
  }

  return await response.json();
};
