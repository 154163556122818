import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useHeaderStyles } from "./useHeaderPaperStyles";
import { t } from "modules/utils/intl";
import { NavLink } from "react-router-dom";
import { SimpleIcon } from "uiKit/components/Icon";

import { ReactComponent as ArrowIcon } from "uiKit/icons/arrow-down.svg";

interface IHeaderPaperProps {
  hideRightSideBlock?: boolean;
  imageSrc: string;
  gameName?: string;
  developerSrc?: string;
  rightSideContentSlot?: JSX.Element;
  leftSideContentSlot?: JSX.Element;
}

export const HeaderPaper = ({
  hideRightSideBlock = false,
  imageSrc,
  gameName,
  rightSideContentSlot,
  leftSideContentSlot,
  developerSrc = "",
}: IHeaderPaperProps): JSX.Element => {
  const { classes } = useHeaderStyles({ imageUrl: imageSrc });
  const { palette } = useTheme();

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.img} />

        <Box className={classes.wrap}>
          <Box display="flex" flexDirection="column">
            {rightSideContentSlot}
          </Box>

          <Box
            flexDirection="column"
            maxWidth={300}
            display={hideRightSideBlock ? "none" : "flex"}
          >
            <Box mb={4}>
              <Typography variant="body1Demibold">
                {/* {t("plaza.games.about", { value: gameName })} */}
                {gameName}
              </Typography>
            </Box>

            {leftSideContentSlot}

            {!!developerSrc && (
              <NavLink to={developerSrc}>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Typography
                    variant="body1Demibold"
                    color={palette.yellow.main}
                  >
                    {t("plaza.games.more-games-by-dev")}
                  </Typography>
                  <SimpleIcon
                    sx={{ transform: "rotate(0.75turn)" }}
                    color={palette.yellow.main}
                    icon={<ArrowIcon />}
                  />
                </Stack>
              </NavLink>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
