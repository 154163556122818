import { Network, NetworkCode, Token } from "../types";

export const getNativeToken = (network?: Network | NetworkCode): Token => {
  switch (network) {
    case Network.Matic:
    case NetworkCode.MATIC:
      return Token.MATIC;
    case Network.Eth:
    case NetworkCode.SEPOLIA:
    case NetworkCode.GOERLI:
    default:
      return Token.ETH;
  }
};
