import { selectConnectionData } from "store/authSlice";
import { useAppSelector } from "store/useAppSelector";
import { useConnectMutation } from "../actions/useConnectMutations";
import { getIsMetamaskWallet } from "../utils/getIsMetamaskWallet";

export interface IUseConnectedData {
  isConnected: boolean;
  isLoading: boolean;
  address?: string;
  chainId?: number;
  walletName?: string;
  walletIcon?: string;
  error: unknown;
  isInjected: boolean;
  walletId?: string;
}

export const useConnectedData = (): IUseConnectedData => {
  const [
    ,
    {
      data: mutationData,
      isLoading: isMutationLoading,
      isError: isMutationError,
    },
  ] = useConnectMutation({
    fixedCacheKey: "useConnectMutation",
  });

  const connectionStatus = useAppSelector(selectConnectionData);

  const walletName = mutationData?.walletName;

  return {
    error: isMutationError,
    isConnected: !!mutationData?.isConnected && !!connectionStatus.isActive,
    address: connectionStatus?.address,
    isLoading: isMutationLoading,
    chainId: connectionStatus?.chainId,
    walletName,
    walletIcon: mutationData?.walletIcon,
    isInjected: walletName ? getIsMetamaskWallet(walletName) : false,
    walletId: mutationData?.walletId,
  };
};
