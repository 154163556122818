import { Box } from "@mui/material";
import { SearchInput } from "uiKit/components/SearchInput";
import { useCollectiblesFilterStyles } from "./useCollectiblesFilterStyles";

interface ICollectiblesFilterProps {
  toggleFilter?: boolean;
  search: string;
  isLoading: boolean;
  setSearch: (value: string) => void;
}

export const HeaderFilters = ({
  search,
  isLoading,
  setSearch,
}: ICollectiblesFilterProps) => {
  const { classes } = useCollectiblesFilterStyles();

  return (
    <Box className={classes.root} mt={8} mb={8}>
      <Box mr={2} display="flex" flexBasis="center">
        <SearchInput
          disabled={isLoading}
          value={search}
          setSearch={(value) => setSearch(value)}
        />
      </Box>
    </Box>
  );
};
