import { makeStyles } from "tss-react/mui";

export const useNetworkButtonStyles = makeStyles()(theme => ({
  root: {
    height: 40,
    width: 80,
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[500]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(1),
  },
  networkRoot: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  itemIcon: {
    height: 28,
    width: 28,
    marginRight: theme.spacing(2),
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  openedChevron: {
    transform: "rotate(180deg)",
  },
}));
