import * as reservoir from "@reservoir0x/reservoir-sdk";
import { ethers } from "ethers";
import { web3Api } from "modules/api";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { EWalletId } from "modules/api/const";
import { notify } from "uiKit/components/Notifications";
import {
  RESERVOIR_ARBITRUM_NOVA_API,
  RESERVOIR_ETH_API,
  RESERVOIR_SEPOLIA_API,
  RESERVOIR_POLYGON_API,
} from "modules/api/urls";
import {
  ARBITRUM_NOVA_MAINNET_CHAIN_ID,
  POLYGON_MAINNET_CHAIN_ID,
  RESERVOIR_API_KEY,
  SEPOLIA_CHAIN_ID,
} from "modules/common/const";
import { getProviderManager } from "modules/api/getProviderManager";
import { CollectibleCacheTags } from "../const";

interface ICancelOrderArgs {
  wallet?: EWalletId;
  chainId: number;
  id: string;
}

export const { useCancelReservoirOrderMutation } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    cancelReservoirOrder: build.mutation<boolean, ICancelOrderArgs>({
      queryFn: queryFnNotifyWrapper<ICancelOrderArgs, never, boolean>(
        async ({ wallet, chainId, id }: ICancelOrderArgs) => {
          const providerManager = getProviderManager();

          const provider = await providerManager.getETHWriteProvider(wallet);

          const etherProvider = new ethers.providers.Web3Provider(
            window.ethereum,
            chainId,
          );

          if (provider.currentChain !== chainId) {
            await provider.switchNetwork(chainId);
          }

          let baseUrl = RESERVOIR_ETH_API;
          if (chainId === SEPOLIA_CHAIN_ID) baseUrl = RESERVOIR_SEPOLIA_API;
          if (chainId === POLYGON_MAINNET_CHAIN_ID)
            baseUrl = RESERVOIR_POLYGON_API;
          if (chainId === ARBITRUM_NOVA_MAINNET_CHAIN_ID)
            baseUrl = RESERVOIR_ARBITRUM_NOVA_API;

          const reservoirClient = reservoir.createClient({
            chains: [
              {
                id: chainId,
                baseApiUrl: baseUrl,
                default: true,
                apiKey: RESERVOIR_API_KEY,
              },
            ],
            source: "localhost",
          });

          const signer = etherProvider.getSigner();

          const result = await reservoirClient.actions.cancelOrder({
            ids: [id],
            signer,
            onProgress: (steps) => {
              steps.forEach((step) => {
                console.log("step", step);
              });
            },
          });

          return {
            data: result,
          };
        },
      ),

      async onQueryStarted(_args, { queryFulfilled }) {
        return queryFulfilled.then(() => {
          notify({
            message: "Order canceled!",
            type: "success",
            key: "success",
          });
        });
      },
      invalidatesTags: [CollectibleCacheTags.main],
    }),
  }),
});
