import {
  Accordion as BaseAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ReactComponent as ExpandIcon } from "uiKit/icons/expand-icon.svg";

interface IAccordionProps {
  title: string;
  contentSlot: JSX.Element;
  isExpanded: boolean;
  className?: string;
  handleClickExpand: () => void;
}

export const Accordion = ({
  title,
  contentSlot,
  isExpanded,
  className,
  handleClickExpand,
}: IAccordionProps): JSX.Element => {
  return (
    <BaseAccordion
      expanded={isExpanded}
      className={className}
      onChange={handleClickExpand}
    >
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Typography variant="body1Demibold">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{contentSlot}</AccordionDetails>
    </BaseAccordion>
  );
};
