import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import { t } from "modules/utils/intl";
import { Controller, useForm } from "react-hook-form";
import { IAddFundsFormData, useFormData } from "./useFormData";
import { useFormStyles } from "./useFormStyles";

export const Form = (): JSX.Element => {
  const { classes } = useFormStyles();

  const { onSubmit } = useFormData();

  const {
    control,
    formState: { isValid },
    getValues,
    handleSubmit,
  } = useForm<IAddFundsFormData>({
    defaultValues: {
      address: "",
      token: undefined,
      network: undefined,
    },
  });

  const onPrepareSubmit = (data: IAddFundsFormData): void => {
    if (isValid) {
      onSubmit(data);
    }
  };

  const isFilledAddress = !!getValues().address;

  return (
    <form className={classes.root} onSubmit={handleSubmit(onPrepareSubmit)}>
      <Typography variant="body1" mb={4}>
        {t("account.add-funds.address-description")}
      </Typography>

      <Controller
        name="address"
        control={control}
        rules={{
          required: t("validation.required"),
        }}
        render={({ field, fieldState }) => {
          const errorText = fieldState.error?.message;
          return (
            <FormControl fullWidth>
              <div className={classes.mb8}>
                <InputLabel>
                  {t("account.add-funds.address-placeholder")}
                </InputLabel>
                <Input
                  {...field}
                  fullWidth
                  required
                  error={!!fieldState.error}
                  inputProps={{ maxLength: 50 }}
                  onChange={field.onChange}
                />
                {!!errorText && (
                  <FormHelperText error>{errorText}</FormHelperText>
                )}
              </div>
            </FormControl>
          );
        }}
      />

      {isFilledAddress && (
        <>
          <Typography variant="body1" mb={4}>
            {t("account.add-funds.currency-description")}
          </Typography>

          <Controller
            name="token"
            control={control}
            rules={{
              required: t("validation.required"),
            }}
            render={({ field, fieldState }) => {
              const errorText = fieldState.error?.message;
              return (
                <FormControl fullWidth>
                  <div className={classes.mb8}>
                    <InputLabel>
                      {t("account.add-funds.currency-placeholder")}
                    </InputLabel>
                    <Input
                      {...field}
                      fullWidth
                      required
                      error={!!fieldState.error}
                      inputProps={{ maxLength: 50 }}
                      onChange={field.onChange}
                    />
                    {!!errorText && (
                      <FormHelperText error>{errorText}</FormHelperText>
                    )}
                  </div>
                </FormControl>
              );
            }}
          />
        </>
      )}
    </form>
  );
};
