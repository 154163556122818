import { configFromEnv, web3Api } from "modules/api";

import { GET_INVENTORY } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { PAGE_CONTENT_WIDTH, ITEM_TILE_WIDHT } from "modules/common/const";
import { ICollectibleItem } from "modules/plaza/types";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IResponse {
  items: ICollectibleItem[];
  pageNumber: number;
  totalCount: number;
  totalPages: number;
}

interface IGetInventoryArgs {
  pageNumber?: number;
  pageSize?: number;
}

interface IGetInventoryParams {
  PageNumber?: string;
  PageSize?: string;
}

const PAGE_SIZE = Math.floor(PAGE_CONTENT_WIDTH / (ITEM_TILE_WIDHT + 20)) * 2;

export const { useLazyFetchUserInventoryQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    fetchUserInventory: build.query<IResponse, IGetInventoryArgs>({
      queryFn: queryFnNotifyWrapper<IGetInventoryArgs, never, IResponse>(
        async ({ pageNumber = 1, pageSize = PAGE_SIZE }) => {
          try {
            const params: IGetInventoryParams = {};

            if (pageNumber) {
              params.PageNumber = pageNumber.toString();
            }
            if (pageSize) {
              params.PageSize = pageSize.toString();
            }

            const response = await fetch(
              nftManagerUrl +
                GET_INVENTORY +
                "?" +
                new URLSearchParams({
                  ...params,
                }),
              {
                method: "GET",
                credentials: "include",
              },
            );

            const data = await processApiResponse(response);

            return { data };
          } catch (err) {
            console.error("Error in `useLazyFetchUserInventoryQuery.ts`", err);
            throw err;
          }
        },
      ),
    }),
  }),
});
