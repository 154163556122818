import { ISlide, TLightboxContent } from "../plaza/screens/Game/types";
import { IImageItem, IMediaItem, IVideoItem } from "../plaza/types";

/**
 * Creates an array of slide objects for a lightbox, based on the provided media and content type.
 *
 * @param {IMediaItem[]} media - The media items to be included in the slides. This can be an array of image URLs or video objects.
 * @param {TLightboxContent} contentType - The type of content to be displayed in the lightbox. This should be either "image" or "video".
 * @returns {ISlide[]} An array of slide objects. Each slide object includes an id, contentType, and src. If the contentType is "video", the slide object also includes a poster and type.
 *
 * @example
 * // returns [{ id: 'image1.jpg', contentType: 'image', src: 'image1.jpg' }, { id: 'image2.jpg', contentType: 'image', src: 'image2.jpg' }]
 * createSlides(['image1.jpg', 'image2.jpg'], 'image')
 *
 * @example
 * // returns [{ id: 'video1.mp4', contentType: 'video', src: 'video1.mp4', poster: 'poster1.jpg', type: 'mp4' }]
 * createSlides([{ fileUri: 'video1.mp4', poster: 'poster1.jpg', mediaType: 'mp4' }], 'video')
 */
export const createSlides = (
  media: IMediaItem[],
  contentType: TLightboxContent,
): ISlide[] => {
  const slides: ISlide[] = [];
  if (contentType === "image") {
    const screenshots = media as IImageItem[];
    screenshots.forEach(({ id, fileUri }) => {
      slides.push({
        id: id,
        contentType: "image",
        fileUri: fileUri,
      });
    });
  }

  if (contentType === "video") {
    const videos = media as IVideoItem[];

    videos.forEach(({ fileUri, poster, mediaType }) => {
      slides.push({
        id: fileUri,
        contentType: "video",
        fileUri: fileUri,
        poster: poster,
        type: mediaType,
      });
    });
  }

  return slides;
};
