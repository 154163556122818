import { createContext, Reducer, useReducer } from "react";
import { IFiltersState } from "../../plaza/screens/Games/types";
import {
  gamesFiltersInitialState,
  gamesFiltersReducer,
  IAction,
} from "../../plaza/screens/Games/components/Filters/reducer";

export const FiltersContext = createContext<{
  activeFilters: IFiltersState;
  setActiveFilters: React.Dispatch<IAction>;
}>({
  activeFilters: gamesFiltersInitialState,
  setActiveFilters: () => {},
});

export const FiltersStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeFilters, setActiveFilters] = useReducer<
    Reducer<IFiltersState, IAction>
  >(gamesFiltersReducer, gamesFiltersInitialState);

  return (
    <FiltersContext.Provider value={{ activeFilters, setActiveFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};
