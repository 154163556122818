import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "modules/utils/intl";

export const Header = (): JSX.Element => {
  return (
    <Box mb={10}>
      <Typography variant="h2" mb={2}>
        {t("account.add-funds.title")}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {t("account.add-funds.description")}
      </Typography>
    </Box>
  );
};
