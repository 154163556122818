import { useRef } from "react";
import { Network, NetworkCode, Token } from "../../../modules/common/types";
import { CustomSvgIcon } from "modules/common/components/CustomSvgIcon";

interface ISimpleNetworkIconProps {
  name: Network | NetworkCode | Token;
}

interface IIcon {
  width?: number;
  height?: number;
  color?: string;
}

export const ArbitrumIcon = ({ width, height, color }: IIcon) => (
  <CustomSvgIcon
    width={20.59}
    height={14}
    fill={color || "#FFFFFF"}
    viewBox="0 0 20.59 14"
    style={{ width: width || 15, height: height || 15 }}
  >
    <path
      d="M7.14297 0H5.25723C5.127 0 5.01077 0.0814337 4.96608 0.203739L0.00944174 13.7919C-0.0274145 13.893 0.0474304 14 0.155013 14H2.04075C2.17099 14 2.28722 13.9186 2.3319 13.7964L7.28854 0.208062C7.3254 0.106965 7.25055 0 7.14297 0ZM9.4366 5.69356C9.38708 5.55777 9.19508 5.55777 9.14545 5.69356L8.16784 8.37397C8.14282 8.44254 8.14282 8.5178 8.16784 8.58636L10.0683 13.7963C10.1129 13.9186 10.2292 13.9999 10.3594 13.9999H12.2452C12.3528 13.9999 12.4276 13.8929 12.3908 13.7918L9.4366 5.69356ZM14.2439 8.31715C14.2934 8.45294 14.4854 8.45294 14.5349 8.31715L17.4929 0.208062C17.5297 0.106965 17.4549 0 17.3473 0H15.4616C15.3313 0 15.2151 0.0813307 15.1704 0.203739L13.2661 5.42424C13.2411 5.49281 13.2411 5.56796 13.2661 5.63653L14.2439 8.31715ZM10.5257 0.202297C10.4815 0.0808159 10.366 0 10.2368 0H8.34471C8.21458 0 8.09835 0.0813307 8.05367 0.203636L3.09713 13.7919C3.06027 13.893 3.13511 14 3.2427 14H5.12854C5.25877 14 5.375 13.9187 5.41968 13.7964L9.21464 3.39242C9.23955 3.32416 9.33601 3.32416 9.36083 3.39242L13.1559 13.7964C13.2005 13.9187 13.3168 14 13.447 14H15.3329C15.4404 14 15.5153 13.893 15.4784 13.792L10.5257 0.202297ZM20.435 0H18.549C18.4189 0 18.3026 0.0813307 18.258 0.203636L14.8099 9.65653C14.7849 9.72509 14.7849 9.80025 14.8099 9.86881L15.7877 12.5494C15.8372 12.6852 16.0292 12.6852 16.0787 12.5494L19.4921 3.19197L20.5806 0.20796C20.6174 0.106965 20.5426 0 20.435 0Z"
      fill={color || "#FFFFFF"}
    />
  </CustomSvgIcon>
);

export const EthereumIcon = ({ width, height, color }: IIcon) => (
  <CustomSvgIcon
    width={20}
    height={20}
    fill={color || "#FFFFFF"}
    viewBox="0 0 20 20"
    style={{ width: width || 15, height: height || 15 }}
  >
    <path
      d="M9.99834 0V7.39307L15.996 10.1853L9.99834 0Z"
      fill={color || "#FFFFFF"}
    />
    <path
      d="M9.99843 0L4 10.1853L9.99843 7.39307V0Z"
      fill={color || "#FFFFFF"}
    />
    <path
      d="M9.99834 14.9765V20L16 11.3492L9.99834 14.9765Z"
      fill={color || "#FFFFFF"}
    />
    <path
      d="M9.99843 20V14.9757L4 11.3492L9.99843 20Z"
      fill={color || "#FFFFFF"}
    />
    <path
      d="M9.99834 13.8142L15.996 10.1859L9.99834 7.39543V13.8142Z"
      fill={color || "#FFFFFF"}
    />
    <path
      d="M4 10.1859L9.99843 13.8142V7.39543L4 10.1859Z"
      fill={color || "#FFFFFF"}
    />
  </CustomSvgIcon>
);

export const PolygonIcon = ({ width, height, color }: IIcon) => (
  <CustomSvgIcon
    width={19}
    height={16}
    fill={color || "#FFFFFF"}
    viewBox="0 0 19 16"
    style={{ width: width || 17, height: height || 15 }}
  >
    <path
      d="M13.6968 4.89093C13.3645 4.70036 12.9398 4.70036 12.564 4.89093L9.91721 6.45908L8.12002 7.4555L5.52227 9.01821C5.19006 9.20878 4.76527 9.20878 4.3895 9.01821L2.35813 7.7822C2.02592 7.59163 1.79174 7.21048 1.79174 6.78577V4.41177C1.79174 4.03063 1.98235 3.65493 2.35813 3.41535L4.3895 2.22835C4.7217 2.03777 5.14649 2.03777 5.52227 2.22835L7.55364 3.46435C7.88584 3.65493 8.12002 4.03607 8.12002 4.46078V6.02893L9.91721 4.98349V3.37179C9.91721 2.99064 9.7266 2.61494 9.35082 2.37536L5.57128 0.14293C5.23908 -0.0476434 4.81429 -0.0476434 4.43851 0.14293L0.566386 2.42437C0.190611 2.61494 0 2.99609 0 3.37179V7.83121C0 8.21235 0.190611 8.58805 0.566386 8.82763L4.39494 11.0601C4.72715 11.2506 5.15194 11.2506 5.52772 11.0601L8.12547 9.54092L9.92266 8.49549L12.5204 6.97635C12.8526 6.78577 13.2774 6.78577 13.6532 6.97635L15.6846 8.16335C16.0168 8.35392 16.2509 8.73507 16.2509 9.15978V11.5338C16.2509 11.9149 16.0603 12.2906 15.6846 12.5302L13.7022 13.7172C13.37 13.9078 12.9452 13.9078 12.5694 13.7172L10.5326 12.5302C10.2004 12.3396 9.96623 11.9585 9.96623 11.5338V10.0146L8.16904 11.0601V12.6282C8.16904 13.0094 8.35965 13.3851 8.73542 13.6246L12.564 15.8571C12.8962 16.0476 13.321 16.0476 13.6968 15.8571L17.5253 13.6246C17.8575 13.4341 18.0917 13.0529 18.0917 12.6282V8.11979C18.0917 7.73864 17.9011 7.36294 17.5253 7.12336L13.6968 4.89093Z"
      fill={color || "#FFFFFF"}
    />
  </CustomSvgIcon>
);

export const BSCIcon = ({ width, height, color }: IIcon) => (
  <CustomSvgIcon
    width={18}
    height={18}
    fill={color || "#FFFFFF"}
    viewBox="0 0 18 18"
    style={{ width: width || 15, height: height || 15 }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99996 4.0681L5.50436 7.56363L3.47034 5.52962L8.99996 0L14.5313 5.53141L12.4973 7.56547L8.99996 4.0681ZM4.06805 8.99973L2.03406 6.96574L0 8.99983L2.03396 11.0338L4.06805 8.99973ZM8.99996 13.9316L5.50436 10.4361L3.46744 12.4674L3.47027 12.4702L8.99996 17.9998L14.5313 12.4684L14.5324 12.4673L12.4973 10.4344L8.99996 13.9316ZM15.966 11.0341L18 9.00009L15.966 6.96611L13.9321 9.00009L15.966 11.0341ZM11.061 8.99885H11.0601L11.062 8.99984L8.997 11.0649L6.93479 9.00272L6.93199 8.99984L8.997 6.93483L11.061 8.99885Z"
      fill={color || "#FFFFFF"}
    />
  </CustomSvgIcon>
);

export const SimpleNetworkIcon = ({
  name,
  width,
  height,
  color,
}: ISimpleNetworkIconProps & IIcon): JSX.Element | null => {
  let networkName = useRef("");

  switch (name) {
    case Network.Eth:
    case NetworkCode.SEPOLIA:
    case NetworkCode.GOERLI:
    case NetworkCode.ETH:
    case Token.ETH:
      networkName.current = Token.ETH;
      return <EthereumIcon width={width} height={height} color={color} />;
    case Network.Matic:
    case NetworkCode.MATIC:
    case Token.MATIC:
      return <PolygonIcon width={width} height={height} color={color} />;
    case Network.Bsc:
    case NetworkCode.BNB:
    case Token.BNB:
      return <BSCIcon width={width} height={height} color={color} />;
    case Network.ArbitrumNova:
    case NetworkCode.ARB:
    case Token.ARB:
      return <ArbitrumIcon width={width} height={height} color={color} />;
    default:
      return null;
  }
};
