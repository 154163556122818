import {makeStyles} from "tss-react/mui"


export const useControlsStyles = makeStyles<{ isActive?: boolean }>()((theme, params) => ({
  heading: {
    fontSize: "40px",
    lineHeight: "44px",
    marginBottom: 20
  },
  slideWrapper: {
    display: "flex",
    maxWidth: 156,
    maxHeight: 90,
    width: '100%',
    height: "100%",
    marginBottom: theme.spacing(4),
    border: `2px solid ${params?.isActive ? theme.palette.yellow.main : 'transparent'}`,
    borderRadius: '6px',
    cursor: "pointer",
  },
  slide: {
    width: 'inherit',
    objectFit: 'contain',
    borderRadius: 6,
  },
}))
