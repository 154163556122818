import { makeStyles } from "tss-react/mui";

export const useTabButtonsStyles = makeStyles()(theme => ({
  root: {},

  tab: {
    height: 40,
    minHeight: 40,
    background: theme.palette.grey[700],
    marginRight: theme.spacing(0.5),
    padding: 0,
    fontWeight: 600,

    "&.Mui-selected": {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.yellow.main,
    },

    "&: first-of-type": {
      borderRadius: "6px 0 0 6px",
    },
    "&: last-of-type": {
      borderRadius: "0 6px 6px 0",
    },
  },
}));
