import { Box } from "@mui/material";
import { CollectibleTile, ICollectibleTileData } from "../CollectibleTile";

interface IListProps {
  items?: ICollectibleTileData[];
}

export const CollectiblesGrid = ({ items }: IListProps): JSX.Element => {
  return (
    <Box display="flex" flexWrap="wrap" gap="40px 20px">
      {!!items && items.length > 0
        ? items.map((x) => {
            return (
              <CollectibleTile
                key={x.id}
                network={x.network}
                token={x.token}
                name={x.name}
                tokenId={x.tokenId}
                status={x.status}
                collectionName={x.collectionName}
                price={x.price}
                image={x.image}
                link={x.link}
              />
            );
          })
        : null}
    </Box>
  );
};
