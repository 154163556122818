import { GAME_TILE_WIDHT } from "modules/common/const";
import { makeStyles } from "tss-react/mui";

export const useGameTileSkeletonStyles = makeStyles()((theme) => ({
  sliderRoot: {
    objectFit: "contain",
    width: GAME_TILE_WIDHT,
    maxWidth: GAME_TILE_WIDHT,
    height: 156,
    maxHeight: 156,
    borderRadius: 12,
    transform: "unset",
    marginBottom: theme.spacing(4),
  },
  small: {
    borderRadius: 4,
    transform: "unset",
    "& + &": {
      marginTop: theme.spacing(0.75),
    },
  },
  leftMargin: {
    marginLeft: theme.spacing(0.5),
  },
}));
