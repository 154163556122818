import { Components, Palette } from "@mui/material";

export const getMuiAccordion = (
  palette: Palette
): Components["MuiAccordion"] => {
  return {
    styleOverrides: {
      root: {
        boxShadow: "none",
        borderRadius: 12,
        backgroundColor: palette.background.default,
        padding: 0,
        border: `1px solid ${palette.grey[500]}`,
        minHeight: 68,
        marginTop: 0,

        "&.Mui-expanded": {
          marginBottom: 12,
          marginTop: 0,

          "&:first-of-type": {
            marginBottom: 12,
            marginTop: 0,
          },
        },

        "&:before": {
          display: "none",
        },

        "&:first-of-type": {
          borderRadius: 12,
        },

        "&:last-of-type": {
          borderRadius: 12,
        },
      },
    },
  };
};
