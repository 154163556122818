import { Token } from "modules/common/types";

import metaApesLogo from "assets/img/meta_apes_logo.png";

enum Platforms {
  PC = "PC",
  MOBILE = "MOBILE",
  CONSOLE = "CONSOLE",
}

export interface IGameItem {
  id: string;
  name: string;
  developerName?: string;
  token: Token;
  type?: string;
  platforms: Platforms[];
  genres: string[],
}


interface IDeveloperData {
  game: {
    name: string;
    genres?: string[];
    logo?: string;
    description?: string;
    secondaryDesc?: string;
    platform: Platforms;
    background: string;
  },
  games?: IGameItem[],
}

const random = (min: number, max: number) => Math.floor(Math.random() * (max - min) + min);
const uid = random.bind(null, 0, 10_000);

const gameDescription = `
Meta Apes is a collaborative project made up of folks
from both the gaming and blockchain industries.
Our gaming professionals have worked at places
like Ubisoft, Gameloft, Zynga, EA, AppLovin & Epic.
Our blockchain/crypto experts have been in the space since 2017.`;

export const useDeveloperData = ():IDeveloperData => {

  return {
    game: {
      background: 'https://i.imgur.com/ezBM7kw.png',
      name: 'Meta Apes',
      description: gameDescription,
      logo: metaApesLogo,
      platform: Platforms.MOBILE,
    },
    games: [
      {
        id: uid.toString(),
        name: "Superpower Squad",
        developerName: "Superpower Squad",
        token: Token.ETH,
        type: "SPS - Action",
        genres: ['SPS', 'Action'],
        platforms: [Platforms.MOBILE],
      },
      {
        id: uid.toString(),
        name: "Starfall Arena",
        developerName: "Starfall studio",
        token: Token.ETH,
        genres: ['SPS', 'Action'],
        platforms: [Platforms.MOBILE],
      },
    ]
  };
}
