import { CustomSvgIcon } from "../../modules/common/components/CustomSvgIcon";

interface IIcon {
  width?: number
  height?: number
  color?: string
}
export const Filters = ({ width, height, color }: IIcon) =>
  <CustomSvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" style={{ width: width || 14, height: height || 14 }}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6 3C6 3.55228 5.55228 4 5 4C4.44772 4 4 3.55228 4 3C4 2.44772 4.44772 2 5 2C5.55228 2 6 2.44772 6 3ZM5 6C6.30622 6 7.41746 5.16519 7.82929 4H14V2H7.82929C7.41746 0.834808 6.30622 0 5 0C3.69378 0 2.58254 0.834808 2.17071 2H0L0 4H2.17071C2.58254 5.16519 3.69378 6 5 6ZM10 11C10 11.5523 9.55228 12 9 12C8.44772 12 8 11.5523 8 11C8 10.4477 8.44772 10 9 10C9.55228 10 10 10.4477 10 11ZM11.8293 12C11.4175 13.1652 10.3062 14 9 14C7.69378 14 6.58254 13.1652 6.17071 12H0L0 10H6.17071C6.58254 8.83481 7.69378 8 9 8C10.3062 8 11.4175 8.83481 11.8293 10H14V12H11.8293Z" fill={color || "#131313"}/>
  </CustomSvgIcon>