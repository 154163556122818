import { Button } from "@mui/material";
import { t } from "modules/utils/intl";
import { useActionCellStyles } from "./useActionCellStyles";

export const ActionCell = (): JSX.Element => {
  const { classes } = useActionCellStyles();

  return (
    <Button variant="outlined" className={classes.button}>
      {t("account.balance.send")}
    </Button>
  );
};
