/**
 * Capitalizes the first letter of each word in a string.
 *
 * @param {string} str - The string to be capitalized.
 * @param {string} [separator] - The separator used to distinguish words in the string. If not provided, only the first letter of the string will be capitalized.
 * @returns {string} The capitalized string.
 *
 * @example
 * // returns "Hello World"
 * capitalize("hello world", " ")
 *
 * @example
 * // returns "Hello"
 * capitalize("hello")
 */
export const capitalize = (str: string, separator?: string) => {
  if (separator) {
    return str
      .split(separator)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(separator);
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};
