import { useCallback } from "react";
import { Box, Skeleton } from "@mui/material";
import { useControlsStyles } from "./useControlsStyles";
import { ISlide, LightboxMedia } from "../../../types";
import isEmpty from "lodash/isEmpty";

type ISlideComponent = {
  slide: ISlide;
  idx: number;
  isActive: boolean;
  onClick: (slide: LightboxMedia, idx: number) => void;
};

export const Slide = ({ slide, isActive, onClick, idx }: ISlideComponent) => {
  const { classes } = useControlsStyles({ isActive });
  const src = slide.contentType === "image" ? slide.fileUri : slide.poster;

  const handleClick = useCallback(() => {
    onClick(slide, idx);
  }, [slide, idx, onClick]);

  return (
    <Box
      className={classes.slideWrapper}
      onClick={handleClick}
      data-id={isActive && "active-slide"}
    >
      {isEmpty(src) ? (
        <Skeleton className={classes.slide} />
      ) : (
        <img
          src={src || ""}
          alt="lightbox slide thumbnail"
          className={classes.slide}
        />
      )}
    </Box>
  );
};
