import { Box } from "@mui/material";
import { ReactComponent as LoaderIcon } from "uiKit/icons/loader.svg";
import { useLoaderCenteredStyles } from "./useLoaderCenteredStyles";

export const LoaderCentered = (): JSX.Element => {
  const { classes } = useLoaderCenteredStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={1}
      width={1}
    >
      <LoaderIcon className={classes.root} />
    </Box>
  );
};
