import BigNumber from "bignumber.js";
import { useBuyDialogStyles } from "./useBuyDialogStyles";
import { Box, Typography } from "@mui/material";
import { t, tHTML } from "modules/utils/intl";
import { Badge } from "uiKit/components/Badge";
import { Button } from "uiKit/components/Button";
import { SimpleNetworkIcon } from "uiKit/components/Icon";
import { NetworkCode } from "modules/common/types";
import { getNativeToken } from "modules/common/utils/getNativeToken";
import { USD_DECIMALS } from "modules/common/const";
import { useMemo } from "react";

export interface IBuyDialogData {
  image: string;
  name: string;
  tokenId: number;
  fromAddress: string;
  tokenStandart: string;
  price: BigNumber;
  usdPrice: BigNumber;
  gasFee: BigNumber;
  usdGasFee: BigNumber;
  network: NetworkCode;
}

interface IBuyDialogProps extends IBuyDialogData {
  isLoading: boolean;
  handleClickCheckout: () => void;
}

export const BuyDialog = ({
  image,
  name,
  tokenId,
  fromAddress,
  tokenStandart,
  price,
  usdPrice,
  gasFee,
  usdGasFee,
  network,
  isLoading,
  handleClickCheckout,
}: IBuyDialogProps): JSX.Element => {
  const { classes } = useBuyDialogStyles();

  const token = useMemo(() => getNativeToken(network), [network]);

  return (
    <Box>
      <Box display="flex" mb={5}>
        <img alt="" src={image} className={classes.image} />
        <Box display="flex" flexDirection="column">
          <Typography variant="body1Demibold" mb={1.5}>
            {name}
          </Typography>
          <Typography
            variant="notes"
            color="textSecondary"
            className={classes.mainInfoRow}
          >
            {tHTML("plaza.collectible.buy-dialog.token-id", {
              value: tokenId,
            })}
          </Typography>
          <Typography
            variant="notes"
            color="textSecondary"
            className={classes.mainInfoRow}
            mb={2}
          >
            {tHTML("plaza.collectible.buy-dialog.from", {
              value: fromAddress,
            })}
          </Typography>
          <Badge text={tokenStandart} />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="caption" color="textSecondary">
          {t("plaza.collectible.buy-dialog.subtotal")}
        </Typography>
        <Box display="flex" gap={1} alignItems="center">
          <SimpleNetworkIcon height={15} width={15} name={network} />
          <Typography variant="body1Demibold">
            {t("formats.token-amount", {
              value: price.toFormat(),
              token,
            })}
          </Typography>
          <Typography color="textSecondary">
            {t("formats.estimated-usd-value-wo-space", {
              value: usdPrice.toFormat(USD_DECIMALS),
            })}
          </Typography>
        </Box>
      </Box>
      {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="caption" color="textSecondary">
          {t("plaza.collectible.buy-dialog.gas-fee")}
        </Typography>
        <Typography>{gasFee.toFormat()}</Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={10}
      >
        <Typography variant="caption" color="textSecondary">
          {t("plaza.collectible.buy-dialog.total")}
        </Typography>

        <Box display="flex" gap={1} alignItems="center">
          <SimpleNetworkIcon name={network} />
          <Typography variant="body1Demibold">
            {t("formats.token-amount", {
              value: price.plus(gasFee).toFormat(),
              token,
            })}
          </Typography>
          <Typography color="textSecondary">
            {t("formats.estimated-usd-value-wo-space", {
              value: usdPrice.plus(usdGasFee).toFormat(USD_DECIMALS),
            })}
          </Typography>
        </Box>
      </Box> */}
      <Button
        fullWidth
        variant="contained"
        disabled={isLoading}
        isLoading={isLoading}
        className={classes.button}
        onClick={handleClickCheckout}
      >
        <Typography variant="body1Demibold">
          {t("plaza.collectible.buy-dialog.checkout")}
        </Typography>
      </Button>
      <Typography variant="body2" color="textSecondary">
        {tHTML("plaza.collectible.buy-dialog.agreeement", {
          service: "",
          policy: "",
        })}
      </Typography>
    </Box>
  );
};
