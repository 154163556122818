import { Palette } from "@mui/material";

export function getGlobalStyles(palette: Palette): string {
  return `
    html {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      min-height: -webkit-fill-available;
      scroll-behavior: smooth;
      font-family: "TT Commons Regular";
      background-color: #131313;
    }

    body {
      background-color: #131313;
    }

    a {
      font-size: inherit;
      text-decoration: none;
      color: #FDFF5D;
    }

    input {
      font-family: "TT Commons Regular";
    }
  `;
}
