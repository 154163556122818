import { Form } from "./components/Form";
import { Header } from "./components/Header";

export const AddFunds = (): JSX.Element => {
  return (
    // TODO: change it
    <div style={{ width: "400px" }}>
      <Header />
      <Form />
    </div>
  );
};
