import { Env } from "../common/types";
import { currentEnv } from "../common/env";

interface IGatewayConfig {
  gamingUrl: string;
  mirageIdUrl: string;
  nftManagerUrl: string;
  marketPlaceUrl: string;
}

interface IWeb3Config {
  ethChainId: number;
  ethSepoliaChainId: number;
  polygonChainId: number;
  bscChainId: number;
  arbitrumNovaChainId: number;
}

interface IConfig {
  gatewayConfig: IGatewayConfig;
  // TODO: review it, because we don't use it...probably better to use
  web3Config: IWeb3Config;
  oryUrl: string;
  developersUrl: string;
  companyUrl: string;
}

const PROD_CONFIG: IConfig = {
  gatewayConfig: {
    gamingUrl: "https://mosaic-game-services.prod.mirage.xyz/api/",
    mirageIdUrl: "https://mirage-id.prod.mirage.xyz/api/",
    nftManagerUrl: "https://nft-manager.prod.mirage.xyz/api/",
    marketPlaceUrl: "https://mosaic-marketplace.prod.mirage.xyz/api/",
  },
  web3Config: {
    // TODO: change it to mainnet in the near future
    ethChainId: 1, // mainnet
    ethSepoliaChainId: 11155111, // sepoia
    polygonChainId: 137, // mainnet
    bscChainId: 56, // mainnet
    arbitrumNovaChainId: 42170, // mainnet
  },
  oryUrl: "https://auth.prod.mirage.xyz",
  developersUrl: "https://mirage.xyz/developers",
  companyUrl: "https://mirage.xyz/about",
};

const STAGE_CONFIG: IConfig = {
  gatewayConfig: {
    gamingUrl: "https://mosaic-game-services.staging.mirage.xyz/api/",
    mirageIdUrl: "https://mirage-id.staging.mirage.xyz/api/",
    nftManagerUrl: "https://nft-manager.staging.mirage.xyz/api/",
    marketPlaceUrl: "https://mosaic-marketplace.staging.mirage.xyz/api/",
  },
  web3Config: {
    ethChainId: 5, // goerli
    ethSepoliaChainId: 11155111, // sepoia
    polygonChainId: 137, // mainnet
    bscChainId: 97, // testnet
    arbitrumNovaChainId: 42170, // mainnet
  },
  oryUrl: "https://ory.staging.mirage.xyz",
  developersUrl: "https://mirage.xyz/developers",
  companyUrl: "https://mirage.xyz/about",
};

const LOCAL_CONFIG: IConfig = {
  gatewayConfig: {
    gamingUrl: "http://localhost:6001/api/",
    mirageIdUrl: "http://localhost:5001/api/",
    nftManagerUrl: "http://localhost:7001/api/",
    marketPlaceUrl: "https://mosaic-marketplace.works.mirage.xyz/api/",
  },
  web3Config: {
    ethChainId: 5, // goerli
    ethSepoliaChainId: 11155111, // sepoia
    polygonChainId: 137, // mainnet
    bscChainId: 97, // testnet
    arbitrumNovaChainId: 42170, // mainnet
  },
  oryUrl: "http://localhost:4000",
  developersUrl: "https://mirage.xyz/developers",
  companyUrl: "https://mirage.xyz/about",
};

export function configFromEnv(env = currentEnv): IConfig {
  switch (env) {
    case Env.Prod:
      return PROD_CONFIG;
    case Env.Stage:
      return STAGE_CONFIG;
    default:
      return LOCAL_CONFIG;
  }
}
