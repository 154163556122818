import { useState, useCallback, useEffect, useMemo } from "react";
import { uid } from "react-uid";
import { Header } from "./components/Header";
// import { Navigation } from "./components/Navigation";
import { Box } from "@mui/material";
import { LoaderCentered } from "modules/common/components/LoaderCentered";
import { Dialog } from "modules/common/components/Dialog";
import { ViewMoreButton } from "modules/common/components/ViewMoreButton";
import { UnauthorizedWalletDialog } from "./components/UnauthorizedWalletDialog";
import { Empty } from "modules/common/components/Empty";
import { t } from "modules/utils";
import { ContentFullWrapper } from "modules/common/components/ContentFullWrapper";
import {
  CollectibleTile,
  ICollectibleTileData,
} from "modules/common/components/CollectibleTile";
import { METAMASK_ID } from "modules/api/const";
import { useLazyFetchCheckIsWalletConnectedQuery } from "modules/common/actions/useLazyFetchCheckIsWalletConnectedQuery";
import {
  useVerifyWalletMutation,
  IVerifyWalletResponse,
} from "modules/common/actions/useVerifyWalletMutation";
import { useDialog, useConnectedData } from "modules/common/hooks";
import { IMutationResponse } from "modules/common/types";
import { useLazyFetchUserInventoryQuery } from "./actions/useLazyFetchUserInventoryQuery";
import { transformCollectiblesData } from "modules/common/utils/transformCollectiblesData";
import { oryLogoutAsync } from "modules/auth";
// import { SearchSortBar } from "./components/SearchSortBar";

export const Inventory = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<ICollectibleTileData[]>([]);
  const {
    isOpened: isOpenedGuard,
    onOpen: onOpenGuard,
    onClose: onCloseGuard,
  } = useDialog();

  const { address, walletId } = useConnectedData();

  const [fetchUserInventory, { data: fetchUserInventoryData, isFetching }] =
    useLazyFetchUserInventoryQuery();
  const [
    fetchCheckIsWalletConnected,
    { data: isWalletConnectedData, isLoading: isWalletConnectedLoading },
  ] = useLazyFetchCheckIsWalletConnectedQuery();

  const [verifyWallet, { isLoading: isConnectWalletLoading }] =
    useVerifyWalletMutation();

  const handleClickConnectAnotherAccount = useCallback(
    () => oryLogoutAsync(),
    [],
  );
  const handleClickConnectWallet = useCallback(async () => {
    const verification = (await verifyWallet({
      wallet: walletId || METAMASK_ID,
    })) as IMutationResponse<IVerifyWalletResponse>;

    if (verification?.error) return;

    const walletConnectedCheck =
      (await fetchCheckIsWalletConnected()) as IMutationResponse<boolean>;

    if (walletConnectedCheck?.error) return;
  }, [verifyWallet, walletId, fetchCheckIsWalletConnected]);

  const updateUserInventory = useCallback(
    async (isNewAddress: boolean) => {
      if (!isWalletConnectedLoading) {
        if (isWalletConnectedData === undefined) {
          await fetchCheckIsWalletConnected();
        } else if (!isWalletConnectedData) {
          onOpenGuard();
        } else if (!isWalletConnectedLoading) {
          onCloseGuard();
          const fetchInventoryRequest = await fetchUserInventory({
            pageNumber: page,
          });

          if (fetchInventoryRequest.isSuccess) {
            setData((oldData) => {
              const newData = transformCollectiblesData(
                fetchInventoryRequest.data.items,
              );

              return isNewAddress ? newData : [...oldData, ...newData];
            });
          }
        }
      }
    },
    [
      page,
      isWalletConnectedData,
      isWalletConnectedLoading,
      fetchCheckIsWalletConnected,
      onOpenGuard,
      onCloseGuard,
      fetchUserInventory,
    ],
  );

  useEffect(() => {
    updateUserInventory(false);
  }, [
    page,
    fetchUserInventory,
    fetchCheckIsWalletConnected,
    onCloseGuard,
    onOpenGuard,
    updateUserInventory,
  ]);

  useEffect(() => {
    updateUserInventory(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const handleClickViewMore = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const isLoading = useMemo(
    () => isFetching || isWalletConnectedLoading,
    [isFetching, isWalletConnectedLoading],
  );
  const hasNextPage = useMemo(
    () =>
      fetchUserInventoryData?.totalPages &&
      fetchUserInventoryData?.totalPages > page
        ? true
        : false,
    [fetchUserInventoryData?.totalPages, page],
  );

  if (isOpenedGuard) {
    return (
      <>
        <Dialog open isCloseButtonHidden>
          <UnauthorizedWalletDialog
            isConnectWalletLoading={isConnectWalletLoading}
            handleClickConnectWallet={handleClickConnectWallet}
            handleClickConnectAnotherAcc={handleClickConnectAnotherAccount}
          />
        </Dialog>
      </>
    );
  }

  return (
    <Box width={1} mb={25}>
      <Header />

      {/* <Navigation onNavigationItemClick={() => {}} /> */}
      {/* <SearchSortBar /> */}

      {isLoading && data.length === 0 && (
        <ContentFullWrapper>
          <LoaderCentered />
        </ContentFullWrapper>
      )}

      {!isLoading && data.length === 0 && (
        <ContentFullWrapper>
          <Empty text={t("account.inventory.empty")} />
        </ContentFullWrapper>
      )}

      <Box display="flex" flexWrap="wrap" width={1} gap={5} mb={8}>
        {data.map((item) => (
          <CollectibleTile key={uid(item)} {...item} />
        ))}
      </Box>

      {hasNextPage && (
        <ViewMoreButton isLoading={isLoading} onClick={handleClickViewMore} />
      )}
    </Box>
  );
};
