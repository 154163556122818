import { Components, Palette } from "@mui/material";

export const getMuiAccordionDetails = (
  palette: Palette
): Components["MuiAccordionDetails"] => {
  return {
    styleOverrides: {
      root: {
        margin: "20px 20px 32px",
        padding: 0,
      },
    },
  };
};
