import { makeStyles } from "tss-react/mui";

export const useBadgeStyles = makeStyles()(theme => ({
  root: {
    minHeight: 24,
    width: "fit-content",
    borderRadius: 6,
    backgroundColor: theme.palette.grey[500],
    padding: theme.spacing(0, 2),
  },
}));
