import { Box } from "@mui/material";
import { ViewMode } from "../../types";
import { useGamesListStyles } from "./useGamesListStyles";
import { GamesList as BaseGameList } from "modules/common/components/GamesList";
import { GamesGrid } from "modules/common/components/GamesGrid";
import { IGamesListItem } from "modules/plaza/types";

interface IGamesListProps {
  viewMode: ViewMode;
  items: IGamesListItem[];
}

export const GamesList = ({
  viewMode,
  items,
}: IGamesListProps): JSX.Element => {
  const { classes } = useGamesListStyles();

  return (
    <>
      {viewMode === ViewMode.GRID && (
        <Box className={classes[ViewMode.GRID]}>
          <GamesGrid data={items} />
        </Box>
      )}

      {viewMode === ViewMode.LIST && (
        <Box className={classes[ViewMode.LIST]}>
          <BaseGameList data={items} />
        </Box>
      )}
    </>
  );
};
