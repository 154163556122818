import { Box, Skeleton } from "@mui/material";
import { useCollectibleTileSkeletonStyles } from "./useCollectibleTileSkeletonStyles";

export const CollectibleTileSkeleton = (): JSX.Element => {
  const { classes } = useCollectibleTileSkeletonStyles();

  return (
    <Box>
      <Skeleton className={classes.sliderRoot} />
      <Skeleton height={16} width={92} className={classes.small} />
      <Skeleton height={16} width={126} className={classes.small} />
    </Box>
  );
};
