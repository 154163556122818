import { CustomSvgIcon } from "modules/common/components/CustomSvgIcon";

export const Trailer = () => (
  <CustomSvgIcon
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    style={{ fill: "none" }}
  >
    <circle
      cx="9.00039"
      cy="9.50039"
      r="7.1"
      stroke="#FDFF5D"
      strokeWidth="2"
    />
    <path
      d="M8.19961 8.11475L10.5996 9.50039L8.19961 10.886L8.19961 8.11475Z"
      fill="#FDFF5D"
      stroke="#FDFF5D"
      strokeWidth="2"
    />
  </CustomSvgIcon>
);
