import { CustomSvgIcon } from "../../modules/common/components/CustomSvgIcon";

export const Copy = ({ color }: { color?: string }) => (
  <CustomSvgIcon width="20" height="20" viewBox="0 0 20 20" fill="transparent">
    <path
      d="M3 7H13V17H3V7Z"
      stroke={color || "#FDFF5D"}
      fill="transparent"
      strokeWidth="2"
    />
    <path
      d="M6 3H17V14"
      stroke={color || "#FDFF5D"}
      fill="transparent"
      strokeWidth="2"
    />
  </CustomSvgIcon>
);
