// Enum prefix?
export enum ICollectionTabs {
  ITEMS = "items",
  ACTIVITY = "activity",
}

export interface IGameVendorItem {
  title: string;
  icon: string;
}

export interface ICollectionTab {
  title: string;
  value: ICollectionTabs;
}

export interface FilterParams {
  type: "viewType" | "toggleFilters" | "searchChange";
  value?: any;
}
