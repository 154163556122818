import { Box, Typography } from "@mui/material";
import { TypeOptions } from "react-toastify";
import { useNotificationStyles } from "./useNotificationStyles";

import { ReactComponent as SuccessIcon } from "uiKit/icons/toasts/success.svg";
import { ReactComponent as InfoIcon } from "uiKit/icons/toasts/info.svg";
import { ReactComponent as WarningIcon } from "uiKit/icons/toasts/warning.svg";
import { ReactComponent as ErrorIcon } from "uiKit/icons/toasts/error.svg";
import { ReactComponent as CloseIcon } from "uiKit/icons/toasts/close.svg";

interface INotificationProps {
  className?: string;
  message: string;
  type: TypeOptions;
  title?: string;
}
// TODO: import from theme?
const GAP = 3;

const icon = {
  success: <SuccessIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
  default: <InfoIcon />,
};

export const Notification = ({
  className,
  message,
  type = "info",
  title,
}: INotificationProps): JSX.Element => {
  const { classes, cx } = useNotificationStyles();

  return (
    <Box className={cx(classes.root, className)}>
      <Box ml={GAP} mt={GAP} mb={GAP}>
        {type in icon ? icon[type] : icon["default"]}
      </Box>

      <Box display="flex" flexDirection="column" ml={GAP} mt={GAP} mb={GAP}>
        <Typography variant="caption">{title}</Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.message}
        >
          {message}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexGrow={2}
      >
        <CloseIcon className={classes.closeIcon} />
      </Box>
    </Box>
  );
};
