import { Session } from "@ory/client";
import { oryApi } from "modules/auth";
import { OrySessionContext } from "./context";
import { useState, useEffect } from "react";

export const OryLifecycleProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [session, setSession] = useState<Session | undefined>();

  // sorry for the callback hell doesn't play well with promises
  useEffect(() => {
    oryApi
      .toSession()
      .then(({ data: sessionData }) => {
        console.log("Ory session: ", sessionData.id);
        // User has a session!
        setSession(sessionData);
        // We could initialize a global logout URL here, but it's simpler to create a new flow anytime we need to log out
      })
      .catch((err) => {
        console.error("Ory login error: ", err);
      });
  }, []);

  return (
    <OrySessionContext.Provider value={{ session, setSession }}>
      {children}
    </OrySessionContext.Provider>
  );
};
