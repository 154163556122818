import { configFromEnv, web3Api } from "modules/api";
import { GET_POST_NFT_TOKEN } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { INftInfoData } from "modules/plaza/types";
import { processApiResponse } from "modules/utils/processApiResponse";
import { CollectibleCacheTags } from "../const";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IGetNftArgs {
  id: string;
}

export const { useGetCollectibleDetailsQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    getCollectibleDetails: build.query<INftInfoData, IGetNftArgs>({
      queryFn: queryFnNotifyWrapper<IGetNftArgs, never, INftInfoData>(
        async ({ id }: IGetNftArgs) => {
          try {
            const response = await fetch(
              nftManagerUrl + GET_POST_NFT_TOKEN + id,
              {
                method: "GET",
                credentials: "include",
              },
            );

            const data = await processApiResponse(response);
            return { data };
          } catch (err) {
            console.error("Error in `useGetCollectibleDetailsQuery.ts`", err);
            throw err;
          }
        },
      ),
      providesTags: [CollectibleCacheTags.main],
    }),
  }),
});
