import { Box, Button, Link, Typography } from "@mui/material";
import { getTxLinkByNetwork } from "modules/common/utils/getTxLinkByNetwork";
import { t, tHTML } from "modules/utils/intl";
import { ReactComponent as LinkIcon } from "uiKit/icons/external-link.svg";
import { useBuyInProgressDialogStyles } from "./useBuyInProgressDialogStyles";
import { getScanNameByNetwork } from "modules/common/utils/getScanNameByNetwork";

interface IBuyInProgressDialogProps {
  image: string;
  name: string;
  tokenId: number;
  chainId: number;
  txHash: string;
}

export const BuyInProgressDialog = ({
  image,
  name,
  tokenId,
  chainId,
  txHash,
}: IBuyInProgressDialogProps): JSX.Element => {
  const { classes } = useBuyInProgressDialogStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width={1}>
      <img alt="" src={image} className={classes.image} />
      <Typography variant="h3Demibold" mb={1}>
        {t("plaza.collectible.buy-in-progress.title")}
      </Typography>
      <Typography variant="body2" color="textSecondary" mb={12}>
        {tHTML("plaza.collectible.buy-in-progress.description", {
          name,
          tokenId,
        })}
      </Typography>

      <Link
        href={getTxLinkByNetwork(txHash, chainId)}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <Button fullWidth variant="contained" className={classes.button}>
          <Typography variant="body1Demibold" mr={1.5}>
            {t("plaza.collectible.buy-in-progress.view-scan", {
              value: getScanNameByNetwork(chainId),
            })}
          </Typography>
          <LinkIcon />
        </Button>
      </Link>
    </Box>
  );
};
