import { Components, Palette } from "@mui/material";

export const getMuiAccordionSummary = (
  palette: Palette
): Components["MuiAccordionSummary"] => {
  return {
    styleOverrides: {
      root: {
        height: 60,
        padding: 0,
        margin: "0 20px",
        borderBottom: `1px solid ${palette.background.default}`,

        "&.Mui-expanded": {
          minHeight: 0,
          // marginTop: 12,
          borderBottom: `1px solid ${palette.grey[500]}`,
        },

        "& > :last-child": {},
      },
    },
  };
};
