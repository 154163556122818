import { makeStyles } from "tss-react/mui";

export const useHeaderStyles = makeStyles()(theme => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(10),
  },
  totalBalance: {
    borderRadius: 12,
    backgroundColor: theme.palette.yellow.main,
    color: theme.palette.common.black,
    width: "100%",
    height: 112,
    padding: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  blackButton: {
    backgroundColor: theme.palette.grey[700],

    "&:hover": {
      backgroundColor: theme.palette.grey[700],
    },
  },
  yellowButton: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  ml3: {
    marginLeft: theme.spacing(3),
  },
}));
