import { Components, Palette } from "@mui/material";

export function getMuiTooltip(palette: Palette): Components["MuiTooltip"] {
  return {
    styleOverrides: {
      tooltip: {
        backgroundColor: palette.grey[500],
        color: palette.common.white,
        fontSize: 13,
        lineHeight: "16px",
        fontWeight: 400,
        borderRadius: 6,
        padding: 12,
      },
      arrow: {
        "&::before": {
          backgroundColor: palette.grey[500],
        },
      },
    },
  };
}
