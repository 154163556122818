import { Box, Typography } from "@mui/material";
import { ReactComponent as WalletIcon } from "uiKit/icons/wallet.svg";
import { useUnauthorizedWalletDialogStyles } from "./useUnauthorizedWalletDialogStyles";
import { t, tHTML } from "modules/utils/intl";
import { Button } from "uiKit/components/Button";

interface IUnauthorizedWalletDialogProps {
  isConnectWalletLoading: boolean;
  handleClickConnectWallet: () => void;
  handleClickConnectAnotherAcc: () => void;
}

export const UnauthorizedWalletDialog = ({
  isConnectWalletLoading,
  handleClickConnectWallet,
  handleClickConnectAnotherAcc,
}: IUnauthorizedWalletDialogProps): JSX.Element => {
  const { classes } = useUnauthorizedWalletDialogStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <WalletIcon className={classes.icon} />
      <Typography variant="h3Demibold" mb={3}>
        {t("unauthorized-wallet.title")}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        textAlign="center"
        mb={7}
      >
        {tHTML("unauthorized-wallet.description")}
      </Typography>
      <Box display="flex" flexDirection="column" gap={2} width={1}>
        <Button
          variant="contained"
          isLoading={isConnectWalletLoading}
          onClick={handleClickConnectWallet}
        >
          {t("unauthorized-wallet.connect-wallet")}
        </Button>
        <Button variant="outlined" onClick={handleClickConnectAnotherAcc}>
          {t("unauthorized-wallet.log-in")}
        </Button>
      </Box>
    </Box>
  );
};
