import { makeStyles } from "tss-react/mui";

export const useChainFilterStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    gap: "2px",
    marginRight: theme.spacing(2),
    maxHeight: 40,
  },
  btnAllChains: {
    textTransform: "none",
    padding: theme.spacing(2.75, 5.25),
  },
  btnChain: {
    padding: theme.spacing(2.5, 4),
  },
}));
