import { TransactionReceipt } from "web3-core";
import retry from "async-retry";
import { web3Api } from "modules/api";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { getProviderManager } from "modules/api/getProviderManager";

interface IGetTxReceiptArgs {
  txHash: string;
  wallet: string;
}

const POLL_INTERVAL = 5_000;

export const { useGetTxReceiptQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    getTxReceipt: build.query<TransactionReceipt | null, IGetTxReceiptArgs>({
      queryFn: queryFnNotifyWrapper<
        IGetTxReceiptArgs,
        never,
        TransactionReceipt | null
      >(async ({ txHash, wallet }) => {
        const providerManager = getProviderManager();
        const provider = await providerManager.getETHWriteProvider(wallet);

        const web3 = provider.getWeb3();

        return {
          data: await retry(
            async () => {
              return web3.eth.getTransactionReceipt(txHash);
            },
            {
              factor: 1,
              minTimeout: POLL_INTERVAL,
              retries: 3,
            },
          ),
        };
      }),
    }),
  }),
});
