import { Components, Palette } from "@mui/material";

export function getMuiMenuItem(palette: Palette): Components["MuiMenuItem"] {
  return {
    styleOverrides: {
      root: {
        height: 36,
        borderRadius: 6,
        padding: "4px 8px",
        marginBottom: "8px",

        "&:hover": {
          backgroundColor: palette.grey[500],
        },
      },
    },
  };
}
