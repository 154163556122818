import { prepareUrl } from "modules/common/utils/prepareUrl";
import { makeStyles } from "tss-react/mui";

interface IHeaderStyles {
  bg: string;
}

export const useHeaderStyles = makeStyles<IHeaderStyles>()((theme, { bg }) => ({
  root: {
    minHeight: 265,
    position: "relative",
    width: "100%",
    backgroundColor: "#1E1C33",
    marginBottom: theme.spacing(17),
  },
  img: {
    height: 265,
    borderRadius: 12,
    width: "100%",
    opacity: 0.4,
    position: "absolute",
    zIndex: 1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: `url(${prepareUrl(bg)})`,
  },
  icon: {
    height: 104,
    width: 104,
    borderRadius: 12,
    position: "absolute",
    bottom: theme.spacing(-7),
    zIndex: 1,
    left: theme.spacing(7),
  },
  nav: {
    transform: "rotate(-0.75turn)",
  },
}));
