import { configFromEnv, web3Api } from "modules/api";
import { GET_POST_NFT_TOKEN } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IResponse {
  chainTokenId: string;
  nftContractAddress: string;
}

interface IGetCollectibleContractArgs {
  nftTokenId: string;
}

export const { useGetCollectibleContractQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    getCollectibleContract: build.query<IResponse, IGetCollectibleContractArgs>(
      {
        queryFn: queryFnNotifyWrapper<
          IGetCollectibleContractArgs,
          never,
          IResponse
        >(async ({ nftTokenId }) => {
          try {
            const response = await fetch(
              nftManagerUrl + GET_POST_NFT_TOKEN + nftTokenId + "/contract",
              {
                method: "GET",
                credentials: "include",
              },
            );

            const data = await processApiResponse(response);

            return { data };
          } catch (err) {
            console.error("Error in `useGetCollectibleContractQuery.ts`", err);
            throw err;
          }
        }),
      },
    ),
  }),
});
