import { Components, Palette } from "@mui/material";

export function getMuiSkeleton(palette: Palette): Components["MuiSkeleton"] {
  return {
    styleOverrides: {
      root: {
        backgroundColor: palette.grey[500],
      },
    },
  };
}
