import { Box, Typography } from "@mui/material";
import { INftDetails, INftProperty } from "modules/plaza/types";
import { t } from "modules/utils/intl";
import { useCallback, useState } from "react";
import { Accordion } from "uiKit/components/Accordion";
import { useAdditionalInfoStyles } from "./useAdditionalInfoStyles";
import { AttributeRow } from "../AttributeRow";
import { DetailsAccordion } from "../DetailsAccordion";

interface IAdditionalInfoProps {
  description?: string;
  attributes?: INftProperty[];
  details: INftDetails;
}

export const AdditionalInfo = ({
  description,
  attributes,
  details,
}: IAdditionalInfoProps): JSX.Element => {
  const { classes } = useAdditionalInfoStyles();

  const [expandedDescription, setExpandedDescription] = useState(true);
  const [expandedAttributes, setExpandedAttributes] = useState(true);
  const [expandedDetails, setExpandedDetails] = useState(false);

  const handleExpandDescription = useCallback(() => {
    setExpandedDescription(expanded => !expanded);
  }, [setExpandedDescription]);

  const handleExpandAttributes = useCallback(() => {
    setExpandedAttributes(expanded => !expanded);
  }, [setExpandedAttributes]);

  const handleExpandDetails = useCallback(() => {
    setExpandedDetails(expanded => !expanded);
  }, [setExpandedDetails]);

  const { contractAddress, network, royalty, tokenId } = details;
  const shouldShowAccordion = [contractAddress, network, royalty, tokenId].some(
    item => !!item
  );

  return (
    <Box mb={6} maxWidth={0.5}>
      {!!description && (
        <Accordion
          title={t("plaza.collectible.description")}
          className={classes.root}
          contentSlot={
            <Typography color="textSecondary">{description}</Typography>
          }
          isExpanded={expandedDescription}
          handleClickExpand={handleExpandDescription}
        />
      )}
      {!!attributes && !!attributes?.length && attributes.length > 0 && (
        <Accordion
          title={t("plaza.collectible.attributes")}
          className={classes.root}
          contentSlot={
            <Box display="flex" flexDirection="column" gap={3}>
              <Box display="flex" alignItems="center">
                <Typography
                  ml={4}
                  flexGrow={1}
                  variant="caption"
                  color="textSecondary"
                >
                  {t("plaza.collectible.name")}
                </Typography>
                <Typography
                  flexGrow={1}
                  variant="caption"
                  color="textSecondary"
                >
                  {t("plaza.collectible.value")}
                </Typography>
              </Box>
              {attributes.map(x => (
                <AttributeRow key={x.id} name={x.name} value={x.value} />
              ))}
            </Box>
          }
          isExpanded={expandedAttributes}
          handleClickExpand={handleExpandAttributes}
        />
      )}
      {shouldShowAccordion && (
        <Accordion
          title={t("plaza.collectible.details")}
          className={classes.root}
          contentSlot={<DetailsAccordion {...details} />}
          isExpanded={expandedDetails}
          handleClickExpand={handleExpandDetails}
        />
      )}
    </Box>
  );
};
