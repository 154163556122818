import {
  ARBITRUM_NOVA_MAINNET_CHAIN_ID,
  BSC_MAINNET_CHAIN_ID,
  BSC_TESTNET_CHAIN_ID,
  ETH_MAINNET_CHAIN_ID,
  GOERLI_CHAIN_ID,
  POLYGON_MAINNET_CHAIN_ID,
  SEPOLIA_CHAIN_ID,
} from "../const";
import { Network } from "../types";

export const getNetworkByChainId = (network: number): Network => {
  switch (network) {
    case BSC_MAINNET_CHAIN_ID:
    case BSC_TESTNET_CHAIN_ID:
      return Network.Bsc;
    case POLYGON_MAINNET_CHAIN_ID:
      return Network.Matic;
    case ARBITRUM_NOVA_MAINNET_CHAIN_ID:
      return Network.ArbitrumNova;
    case ETH_MAINNET_CHAIN_ID:
    case GOERLI_CHAIN_ID:
    case SEPOLIA_CHAIN_ID:
    default:
      return Network.Eth;
  }
};
