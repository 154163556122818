import { Tabs, TabsProps } from "@mui/material";
import { useTabButtonsStyles } from "./useTabButtonsStyles";

export const TabButtons = ({ ...restProps }: TabsProps): JSX.Element => {
  const { classes } = useTabButtonsStyles();

  return (
    <Tabs
      {...restProps}
      className={classes.root}
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
    />
  );
};
