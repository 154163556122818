import { Box, Typography } from "@mui/material";
import { useAttributeRowStyles } from "./useAttributeRowStyles";

interface IAttributeRowProps {
  name: string;
  value: string;
}

// TODO: add rarity
export const AttributeRow = ({
  name,
  value,
}: IAttributeRowProps): JSX.Element => {
  const { classes } = useAttributeRowStyles();

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <Typography ml={4} flex="1 1 0" variant="body1Demibold">
        {name}
      </Typography>
      <Typography flex="1 1 0" variant="body1Demibold">
        {value}
      </Typography>
    </Box>
  );
};
