import { makeStyles } from "tss-react/mui";

export const useNotificationStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.grey[700],
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 6,
    maxWidth: 344,
  },
  closeIcon: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(4),
  },
  message: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    whiteSpace: "pre-wrap",
  },
}));
