import { IconProps } from "@mui/material";
import { cloneElement } from "react";
import { useIconStyles } from "./useIconStyles";

interface IIconBadgeProps extends IconProps {
  iconSlot: JSX.Element;
  darkBg?: boolean;
  blackText?: boolean;
  small?: boolean;
  className?: string;
}

export const IconBadge = ({
  iconSlot,
  darkBg = false,
  blackText = false,
  small = false,
  className,
}: IIconBadgeProps): JSX.Element => {
  const { classes, cx } = useIconStyles();

  return (
    <div
      className={cx(
        classes.root,
        classes.badgeRoot,
        darkBg ? classes.darkBackground : classes.defaultBackground,
        blackText ? classes.blackContent : classes.whiteContent,
        small && classes.small,
        className
      )}
    >
      {cloneElement(iconSlot)}
    </div>
  );
};
