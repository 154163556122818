import { Box, Typography } from "@mui/material";
import {
  HeaderFilters,
  TViewMode,
} from "../../../common/components/HeaderFilters";
import { CollectionsGrid } from "../../../common/components/CollectionsGrid";
import { ViewMoreButton } from "modules/common/components/ViewMoreButton";
import { t } from "modules/utils/intl";
import { ContentFullWrapper } from "modules/common/components/ContentFullWrapper";
import { Empty } from "modules/common/components/Empty";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import { CollectionsList } from "../../../common/components/CollectionsList";
import Filters from "./components/Filters/Filters";
import { useLazyFetchCollectionsByChainQuery } from "../Home/actions/useFetchCollectionsByChain";
import { NoResults } from "modules/common/components/NoResults";
import { FilterTags } from "./components/FilterTags";
import {
  collectionsFilterInitialState,
  collectionsFilterReducer,
  IAction,
} from "./reducer";
import { normalizeFilters } from "./helpers";
import { CollectionsSkeletonGrid } from "modules/common/components/CollectionsSkeletonGrid";
import { IFiltersState, ViewMode } from "../Games/types";
import { useSearchField } from "../../../common/hooks";
import { NetworkCode, ReactText } from "../../../common/types";
import {
  COLLECTIONS_PAGE_SIZE,
  SEARCH_DEBOUNCE_TIME,
} from "../../../common/const";
import { useDebouncedCallback } from "use-debounce";

export const Collections = (): JSX.Element => {
  const [viewMode, toggleViewMode] = useState<TViewMode>(ViewMode.GRID);
  const [areFiltersOpen, toggleFilters] = useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useReducer<
    Reducer<IFiltersState, IAction>
  >(collectionsFilterReducer, collectionsFilterInitialState);
  const [page, setPage] = useState(1);
  const [viewMoreDelay, setViewMoreDelay] = useState(false);
  const { search, searchDelay, setSearchDelay, setSearch } = useSearchField();
  const [localSearchQuery, setLocalSearchQuery] = useState("");
  const [activeChain, setActiveChain] = useState<NetworkCode | null>(null);
  const [fetchCollectionsByChain, { data, isLoading }] =
    useLazyFetchCollectionsByChainQuery();

  const [collections, setCollections] = useState(() => data?.items || null);
  const hasNextPage = !!(data?.totalPages && data?.totalPages > page);

  useEffect(() => {
    fetchCollectionsByChain({
      chainCode: null,
      query: "",
      pageNumber: 1,
      pageSize: COLLECTIONS_PAGE_SIZE,
    });
  }, [fetchCollectionsByChain]);

  useEffect(() => {
    const hasChainChanged = activeFilters.chain !== activeChain;

    if (hasChainChanged) {
      setPage(1);
      setLocalSearchQuery("");
      setActiveChain(activeFilters.chain as NetworkCode);
      return;
    }

    fetchCollectionsByChain({
      chainCode: activeFilters.chain || null,
      query: hasChainChanged ? "" : localSearchQuery,
      pageNumber: hasChainChanged ? 1 : page,
      pageSize: COLLECTIONS_PAGE_SIZE,
    });
  }, [
    fetchCollectionsByChain,
    activeFilters.chain,
    localSearchQuery,
    page,
    activeChain,
  ]);

  useEffect(() => {
    if (data?.items) {
      if (activeChain !== activeFilters?.chain) {
        setCollections(data?.items);
        setActiveChain(activeFilters?.chain as NetworkCode);
        return;
      }

      page === 1
        ? setCollections(data?.items)
        : setCollections((prev) => [...(prev || []), ...data?.items]);
    }
  }, [data?.items]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickViewMore = useCallback(() => {
    setViewMoreDelay(true);
    setPage(page + 1);
  }, [page]);

  const fetchCollectionsWithSearchField = (value: ReactText) => {
    setPage(1);
    setLocalSearchQuery(value.toString());
  };

  const debouncedFetchCollectionsList = useDebouncedCallback(
    fetchCollectionsWithSearchField,
    SEARCH_DEBOUNCE_TIME,
  );

  const handleSearchChange = (value: string) => {
    setSearch(value);
    setSearchDelay(true);
    debouncedFetchCollectionsList(value);
  };

  return (
    <Box width={1} marginBottom={25}>
      <Typography variant="h1" marginBottom={7}>
        {t("plaza.collections.title")}
      </Typography>

      <HeaderFilters
        search={search}
        isLoading={isLoading}
        setSearch={handleSearchChange}
        viewState={[viewMode, toggleViewMode]}
        filtersState={[areFiltersOpen, toggleFilters]}
        hideViewMode
      />

      {isLoading && !viewMoreDelay && <CollectionsSkeletonGrid />}

      {!isLoading && !searchDelay && search && collections?.length === 0 && (
        <ContentFullWrapper>
          <Empty text={t("plaza.collections.empty")} />
        </ContentFullWrapper>
      )}
      <Box display="flex" flex={1} alignItems="flex-start" height="100%">
        <Filters
          areFiltersOpen={areFiltersOpen}
          filtersState={[activeFilters, setActiveFilters]}
        />
        {!!collections && (
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            height="100%"
            sx={{ overflowX: "auto" }}
          >
            <FilterTags
              tags={normalizeFilters(activeFilters)}
              onFilterRemove={setActiveFilters}
            />
            {!isLoading && collections.length > 0 && (
              <>
                {viewMode === "grid" && <CollectionsGrid items={collections} />}
                {viewMode === "list" && <CollectionsList items={collections} />}

                {(hasNextPage || isLoading) && (
                  <ViewMoreButton
                    isLoading={isLoading}
                    onClick={handleClickViewMore}
                  />
                )}
              </>
            )}
            {!isLoading && collections.length === 0 && (
              <NoResults
                emptyDataName="collections"
                onClearFilters={setActiveFilters}
                clearSearch={[setLocalSearchQuery, setSearch]}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
