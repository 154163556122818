import { cloneElement } from "react";

interface ISimpleIconProps {
  className?: string;
  icon: JSX.Element;
  [restProps: string]: any;
};

export const SimpleIcon = ({
  className,
  icon,
  ...restProps
}:ISimpleIconProps) => cloneElement(icon, { className: className, ...restProps});
