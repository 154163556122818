import BigNumber from "bignumber.js";
import { ACTION_CACHE_SEC, ZERO } from "modules/common/const";
import { useConnectedData } from "modules/common/hooks/useConnectedData";
import { useDisconnectAll } from "modules/common/hooks/useDisconnectAll";
import { Token } from "modules/common/types";
import { useCallback, useMemo } from "react";
import { useGetNativeTokenBalanceQuery } from "../../actions/useGetNativeTokenBalanceQuery";
import { getTokenByChainId } from "modules/common/utils/getTokenByChainId";
import { useGetEthPriceQuery } from "modules/common/actions/useGetEthPriceQuery";
import { useGetMaticPriceQuery } from "modules/common/actions/useGetMaticPriceQuery";
import { useGetBnbPriceQuery } from "modules/common/actions/useGetBnbPriceQuery";
import { useCopyHook } from "modules/common/hooks/useCopyHook";
import { oryLogoutAsync } from "modules/auth";

interface IAccountInfoData {
  address: string;
  walletIcon?: string;
  token: Token;
  balance: BigNumber;
  usdBalance: BigNumber;
  isBalanceLoading: boolean;
  isCopiedAddress: boolean;
  isConnected: boolean;
  handleCopyAddress: () => void;
  handleClickDisconnect: () => void;
}

export const useAccountInfoData = (): IAccountInfoData => {
  const { address, walletIcon, chainId, isConnected } = useConnectedData();
  const { isCopied, handleCopy } = useCopyHook();
  const disconnectAll = useDisconnectAll();

  const token = getTokenByChainId(chainId ?? 1);

  const { data: nativeTokenBalance, isLoading: isBalanceLoading } =
    useGetNativeTokenBalanceQuery(undefined, {
      skip: !isConnected,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    });
  const { data: ethPrice, isFetching: isEthPriceLoading } = useGetEthPriceQuery(
    undefined,
    {
      skip: token !== Token.ETH,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    },
  );
  const { data: maticPrice, isFetching: isMaticPriceLoading } =
    useGetMaticPriceQuery(undefined, {
      skip: token !== Token.MATIC,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    });
  const { data: bnbPrice, isFetching: isBnbPriceLoading } = useGetBnbPriceQuery(
    undefined,
    {
      skip: token !== Token.BNB,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    },
  );

  const handleClickDisconnect = useCallback(() => {
    disconnectAll();
    oryLogoutAsync();
  }, [disconnectAll]);

  let externalUsdPrice = ethPrice ?? ZERO;
  if (token === Token.BNB) externalUsdPrice = bnbPrice ?? ZERO;
  if (token === Token.MATIC) externalUsdPrice = maticPrice ?? ZERO;

  const balance = useMemo(
    () => nativeTokenBalance ?? ZERO,
    [nativeTokenBalance],
  );
  const usdBalance = balance.multipliedBy(externalUsdPrice);

  return {
    address: address ?? "",
    walletIcon,
    token,
    balance,
    usdBalance,
    isBalanceLoading:
      isBalanceLoading ||
      isEthPriceLoading ||
      isMaticPriceLoading ||
      isBnbPriceLoading,
    isCopiedAddress: isCopied,
    isConnected,
    handleCopyAddress: handleCopy,
    handleClickDisconnect,
  };
};
