import { Box, Typography } from "@mui/material";
import { GamesList } from "./components/GamesList";
import { ViewMoreButton } from "modules/common/components/ViewMoreButton";
import { t } from "modules/utils/intl";
import { Empty } from "../../../common/components/Empty";
import { ContentFullWrapper } from "modules/common/components/ContentFullWrapper";
import { GamesSkeletonGrid } from "modules/common/components/GamesSkeletonGrid";
import {
  HeaderFilters,
  TViewMode,
} from "../../../common/components/HeaderFilters";
import { useCallback, useContext, useEffect, useState } from "react";
import { Filters } from "./components/Filters";
import { ViewMode } from "./types";
import { FilterTags } from "../Collections/components/FilterTags";
import { normalizeFilters } from "../Collections/helpers";
import { NoResults } from "../../../common/components/NoResults";
import { useLazyFetchGameProjectsQuery } from "../Home/actions/useFetchGameProjectsQuery";
import { FiltersContext } from "../../../common/context/FiltersContext";
import { useSearchField } from "../../../common/hooks";
import { ReactText } from "../../../common/types";
import { GAMES_PAGE_SIZE, SEARCH_DEBOUNCE_TIME } from "../../../common/const";
import { useDebouncedCallback } from "use-debounce";

export const Games = (): JSX.Element => {
  const { activeFilters, setActiveFilters } = useContext(FiltersContext);
  const [viewMode, toggleViewMode] = useState<TViewMode>(ViewMode.GRID);
  const [areFiltersOpen, toggleFilters] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [viewMoreDelay, setViewMoreDelay] = useState(false);
  const { search, searchDelay, setSearchDelay, setSearch } = useSearchField();
  const [localSearchQuery, setLocalSearchQuery] = useState("");
  const [fetchGameProjects, { data, isLoading }] =
    useLazyFetchGameProjectsQuery();
  const [games, setGames] = useState(() => data?.items ?? []);
  const hasNextPage = data?.hasNextPage ?? false;
  const params = {
    chainCode: !!activeFilters.chain ? activeFilters.chain : undefined,
    platforms: activeFilters.platforms || null,
    developer: activeFilters.developer || null,
    genre: activeFilters.genre || null,
    query: localSearchQuery,
    pageNumber: page,
    pageSize: GAMES_PAGE_SIZE,
  };

  useEffect(() => {
    setPage(1);
    fetchGameProjects({ ...params, pageNumber: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeFilters.chain,
    activeFilters.genre,
    activeFilters.developer,
    activeFilters.platforms,
    localSearchQuery,
    fetchGameProjects,
  ]);

  useEffect(() => {
    fetchGameProjects(params);
  }, [fetchGameProjects, page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.items) {
      page === 1
        ? setGames(data?.items)
        : setGames((prev) => [...(prev || []), ...data?.items]);
    }
  }, [data?.items]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickViewMore = useCallback(() => {
    setViewMoreDelay(true);
    setPage(page + 1);
  }, [page]);

  const fetchGamesWithSearchField = (value: ReactText) => {
    setPage(1);
    setLocalSearchQuery(value.toString());
  };

  const debouncedFetchGamesList = useDebouncedCallback(
    fetchGamesWithSearchField,
    SEARCH_DEBOUNCE_TIME,
  );

  const handleSearchChange = (value: string) => {
    setSearch(value);
    setSearchDelay(true);
    debouncedFetchGamesList(value);
  };
  return (
    <Box width={1} marginBottom={25}>
      <Typography variant="h1" marginBottom={7}>
        {t("plaza.games.title")}
      </Typography>

      <HeaderFilters
        search={search}
        isLoading={isLoading}
        setSearch={handleSearchChange}
        viewState={[viewMode, toggleViewMode]}
        filtersState={[areFiltersOpen, toggleFilters]}
        hideViewMode={true}
      />

      {isLoading && !viewMoreDelay && <GamesSkeletonGrid />}

      {!isLoading && !searchDelay && search && games?.length === 0 && (
        <ContentFullWrapper>
          <Empty text={t("plaza.games.empty")} />
        </ContentFullWrapper>
      )}

      <Box display="flex" flex={1} alignItems="flex-start" height="100%">
        <Filters areFiltersOpen={areFiltersOpen} />
        {!!games && (
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            height="100%"
            sx={{ overflowX: "auto" }}
          >
            <FilterTags
              tags={normalizeFilters(activeFilters)}
              onFilterRemove={setActiveFilters}
            />
            {!isLoading && games?.length > 0 && (
              <>
                <GamesList viewMode={viewMode} items={games} />

                {(hasNextPage || isLoading) && (
                  <ViewMoreButton
                    isLoading={isLoading}
                    onClick={handleClickViewMore}
                  />
                )}
              </>
            )}
            {!isLoading && games?.length === 0 && (
              <NoResults
                emptyDataName="games"
                onClearFilters={setActiveFilters}
                clearSearch={[setLocalSearchQuery, setSearch]}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
