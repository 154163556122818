import { Grid } from "@mui/material";
import { PopularWalletsList } from "./components/PopularWallets";
import { MainRightBlock } from "./components/MainRightBlock";
import { EWalletId } from "modules/api/const";

interface IWalletConnectDialogProps {
  walletInConnectProcess: EWalletId | null;
  handleClickConnect: (walletId: EWalletId) => void;
}

export const WalletConnectDialog = ({
  walletInConnectProcess,
  handleClickConnect,
}: IWalletConnectDialogProps): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={4.5}>
        <PopularWalletsList
          walletInConnectProcess={walletInConnectProcess}
          handleClickConnect={handleClickConnect}
        />
      </Grid>
      <Grid item xs={7.5}>
        <MainRightBlock />
      </Grid>
    </Grid>
  );
};
