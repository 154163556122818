import { default as isEqual } from 'lodash/isEqual';
import intl from 'react-intl-universal';

const cache = Object.create(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function t(key: string, variables?: any): string {
  const memo = cache[key];
  if (memo && isEqual(memo.variables, variables)) {
    return memo.value;
  }
  const value = intl.get(key, variables) || key;

  cache[key] = {
    variables,
    value,
  };

  return value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tHTML(key: string, variables?: any): string {
  return intl.getHTML(key, variables) || key;
}
