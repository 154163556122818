import createPalette, {
  Palette,
  PaletteOptions,
} from "@mui/material/styles/createPalette";

export const mainPaletteOptions: PaletteOptions = {
  error: {
    light: "#F0251C",
    main: "#FF3027",
  },
  success: {
    main: "#39DF1E",
  },
  background: {
    default: "#131313",
    paper: "#ffffff",
  },
  yellow: {
    main: "#FDFF5D",
    light: "#FEFF8F",
  },
  orange: {
    main: "#FFA800",
  },
  blue: {
    main: "#6A67FC",
  },
  violet: {
    main: "#AD2AEB",
  },
  pink: {
    main: "#FFEAEB",
  },
  beige: {
    main: "#FFFAE9",
  },
  pale: {
    blue: "#EEF6FE",
    green: "#F5FFF3",
  },
  grey: {
    300: "#949494",
    500: "#3D3D3D",
    700: "#282828",
    900: "#131313",
  },
  text: {
    primary: "#ffffff",
    secondary: "#949494",
    disabled: "#BABDC4",
  },
  alert: {
    bg: {
      success: "#EFFCED",
      info: "#EEF6FE",
      warning: "#FFF7DD",
      error: "#FFEAEB",
    },
    text: {
      info: "#6A67FC",
    },
  },
};

export const mainPalette: Palette = createPalette(mainPaletteOptions);
