import {
  tabClasses,
  Palette,
  Components,
} from "@mui/material";

export const getMuiTabs = (palette: Palette): Components['MuiTabs'] => ({
  styleOverrides: {
    indicator: {
      backgroundColor: palette.yellow.light,
    }
  }
});


export const getMuiTab = (palette: Palette): Components['MuiTab'] => ({
  styleOverrides: {
    root: {
      fontSize: 15,
      fontWeight: 600,
      color: palette.grey[300],
      textTransform: 'none',
      marginRight: 20,
      padding: 0,
      paddingBottom: '15px',

      [`&.${tabClasses.selected}`]: {
        color: palette.text.primary
      }
    },
  }
});
