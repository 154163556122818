import { makeStyles } from "tss-react/mui";

export const useAccountInfoStyles = makeStyles()(theme => ({
  root: {
    height: 40,
    width: 190,
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[500]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mainRoot: {
    padding: theme.spacing(6, 5),
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  walletRoot: {
    padding: theme.spacing(6, 5),
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  userLogo: {
    height: 52,
    width: 52,
    borderRadius: "50%",
    marginRight: theme.spacing(3),
  },
  menuItemText: {
    color: theme.palette.text.primary,
  },
  comingSoonRoot: {
    position: "absolute",
    left: 124,
    top: -10,
    height: 16,
    width: 77,
    borderRadius: 6,
    backgroundColor: theme.palette.grey[500],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  comingSoon: {
    color: theme.palette.yellow.main,
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: 11,
    lineHeight: "16px",
    letterSpacing: "-0.01em",
  },
  walletIcon: {
    height: 28,
    width: 28,
    marginRight: theme.spacing(2),
  },
  mainIcons: {
    marginRight: theme.spacing(3),
  },
  openedChevron: {
    transform: "rotate(180deg)",
  },
  smallButton: {
    height: 32,
    width: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 6,
  },
  logoutButton: {
    padding: theme.spacing(5, 0, 5, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  token: {
    height: 28,
    width: 28,
    marginRight: theme.spacing(2),
  },
  balanceRoot: {
    height: 52,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 6,
    padding: theme.spacing(0, 3),
  },
}));
