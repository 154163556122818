import { Box, Button, ButtonBase, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { RoutesConfig } from "modules/account/Routes";
import { TooltipIcon } from "modules/common/components/TooltipIcon";
import { useCopyHook } from "modules/common/hooks/useCopyHook";
import { t } from "modules/utils/intl";
import CopyToClipboard from "react-copy-to-clipboard";
import { NavLink } from "react-router-dom";
import { ReactComponent as CopyIcon } from "uiKit/icons/copy.svg";
import { useHeaderStyles } from "./useHeaderStyles";

interface IHeaderProps {
  userId: string;
  totalBalance: BigNumber;
}

export const Header = ({ userId, totalBalance }: IHeaderProps): JSX.Element => {
  const { classes } = useHeaderStyles();

  const { handleCopy } = useCopyHook();

  return (
    <div className={classes.root}>
      <Box mb={8}>
        <Typography variant="h1" mb={2}>
          {t("account.balance.title")}
        </Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography mr={2} color="textSecondary">
            {t("account.balance.user-id", {
              value: userId,
            })}
          </Typography>

          <CopyToClipboard text={userId} onCopy={handleCopy}>
            <ButtonBase>
              <CopyIcon />
            </ButtonBase>
          </CopyToClipboard>
        </Box>
      </Box>
      <Box className={classes.totalBalance}>
        <Box display="flex" flexDirection="column">
          <Typography variant="caption" component="p" mb={3}>
            {t("account.balance.estimated-balance")}
            <TooltipIcon
              className={classes.ml3}
              placement="right"
              title={t("account.balance.estimated-tooltip")}
            />
          </Typography>
          <Typography variant="h2">
            {t("formats.estimated-usd-value", {
              value: totalBalance.toFormat(2),
            })}
          </Typography>
        </Box>
        <Box display="flex" gap={2}>
          <Button variant="outlined" className={classes.blackButton}>
            <Typography variant="body1Demibold">
              {t("account.balance.send-funds")}
            </Typography>
          </Button>
          <NavLink to={RoutesConfig.addFunds.generatePath()}>
            <Button variant="outlined" className={classes.blackButton}>
              <Typography variant="body1Demibold">
                {t("account.balance.add-funds")}
              </Typography>
            </Button>
          </NavLink>
          <Button variant="contained" className={classes.yellowButton}>
            <Typography variant="body1Demibold">
              {t("account.balance.request")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </div>
  );
};
