import { configFromEnv } from "modules/api";
import { useConnectedData } from "modules/common/hooks/useConnectedData";
import { useLocation } from "react-router-dom";
import DefaultBlueAvatar from "./assets/default-blue-avatar.png";
import DefaultOrangeAvatar from "./assets/default-orange-avatar.png";
import DefaultVioletAvatar from "./assets/default-violet-avatar.png";
import DefaultYellowAvatar from "./assets/default-yellow-avatar.png";
import DefaultYellowGreenAvatar from "./assets/default-yellow-green-avatar.png";
import { useOrySession } from "../OryLifecycleProvider/context";
import { randomIntForInterval } from "modules/common/utils/randomIntForInterval";

interface IHeaderData {
  userName: string;
  isConnected: boolean;
  userLogo: JSX.Element;
  pathname: string;
  developersUrl: string;
  companyUrl: string;
}
interface IOryTraits {
  email: string;
  username: string;
  name: {
    first?: string;
    last?: string;
  };
}

const DEFAULT_AVATARS_LIST = [
  DefaultBlueAvatar,
  DefaultOrangeAvatar,
  DefaultVioletAvatar,
  DefaultYellowAvatar,
  DefaultYellowGreenAvatar,
];
const AVATAR =
  DEFAULT_AVATARS_LIST[
    randomIntForInterval(0, DEFAULT_AVATARS_LIST.length - 1)
  ];

export const useHeaderData = (): IHeaderData => {
  const { pathname } = useLocation();
  const { isConnected } = useConnectedData();
  const { session } = useOrySession();

  const { developersUrl, companyUrl } = configFromEnv();

  return {
    userName: (session?.identity.traits as IOryTraits)?.username ?? "",
    isConnected,
    userLogo: <img src={AVATAR} alt="" />,
    pathname,
    developersUrl,
    companyUrl,
  };
};
