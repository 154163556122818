import { makeStyles } from "tss-react/esm/mui";

export const useNoResultsStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
  message: {
    marginBottom: theme.spacing(6),
    color: theme.palette.grey[300],
  },
}));
