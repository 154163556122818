import { prepareUrl } from "modules/common/utils/prepareUrl";
import { makeStyles } from "tss-react/mui";

interface IHeroProps {
  bannerUrl: string;
}

export const useHeroStyles = makeStyles<IHeroProps>()(
  (theme, { bannerUrl }) => ({
    root: {
      position: "relative",
      height: 420,
      maxHeight: 420,
      width: "100%",
      borderRadius: 20,
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
      marginBottom: theme.spacing(15),
    },
    banner: {
      position: "absolute",
      height: "100%",
      width: "100%",
      opacity: 0.4,
      zIndex: 0,
      borderRadius: 20,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url(${prepareUrl(bannerUrl)})`,
    },
    content: {
      position: "absolute",
      display: "flex",
      height: "100%",
      width: "100%",
      padding: theme.spacing(10),
      zIndex: 1,
    },
    gameInfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      flex: 0.7,
    },
    badges: {
      display: "flex",
      alignItems: "center",
    },
    chainBadge: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.grey[700],
      borderRadius: 6,
    },
    tag: {
      height: 24,
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.grey[700],
      borderRadius: 6,
      padding: theme.spacing(0.5, 2, 0.75),
      fontSize: 13,
      fontWeight: 600,
      textAlign: "center",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    },
    brand: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(4, 0),
    },
    logo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(180deg, #B4093A 0%, #071264 131.25%)",
      borderRadius: 10,
      height: 52,
      width: 52,
      marginRight: theme.spacing(4),
    },
    heading: {},
    assets: {
      height: 22,
      marginBottom: theme.spacing(6),
    },
    btnAsset: {
      justifyContent: "flex-start",
      border: 0,
      backgroundColor: "transparent",
      color: theme.palette.yellow.main,
      padding: theme.spacing(0, 0, 0, 1),
      marginRight: theme.spacing(4),
      minWidth: 40,
      minHeight: 0,
      height: "22px !important",
      "&:hover": {
        backgroundColor: "transparent",
      },
      ".MuiButton-startIcon": {
        marginRight: 8,
      },
    },
    descriptionWrapper: {
      maxWidth: 380,
      marginBottom: theme.spacing(8),
    },
    descriptionText: {
      fontWeight: 400,
      lineHeight: "16px",
      fontSize: 15,
    },
    descriptionLearnMore: {
      fontWeight: 400,
      lineHeight: "16px",
      fontSize: 15,
      color: theme.palette.text.secondary,
      cursor: "pointer",
      textDecoration: "underline",
      marginLeft: theme.spacing(1),
    },
    developerInfo: {
      display: "flex",
      flex: 0.3,
      justifyContent: "center",
    },
    developerInfoWrapper: {
      margin: theme.spacing(0, "auto"),
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    btnPlay: {
      width: 102,
      minWidth: 102,
      marginRight: theme.spacing(3),
    },
    btnShare: {
      width: 106,
      svg: {
        marginLeft: theme.spacing(1.5),
        width: 16,
      },
    },
    developerName: {
      marginBottom: theme.spacing(3),
    },
    developerDescription: {
      maxWidth: 220,
      marginBottom: theme.spacing(4),
    },
    socialMediaWrapper: {
      display: "flex",
    },
    btnSocialMedia: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 32,
      height: 32,
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 6,
      marginRight: theme.spacing(1.5),
      svg: {
        width: 20,
      },
    },
  }),
);
