export const checkSimplePathMatch = (
  location: string,
  link: string,
): boolean => {
  const [pathWithoutQueries] = link.split("?");
  if (
    pathWithoutQueries === location ||
    pathWithoutQueries + "/" === location
  ) {
    return true;
  }
  return false;
};

export const checkPathMatch = (location: string, link: string): boolean => {
  const [mainPath] = location
    .toString()
    .split("/")
    .filter((x) => !!x);

  if (mainPath === link.replaceAll("/", "")) {
    return true;
  }
  return false;
};

export const checkParentPathMatch = (
  location: string,
  link: string,
): boolean => {
  if (location.includes(link)) {
    return true;
  }
  return false;
};
