import { filledInputClasses } from "@mui/material";
import { inputClasses } from "@mui/material/Input";
import { inputBaseClasses } from "@mui/material/InputBase";
import { Components, Palette } from "@mui/material/styles";

export function getMuiInputBase(palette: Palette): Components["MuiInputBase"] {
  return {
    styleOverrides: {
      root: {
        height: 64,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: 6,
        transition: "all 0.3s",
        padding: "10px 20px !important",
        marginTop: "0 !important",

        [`&.${inputBaseClasses.formControl}`]: {
          borderRadius: 6,
          backgroundColor: palette.background.default,
        },

        "&:after, &:before": {
          display: "none",
        },

        [`&:not(.${inputBaseClasses.disabled}):hover`]: {
          border: `1px solid ${palette.common.white}`,
          backgroundColor: `${palette.background.default} !important`,
        },

        [`&&.${inputBaseClasses.focused}`]: {
          border: `1px solid ${palette.yellow.main}`,
          backgroundColor: palette.background.default,
        },

        [`&&.${inputBaseClasses.focused}.${inputBaseClasses.error}`]: {
          border: `1px solid ${palette.error.main}`,
          backgroundColor: palette.background.default,
        },

        [`&&.${inputBaseClasses.disabled}`]: {
          backgroundColor: palette.background.default,
          color: palette.text.secondary,
        },
      },
      input: {
        height: 24,
        color: palette.text.primary,
        fontSize: 15,
        lineHeight: "16px",
        letterSpacing: "-0.02em",
        fontWeight: 600,
        transform: "translate(0px, 5px)",

        [`&.${filledInputClasses.input}`]: {
          padding: "30px 20px 12px",
        },

        [`&.${inputClasses.disabled}`]: {
          color: palette.grey[900],
          WebkitTextFillColor: palette.grey[900],
          opacity: 1,
        },

        "&:-webkit-autofill": {
          backgroundColor: "red !important",
        },
      },
    },
  };
}
