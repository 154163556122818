import { AvailableWriteProviders } from "@ankr.com/provider";
import { web3Api } from "modules/api";
import { getProviderManager } from "modules/api/getProviderManager";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { resetStatus } from "store/authSlice";

export const {
  useDisconnectMutation,
  endpoints: { disconnect },
} = web3Api.injectEndpoints({
  endpoints: build => ({
    disconnect: build.mutation<boolean, AvailableWriteProviders>({
      queryFn: queryFnNotifyWrapper<AvailableWriteProviders, never, boolean>(
        async provider => {
          const providerManager = getProviderManager();
          providerManager.disconnect(provider);
          return { data: true };
        }
      ),
      async onQueryStarted(arg, { dispatch }) {
        dispatch(resetStatus());
      },
    }),
  }),
});
