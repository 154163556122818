import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardProps,
} from "@mui/material";
import { Box } from "@mui/system";
import BigNumber from "bignumber.js";
import {
  EListingStatus,
  Network,
  NetworkCode,
  Token,
} from "modules/common/types";
import { t } from "modules/utils/intl";
import { NavLink } from "react-router-dom";
import { IconBadge } from "uiKit/components/Icon";
import { SimpleNetworkIcon } from "uiKit/components/Icon/SimpleNetworkIcon";
import { useCollectibleItemStyles } from "./useCollectibleItemStyles";
import { StatusBadge } from "uiKit/components/StatusBadge/StatusBadge";

export interface ICollectibleTileData extends CardProps {
  name: string;
  tokenId: number;
  network: Network | NetworkCode | null;
  token: Token;
  collectionName: string;
  price: BigNumber | null;
  status: EListingStatus;
  image: string;
  link: string;
  className?: string;
}

export const CollectibleTile = ({
  className,
  image,
  name,
  tokenId,
  status,
  collectionName,
  price,
  network,
  token,
  link,
}: ICollectibleTileData): JSX.Element => {
  const { classes, cx } = useCollectibleItemStyles();

  return (
    <NavLink to={link}>
      <Card className={cx(classes.root, className)}>
        <CardMedia className={classes.media} image={image}>
          {!!network && (
            <Box className={classes.iconWrapper}>
              <IconBadge
                darkBg
                iconSlot={
                  <SimpleNetworkIcon height={12} width={12} name={network} />
                }
              />
            </Box>
          )}
          <Box
            className={cx(
              classes.statusBadge,
              !network && classes.transfrormStatusBadge,
            )}
          >
            <StatusBadge type={status} />
          </Box>
        </CardMedia>

        <CardContent className={classes.content}>
          <Box className={classes.row}>
            <Typography
              variant="body1Demibold"
              className={classes.collectibleName}
            >
              {t("plaza.collectibles.name-id", {
                name,
                tokenId,
              })}
            </Typography>
            {price && (
              <Typography variant="body1Demibold">
                {t("formats.token-amount", {
                  value: price.toFormat(4),
                  token,
                })}
              </Typography>
            )}
          </Box>
          <Box className={classes.row}>
            <Typography variant="notes" className={classes.collectionName}>
              {collectionName}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </NavLink>
  );
};
