import { TTagTuple } from "../../plaza/screens/Collections/components/FilterTags";
import { NetworkCode } from "../types";
import { IFiltersState } from "../../plaza/screens/Games/types";
import { isProd } from "../env";

export const allChainsMap: Record<NetworkCode, string> = {
  [NetworkCode.SEPOLIA]: "Sepolia Ethereum",
  [NetworkCode.BNB]: "BNB Smart Chain",
  [NetworkCode.ETH]: "Ethereum Mainnet",
  [NetworkCode.GOERLI]: "Goerli Ethereum",
  [NetworkCode.MATIC]: "Polygon",
  [NetworkCode.ARB]: "Arbitrum Nova",
};

export const mainChainsMap: Partial<Record<NetworkCode, string>> = {
  [NetworkCode.BNB]: "BNB Smart Chain",
  [NetworkCode.ETH]: "Ethereum Mainnet",
  [NetworkCode.MATIC]: "Polygon",
  [NetworkCode.ARB]: "Arbitrum Nova",
};

export const chainsMap = isProd ? mainChainsMap : allChainsMap;

export const transformStateToTags = (state: IFiltersState): TTagTuple[] => {
  return Object.entries(state).reduce<TTagTuple[]>((acc, [type, value]) => {
    if (value) {
      acc.push([type, value]);
    }

    return acc;
  }, []);
};

export const transformChainValueToLabel = (value: NetworkCode): string => {
  return allChainsMap[value];
};
