import { Box, Typography } from "@mui/material";
import { t } from "modules/utils/intl";
import { useHeaderStyles } from "./useHeaderStyles";

export const Header = (): JSX.Element => {
  const { classes } = useHeaderStyles();

  return (
    <div className={classes.root}>
      <Box mb={8} display="flex" gap={1}>
        <Typography variant="h1" mb={2}>
          {t("account.inventory.title")}
        </Typography>
      </Box>
    </div>
  );
};
