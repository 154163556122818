import {
  transformChainValueToLabel,
  transformStateToTags,
} from "../../../common/utils/filters";
import { NetworkCode } from "../../../common/types";
import { TTagTuple } from "./components/FilterTags";
import { EPlatformType } from "../../../common/const";
import { IFiltersState } from "../Games/types";

export const singleChoiceFilters = ["chain", "platforms"];
export const multiChoiceFilters = ["developer", "genre", "score"];

export const normalizeFilters = (filters: IFiltersState): TTagTuple[] => {
  const stateAsTuples = transformStateToTags(filters);
  const normalizedFilters = [];
  for (const [key, value] of stateAsTuples) {
    if (multiChoiceFilters.includes(key)) {
      switch (key) {
        case "score":
        case "genre":
        case "developer":
          for (const item of value) {
            normalizedFilters.push([key, item]);
          }
          break;
      }
    }

    if (singleChoiceFilters.includes(key)) {
      switch (key) {
        case "chain":
          normalizedFilters.push([
            key,
            transformChainValueToLabel(value as NetworkCode),
          ]);
          break;
        case "platforms":
          normalizedFilters.push([key, EPlatformType[value as number]]);
          break;
      }
    }
  }

  return normalizedFilters as TTagTuple[];
};
