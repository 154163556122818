import { ITEM_TILE_WIDHT } from "modules/common/const";
import { makeStyles } from "tss-react/mui";

export const useCollectibleTileSkeletonStyles = makeStyles()((theme) => ({
  sliderRoot: {
    objectFit: "contain",
    width: ITEM_TILE_WIDHT,
    maxWidth: ITEM_TILE_WIDHT,
    height: ITEM_TILE_WIDHT,
    maxHeight: ITEM_TILE_WIDHT,
    borderRadius: 12,
    transform: "unset",
    marginBottom: theme.spacing(4),
  },
  small: {
    borderRadius: 0,
    transform: "unset",

    "& + &": {
      marginTop: theme.spacing(0.5),
    },
  },
}));
