import { makeStyles } from "tss-react/mui";

export const useGameLinksStyles = makeStyles()((theme) => ({
  root: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: 48,
    padding: theme.spacing(2, 5),
    backgroundColor: theme.palette.grey[700],
    width: "100%",
    minWidth: 220,
    maxWidth: 266,
    borderRadius: 6,
  },
  links: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
  },
  link: {
    display: "flex",
    alignItems: "center",
    padding: "11px 0",
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontSize: 15,
    lineHeight: 16,
    cursor: "pointer",
  },
  linkIcon: {
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
    backgroundColor: theme.palette.grey[500],
  },
  linkTextWrapper: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 12,
    maxHeight: 32,
  },
  linkTitle: {
    lineHeight: "16px",
    fontWeight: 600,
  },
  linkText: {
    fontWeight: 400,
    lineHeight: "16px",
    color: theme.palette.grey[300],
  },
  copySection: {
    display: "inline-block",
    overflowWrap: "anywhere",
    paddingTop: theme.spacing(3),
  },
  btnCopy: {
    height: 32,
    width: 60,
    color: theme.palette.yellow.main,
    fontFamily: "TT Commons Regular", // TODO MM-119: Figure out why font family is not being applied to `useGameLinks` component
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 17,
    lineHeight: 20,
    marginLeft: 12,
    padding: 0,
    maxWidth: 60,
    svg: {
      marginLeft: 4,
      width: 20,
      height: 20,
    },
  },
}));
