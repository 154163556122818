import { CustomSvgIcon } from "modules/common/components/CustomSvgIcon";

export const Screenshots = () => (
  <CustomSvgIcon width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.5H3H13H14V3.5V13.5V14.5H13H3H2V13.5V3.5V2.5ZM4 9V12.5H12V4.5H8.5C8.5 6.98528 6.48528 9 4 9ZM4 7C5.38071 7 6.5 5.88071 6.5 4.5H4V7ZM18 6.5V17.5V18.5H17H6V16.5H16V6.5H18Z"
      fill="#FDFF5D"
    />
  </CustomSvgIcon>
);
