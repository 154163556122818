import { configFromEnv, web3Api } from "modules/api";
import { GET_POST_NFT_CONTRACT } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { INftContract } from "modules/plaza/types";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

export const { useGetNftContractsQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    getNftContracts: build.query<INftContract[], void>({
      queryFn: queryFnNotifyWrapper<void, never, INftContract[]>(async () => {
        try {
          const response = await fetch(nftManagerUrl + GET_POST_NFT_CONTRACT, {
            method: "GET",
            credentials: "include",
          });

          const data = await processApiResponse(response);
          return { data: data ?? [] };
        } catch (err) {
          console.error("Error in `useGetNftContractsQuery.ts`", err);
          throw err;
        }
      }),
    }),
  }),
});
