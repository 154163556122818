import { prepareUrl } from "modules/common/utils/prepareUrl";
import { makeStyles } from "tss-react/mui";

interface IHeaderProps {
  imageUrl: string;
}

export const useHeaderStyles = makeStyles<IHeaderProps>()(
  (theme, { imageUrl }) => ({
    root: {
      height: 420,
      position: "relative",
      backgroundColor: "#1E1C33",
      borderRadius: 12,
      overflow: "hidden",
    },
    wrap: {
      height: 420,
      width: "100%",
      display: "flex",
      position: "absolute",
      zIndex: 1,
      justifyContent: "space-between",
      alignItems: "flex-end",
      padding: theme.spacing(8),
    },
    img: {
      height: 420,
      width: "100%",
      opacity: 0.4,
      position: "absolute",
      zIndex: 1,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url(${prepareUrl(imageUrl)})`,
    },
    description: {
      maxWidth: 380,
    },
    linkArrow: {
      transform: "rotate(0.75turn)",
    },
  }),
);
