import { Box, Typography } from "@mui/material";
import { t } from "modules/utils/intl";
import { ChangeEvent } from "react";
import { TabButtons, TabButton } from "uiKit/components/TabButtons";
import { ETxGroup } from "../../useBalanceData";

interface ITransactionsHeaderProps {
  group: ETxGroup;
  handleSetTxGroup: (newTxGroup: ETxGroup) => void;
}

export const TransactionsHeader = ({
  group,
  handleSetTxGroup,
}: ITransactionsHeaderProps): JSX.Element => {
  return (
    <Box mb={4}>
      <Typography variant="h4" mb={8}>
        {t("account.balance.transactions")}
      </Typography>
      <TabButtons
        value={group}
        onChange={(_event: ChangeEvent<{}>, tabValue: string) =>
          handleSetTxGroup(tabValue as ETxGroup)
        }>
        <TabButton value={ETxGroup.All} label={ETxGroup.All} />
        <TabButton value={ETxGroup.Sent} label={ETxGroup.Sent} />
        <TabButton value={ETxGroup.Added} label={ETxGroup.Added} />
      </TabButtons>
    </Box>
  );
};
