import { Box, ButtonBase, Typography } from "@mui/material";
import { ReactComponent as ArrowIcon } from "uiKit/icons/arrow-small-down.svg";
import { t } from "modules/utils/intl";
import { ReactComponent as MetamaskLogo } from "../../assets/metamask-logo.svg";
import { ReactComponent as CoinbaseWalletLogo } from "../../assets/coinbase-wallet-logo.svg";
import { ReactComponent as MirageWalletIcon } from "uiKit/icons/mirage-wallet.svg";
import { DOWNLOAD_COINBASE, DOWNLOAD_METAMASK, DOWNLOAD_MIRAGE } from 'modules/common/const'
import { uid } from "react-uid";
import { useWalletsListStyles } from "./useWalletsListStyles";
import { WalletItem, IWalletItemProps } from "./WalletItem";

interface IWalletsListProps {
  handleClickGoBack: () => void;
}

// TODO: fix t function. it doesn't work for some reasons
const WALLETS: IWalletItemProps[] = [
  {
    name: "wallet-connect.mrg",
    type: "wallet-connect.extension",
    logo: <MirageWalletIcon />,
    link: DOWNLOAD_MIRAGE,
  },
  {
    name: "wallet-connect.mtmsk",
    type: "wallet-connect.extension",
    logo: <MetamaskLogo />,
    link: DOWNLOAD_METAMASK,
  },
  {
    name: "wallet-connect.coinbase-wallet",
    type: "wallet-connect.extension",
    logo: <CoinbaseWalletLogo />,
    link: DOWNLOAD_COINBASE,
  },
];

export const WalletsList = ({
  handleClickGoBack,
}: IWalletsListProps): JSX.Element => {
  const { classes } = useWalletsListStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" ml={4}>
      <ButtonBase onClick={handleClickGoBack}>
        <ArrowIcon className={classes.arrowIcon} />
        <Typography variant="body1Demibold" color="textSecondary">
          {t("wallet-connect.back")}
        </Typography>
      </ButtonBase>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={1}
        mt={1}
      >
        <Typography variant="h4_1" mb={10}>
          {t("wallet-connect.get-wallet")}
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
          {WALLETS.map(wallet => (
            <WalletItem key={uid(wallet)} {...wallet} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
