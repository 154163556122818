import { Box, Typography } from "@mui/material";
import { CopyWrapper } from "modules/plaza/screens/Collectible/components/CopyWrapper";
import { getShortId } from "modules/common/utils/getShortId";
import { INftDetails } from "modules/plaza/types";
import { t } from "modules/utils/intl";
import { SimpleNetworkIcon } from "uiKit/components/Icon";

export const DetailsAccordion = ({
  contractAddress,
  tokenId,
  network,
  tokenStandart,
  royalty,
}: INftDetails): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" gap={5}>
      {!!contractAddress && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="caption">
            {t("plaza.collectible.address")}
          </Typography>
          <CopyWrapper
            shortValue={getShortId(contractAddress)}
            fullValue={contractAddress}
          />
        </Box>
      )}
      {!!tokenId && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="caption">
            {t("plaza.collectible.token-id")}
          </Typography>
          <CopyWrapper shortValue={tokenId} fullValue={tokenId} />
        </Box>
      )}
      {!!network && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="caption">
            {t("plaza.collectible.network-contract")}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <SimpleNetworkIcon height={15} width={15} name={network} />
            <Typography variant="body1Demibold">{network}</Typography>
            <Typography color="textSecondary">({tokenStandart})</Typography>
          </Box>
        </Box>
      )}
      {!!royalty && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="caption">
            {t("plaza.collectible.royalty")}
          </Typography>
          <Typography variant="body1Demibold">
            {t("formats.percent-value", {
              value: royalty,
            })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
