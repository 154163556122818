import { Box, Button } from "@mui/material";
import { CoinsList } from "./components/CoinsList";
import { Header } from "./components/Header";
import { TransactionsHeader } from "./components/TransactionsHeader";
import { TransactionsTable } from "./components/TransactionsTable";
import { useBalanceData } from "./useBalanceData";

export const Balance = (): JSX.Element => {
  const {
    userId,
    totalUsdBalance: totalBalance,
    coins,
    tx,
    txGroup,
    handleSetTxGroup,
  } = useBalanceData();

  return (
    <Box width="100%" height="100%">
      <Header userId={userId} totalBalance={totalBalance} />

      {!!coins && !!coins?.length && coins.length > 0 && (
        <CoinsList coins={coins} />
      )}

      {!!tx && !!tx?.length && tx.length > 0 && (
        <>
          <TransactionsHeader
            group={txGroup}
            handleSetTxGroup={handleSetTxGroup}
          />
          <TransactionsTable data={tx} />
          <Button style={{ marginBottom: 80 }} variant="outlined">
            View all
          </Button>
        </>
      )}
    </Box>
  );
};
