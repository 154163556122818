import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardProps,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import { useGameTileStyles } from "./useGameTileStyles";
import { Platform } from "uiKit/icons";
import { t } from "modules/utils/intl";
import {
  EPlatformType,
  EProjectType,
  platformTypesMap,
  projectTypesMap,
} from "../../const";
import { NetworkCode } from "modules/common/types";
import { uid } from "react-uid";
import { IconBadge, SimpleNetworkIcon } from "uiKit/components/Icon";

interface IGameTileProps extends CardProps {
  className?: string;
  image?: string;
  name?: string;
  developerName?: string;
  gameProjectTypes: EProjectType[];
  platformTypes: EPlatformType[];
  networks?: NetworkCode[];
  link: string;
  reviewScore?: number;
}

export const GameTile = ({
  className,
  image,
  name,
  developerName,
  gameProjectTypes,
  networks,
  platformTypes,
  link,
  reviewScore,
}: IGameTileProps): JSX.Element => {
  const { classes, cx } = useGameTileStyles();

  const tags = gameProjectTypes
    .map((type) => projectTypesMap[type])
    .slice(0, 2);
  const platforms = platformTypes.map((type) => platformTypesMap[type]);

  return (
    <NavLink to={link}>
      <Card className={cx(classes.root, className)}>
        <CardMedia className={classes.media} image={image}>
          {!!networks && networks.length > 0 && (
            <div className={classes.networksWrapper}>
              {networks.map((network) => (
                <IconBadge
                  key={uid(network)}
                  small
                  darkBg
                  iconSlot={
                    <SimpleNetworkIcon height={12} width={12} name={network} />
                  }
                />
              ))}
            </div>
          )}
        </CardMedia>

        <CardContent className={classes.content}>
          <Box className={classes.meta}>
            <Typography variant="notes" className={classes.developerName}>
              {developerName}
            </Typography>
            <Typography variant="body1Demibold" className={classes.name}>
              {name}
            </Typography>
            <Box className={classes.tags}>
              {platforms.map((platform) => (
                <Typography
                  key={platform}
                  component="span"
                  className={classes.platformTag}
                >
                  <Platform platform={platform} width={16} height={16} />
                </Typography>
              ))}
              {tags.map((tag) => (
                <Typography
                  key={tag}
                  component="span"
                  className={classes.gameTag}
                >
                  {tag}
                </Typography>
              ))}
            </Box>
          </Box>
          {!!reviewScore && (
            <Box>
              <Box className={classes.reviewScoreWrapper}>
                <CircularProgress
                  variant="determinate"
                  value={reviewScore}
                  thickness={2}
                  size={30}
                  className={classes.reviewScore}
                />
                <CircularProgress
                  variant="determinate"
                  value={100}
                  thickness={1}
                  size={30}
                  className={classes.reviewScoreBackground}
                />
                <Box className={classes.reviewScoreTextWrapper}>
                  <Typography
                    className={classes.reviewScoreText}
                    variant="caption"
                    component="div"
                  >
                    {t("formats.percent-value", { value: reviewScore })}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </NavLink>
  );
};
