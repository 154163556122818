import { configFromEnv, web3Api } from "modules/api";
import { GET_NFT_TEMPLATE } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { INftTemplate } from "modules/plaza/types";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

export const { useGetNftTemplatesQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    getNftTemplates: build.query<INftTemplate[], void>({
      queryFn: queryFnNotifyWrapper<void, never, INftTemplate[]>(async () => {
        try {
          const response = await fetch(nftManagerUrl + GET_NFT_TEMPLATE, {
            method: "GET",
            credentials: "include",
          });

          const data = await processApiResponse(response);
          return { data };
        } catch (err) {
          console.error("Error in `useGetNftTemplatesQuery.ts`", err);
          throw err;
        }
      }),
    }),
  }),
});
