import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  createRouterMiddleware,
  createRouterReducer,
} from "@lagunovsky/redux-react-router";
import { persistStore } from "redux-persist";
import { i18nPersistReducer } from "../i18n/store/i18nPersistReducer";
import { historyInstance } from "modules/utils/historyInstance";
import { web3Api } from "modules/api/web3Api";
import { authPersistReducer } from "./authPersistReducer";
import { listenerMiddleware } from "./listeners/createListenerMiddleware";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

const rootReducer = combineReducers({
  i18n: i18nPersistReducer,
  [web3Api.reducerPath]: web3Api.reducer,
  auth: authPersistReducer,
  router: createRouterReducer(historyInstance),
});

// TODO: add wallet event listener!!!
export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(listenerMiddleware.middleware)
      .concat(web3Api.middleware)
      .concat(createRouterMiddleware(historyInstance)),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
