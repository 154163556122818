import { CustomSvgIcon } from "modules/common/components/CustomSvgIcon";

export const Close = ({ color }: { color?: string }) => (
  <CustomSvgIcon
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill={color || "#FFFFFF"}
    style={{ width: 20, height: 20, pointerEvents: "none" }}
  >
    <path d="M6 14L14 6" stroke={color || "#FFFFFF"} strokeWidth="2" />
    <path d="M6 6L14 14" stroke={color || "#FFFFFF"} strokeWidth="2" />
  </CustomSvgIcon>
);
