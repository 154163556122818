import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

export const useSpinnerStyles = makeStyles()(() => ({
  root: {
    animation: `${keyframes`
      100% {
        transform: rotate(360deg);
      }`} 1s infinite linear`,
    willChange: 'transform',
    margin: 'auto',
    height: 20,
    width: 20,
  },
}));
