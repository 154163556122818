import { makeStyles } from "tss-react/mui";

export const useHeaderStyles = makeStyles()(theme => ({
  root: {
    width: "100%",
  },
  itemCount: {
    color: theme.palette.yellow.main,
  },
}));
