import { generatePath, Route, Switch } from "react-router-dom";
import { Layout } from "../common/components/Layout";
import { createRouteConfig, useQueryParams } from "../router";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { Home } from "./screens/Home";
import { Games } from "./screens/Games";
import { Game } from "./screens/Game";
import { Collections } from "modules/plaza/screens/Collections/Collections";
import { Collection } from "./screens/Collection";
import { Developer } from "./screens/Developer";
import { Collectible } from "./screens/Collectible";
import { Collectibles } from "./screens/Collectibles";
import packageJSON from "../../../package.json";
import { NoReactSnap } from "modules/common/components/NoReactSnap";
import { FiltersStateProvider } from "../common/context/FiltersContext";
import { PageNotFound } from "../common/components/PageNotFound";

const ROOT_PLAZA_PATH = packageJSON.homepage;
const HOME_PATH = "/home";
const GAMES_PATH = "/games";
const COLLECTIBLES_PATH = "/collectibles";
const GAME_PATH = `${GAMES_PATH}/info?id=:id`;
const DEVELOPER_PATH = `${GAMES_PATH}/developer?id=:id`;
const COLLECTIONS_PATH = "/collections";
const COLLECTION_PATH = `${COLLECTIONS_PATH}/info?id=:id`;
const COLLECTIBLE_PATH = `${COLLECTIBLES_PATH}/info?id=:id`;

export const RoutesConfig = createRouteConfig(
  {
    home: {
      path: HOME_PATH,
      generatePath: () => generatePath(HOME_PATH),
    },

    games: {
      path: GAMES_PATH,
      generatePath: () => generatePath(GAMES_PATH),
    },

    game: {
      path: GAMES_PATH,
      generatePath: (id?: string) => {
        return id ? generatePath(GAME_PATH, { id }) : generatePath(GAMES_PATH);
      },
      useParams: () => {
        return {
          id: useQueryParams().get("id") ?? undefined,
        };
      },
    },

    collections: {
      path: COLLECTIONS_PATH,
      generatePath: () => generatePath(COLLECTIONS_PATH),
    },

    collection: {
      path: COLLECTIONS_PATH,
      generatePath: (id?: string) => {
        return id
          ? generatePath(COLLECTION_PATH, { id })
          : generatePath(COLLECTIONS_PATH);
      },
      useParams: () => {
        return {
          id: useQueryParams().get("id") ?? undefined,
        };
      },
    },

    developer: {
      path: DEVELOPER_PATH,
      generatePath: (id?: string) => {
        return id
          ? generatePath(DEVELOPER_PATH, { id })
          : generatePath(DEVELOPER_PATH);
      },
      useParams: () => {
        return {
          id: useQueryParams().get("id") ?? undefined,
        };
      },
    },

    collectibles: {
      path: COLLECTIBLES_PATH,
      generatePath: () => generatePath(COLLECTIBLES_PATH),
    },

    collectible: {
      path: COLLECTIBLES_PATH,
      generatePath: (id?: string) => {
        return id
          ? generatePath(COLLECTIBLE_PATH, { id })
          : generatePath(COLLECTIBLES_PATH);
      },
      useParams: () => {
        return {
          id: useQueryParams().get("id") ?? undefined,
        };
      },
    },
  },

  ROOT_PLAZA_PATH,
);

export function getRoutes(): JSX.Element {
  return (
    <Route
      path={[
        RoutesConfig.home.path,
        RoutesConfig.games.path,
        RoutesConfig.game.path,
        RoutesConfig.developer.path,
        RoutesConfig.collections.path,
        RoutesConfig.collection.path,
        RoutesConfig.collectibles.path,
        RoutesConfig.collectible.path,
      ]}
    >
      <Switch>
        <Route exact path={RoutesConfig.home.path}>
          <Layout sidebarSlot={<Sidebar />}>
            <NoReactSnap>
              <Home />
            </NoReactSnap>
          </Layout>
        </Route>

        <Route exact path={RoutesConfig.games.path}>
          <Layout sidebarSlot={<Sidebar />}>
            <NoReactSnap>
              <FiltersStateProvider>
                <Games />
              </FiltersStateProvider>
            </NoReactSnap>
          </Layout>
        </Route>

        <Route path={RoutesConfig.game.path}>
          <Layout sidebarSlot={<Sidebar />}>
            <NoReactSnap>
              <Game />
            </NoReactSnap>
          </Layout>
        </Route>

        <Route exact path={RoutesConfig.developer.path}>
          <Layout sidebarSlot={<Sidebar />}>
            <NoReactSnap>
              <Developer />
            </NoReactSnap>
          </Layout>
        </Route>

        <Route exact path={RoutesConfig.collections.path}>
          <Layout sidebarSlot={<Sidebar />}>
            <NoReactSnap>
              <Collections />
            </NoReactSnap>
          </Layout>
        </Route>

        <Route path={RoutesConfig.collection.path}>
          <Layout sidebarSlot={<Sidebar />}>
            <NoReactSnap>
              <Collection />
            </NoReactSnap>
          </Layout>
        </Route>

        <Route exact path={RoutesConfig.collectibles.path}>
          <Layout sidebarSlot={<Sidebar />}>
            <NoReactSnap>
              <Collectibles />
            </NoReactSnap>
          </Layout>
        </Route>

        <Route path={RoutesConfig.collectible.path}>
          <Layout sidebarSlot={<Sidebar />}>
            <NoReactSnap>
              <Collectible />
            </NoReactSnap>
          </Layout>
        </Route>

        <Route path="*">
          <Layout sidebarSlot={<Sidebar />}>
            <PageNotFound />
          </Layout>
        </Route>
      </Switch>
    </Route>
  );
}
