import { makeStyles } from "tss-react/esm/mui";
import { checkboxClasses, formControlLabelClasses } from "@mui/material";

export const useGenresFilterStyles = makeStyles()((theme, params) => ({
  formControlLabel: {
    margin: theme.spacing(0, 0, 3),
  },
  checkbox: {
    padding: 0,
    marginLeft: 0,
    color: `${theme.palette.yellow.main} !important`,

    [`& .${formControlLabelClasses.root}`]: {
      margin: 0,
    },

    [`& .${checkboxClasses.checked}`]: {
      color: theme.palette.yellow.main,
    },

    [`& .${checkboxClasses.root}`]: {
      color: theme.palette.yellow.main,
    },
  },
}));
