import { createTheme } from "@mui/material/styles";
import { Palette } from "@mui/material/styles/createPalette";
import { Shadows } from "@mui/material/styles";

import {
  getMuiInput,
  getMuiInputBase,
  getMuiButton,
  getMuiPaginationItem,
  getMuiTabs,
  getMuiTab,
  getMuiTooltip,
  getMuiTableContainer,
  getMuiTableCell,
  getMuiInputLabel,
  getMuiDivider,
  getMuiAlert,
  getMuiAccordion,
  getMuiAccordionSummary,
  getMuiAccordionDetails,
  getMuiToggleButton,
  getMuiMenu,
  getMuiMenuItem,
  getMuiSkeleton,
} from "./mui";
import { BREAKPOINTS, SPACING } from "./const";
import { getGlobalStyles } from "./getGlobalStyles";
import { mainPalette } from "./mainPalette";

export const mainTheme = createTheme({
  spacing: SPACING,
  palette: mainPalette as Palette,
  shape: {
    borderRadius: 6,
  },
  shadows: Array(25).fill("none") as Shadows,
  breakpoints: {
    values: BREAKPOINTS,
  },
  typography: {
    fontFamily: "TT Commons Regular",
    fontSize: 17,

    h1: {
      fontWeight: 600,
      letterSpacing: "-0.02em",
      fontSize: 56,
      lineHeight: "56px",
    },

    h2: {
      fontWeight: 600,
      letterSpacing: "-0.01em",
      fontSize: 40,
      lineHeight: "44px",
    },

    h3: {
      fontWeight: 400,
      letterSpacing: "-0.01em",
      fontSize: 24,
      lineHeight: "28px",
    },

    h3Demibold: {
      fontWeight: 600,
      letterSpacing: "-0.01em",
      fontSize: 24,
      lineHeight: "28px",
    },

    h4: {
      fontWeight: 600,
      letterSpacing: "-0.01em",
      fontSize: 20,
      lineHeight: "24px",
      textTransform: "uppercase",
    },

    h4Numbers: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: "24px",
      textTransform: "uppercase",
    },

    h4_1: {
      fontWeight: 600,
      letterSpacing: "-0.01em",
      fontSize: 20,
      lineHeight: "28px",
    },

    body1: {
      fontWeight: 400,
      letterSpacing: "-0.01em",
      fontSize: 17,
      lineHeight: "20px",
    },

    body1Demibold: {
      fontWeight: 600,
      letterSpacing: "-0.01em",
      fontSize: 17,
      lineHeight: "20px",
    },

    body2: {
      fontWeight: 400,
      letterSpacing: "-0.02em",
      fontSize: 15,
      lineHeight: "16px",
    },

    body2Demibold: {
      fontWeight: 600,
      letterSpacing: "-0.02em",
      fontSize: 15,
      lineHeight: "16px",
    },

    caption: {
      fontWeight: 600,
      letterSpacing: "-0.01em",
      fontSize: 13,
      lineHeight: "16px",
      textTransform: "uppercase",
    },

    notes: {
      fontWeight: 400,
      letterSpacing: "-0.01em",
      fontSize: 13,
      lineHeight: "16px",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: getGlobalStyles(mainPalette),
    },
    MuiInput: getMuiInput(),
    MuiInputBase: getMuiInputBase(mainPalette),
    MuiButton: getMuiButton(mainPalette),
    MuiPaginationItem: getMuiPaginationItem(mainPalette),
    MuiTabs: getMuiTabs(mainPalette),
    MuiTab: getMuiTab(mainPalette),
    MuiTooltip: getMuiTooltip(mainPalette),
    MuiTableContainer: getMuiTableContainer(mainPalette),
    MuiTableCell: getMuiTableCell(mainPalette),
    MuiInputLabel: getMuiInputLabel(mainPalette),
    MuiDivider: getMuiDivider(mainPalette),
    MuiAlert: getMuiAlert(mainPalette),
    MuiAccordion: getMuiAccordion(mainPalette),
    MuiAccordionSummary: getMuiAccordionSummary(mainPalette),
    MuiAccordionDetails: getMuiAccordionDetails(mainPalette),
    MuiToggleButton: getMuiToggleButton(mainPalette),
    MuiMenu: getMuiMenu(mainPalette),
    MuiMenuItem: getMuiMenuItem(mainPalette),
    MuiSkeleton: getMuiSkeleton(mainPalette),
  },
});
