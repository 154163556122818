import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useInfoStyles = makeStyles()((theme) => ({
  collection: {
    color: theme.palette.yellow.main,
    marginRight: theme.spacing(2),
  },
  networkWrapper: {
    height: 24,
    width: 24,
  },
  button: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    marginBottom: theme.spacing(4.5),
  },
  tokenPriceIconWrapper: {
    width: 44,
    minHeight: 44,
    maxWidth: 44,
    maxHeight: 44,
  },
  tokenPriceIcon: {
    height: 27.5,
    width: 27.5,
  },
  linkRoot: {
    display: "flex",
  },
  linkButton: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    height: 16,
    width: 16,
  },
  status: {
    color: theme.palette.yellow.main,
  },
  organizationLogo: {
    height: 40,
    width: 40,
    borderRadius: "50%",
    border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
    backdropFilter: "blur(8px)",
  },
}));
