import { configFromEnv, web3Api } from "modules/api";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { GET_POST_COLLECTION } from "modules/api/urls";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { ICollectionListItem } from "../../../types";
import { processApiResponse } from "modules/utils/processApiResponse";
import { GAMES_PAGE_SIZE } from "../../../../common/const";
import { NetworkCode } from "../../../../common/types";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IParams {
  chainCode: NetworkCode | null;
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}

interface IResponse {
  items: ICollectionListItem[];
  pageNumber: number;
  totalPages: number;
  totalCount: number;
}

export const {
  useLazyFetchCollectionsByChainQuery,
  useFetchCollectionsByChainQuery,
} = web3Api.injectEndpoints({
  endpoints: (build) => ({
    fetchCollectionsByChain: build.query<IResponse, IParams>({
      queryFn: queryFnNotifyWrapper<IParams, never, IResponse>(
        async ({
          chainCode,
          query,
          pageNumber,
          pageSize = GAMES_PAGE_SIZE,
        }): Promise<QueryReturnValue<IResponse, never, unknown>> => {
          const requestUrl = new URL(nftManagerUrl + GET_POST_COLLECTION);

          if (chainCode) {
            requestUrl.searchParams.append("ChainCode", chainCode);
          }
          if (query) {
            requestUrl.searchParams.append("Name", query);
          }

          if (pageNumber) {
            requestUrl.searchParams.append("PageNumber", pageNumber.toString());
          }

          if (pageSize) {
            requestUrl.searchParams.append("PageSize", pageSize.toString());
          }

          try {
            const response = await fetch(requestUrl, {
              method: "GET",
              credentials: "include",
            });

            const data = await processApiResponse(response);

            return {
              data,
            };
          } catch (err) {
            console.error("Error in `useFetchCollectionsByChain.ts`", err);
            throw err;
          }
        },
      ),
    }),
  }),
});
