import { IOption } from "modules/common/components/Select";
import { NO_FILTER_SPECIFIED } from "../../../common/const";

export const platformsOptions: IOption[] = [
  { value: NO_FILTER_SPECIFIED, label: "All platforms" },
  { value: "PC", label: "PC" },
  { value: "Mac", label: "Mac" },
  { value: "Linux", label: "Linux" },
  { value: "IOS", label: "IOS" },
  { value: "Android", label: "Android" },
  { value: "Web", label: "Web" },
];

/**
 * @function transformSelectValueToOption
 * @description Converts the provided string value into a platform object containing both the value and the display label.
 * @param {string} value - The string representation of the platform.
 * @returns {IOption | undefined} - Returns an IOption object representing the platform with 'value' and 'label' keys,
 * or undefined if the value does not correspond to any platform in the platformsOptions array.
 */
export const transformSelectValueToOption = (
  value: string,
): IOption | undefined =>
  platformsOptions.find((option) => option.value === value);
