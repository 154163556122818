import { IFiltersState } from "../../types";
import { NetworkCode } from "modules/common/types";
import { EPlatformType } from "modules/common/const";

export interface IAction {
  type: string;
  payload?: string | NetworkCode | EPlatformType | null;
}

export const gamesFiltersInitialState: IFiltersState = {
  chain: null,
  platforms: null,
  developer: null,
  genre: null,
};

export const filterToActionMap: Record<string, string> = {
  chain: "SET_CHAIN",
  platforms: "SET_PLATFORM",
  developer: "SET_DEVELOPERS",
  genre: "SET_GENRES",
};

const addOrRemoveItemFromMultipleChoiceFilter = (
  filterData: string[] | null | undefined,
  element: string,
): string[] | null => {
  if (!filterData) {
    return [element];
  }

  return filterData?.includes(element)
    ? filterData?.filter((item) => item !== element)
    : [...filterData, element];
};

export function gamesFiltersReducer(
  state: IFiltersState,
  action: IAction,
): {
  chain?: NetworkCode | null;
  genre?: string[] | null;
  developer?: string[] | null;
  platforms?: EPlatformType[] | null;
} {
  switch (action.type) {
    case "SET_CHAIN":
      return { ...state, chain: action.payload as NetworkCode };
    case "SET_PLATFORM":
      return {
        ...state,
        platforms: [action.payload as EPlatformType],
      };
    case "SET_DEVELOPERS": {
      return {
        ...state,
        developer: addOrRemoveItemFromMultipleChoiceFilter(
          state.developer,
          action.payload as string,
        ),
      };
    }
    case "SET_GENRES": {
      return {
        ...state,
        genre: addOrRemoveItemFromMultipleChoiceFilter(
          state.genre,
          action.payload as string,
        ),
      };
    }
    case "CLEAR_FILTERS":
      return gamesFiltersInitialState;
    case "CLEAR_FILTER": {
      return { ...state, [action.payload as string]: null };
    }
    default:
      throw new Error();
  }
}
