import { EWalletId } from "modules/api/const";
import { useConnectMutation } from "modules/common/actions/useConnectMutations";
import { useDialog } from "modules/common/hooks/useDialog";
import { Network } from "modules/common/types";
import { getChainId } from "modules/common/utils/getChainId";
import { getIsMetamaskInjected } from "modules/common/utils/getIsMetamaskInjected";
import { useCallback, useEffect, useState } from 'react'

interface IConnectWalletData {
  isAccepted: boolean;
  network?: Network;
  isLoading: boolean;
  isMetamaskInjected: boolean;
  isOpenedDialog: boolean;
  handleAccept: () => void;
  handleOpenConnectWalletDialog: () => void;
  handleCloseConnectWalletDialog: () => void;
  handleConnectWallet: (walletId: EWalletId) => void;
  walletInConnectProcess: EWalletId | null;
  handleChangeNetwork: (network: Network) => void;
}

export const useConnectWalletData = (): IConnectWalletData => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [walletInConnectProcess, setWalletInConnectProcess] = useState<EWalletId | null>(null);
  const [network, setNetwork] = useState<Network | undefined>(undefined);
  const { isOpened, onOpen, onClose } = useDialog();
  const [connect, { isLoading }] = useConnectMutation({
    fixedCacheKey: "useConnectMutation",
  });
  useEffect(() => {
    if(walletInConnectProcess && !isLoading) setWalletInConnectProcess(null);
  }, [isLoading, walletInConnectProcess])

  const isMetamaskInjected = getIsMetamaskInjected();

  const handleAccept = useCallback(() => {
    setIsAccepted(accepted => !accepted);
  }, [setIsAccepted]);

  const handleChangeNetwork = useCallback(
    (network: Network) => {
      setNetwork(network);
    },
    [setNetwork]
  );

  const handleConnectWallet = (walletId: EWalletId) => {
    setWalletInConnectProcess(walletId);
    connect({
      wallet: walletId,
      chainId: getChainId(network ?? Network.Eth),
    });
  };

  return {
    isLoading,
    walletInConnectProcess,
    isAccepted,
    network,
    isMetamaskInjected,
    isOpenedDialog: isOpened,
    handleAccept,
    handleOpenConnectWalletDialog: onOpen,
    handleCloseConnectWalletDialog: onClose,
    handleConnectWallet,
    handleChangeNetwork,
  };
};
