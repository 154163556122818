import { Box, Typography } from "@mui/material";
import { useCollectionData } from "./useCollectionData";
import { Header } from "./components/Header";
import { InfoBlock } from "./components/InfoBlock";
import { LoaderCentered } from "modules/common/components/LoaderCentered";
import { CollectiblesGrid } from "modules/common/components/CollectiblesGrid";
import { ViewMoreButton } from "../../../common/components/ViewMoreButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RoutesConfig } from "../../Routes";
import { useGetCollectionDataQuery } from "./actions/useGetCollectionDataQuery";
import { useLazyGetCollectiblesByCollectionQuery } from "./actions/useLazyGetCollectiblesByCollectionQuery";
import { transformCollectiblesData } from "../../../common/utils/transformCollectiblesData";
import { ICollectibleTileData } from "../../../common/components/CollectibleTile";

export const Collection = (): JSX.Element => {
  const { network, createdBy } = useCollectionData();
  const [page, setPage] = useState(1);
  const { id } = RoutesConfig.collection.useParams();

  const { data, isFetching } = useGetCollectionDataQuery(
    { id: id ?? "" },
    { skip: !id },
  );
  const [
    getCollectiblesByCollection,
    { data: collectiblesData, isFetching: areCollectiblesLoading },
  ] = useLazyGetCollectiblesByCollectionQuery();
  const { name: title, description, bannerImage, logoImage } = data ?? {};
  const [pageData, setPageData] = useState<ICollectibleTileData[]>([]);
  const isLoading = isFetching || areCollectiblesLoading;

  useEffect(() => {
    if (id) {
      getCollectiblesByCollection({
        collectionId: id,
        pageNumber: page,
      });
    }
  }, [getCollectiblesByCollection, id, page]);

  useEffect(() => {
    getCollectiblesByCollection({
      collectionId: id ?? "",
      pageNumber: page,
    });
  }, [getCollectiblesByCollection, page, id]);

  useEffect(() => {
    if (!!collectiblesData?.items) {
      const normalizedData = transformCollectiblesData(
        collectiblesData?.items ?? [],
      );
      page === 1
        ? setPageData(normalizedData)
        : setPageData((prev) => [...prev, ...normalizedData]);
    }
  }, [collectiblesData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleViewMore = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const hasNextPage = useMemo(
    () =>
      collectiblesData?.totalPages && collectiblesData.totalPages > page
        ? true
        : false,
    [collectiblesData?.totalPages, page],
  );

  return (
    <Box width={1} mb={25}>
      <Header
        bgImage={bannerImage?.fileUri || ""}
        logoImage={logoImage?.fileUri || ""}
      />
      <Typography variant="h1" mb={2}>
        {title}
      </Typography>

      <InfoBlock
        itemsCount={pageData.length}
        network={network}
        description={description}
        createdBy={createdBy}
      />

      <CollectiblesGrid items={pageData} />

      {isLoading && <LoaderCentered />}

      {hasNextPage && (
        <ViewMoreButton isLoading={isLoading} onClick={handleViewMore} />
      )}
    </Box>
  );
};
