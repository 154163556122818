import { EWalletId } from "modules/api/const";

export const getWalletName = (id: EWalletId): string => {
  switch (id) {
    case EWalletId.walletConnect:
      return "WalletConnect";
    case EWalletId.metamask:
      return "MetaMask";
    default:
      return "web3 wallet";
  }
};
