import { Box, FormControl, Input, InputLabel, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { Token } from "modules/common/types";
import { t } from "modules/utils/intl";
import { Button } from "uiKit/components/Button";
import { NetworkOrTokenIcon } from "uiKit/components/Icon";
import { useEditListingDialogStyles } from "./useEditListingDialogStyles";
import { useState } from "react";

interface IEditListingDialogProps {
  initPrice: BigNumber;
  token: Token;
  handleSaveChanges: () => void;
}

export const EditListingDialog = ({
  initPrice,
  token,
  handleSaveChanges,
}: IEditListingDialogProps): JSX.Element => {
  const { classes } = useEditListingDialogStyles();
  const [price, setPrice] = useState(initPrice.toFixed());

  const regExp = new RegExp(`^(\\d*\\.{0,1}\\d{0,${8}}$)`);

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h3Demibold" mb={4}>
        {t("plaza.collectible.edit-dialog.set-price")}
      </Typography>
      <FormControl>
        <InputLabel>{t("plaza.collectible.edit-dialog.price")}</InputLabel>
        <Input
          className={classes.inputRoot}
          value={price}
          onChange={e => {
            const { value: inputValue } = e.target;
            const validated = inputValue.match(regExp);
            if ((validated || !inputValue) && inputValue !== ".") {
              if (!!inputValue) {
                setPrice(inputValue);
              } else {
                setPrice("");
              }
            }
          }}
          endAdornment={
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              className={classes.endRoot}
            >
              <NetworkOrTokenIcon name={token} className={classes.tokenIcon} />
              <Typography variant="body2">{token}</Typography>
            </Box>
          }
        />
      </FormControl>
      <Button variant="contained" onClick={handleSaveChanges}>
        {t("plaza.collectible.edit-dialog.save")}
      </Button>
    </Box>
  );
};
