import { Box, Typography } from "@mui/material";
import { useCollectiblesData } from "./useCollectiblesData";
import { ViewMoreButton } from "modules/common/components/ViewMoreButton";
import { t } from "modules/utils/intl";
import { ContentFullWrapper } from "modules/common/components/ContentFullWrapper";
import { Empty } from "modules/common/components/Empty";
import { HeaderFilters } from "./components/HeaderFilters";
import { CollectiblesGrid } from "modules/common/components/CollectiblesGrid";
import { CollectiblesSkeletonGrid } from "modules/common/components/CollectiblesSkeletonGrid";

export const Collectibles = (): JSX.Element => {
  const {
    data,
    isLoading,
    hasNextPage,
    searchValue,
    searchDelay,
    viewMoreDelay,
    setSearch,
    handleClickViewMore,
  } = useCollectiblesData();

  return (
    <Box mb={25}>
      <Typography variant="h1" mb={7}>
        {t("plaza.collectibles.title")}
      </Typography>

      <HeaderFilters
        search={searchValue}
        isLoading={isLoading}
        setSearch={setSearch}
      />

      {isLoading && !viewMoreDelay && <CollectiblesSkeletonGrid />}

      {!isLoading && !searchDelay && searchValue && data.length === 0 && (
        <ContentFullWrapper>
          <Empty text={t("plaza.collectibles.empty")} />
        </ContentFullWrapper>
      )}

      {data.length > 0 && (
        <>
          <CollectiblesGrid items={data} />

          {(hasNextPage || isLoading) && (
            <ViewMoreButton
              isLoading={isLoading}
              onClick={handleClickViewMore}
            />
          )}
        </>
      )}
    </Box>
  );
};
