import { Box, Skeleton } from "@mui/material";
import { useCollectionTileSkeletonStyles } from "./useCollectionTileSkeletonStyles";

export const CollectionTileSkeleton = (): JSX.Element => {
  const { classes } = useCollectionTileSkeletonStyles();

  return (
    <Box className={classes.root}>
      <Skeleton className={classes.sliderRoot} />
      <Box position="absolute" bottom={20} left={20}>
        <Skeleton width={184} height={28} className={classes.light} />
        <Skeleton width={80} height={20} className={classes.light} />
      </Box>
    </Box>
  );
};
