import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { PropsWithChildren, useEffect } from "react";
import { useInitializeLocale } from "useInitializeLocale";
import { Notifications } from "uiKit/components/Notifications/Notifications";
import { useRestoreConnection } from "modules/common/hooks/useRestoreConnection";
import { LoaderCentered } from "modules/common/components/LoaderCentered";
import { mainTheme } from "uiKit/mainTheme";
import { useConnectedData } from "modules/common/hooks/useConnectedData";
import { useDialog } from "modules/common/hooks/useDialog";
import { Dialog } from "modules/common/components/Dialog";
import { NetworkGuard } from "modules/common/components/NetworkGuard";
import { getChainId } from "modules/common/utils/getChainId";
import { Network } from "modules/common/types";
import packageJSON from "../package.json";
import { OryLifecycleProvider } from "modules/common/components/OryLifecycleProvider";
import { BrowserRouter as Router } from "react-router-dom";

const AVAILABLE_CHAIN_IDS = [
  getChainId(Network.Eth),
  getChainId(Network.Matic),
  getChainId(Network.Bsc),
  getChainId(Network.ArbitrumNova),
];

export const AppBase = ({ children }: PropsWithChildren): JSX.Element => {
  const isInitialized = useInitializeLocale();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const connectionRestorePending = useRestoreConnection();

  const { isConnected, chainId } = useConnectedData();

  const { isOpened, onOpen, onClose } = useDialog();

  useEffect(() => {
    if (isConnected && chainId) {
      if (!AVAILABLE_CHAIN_IDS.includes(chainId)) {
        onOpen();
      }

      if (AVAILABLE_CHAIN_IDS.includes(chainId) && isOpened) {
        onClose();
      }
    }
  }, [chainId, isConnected, isOpened, onClose, onOpen]);

  return (
    <ThemeProvider theme={mainTheme}>
      {isInitialized ? (
        <>
          <CssBaseline />
          <Notifications />
          <OryLifecycleProvider>
            <Router basename={packageJSON.homepage}>{children}</Router>
          </OryLifecycleProvider>
        </>
      ) : (
        <Box height="100vh">
          <LoaderCentered />
        </Box>
      )}
      <Dialog isCloseButtonHidden open={isOpened} onClose={onClose}>
        <NetworkGuard />
      </Dialog>
    </ThemeProvider>
  );
};
