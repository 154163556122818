import { makeStyles } from "tss-react/mui";

export const useGamesListStyles = makeStyles()((theme) => ({
  iconText: {
    color: theme.palette.grey[300],
  },
  viewGame: {
    display: "none",
  },
  tableRow: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey[700],
    },

    "&:hover .MuiTableCell-root .platform-icon": {
      display: "none",
    },

    "&:hover .MuiTableCell-root .view-game": {
      display: "flex",
      alignItems: "center",
    },
  },
  icon: {
    height: 52,
    width: 52,
    borderRadius: "50%",
  },
  arrowIcon: {
    marginLeft: theme.spacing(1),
    transform: "rotate(-90deg)",
  },
}));
