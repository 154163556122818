import { configFromEnv, web3Api } from "modules/api";
import { GET_PROJECTS_PLAZA_LIST_URL } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { GAMES_PAGE_SIZE } from "modules/common/const";
import { IGamesListItem } from "modules/plaza/types";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

interface IResponse {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: IGamesListItem[];
  pageNumber: number;
  totalCount: number;
  totalPages: number;
}

interface IGetProjectListArgs {
  name?: string;
  pageNumber?: number;
  pageSize?: number;
}

interface IGetProjectListParams {
  Name?: string;
  PageNumber?: string;
  PageSize?: string;
}

export const { useFetchFeaturedGamesQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    fetchFeaturedGames: build.query<IResponse, IGetProjectListArgs>({
      queryFn: queryFnNotifyWrapper<IGetProjectListArgs, never, IResponse>(
        async ({ name, pageNumber, pageSize = GAMES_PAGE_SIZE }) => {
          try {
            const params: IGetProjectListParams = {};
            if (name) {
              params.Name = name;
            }
            if (pageNumber) {
              params.PageNumber = pageNumber.toString();
            }
            if (pageSize) {
              params.PageSize = pageSize.toString();
            }

            const response = await fetch(
              gamingUrl +
                GET_PROJECTS_PLAZA_LIST_URL +
                "?" +
                new URLSearchParams({
                  ...params,
                }),
              {
                method: "GET",
                credentials: "include",
              },
            );

            const data = await processApiResponse(response);

            return { data };
          } catch (err) {
            console.error("Error in `useFetchFeaturedGamesQuery.ts`", err);
            throw err;
          }
        },
      ),
    }),
  }),
});
