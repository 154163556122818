import { web3Api } from "modules/api";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { setConnectionStatus } from "store/authSlice";
import { EWalletId } from "modules/api/const";
import { getProviderManager } from "modules/api/getProviderManager";

export interface IConnect {
  address: string;
  chainId: number;
  isConnected: boolean;
  walletId: string;
  walletName: string;
  walletIcon?: string;
}

interface IConnectArgs {
  wallet?: EWalletId;
  chainId: number;
}

export const {
  useConnectMutation,
  endpoints: { connect },
} = web3Api.injectEndpoints({
  endpoints: build => ({
    connect: build.mutation<IConnect, IConnectArgs>({
      queryFn: queryFnNotifyWrapper<IConnectArgs, never, IConnect>(
        async ({ wallet, chainId }: IConnectArgs) => {
          const providerManager = getProviderManager();
          const provider = await providerManager.getETHWriteProvider(wallet);

          await provider.connect();

          if (provider.currentChain !== chainId) {
            await provider.switchNetwork(chainId);
          }

          const metaData = provider.getWalletMeta();

          return {
            data: {
              address: provider.currentAccount ?? "",
              chainId: chainId,
              isConnected: provider.isConnected(),
              walletId: metaData.id,
              walletName: metaData.name,
              walletIcon: metaData.icon,
            },
          };
        }
      ),

      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        return queryFulfilled.then(response => {
          const {
            address,
            chainId,
            isConnected,
            walletId,
            walletName,
            walletIcon,
          } = response.data;
          dispatch(
            setConnectionStatus({
              isActive: isConnected,
              chainId: chainId,
              address: address,
              walletId: walletId,
              walletIcon: walletIcon,
              walletName: walletName,
            })
          );
        });
      },
    }),
  }),
});
