import { uid } from "react-uid";
import { Box } from "@mui/material";
import { ICollectionListItem } from "modules/plaza/types";
import { CollectionTile } from "modules/common/components/CollectionTile";

interface IGridProps {
  items?: ICollectionListItem[];
}

export const CollectionsGrid = ({ items }: IGridProps): JSX.Element => {
  return (
    <>
      {!!items && !!items?.length && items.length > 0 && (
        <Box display="flex" flexWrap="wrap" gap={5}>
          {items.map((collectionItem) => (
            <CollectionTile
              key={uid(collectionItem)}
              id={collectionItem.id}
              name={collectionItem.name}
              projectName={collectionItem.gameProjectName}
              nftItemsNumber={collectionItem.countOfNftInCollection}
              capitalization={undefined} // TODO: add it after adding analytics
              image={collectionItem.logoImage.fileUri}
            />
          ))}
        </Box>
      )}
    </>
  );
};
