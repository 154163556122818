import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as SwitchIcon } from "uiKit/icons/switch-icon.svg";
import { useSwitchDialogStyles } from "./useSwitchDialogStyles";
import { t } from "modules/utils/intl";

interface ISwitchDialog {
  handleClickCancel: () => void;
  handleClickConfirm: () => void;
}

export const SwitchDialog = ({
  handleClickCancel,
  handleClickConfirm,
}: ISwitchDialog): JSX.Element => {
  const { classes } = useSwitchDialogStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width={1}>
      <SwitchIcon className={classes.icon} />
      <Typography variant="h3Demibold" mb={3}>
        {t("layout.header.switch-title")}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        textAlign="center"
        mb={7}
      >
        {t("layout.header.switch-description")}
      </Typography>
      <Box width={1} display="flex" gap={3}>
        <Button fullWidth variant="outlined" onClick={handleClickCancel}>
          {t("layout.header.cancel")}
        </Button>
        <Button fullWidth variant="contained" onClick={handleClickConfirm}>
          {t("layout.header.confirm")}
        </Button>
      </Box>
    </Box>
  );
};
