import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Token, TOKEN_FULL_NAME } from "modules/common/types";
import { NetworkOrTokenIcon } from "uiKit/components/Icon";
import { useCoinNameCellStyles } from "./useCoinNameCellStyles";

interface ICoinNameCellProps {
  token: Token;
}

export const CoinNameCell = ({ token }: ICoinNameCellProps): JSX.Element => {
  const { classes } = useCoinNameCellStyles();

  return (
    <Box display="flex" alignItems="center">
      <NetworkOrTokenIcon name={token} className={classes.tokenIcon} />
      <Box>
        <Typography variant="h4">{token}</Typography>
        <Typography variant="body2Demibold" color="textSecondary">
          {TOKEN_FULL_NAME[token]}
        </Typography>
      </Box>
    </Box>
  );
};
