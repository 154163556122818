import {
  ACTION_CACHE_SEC,
  EProjectType,
  projectTypesMap,
  EPlatformType,
  platformTypesMap,
  COLLECTIONS_PAGE_SIZE,
  COLLECTIBLES_PAGE_SIZE,
} from "modules/common/const";
import { useGetGameInfoQuery } from "./actions/useGetGameDataQuery";
import { useLazyGetGameCollectionsQuery } from "./actions/useLazyGetGameCollectionsQuery";
import { useEffect, useState } from "react";
import {
  ICollectionListItem,
  IMediaItem,
  TSocialLink,
} from "modules/plaza/types";
import { TPlatformName } from "./components/Hero/types";
import { useLazyGetGameItemsQuery } from "./actions/useLazyGetGameItemsQuery";
import { ICollectibleTileData } from "modules/common/components/CollectibleTile";
import { NetworkCode } from "modules/common/types";
import { transformCollectiblesData } from "modules/common/utils/transformCollectiblesData";
import { RoutesConfig } from "modules/plaza/Routes";

export interface IGameProjectData {
  isLoading: boolean;
  gameName: string;
  bannerUrl: string;
  logoUrl: string;
  collections: ICollectionListItem[];
  collectibles: ICollectibleTileData[];
  shortDescription: string;
  fullDescription: string;
  gameDeveloperDescription?: string;
  tags: string[];
  platforms: string[];
  videos?: IMediaItem[];
  chains?: NetworkCode[];
  screenshots?: IMediaItem[];
  gameProjectLinks: {
    platformLinks?: Record<TPlatformName, string>;
    socialLinks?: Record<TSocialLink, string>;
  };
  areCollectionsLoading: boolean;
  areItemsLoading: boolean;
}

export const useGameData = (): IGameProjectData => {
  const { id } = RoutesConfig.game.useParams();
  const [collectionsPage] = useState(1);
  const [gamesPage] = useState(1);

  const { data: gameData, isFetching: isGameInfoLoading } = useGetGameInfoQuery(
    { id: id ?? "" },
    { skip: !id, refetchOnMountOrArgChange: ACTION_CACHE_SEC },
  );

  const [
    getCollections,
    { data: collectionsData, isLoading: areCollectionsLoading },
  ] = useLazyGetGameCollectionsQuery();
  const [
    getGameItems,
    { data: gameItemsData, isLoading: areGameItemsLoading },
  ] = useLazyGetGameItemsQuery();

  useEffect(() => {
    if (id) {
      getCollections({
        id,
        pageNumber: collectionsPage,
        pageSize: COLLECTIONS_PAGE_SIZE,
      });
    }
  }, [getCollections, id, collectionsPage]);

  useEffect(() => {
    if (id) {
      getGameItems({
        id,
        pageNumber: gamesPage,
        pageSize: COLLECTIBLES_PAGE_SIZE,
      });
    }
  }, [getGameItems, id, gamesPage]);

  const {
    projectName,
    description,
    gameProjectTypes,
    banner,
    image,
    platformTypes,
    descriptionLong,
    gameDeveloperDescription,
    gameProjectLinks,
    gameScreenshots,
    videos,
    chainCodes: chains,
  } = gameData ?? {};

  return {
    isLoading: isGameInfoLoading,
    gameName: projectName ?? "",
    bannerUrl: banner?.fileUri ?? "",
    logoUrl: image?.fileUri ?? "",
    tags:
      gameProjectTypes !== undefined
        ? gameProjectTypes.map((type: EProjectType) => projectTypesMap[type])
        : [],
    platforms:
      platformTypes !== undefined
        ? platformTypes.map((type: EPlatformType) => platformTypesMap[type])
        : [],
    collections: collectionsData?.items ?? [],
    collectibles: transformCollectiblesData(gameItemsData?.items ?? []),
    shortDescription: description || "",
    fullDescription: descriptionLong ?? "",
    gameProjectLinks: gameProjectLinks ?? {},
    screenshots:
      gameScreenshots?.map(({ fileUri, fileName, id }) => ({
        id,
        fileUri,
        fileName,
      })) ?? [],
    videos: videos ?? [],
    chains: chains ?? [],
    gameDeveloperDescription: gameDeveloperDescription ?? "",
    areCollectionsLoading: areCollectionsLoading,
    areItemsLoading: areGameItemsLoading,
  };
};
