import { ReactNode } from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { useLayoutStyles } from "./useLayoutStyles";
import { Box, Link, Typography } from "@mui/material";
import { t } from "modules/utils/intl";
import { DOCS_LINK, SUPPORT_EMAIL } from "modules/api/urls";

interface ILayoutProps {
  sidebarSlot: JSX.Element;
  children?: ReactNode;
}

export const Layout = ({
  sidebarSlot,
  children,
}: ILayoutProps): JSX.Element => {
  const { classes } = useLayoutStyles();

  return (
    <div className={classes.root}>
      <Header className={classes.header} />

      <div className={classes.sidebar}>
        {sidebarSlot}
        <Box display="flex" flexDirection="column" pb={5.5} pl={10}>
          <Link
            href={DOCS_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <Typography color="textSecondary" variant="body2Demibold" mb={2}>
              {t("layout.footer.faq")}
            </Typography>
          </Link>

          <Link
            href={`mailto:${SUPPORT_EMAIL}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <Typography color="textSecondary" variant="body2Demibold">
              {t("layout.footer.feedback")}
            </Typography>
          </Link>
        </Box>
      </div>

      <div className={classes.content}>
        {children}

        <Footer />
      </div>
    </div>
  );
};
