import { Button } from "uiKit/components/Button";
import { useViewMoreButtonStyles } from "./useViewMoreButtonStyles";
import { t } from "modules/utils/intl";

interface IViewMoreButtonProps {
  isLoading: boolean;
  onClick: () => void;
}

export const ViewMoreButton = ({
  isLoading,
  onClick,
}: IViewMoreButtonProps): JSX.Element => {
  const { classes } = useViewMoreButtonStyles();

  return (
    <Button
      variant="outlined"
      isLoading={isLoading}
      className={classes.root}
      onClick={onClick}
    >
      {t("ui-kit.view-more")}
    </Button>
  );
};
