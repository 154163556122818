import { makeStyles } from "tss-react/mui";

export const useHeaderStyles = makeStyles()(theme => ({
  root: {
    width: "calc(100vw - 56px)",
    height: 80,
    margin: theme.spacing(0, 7),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    marginBottom: theme.spacing(10.5),
  },
  icon: {
    height: 24,
    width: 174,
  },
  main: {
    display: "flex",
    gap: theme.spacing(5),
    alignItems: "center",
    height: 44,
    backgroundColor: theme.palette.grey[500],
    padding: theme.spacing(0, 10),
    marginBottom: theme.spacing(8),
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    color: theme.palette.text.primary,
  },
  link: {
    textDecoration: "unset",
    marginRight: theme.spacing(4),
  },
  menu: {
    display: "flex",
    alignItems: "center",
  },
  secondActions: {
    display: "flex",
    gap: theme.spacing(5),
  },
  userLogo: {
    height: 40,
    width: 40,
    borderRadius: "50%",
  },
  active: {
    color: theme.palette.text.primary,
  },
  divider: {
    height: 12,
    marginRight: theme.spacing(4),
  },
}));
