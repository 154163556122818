import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Chevron } from "../../../../../../../../uiKit/icons";
import { t } from "../../../../../../../utils";
import { NO_FILTER_SPECIFIED } from "../../../../../../../common/const";
import { NetworkCode } from "../../../../../../../common/types";
import { useFiltersStyles } from "../../useFiltersStyles";
import { useCallback, useContext, useEffect, useState } from "react";
import { FiltersContext } from "../../../../../../../common/context/FiltersContext";
import { chainsMap } from "../../../../../../../common/utils/filters";

export const ChainsFilter = () => {
  const { activeFilters, setActiveFilters } = useContext(FiltersContext);
  const [activeChainFilter, setActiveChainFilter] = useState<
    NetworkCode | "All"
  >(NO_FILTER_SPECIFIED);
  const { classes } = useFiltersStyles({});

  const handleChainFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setActiveChainFilter(value as NetworkCode | "All");

      if (value === NO_FILTER_SPECIFIED) {
        setActiveFilters({ type: "CLEAR_FILTER", payload: "chain" });
        return;
      }

      setActiveFilters({ type: "SET_CHAIN", payload: value });
    },
    [setActiveFilters],
  );

  useEffect(() => {
    if (!activeFilters.chain) {
      setActiveChainFilter(NO_FILTER_SPECIFIED);
    }
  }, [activeFilters.chain]);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<Chevron />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body2Demibold">
          {t("plaza.collections.filters.chain")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <FormControl>
          <RadioGroup
            className={classes.radioGroup}
            aria-labelledby="demo-radio-buttons-group-label"
            value={activeChainFilter || NO_FILTER_SPECIFIED}
            name="radio-buttons-group"
            onChange={handleChainFilterChange}
          >
            <FormControlLabel
              value={NO_FILTER_SPECIFIED}
              className={classes.formControlLabel}
              control={<Radio className={classes.radio} id={"All chains"} />}
              label="All chains"
            />
            {Object.entries(chainsMap).map(([key, value]) => (
              <FormControlLabel
                key={key}
                value={key as NetworkCode}
                className={classes.formControlLabel}
                control={<Radio className={classes.radio} id={value} />}
                label={value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};
