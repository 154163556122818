import { Box } from "@mui/material";
import {
  COLLECTION_TILE_WIDHT,
  PAGE_CONTENT_WIDTH,
} from "modules/common/const";
import { CollectionTileSkeleton } from "../CollectionTileSkeleton";
import { uid } from "react-uid";

const PAGE_SIZE =
  Math.floor(PAGE_CONTENT_WIDTH / (COLLECTION_TILE_WIDHT + 20)) * 2;

export const CollectionsSkeletonGrid = (): JSX.Element => {
  return (
    <Box display="flex" flexWrap="wrap" gap={5}>
      {Array.from({ length: PAGE_SIZE }, (_, index) => {
        return <CollectionTileSkeleton key={uid(index)} />;
      })}
    </Box>
  );
};
