import { makeStyles } from "tss-react/mui";

export const useEditListingDialogStyles = makeStyles()(theme => ({
  inputRoot: {
    marginBottom: theme.spacing(8),
  },
  endRoot: {
    borderLeft: "1px solid red",
  },
  tokenIcon: {
    height: 28,
    width: 28,
  },
}));
