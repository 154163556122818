import { makeStyles } from "tss-react/mui";

export const useCollectibleStyles = makeStyles()(theme => ({
  mainSection: {
    flex: 1,

    "& + &": {
      marginLeft: theme.spacing(10),
    },
  },
}));
