import { makeStyles } from "tss-react/mui";

export const useNetworkGuardStyles = makeStyles()(theme => ({
  root: {
    width: 120,
  },
  icon: {
    marginBottom: theme.spacing(5.5),
  },
  networkName: {
    color: theme.palette.yellow.main,
  },
  networkIcon: {
    height: 18,
    width: 18,
    marginLeft: theme.spacing(2),
  },
}));
