import {
  ARBITRUM_NOVA_MAINNET_CHAIN_ID,
  BSC_MAINNET_CHAIN_ID,
  BSC_TESTNET_CHAIN_ID,
  ETH_MAINNET_CHAIN_ID,
  GOERLI_CHAIN_ID,
  POLYGON_MAINNET_CHAIN_ID,
  SEPOLIA_CHAIN_ID,
} from "../const";
import { Token } from "../types";

export const getTokenByChainId = (network: number): Token => {
  switch (network) {
    case BSC_MAINNET_CHAIN_ID:
    case BSC_TESTNET_CHAIN_ID:
      return Token.BNB;
    case POLYGON_MAINNET_CHAIN_ID:
      return Token.MATIC;
    case ETH_MAINNET_CHAIN_ID:
    case GOERLI_CHAIN_ID:
    case SEPOLIA_CHAIN_ID:
    case ARBITRUM_NOVA_MAINNET_CHAIN_ID:
    default:
      return Token.ETH;
  }
};
