import { makeStyles } from "tss-react/mui";

export const useCollectiblesFilterStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  gridIcon: {
    color: theme.palette.grey[300],
  },
}));
