import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { Chevron } from "../../../../../../../../uiKit/icons";
import { t } from "../../../../../../../utils";
import { useFiltersStyles } from "../../useFiltersStyles";
import { useCallback, useContext, useEffect, useState } from "react";
import { FiltersContext } from "../../../../../../../common/context/FiltersContext";
import { useFetchOrganizationsQuery } from "../../../../actions/useFetchOrganizationsQuery";
import { useDevelopersFilterStyles } from "./useDevelopersFilterStyles";

interface ICheckbox {
  id: string;
  name: string;
  checked: boolean;
}

export const DevelopersFilter = () => {
  const { activeFilters, setActiveFilters } = useContext(FiltersContext);
  const { data, isLoading } = useFetchOrganizationsQuery({});
  const [checkboxes, setCheckboxes] = useState<ICheckbox[]>([]);
  const { classes: filterClasses } = useFiltersStyles({});
  const { classes } = useDevelopersFilterStyles();

  useEffect(() => {
    if (data?.length) {
      const checkboxes = data.map((item: any) => ({
        id: item.id,
        name: item.name,
        checked: false,
      }));
      setCheckboxes(checkboxes);
    }
  }, [data]);

  useEffect(() => {
    if (activeFilters.developer) {
      const developersFilterState = new Set([
        ...activeFilters.developer.map((item) => item.split("::")[0]),
      ]);

      const checkedCheckboxes = checkboxes?.map((checkbox) => {
        if (developersFilterState.has(checkbox.id)) {
          return { ...checkbox, checked: true };
        } else {
          return { ...checkbox, checked: false };
        }
      });

      setCheckboxes(checkedCheckboxes);
    }
  }, [activeFilters.developer, checkboxes]);

  const handleDeveloperFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { id, name } = event.target;

      setCheckboxes((prevCheckboxes) =>
        prevCheckboxes?.map((checkbox) =>
          checkbox.id === id
            ? { ...checkbox, checked: !checkbox.checked }
            : checkbox,
        ),
      );

      setActiveFilters({ type: "SET_DEVELOPERS", payload: `${id}::${name}` });
    },
    [setActiveFilters],
  );

  return (
    <Accordion className={filterClasses.accordion}>
      <AccordionSummary
        className={filterClasses.accordionSummary}
        expandIcon={<Chevron />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body2Demibold">
          {t("plaza.games.filters.developers")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={filterClasses.accordionDetails}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <FormControl
            sx={{ margin: 0 }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              {checkboxes?.map((item: any) => (
                <FormControlLabel
                  className={classes.formControlLabel}
                  key={item.id}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      checked={item.checked}
                      onChange={handleDeveloperFilterChange}
                      name={item.name}
                      id={item.id}
                    />
                  }
                  label={item.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
