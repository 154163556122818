import { COLLECTION_TILE_WIDHT } from "modules/common/const";
import { prepareUrl } from "modules/common/utils/prepareUrl";
import { makeStyles } from "tss-react/mui";

interface ICollectionTileProps {
  imageUrl: string;
}

export const useCollectionTileStyles = makeStyles<ICollectionTileProps>()(
  (theme, { imageUrl }) => ({
    root: {
      width: COLLECTION_TILE_WIDHT,
      maxWidth: COLLECTION_TILE_WIDHT,
      height: 244,
      borderRadius: 16,
      overflow: "hidden",
      position: "relative",
      backgroundImage: `url(${prepareUrl(imageUrl)})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    bg: {
      position: "absolute",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)",
      zIndex: 0,
      opacity: 0.4,
    },
    content: {
      position: "relative",
      width: "100%",
      height: "100%",
      zIndex: 1,
    },
    contentWrapper: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(6),
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    text: {
      color: theme.palette.text.primary,
    },
    projectName: {
      color: theme.palette.yellow.main,
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      whiteSpace: "nowrap",
    },
    icon: {
      display: "block",
      position: "absolute",
      top: 20,
      right: 20,
      maxWidth: 32,
      width: 32,
      height: 32,
    },
  }),
);
