import { Components, Palette } from "@mui/material";

export function getMuiTableContainer(
  palette: Palette
): Components["MuiTableContainer"] {
  return {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.default,
        border: "none",
        boxShadow: "none",
        borderRadius: 0,
      },
    },
  };
}
