import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { ICollectionListItem } from "modules/plaza/types";
import { RoutesConfig } from "modules/plaza/Routes";
import { useCollectionsListStyles } from "./useCollectionsListStyles";
import { t } from "modules/utils/intl";
import { ReactComponent as IndicatorIcon } from "uiKit/icons/indicatorArrow.svg";
import { getShortNumber } from "../../utils/getShortNumber";
import BigNumber from "bignumber.js";
import { useMemo } from "react";
import { useHistory } from "react-router";

export interface IListProps {
  items?: ICollectionListItem[];
}

export const CollectionsList = ({ items }: IListProps) => {
  const history = useHistory();
  const { classes } = useCollectionsListStyles();
  const { palette } = useTheme();
  const rows = useMemo(
    () =>
      items?.map((item) => ({
        ...item,
        id: item.id,
        collectionName: item.name,
        gameProjectName: item.gameProjectName || "",
        logo: item.logoImage.fileUri || "",
        items: 208,
        volume: {
          currentPrice: 150019,
          previousPrice: 145006,
          change: 5.3,
          changePercent: 3.64,
          isHigher: true,
        },
        sales: 245,
        floorPrice: {
          currentPrice: 150.19,
          previousPrice: 145.6,
          change: -5.3,
          changePercent: 3.64,
          isHigher: false,
        },
        owners: 14000,
        totalSupply: 6000,
      })) || [],
    [items],
  );

  const handleRowClick = (collectionId: string) => {
    history.push(collectionId);
  };

  return (
    <Box className={classes.root}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={classes.tableColumn} variant="notes">
                  #
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.tableColumn} variant="notes">
                  {t("plaza.collections.list.collections")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.tableColumn} variant="notes">
                  {t("plaza.collections.list.items")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  className={classes.tableColumn}
                  variant="notes"
                  whiteSpace="nowrap"
                >
                  {t("plaza.collections.list.24h-volume")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.tableColumn} variant="notes">
                  {t("plaza.collections.list.sales")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  className={classes.tableColumn}
                  variant="notes"
                  whiteSpace="nowrap"
                >
                  {t("plaza.collections.list.floor-price")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.tableColumn} variant="notes">
                  {t("plaza.collections.list.owners")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  className={classes.tableColumn}
                  variant="notes"
                  whiteSpace="nowrap"
                >
                  {t("plaza.collections.list.total-supply")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                className={classes.tableRow}
                key={index}
                onClick={() =>
                  handleRowClick(RoutesConfig.collection.generatePath(row.id))
                }
              >
                <TableCell>
                  <Typography variant="body2Demibold">{index + 1}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Box display="flex" alignItems="center">
                    <img
                      className={classes.logo}
                      width={52}
                      height={52}
                      alt={`logo-${row.collectionName}`}
                      src={row.logo}
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography
                        className={classes.collectionName}
                        variant="body1Demibold"
                        component="span"
                      >
                        {row.collectionName}
                      </Typography>
                      <Typography
                        className={classes.gameProjectName}
                        variant="notes"
                        component="span"
                      >
                        {row.gameProjectName}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body2Demibold">{row.items}</Typography>
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography variant="body2Demibold" whiteSpace="nowrap">
                      {t("formats.usd-value", {
                        value: (row.volume.currentPrice / 1000).toFixed(2),
                      })}
                    </Typography>
                    <Typography
                      display="flex"
                      alignItems="center"
                      variant="body2Demibold"
                      className={
                        row.volume.change > 0
                          ? classes.indicatorGreen
                          : classes.indicatorRed
                      }
                    >
                      {row.volume.change !== 0 && (
                        <IndicatorIcon
                          className={classes.indicatorIcon}
                          fill={
                            row.volume.change > 0
                              ? palette.success.main
                              : palette.error.light
                          }
                        />
                      )}
                      {t("formats.percent-value", {
                        value: row.volume.change,
                      })}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body2Demibold">{row.sales}</Typography>
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography variant="body2Demibold" whiteSpace="nowrap">
                      {t("formats.usd-value", {
                        value: (row.floorPrice.currentPrice / 1000).toFixed(2),
                      })}
                    </Typography>
                    <Typography
                      display="flex"
                      alignItems="center"
                      variant="body2Demibold"
                      className={
                        row.floorPrice.change > 0
                          ? classes.indicatorGreen
                          : classes.indicatorRed
                      }
                    >
                      {row.floorPrice.change !== 0 && (
                        <IndicatorIcon
                          className={classes.indicatorIcon}
                          fill={
                            row.floorPrice.change > 0
                              ? palette.success.main
                              : palette.error.light
                          }
                        />
                      )}
                      {t("formats.percent-value", {
                        value: row.floorPrice.change,
                      })}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body2Demibold">
                    {row.owners > 1000
                      ? getShortNumber(new BigNumber(row.owners))
                      : row.owners}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2Demibold">
                    {row.totalSupply > 1000
                      ? getShortNumber(new BigNumber(row.totalSupply))
                      : row.totalSupply}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
