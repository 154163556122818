import { CoinNameCell } from "../CoinNameCell";
import { ValueWithUsdCell } from "../ValueWithUsdCell";
import { ActionCell } from "../ActionCell";
import { ICoinBalanceItem } from "../../useBalanceData";
import { useCoinsListStyles } from "./useCoinsListStyles";

export const Item = ({
  token,
  value,
  usdValue,
}: ICoinBalanceItem): JSX.Element => {
  const { classes } = useCoinsListStyles();

  return (
    <div className={classes.itemRoot}>
      <CoinNameCell token={token} />
      <ValueWithUsdCell value={value} usdValue={usdValue} />
      <ActionCell />
    </div>
  );
};
