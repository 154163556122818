interface IAccountData {
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
}

export const useAccountData = (): IAccountData => {
  return {
    userName: "dragonborn",
    email: "dragonborn@gmail.com",
    firstName: "dragon",
    lastName: "born",
  };
};
