import { ICollectibleTileData } from "modules/common/components/CollectibleTile";
import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useLazyGetAllCollectiblesQuery } from "./actions/useLazyGetAllCollectiblesQuery";
import { ReactText } from "modules/common/types";
import {
  COLLECTIBLES_PAGE_SIZE,
  SEARCH_DEBOUNCE_TIME,
} from "modules/common/const";
import { transformCollectiblesData } from "modules/common/utils/transformCollectiblesData";
import { useSearchField } from "modules/common/hooks";

interface ICollectiblesData {
  page: number;
  isLoading: boolean;
  hasNextPage: boolean;
  data: ICollectibleTileData[];
  searchValue: string;
  searchDelay: boolean;
  viewMoreDelay: boolean;
  setSearch: (value: string) => void;
  handleClickViewMore: () => void;
}

export const useCollectiblesData = (): ICollectiblesData => {
  const [page, setPage] = useState(1);
  const [viewMoreDelay, setViewMoreDelay] = useState(false);
  const [data, setData] = useState<ICollectibleTileData[]>([]);
  const { search, searchDelay, setSearchDelay, setSearch } = useSearchField();

  const [getAllCollectibles, { data: collectiblesData, isFetching }] =
    useLazyGetAllCollectiblesQuery();

  const handleClickViewMore = useCallback(() => {
    setViewMoreDelay(true);
    setPage(page + 1);
  }, [page]);

  const fetchCollectiblesWithSearchField = (value: ReactText) => {
    setData([]);
    setPage(1);
    getAllCollectibles({
      name: value.toString(),
      pageNumber: 1,
      pageSize: COLLECTIBLES_PAGE_SIZE,
    })
      .unwrap()
      .then((data) => setData(transformCollectiblesData(data.items)))
      .finally(() => setSearchDelay(false));
  };

  const debouncedFetchCollectiblesList = useDebouncedCallback(
    fetchCollectiblesWithSearchField,
    SEARCH_DEBOUNCE_TIME,
  );

  const handleSearchChange = (value: string) => {
    setSearch(value);
    setSearchDelay(true);
    debouncedFetchCollectiblesList(value);
  };

  useEffect(() => {
    getAllCollectibles({
      pageNumber: page,
      pageSize: COLLECTIBLES_PAGE_SIZE,
    })
      .unwrap()
      .then((data) => {
        const newPageData = transformCollectiblesData(data?.items ?? []);
        setData((oldData) => [...oldData, ...newPageData]);
      })
      .finally(() => {
        setViewMoreDelay(false);
      });
  }, [getAllCollectibles, page]);

  return {
    page,
    hasNextPage:
      collectiblesData?.totalPages && collectiblesData.totalPages > page
        ? true
        : false,
    isLoading: isFetching,
    data,
    searchDelay,
    searchValue: search,
    viewMoreDelay,
    setSearch: handleSearchChange,
    handleClickViewMore,
  };
};
