import { Box, IconButton } from "@mui/material";
import { ReactComponent as PencilIcon } from "uiKit/icons/pencil.svg";
import { useHeaderStyles } from "./useHeaderStyles";
import bg from "./blue.jpg";
import accIcon from "./01_V2.jpg";

export const Header = (): JSX.Element => {
  const { classes } = useHeaderStyles({
    bg: bg,
    icon: accIcon,
  });

  return (
    <Box className={classes.root}>
      <IconButton className={classes.icon}>
        <PencilIcon className={classes.pen} />
      </IconButton>
    </Box>
  );
};
