import { FrontendApi, Configuration } from "@ory/client";
import { configFromEnv } from "modules/api";
import { RoutesConfig } from "modules/plaza/Routes";

const oryBaseUrl = configFromEnv().oryUrl || "http://localhost:4000";

export const oryApi = new FrontendApi(
  new Configuration({
    basePath: oryBaseUrl,
    baseOptions: {
      withCredentials: true,
    },
  }),
);

export const oryLogoutAsync = async () => {
  const logoutFlow = await oryApi.createBrowserLogoutFlow();
  window.location.replace(logoutFlow.data.logout_url);
};

export const oryLoginAsync = async () => {
  const loginFlow = await oryApi.createBrowserLoginFlow({
    returnTo: new URL(
      window.location.origin +
        RoutesConfig.root +
        RoutesConfig.home.generatePath(),
    ).toString(),
    refresh: true,
  });
  window.location.replace(loginFlow.data.request_url);
};
