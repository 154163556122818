import { configFromEnv, web3Api } from "modules/api";
import { GET_ORGANIZATIONS } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { IOrganizationData } from "modules/plaza/types";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { mirageIdUrl },
} = configFromEnv();

interface IGetOrganizationDataArgs {
  id: string;
}

export const { useFetchOrganizationDataQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    fetchOrganizationData: build.query<
      IOrganizationData,
      IGetOrganizationDataArgs
    >({
      queryFn: queryFnNotifyWrapper<
        IGetOrganizationDataArgs,
        never,
        IOrganizationData
      >(async ({ id }: IGetOrganizationDataArgs) => {
        try {
          const response = await fetch(mirageIdUrl + GET_ORGANIZATIONS + id, {
            method: "GET",
            credentials: "include",
          });

          const data = await processApiResponse(response);
          return { data };
        } catch (err) {
          console.error("Error in `useFetchOrganizationDataQuery.ts`", err);
          throw err;
        }
      }),
    }),
  }),
});
