import { makeStyles } from "tss-react/mui";

export const usePurchasedCancelledDialogStyles = makeStyles()(theme => ({
  icon: {
    height: 80,
    width: 80,
    marginBottom: theme.spacing(8),
  },
  button: {
    display: "flex",
    alignItems: "center",
  },
}));
