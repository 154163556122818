import { makeStyles } from "tss-react/mui";

export const useBigImageStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    borderRadius: 20,
  },
  owned: {
    position: "absolute",
    bottom: theme.spacing(5),
    right: theme.spacing(6),
  },
}));
