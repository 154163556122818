import { makeStyles } from "tss-react/mui";

export const useSidebarItemStyles = makeStyles()(theme => ({
  icon: {
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(9),
  },
  item: {
    display: "flex",
    marginBottom: theme.spacing(5),
    color: theme.palette.text.secondary,
  },
  active: {
    borderLeft: `4px solid ${theme.palette.yellow.main}`,
    color: theme.palette.text.primary,
  },
}));
