import { createListenerMiddleware } from "@reduxjs/toolkit";
import { numberToHex } from "web3-utils";

import {
  AvailableWriteProviders,
  EthereumWeb3KeyProvider,
  EventProvider,
  EVENTS,
  getProvider,
  ProviderEvents,
} from "@ankr.com/provider";

import { CommonDataCacheTags } from "modules/common/const";
import { RootState } from "store/store";
import { connect } from "modules/common/actions/useConnectMutations";
import {
  IConnectionStatus,
  selectConnectionData,
  setAddress,
  setChainId,
} from "store/authSlice";
import { web3Api } from "modules/api";
import { disconnect } from "modules/common/actions/useDisconnectMutation";
import { getProviderManager } from "modules/api/getProviderManager";

export const listenerMiddleware = createListenerMiddleware();

const ethProviderId = AvailableWriteProviders.ethCompatible;

listenerMiddleware.startListening({
  predicate: action => {
    if (connect?.matchFulfilled(action)) {
      return true;
    }

    return false;
  },
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { dispatch, getState } = listenerApi;

    const providerManager = getProviderManager();
    const ethWeb3KeyProvider = await providerManager.getETHWriteProvider();
    const web3 = ethWeb3KeyProvider.getWeb3();

    const eventProvider: EventProvider | null = getProvider(
      web3?.currentProvider
    );

    if (eventProvider === null) return;

    EVENTS.forEach((eventName: ProviderEvents): void => {
      eventProvider.removeAllListeners(eventName);
      eventProvider.on(eventName, async (data): Promise<void> => {
        const connectionState: IConnectionStatus | undefined =
          selectConnectionData(getState() as RootState);
        if (!connectionState.isActive) return;

        switch (eventName) {
          case ProviderEvents.AccountsChanged: {
            const currentAddress: string | undefined = data[0];

            // After clicking on the "lock" button in the MetaMask extension,
            // the event is triggered with an empty array. And in this case,
            // we need to disconnect the user.
            if (!currentAddress) {
              dispatch(
                disconnect.initiate(ethProviderId, {
                  fixedCacheKey: ethProviderId,
                })
              );
            } else {
              const provider =
                await providerManager.getProvider<EthereumWeb3KeyProvider>(
                  ethProviderId
                );

              provider.currentAccount = currentAddress;

              dispatch(
                setAddress({
                  address: currentAddress,
                })
              );

              dispatch(
                web3Api.util.invalidateTags([CommonDataCacheTags.balanceData])
              );
            }
            break;
          }

          case ProviderEvents.ChainChanged: {
            const chainId = data.toString().startsWith("0x")
              ? data
              : numberToHex(data);

            const selectedChainId = Number.parseInt(chainId, 16);

            const provider =
              await providerManager.getProvider<EthereumWeb3KeyProvider>(
                ethProviderId
              );

            provider.currentChain = selectedChainId;

            dispatch(
              setChainId({
                isActive: true,
                chainId: selectedChainId,
              })
            );

            dispatch(
              web3Api.util.invalidateTags([CommonDataCacheTags.balanceData])
            );

            break;
          }
          default:
            break;
        }
      });
    });
  },
});
