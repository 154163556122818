import { configFromEnv, web3Api } from "modules/api";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { GET_POST_NFT_LISTING } from "modules/api/urls";
import { CollectibleCacheTags } from "../const";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IResponse {
  orderId: string;
}

interface IUpdateOrderIdArgs {
  id: string;
  orderId: string;
}

export const { useUpdateCollectibleOrderIdMutation } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    updateCollectibleOrderId: build.mutation<IResponse, IUpdateOrderIdArgs>({
      queryFn: queryFnNotifyWrapper<IUpdateOrderIdArgs, never, IResponse>(
        async (args: IUpdateOrderIdArgs) => {
          try {
            const response = await fetch(
              nftManagerUrl + GET_POST_NFT_LISTING + "/" + args.id + "/orderId",
              {
                method: "PUT",
                body: JSON.stringify({ orderId: args.orderId }),
                credentials: "include",
                headers: new Headers({
                  "Content-Type": "application/json",
                }),
              },
            );

            const data = await processApiResponse(response);
            return { data };
          } catch (err) {
            console.error(
              "Error in `useUpdateCollectibleOrderIdMutation.ts`",
              err,
            );
            throw err;
          }
        },
      ),
      invalidatesTags: [CollectibleCacheTags.main],
    }),
  }),
});
