import BigNumber from "bignumber.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ACTION_CACHE_SEC,
  COMMON_ERROR_MESSAGE,
  REJECTED_ERROR_MESSAGE,
  ZERO,
} from "modules/common/const";
import {
  EListingStatus,
  EOrderListingStatus,
  IMutationResponse,
  NetworkCode,
  TTokenStandart,
} from "modules/common/types";
import { useGetCollectibleDetailsQuery } from "./actions/useGetCollectibleDetailsQuery";
import { useGetCollectionQuery } from "./actions/useGetCollectionQuery";
import { IInfoProps } from "./components/Info";
import { useBuyCollectibleMutation } from "./actions/useBuyCollectibleMutation";
import { useGetCollectibleContractQuery } from "./actions/useGetCollectibleContractQuery";
import { EWalletId, METAMASK_ID } from "modules/api/const";
import { useSellCollectibleMutation } from "./actions/useSellCollectibleMutation";
import { useUpdateCollectibleOrderIdMutation } from "./actions/useUpdateCollectibleOrderIdMutation";
import { useGetOrderOnReservoirQuery } from "./actions/useGetOrderOnReservoirQuery";
import { useConnectedData } from "modules/common/hooks/useConnectedData";
import { useUpdateCollectibleOwnerMutation } from "./actions/useUpdateCollectibleOwnerMutation";
import { getNativeToken } from "modules/common/utils/getNativeToken";
import { getChainId } from "modules/common/utils/getChainId";
import { INftProperty } from "modules/plaza/types";
import { useDialog } from "modules/common/hooks/useDialog";
import { useGetNftContractsQuery } from "./actions/useGetNftContractsQuery";
import { useGetNftTemplatesQuery } from "./actions/useGetNftTemplatesQuery";
import { IBuyDialogData } from "./components/BuyDialog";
import { getTokenStandartName } from "modules/common/utils/getTokenStandartName";
import { useGetTxReceiptQuery } from "./actions/useGetTxReceiptQuery";
import { notify } from "uiKit/components/Notifications";
import { t } from "modules/utils/intl";
import { useGetMaticPriceQuery } from "modules/common/actions/useGetMaticPriceQuery";
import { useGetEthPriceQuery } from "modules/common/actions/useGetEthPriceQuery";
import { useLazyFetchCheckIsWalletConnectedQuery } from "modules/common/actions/useLazyFetchCheckIsWalletConnectedQuery";
import {
  IVerifyWalletResponse,
  useVerifyWalletMutation,
} from "modules/common/actions/useVerifyWalletMutation";
import { descSortByDate } from "modules/common/utils/descSortByDate";
import { useGetBnbPriceQuery } from "modules/common/actions/useGetBnbPriceQuery";
import { useFetchOrganizationDataQuery } from "./actions/useFetchOrganizationDataQuery";
import { useCancelReservoirOrderMutation } from "./actions/useCancelReservoirOrderMutation";
import { useUpdateCanceledListingMutation } from "./actions/useUpdateCanceledListingMutation";
import { RoutesConfig } from "modules/plaza/Routes";
import { useOrySession } from "modules/common/components/OryLifecycleProvider/context";
import { oryLoginAsync } from "modules/auth";

interface ICollectibleData extends IInfoProps {
  description?: string;
  attributes?: INftProperty[];
  tokenStandart: string;
  isLoading: boolean;
  image: string;
  listingStatus: EListingStatus;
  orderId?: string | null;
  // TODO: remove it and replace with listingStatus
  orderStatus?: EOrderListingStatus;
  contractAddress: string;
  isMyCollectible: boolean;
  isBuyLoading: boolean;
  isSellLoading: boolean;
  isOpenEditing: boolean;
  isOpenBuyDialog: boolean;
  isOpenBuyInProgressDialog: boolean;
  collectibleForPurchase: IBuyDialogData | null;
  buyTxHash?: string;
  chainId: number;
  tokenId: string;
  royalty?: number | null;
  isOpenCancelledDialog: boolean;
  handleClickOpenEdit: () => void;
  handleClickCloseEdit: () => void;
  handleClickSaveEdit: () => void;
  handleCloseBuyDialog: () => void;
  handleCloseBuyInProgressDialog: () => void;
  handleCloseCancelledDialog: () => void;
  handleClickBuyCollectible: () => void;
  handleClickCheckoutCollectible: () => void;
  handleSellCollectible: () => void;
}

export const useCollectibleData = (): ICollectibleData => {
  const { id } = RoutesConfig.collectible.useParams();
  const { session } = useOrySession();

  const {
    isOpened: isOpenBuyDialog,
    onOpen: onOpenBuyDialog,
    onClose: onCloseBuyDialog,
  } = useDialog();
  const {
    isOpened: isOpenBuyInProgressDialog,
    onOpen: onOpenBuyInProgressDialog,
    onClose: onCloseBuyInProgressDialog,
  } = useDialog();
  const {
    isOpened: isOpenCancelledDialog,
    onOpen: onOpenCancelledDialog,
    onClose: onCloseCancelledDialog,
  } = useDialog();
  const { address, walletId } = useConnectedData();
  const [collectibleForPurchase, setCollectibleForPurchase] =
    useState<IBuyDialogData | null>(null);

  const { isOpened, onOpen, onClose } = useDialog();

  const {
    data: collectibleDetailsData,
    isFetching: isCollectibleDetailsLoading,
  } = useGetCollectibleDetailsQuery(
    { id: id ?? "" },
    {
      skip: !id,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    },
  );
  const { data: organizationData, isFetching: isOrganizationDataLoading } =
    useFetchOrganizationDataQuery(
      { id: collectibleDetailsData?.organizationId ?? "" },
      {
        skip: !collectibleDetailsData,
        refetchOnMountOrArgChange: ACTION_CACHE_SEC,
      },
    );

  const listingData = useMemo(
    () =>
      !!collectibleDetailsData?.nftListings &&
      collectibleDetailsData.nftListings.length > 0
        ? [...collectibleDetailsData.nftListings].sort((a, b) =>
            descSortByDate(a.lastModified, b.lastModified),
          )[0]
        : null,
    [collectibleDetailsData?.nftListings],
  );

  const { data: nftContractsData, isFetching: isFetchingNftContractData } =
    useGetNftContractsQuery(undefined, {
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    });
  const { data: nftTemplates, isFetching: isNftTemplatesLoading } =
    useGetNftTemplatesQuery(undefined, {
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    });
  const network = collectibleDetailsData?.chainCode ?? NetworkCode.SEPOLIA;
  const chainId = getChainId(network);
  const { data: ethPrice } = useGetEthPriceQuery(undefined, {
    skip: network !== NetworkCode.SEPOLIA,
    refetchOnMountOrArgChange: ACTION_CACHE_SEC,
  });
  const { data: bnbPrice } = useGetBnbPriceQuery(undefined, {
    skip: network !== NetworkCode.BNB,
    refetchOnMountOrArgChange: ACTION_CACHE_SEC,
  });
  const { data: maticPrice } = useGetMaticPriceQuery(undefined, {
    skip: network !== NetworkCode.MATIC,
    refetchOnMountOrArgChange: ACTION_CACHE_SEC,
  });
  const externalUsdPrice = ethPrice ?? bnbPrice ?? maticPrice ?? ZERO;

  // TODO: remove it and replace with listingStatus
  const { data: reservoirOrderData, isLoading: isReservoirOrderDataLoading } =
    useGetOrderOnReservoirQuery(
      {
        orderId: listingData?.orderId ?? "",
        chainId: chainId ?? 1,
      },
      {
        skip: !listingData,
        refetchOnMountOrArgChange: ACTION_CACHE_SEC,
      },
    );

  const { data: collectionData, isFetching: isFetchingCollectionData } =
    useGetCollectionQuery(
      { id: collectibleDetailsData?.nftCollectionId ?? "" },
      {
        skip: !collectibleDetailsData,
        refetchOnMountOrArgChange: ACTION_CACHE_SEC,
      },
    );
  const {
    data: collectibleContractData,
    isFetching: isGetCollectibleContractFetching,
  } = useGetCollectibleContractQuery(
    {
      nftTokenId: id ?? "",
    },
    {
      skip: !id,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    },
  );
  const [verifyWallet] = useVerifyWalletMutation();
  const [fetchCheckIsWalletConnected] =
    useLazyFetchCheckIsWalletConnectedQuery();

  const [
    buyCollectible,
    { data: buyData, error: buyError, isLoading: isBuyLoading },
  ] = useBuyCollectibleMutation();
  const [sellCollectible, { error: sellError, isLoading: isSellLoading }] =
    useSellCollectibleMutation();
  const [cancelOrder, { isLoading: isCancelOrderLoading }] =
    useCancelReservoirOrderMutation();
  const [updateCanceledListing, { isLoading: isUpdateCanceledOrderLoading }] =
    useUpdateCanceledListingMutation();
  const { data: txReceiptData } = useGetTxReceiptQuery(
    { txHash: buyData?.txHash ?? "", wallet: walletId || METAMASK_ID },
    {
      skip: !buyData?.txHash,
      pollingInterval: 1_000,
      refetchOnMountOrArgChange: ACTION_CACHE_SEC,
    },
  );
  const [updateCollectibleOrderId, { isLoading: isUpdatingOrderIdLoading }] =
    useUpdateCollectibleOrderIdMutation();
  const [updateCollectibleOwner, { isLoading: isUpdatindOwnerLoading }] =
    useUpdateCollectibleOwnerMutation();

  const nftContract = useMemo(
    () =>
      nftContractsData?.find(
        (x) => x.contractAddress === collectibleDetailsData?.contractAddress,
      ),
    [collectibleDetailsData?.contractAddress, nftContractsData],
  );
  const nftTemplate = useMemo(
    () =>
      !!nftContract
        ? nftTemplates?.find((x) => x.id === nftContract.nftTemplateId)?.name ??
          ""
        : "",
    [nftContract, nftTemplates],
  );
  const image = useMemo(
    () =>
      !!collectibleDetailsData?.nftAsset
        ? collectibleDetailsData.nftAsset.fileUri
        : "",
    [collectibleDetailsData?.nftAsset],
  );

  const price = useMemo(() => {
    if (
      !collectibleDetailsData?.nftListings ||
      collectibleDetailsData.nftListings.length === 0
    )
      return ZERO;

    return (
      collectibleDetailsData?.nftListings
        ?.map((x) => new BigNumber(x.price))
        .filter((x) => !x.isZero())
        .reduce((prev, current) => {
          return prev.isGreaterThan(current) ? current : prev;
        }) ?? ZERO
    );
  }, [collectibleDetailsData?.nftListings]);
  const usdPrice = price.multipliedBy(externalUsdPrice);

  useEffect(() => {
    if (!!txReceiptData) {
      console.log("txReceiptData", txReceiptData);
    }
  }, [txReceiptData]);

  useEffect(() => {
    if (!!buyError) {
      if ((buyError as Error).message === REJECTED_ERROR_MESSAGE) {
        onCloseBuyInProgressDialog();
        onCloseBuyDialog();
        notify({
          message: t("plaza.collectible.purchase-reject-description", {
            name: collectibleDetailsData?.name ?? "",
            tokenId: +(collectibleDetailsData?.tokenId ?? 0),
          }),
          type: "info",
          title: t("plaza.collectible.reject-title"),
        });
      }
      if ((buyError as Error).message === COMMON_ERROR_MESSAGE) {
        onCloseBuyInProgressDialog();
        onCloseBuyDialog();
        onOpenCancelledDialog();
      }
    }

    if (!!sellError) {
      if ((sellError as Error).message === REJECTED_ERROR_MESSAGE) {
        onCloseBuyInProgressDialog();
        notify({
          message: t("plaza.collectible.selling-reject-description", {
            name: collectibleDetailsData?.name ?? "",
            tokenId: +(collectibleDetailsData?.tokenId ?? 0),
          }),
          type: "info",
          title: t("plaza.collectible.reject-title"),
        });
      }
      if ((sellError as Error).message === COMMON_ERROR_MESSAGE) {
        onCloseBuyInProgressDialog();
        onOpenCancelledDialog();
      }
    }
  }, [
    buyError,
    sellError,
    collectibleDetailsData?.name,
    collectibleDetailsData?.tokenId,
    onCloseBuyDialog,
    onCloseBuyInProgressDialog,
    onOpenCancelledDialog,
  ]);

  const handleClickCheckoutCollectible = useCallback(async () => {
    if (collectibleContractData) {
      const isVerifiedWalletAddress = await fetchCheckIsWalletConnected();

      if (!isVerifiedWalletAddress.isSuccess) return;

      const verificationData = isVerifiedWalletAddress.data
        ? null
        : ((await verifyWallet({
            wallet: walletId || METAMASK_ID,
          })) as IMutationResponse<IVerifyWalletResponse>);

      if (
        isVerifiedWalletAddress.data ||
        (verificationData !== null && !verificationData?.error)
      ) {
        buyCollectible({
          wallet: (walletId as EWalletId) || EWalletId.metamask,
          chainId,
          contractAddress: collectibleContractData.nftContractAddress,
          tokenId: collectibleDetailsData?.tokenId ?? "",
          price: listingData?.price?.toString() ?? "",
        })
          .unwrap()
          .then((buyData) => {
            if (buyData) {
              onCloseBuyDialog();
              onOpenBuyInProgressDialog();
              updateCollectibleOwner({
                id: listingData?.id ?? "",
                soldToAddress: address ?? "",
              });
            }
          });
      }
    }
  }, [
    collectibleContractData,
    chainId,
    collectibleDetailsData?.tokenId,
    listingData?.price,
    listingData?.id,
    address,
    onCloseBuyDialog,
    onOpenBuyInProgressDialog,
    updateCollectibleOwner,
    fetchCheckIsWalletConnected,
    verifyWallet,
    buyCollectible,
    walletId,
  ]);

  const handleClickBuyCollectible = useCallback(() => {
    setCollectibleForPurchase({
      image,
      name: collectibleDetailsData?.name ?? "",
      tokenId: +(collectibleDetailsData?.tokenId ?? 0),
      fromAddress: reservoirOrderData?.orders[0].maker ?? "",
      tokenStandart: getTokenStandartName(nftTemplate as TTokenStandart),
      price: price,
      usdPrice: usdPrice,
      gasFee: ZERO,
      usdGasFee: ZERO,
      network,
    });
    onOpenBuyDialog();
  }, [
    collectibleDetailsData?.name,
    collectibleDetailsData?.tokenId,
    image,
    network,
    nftTemplate,
    onOpenBuyDialog,
    price,
    reservoirOrderData?.orders,
    usdPrice,
  ]);

  const handleSellCollectible = useCallback(async () => {
    if (collectibleContractData) {
      sellCollectible({
        wallet: (walletId as EWalletId) || EWalletId.metamask,
        chainId,
        contractAddress: collectibleContractData.nftContractAddress,
        tokenId: collectibleDetailsData?.tokenId ?? "",
        price: listingData?.price?.toString() ?? "",
      })
        .unwrap()
        .then((sellData) => {
          if (sellData && !!sellData.orderId) {
            updateCollectibleOrderId({
              id: listingData?.id ?? "",
              orderId: sellData.orderId,
            });
          }
        });
    }
  }, [
    collectibleContractData,
    chainId,
    collectibleDetailsData?.tokenId,
    listingData?.price,
    listingData?.id,
    updateCollectibleOrderId,
    sellCollectible,
    walletId,
  ]);

  const handleClickStopSale = useCallback(async () => {
    if (id && listingData?.id && listingData?.orderId && chainId) {
      const cancelingOrderResponse = (await cancelOrder({
        id: listingData?.orderId,
        chainId,
      })) as IMutationResponse<boolean>;

      if (!cancelingOrderResponse.error) {
        await updateCanceledListing({
          id: listingData.id,
        });
      }
    }
  }, [
    id,
    listingData?.orderId,
    listingData?.id,
    chainId,
    cancelOrder,
    updateCanceledListing,
  ]);

  const handleClickLogin = useCallback(async () => {
    await oryLoginAsync();
  }, []);

  const owner = useMemo(
    () => collectibleDetailsData?.ownerAddress ?? "",
    [collectibleDetailsData?.ownerAddress],
  );

  return {
    collection: collectionData?.name ?? "",
    name: collectibleDetailsData?.name ?? "",
    game: "Game Studio",
    tokenStandart: getTokenStandartName(nftTemplate as TTokenStandart),
    createdBy: owner,
    price,
    usdPrice,
    token: getNativeToken(network),
    network: network,
    description: collectibleDetailsData?.description,
    attributes: collectibleDetailsData?.nftProperties?.filter(
      (x) => !!x.name && !!x.value,
    ),
    image,
    listingStatus: listingData?.listingStatus ?? EListingStatus.LISTED,
    orderId: listingData?.orderId,
    orderStatus:
      !!reservoirOrderData && reservoirOrderData.orders.length > 0
        ? reservoirOrderData.orders[0].status
        : undefined,
    contractAddress: collectibleContractData?.nftContractAddress ?? "",
    isMyCollectible: owner.toLocaleLowerCase() === address?.toLowerCase(),
    isLoading:
      isCollectibleDetailsLoading ||
      isOrganizationDataLoading ||
      isFetchingCollectionData ||
      isReservoirOrderDataLoading ||
      isGetCollectibleContractFetching ||
      isFetchingNftContractData ||
      isNftTemplatesLoading,
    isBuyLoading: isBuyLoading || isUpdatindOwnerLoading,
    isSellLoading: isSellLoading || isUpdatingOrderIdLoading,
    isOpenEditing: isOpened,
    organizationName: organizationData?.name,
    organizationLogoUri: organizationData?.iconImage.fileUri,
    isStopSaleLoading: isCancelOrderLoading || isUpdateCanceledOrderLoading,
    handleClickOpenEdit: onOpen,
    handleClickCloseEdit: onClose,
    handleClickSaveEdit: () => null,
    handleClickStopSale,
    isOpenBuyDialog,
    collectibleForPurchase,
    isOpenBuyInProgressDialog,
    buyTxHash: buyData?.txHash,
    chainId,
    tokenId: collectibleDetailsData?.tokenId ?? "",
    royalty: collectibleDetailsData?.royalty,
    isOpenCancelledDialog,
    isAuthorized: !!session?.active,
    handleCloseBuyDialog: onCloseBuyDialog,
    handleClickBuyCollectible,
    handleClickCheckoutCollectible,
    handleSellCollectible,
    handleCloseBuyInProgressDialog: onCloseBuyInProgressDialog,
    handleCloseCancelledDialog: onCloseCancelledDialog,
    handleClickLogin,
  };
};
