import { ICollectibleTileData } from "modules/common/components/CollectibleTile";
import { Network } from "modules/common/types";
import { useEffect, useState } from "react";
import { useGetCollectionDataQuery } from "./actions/useGetCollectionDataQuery";
import { useLazyGetCollectiblesByCollectionQuery } from "./actions/useLazyGetCollectiblesByCollectionQuery";
import { ICollectionTab, ICollectionTabs } from "./types";
import { transformCollectiblesData } from "modules/common/utils/transformCollectiblesData";
import { RoutesConfig } from "modules/plaza/Routes";

export interface ICollectionData {
  id: string;
  description: string;
  title: string;
  network?: Network;
  createdBy?: {
    title: string;
    icon: string;
  };
  bgImage: string;
  logoImage: string;
  tabs: ICollectionTab[];
  currentTab: ICollectionTabs;
  collectibles: ICollectibleTileData[];
  isLoading: boolean;
}

export const useCollectionData = (): ICollectionData => {
  const [tab] = useState<ICollectionTab["value"]>(ICollectionTabs.ITEMS);
  const [page] = useState(1);
  const { id } = RoutesConfig.collection.useParams();

  const { data, isFetching } = useGetCollectionDataQuery(
    { id: id ?? "" },
    { skip: !id },
  );
  const [
    getCollectiblesByCollection,
    { data: collectiblesData, isFetching: isCollectiblesLoading },
  ] = useLazyGetCollectiblesByCollectionQuery();

  useEffect(() => {
    if (id) {
      getCollectiblesByCollection({
        collectionId: id,
        pageNumber: page,
      });
    }
  }, [getCollectiblesByCollection, id, page]);

  useEffect(() => {
    getCollectiblesByCollection({
      collectionId: id ?? "",
      pageNumber: page,
    });
  }, [getCollectiblesByCollection, page, id]);
  return {
    id: id ?? "",
    title: data?.name ?? "",
    description: data?.description ?? "",
    network: undefined,
    tabs: [],
    currentTab: tab,
    bgImage: data?.bannerImage.fileUri ?? "",
    logoImage: data?.logoImage.fileUri ?? "",
    collectibles: transformCollectiblesData(collectiblesData?.items ?? []),
    isLoading: isFetching || isCollectiblesLoading,
  };
};
