import { Typography } from "@mui/material";
import { t } from "modules/utils/intl";
import { useControlsStyles } from "./useControlsStyles";

export const Heading = ({ contentType }: { contentType: string }) => {
  const { classes } = useControlsStyles({});
  return (
    <Typography variant="h1" className={classes.heading}>
      {t(`plaza.games.${contentType === "image" ? "screenshots" : "videos"}`)}
    </Typography>
  );
};
