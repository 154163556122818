/* eslint-disable jsx-a11y/anchor-is-valid */
import Typography from "@mui/material/Typography";
import { t } from "modules/utils/intl";
import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import { useFooterStyles } from "./useFooterStyles";
import { featuresConfig } from "modules/common/featuresConfig";

interface Props {
  className?: string;
}

export function Footer({ className }: Props): JSX.Element {
  const { classes, cx } = useFooterStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.main}>
        <div className={classes.logo}>
          <Typography variant="caption" color="textSecondary">
            {t("layout.footer.powered-by")}
          </Typography>
          <LogoIcon />
        </div>
        {featuresConfig.isLegacyLinksEnabled && (
          <div className={classes.links}>
            <a href="#" className={classes.link}>
              <Typography color="textSecondary" variant="body2Demibold">
                {t("layout.footer.legal")}
              </Typography>
            </a>
            <a href="#" className={classes.link}>
              <Typography color="textSecondary" variant="body2Demibold">
                {t("layout.footer.privacy-policy")}
              </Typography>
            </a>
            <a href="#" className={classes.link}>
              <Typography color="textSecondary" variant="body2Demibold">
                {t("layout.footer.cookies")}
              </Typography>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
