import { Box, Stack, Typography } from "@mui/material";
import { HeaderPaper } from "modules/common/components/HeaderPaper";
import { useDeveloperData } from "./useDeveloperData";
import { SimpleIcon } from "uiKit/components/Icon";

import { ReactComponent as MobileIcon } from "uiKit/icons/mobile.svg";
import { ReactComponent as DividerIcon } from "uiKit/icons/dot-divider.svg";
import { ReactComponent as EthIcon } from "uiKit/icons/eth.svg";

import { t } from "modules/utils/intl";

export const Developer = (): JSX.Element => {
  const { game, games } = useDeveloperData();

  return (
    <Box width={1}>
      <HeaderPaper
        hideRightSideBlock
        imageSrc={game.background}
        rightSideContentSlot={
          <>
            <Stack
              gap={2}
              direction="row"
              alignItems="center"
              divider={<DividerIcon />}
            >
              <SimpleIcon icon={<MobileIcon />} />
              <SimpleIcon icon={<EthIcon />} />

              {game.genres && game.genres.length > 0 ? (
                <Typography variant="body1Demibold">
                  {game.genres.join(" - ")}
                </Typography>
              ) : null}

              {games && games.length > 0 ? (
                <Typography variant="body1Demibold">
                  {t("plaza.games.developer.games", { value: games.length })}
                </Typography>
              ) : null}
            </Stack>

            <Box mt={5} mb={6}>
              <img src={game.logo} alt="" />
            </Box>

            <Box sx={{ maxWidth: 380 }}>
              <Typography variant="body2">{game.description}</Typography>
            </Box>
          </>
        }
      />
    </Box>
  );
};
