import { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Hero } from "./components/Hero";
import { ChainFilter } from "../Games/components/ChainFilter";
import { t } from "modules/utils/intl";
import { ViewAllButton } from "modules/common/components/ViewAllButton";
import { GamesGrid } from "modules/common/components/GamesGrid";
import { CollectiblesGrid } from "modules/common/components/CollectiblesGrid";
import { useFetchCollectiblesByChainQuery } from "./actions/useFetchCollectiblesByChain";
import { useFetchCollectionsByChainQuery } from "./actions/useFetchCollectionsByChain";
import { useFetchGameProjectsQuery } from "./actions/useFetchGameProjectsQuery";
import { RoutesConfig } from "../../Routes";
import { EmptyData } from "modules/common/components/EmptyData";
import { IOption, Select } from "modules/common/components/Select";
import {
  platformsOptions,
  transformSelectValueToOption,
} from "./platformsOptions";
import { EPlatformType, NO_FILTER_SPECIFIED } from "../../../common/const";
import { CollectionsGrid } from "../../../common/components/CollectionsGrid";
import { GamesSkeletonGrid } from "modules/common/components/GamesSkeletonGrid";
import { CollectionsSkeletonGrid } from "modules/common/components/CollectionsSkeletonGrid";
import { CollectiblesSkeletonGrid } from "modules/common/components/CollectiblesSkeletonGrid";
import { TChainCode } from "../../types";

export const Home = (): JSX.Element => {
  const [collectiblesActiveChain, setCollectiblesActiveChain] =
    useState<TChainCode>(NO_FILTER_SPECIFIED);
  const [collectionsActiveChain, setCollectionsActiveChain] =
    useState<TChainCode>(NO_FILTER_SPECIFIED);
  const [gameProjectsActiveChain, setGameProjectsActiveChain] =
    useState<TChainCode>(NO_FILTER_SPECIFIED);

  const [currentPlatformSortOption, setCurrentPlatformSortOption] =
    useState<IOption>({
      value: NO_FILTER_SPECIFIED,
      label: "All platforms",
    });

  const gamesLink = RoutesConfig.games.generatePath();
  const collectionsLink = RoutesConfig.collections.generatePath();
  const collectiblesLink = RoutesConfig.collectibles.generatePath();

  const { data: gameProjects, isLoading: areGameProjectsLoading } =
    useFetchGameProjectsQuery({
      chainCode:
        gameProjectsActiveChain === NO_FILTER_SPECIFIED
          ? null
          : gameProjectsActiveChain,
      platforms:
        currentPlatformSortOption.value === NO_FILTER_SPECIFIED
          ? null
          : [
              EPlatformType[
                currentPlatformSortOption.value as keyof typeof EPlatformType
              ],
            ],
    });

  const { data: collections, isLoading: areCollectionsLoading } =
    useFetchCollectionsByChainQuery({
      chainCode:
        collectionsActiveChain === NO_FILTER_SPECIFIED
          ? null
          : collectionsActiveChain,
    });

  const { data: collectibles, isLoading: areCollectiblesLoading } =
    useFetchCollectiblesByChainQuery(
      collectiblesActiveChain === NO_FILTER_SPECIFIED
        ? null
        : collectiblesActiveChain,
    );

  const handleGameProjectsChainChange = useCallback((param: TChainCode) => {
    setGameProjectsActiveChain(param);
  }, []);

  const handleCollectionsChainChange = useCallback((param: TChainCode) => {
    setCollectionsActiveChain(param);
  }, []);

  const handleItemsChainChange = useCallback((param: TChainCode) => {
    setCollectiblesActiveChain(param);
  }, []);

  const handleSelectChange = useCallback((value: string) => {
    const option = transformSelectValueToOption(value);
    option && setCurrentPlatformSortOption(option);
  }, []);

  return (
    <Box width={1} marginBottom={25}>
      <Hero />

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" marginTop={8} marginBottom={7}>
          {t("plaza.home.games-title")}
        </Typography>
        <ViewAllButton link={gamesLink} />
      </Box>
      <Box display="flex" alignItems="center">
        <ChainFilter onChange={handleGameProjectsChainChange} />
        <Select
          defaultValue={currentPlatformSortOption}
          options={platformsOptions}
          onChange={handleSelectChange}
        />
      </Box>
      {!!gameProjects?.items.length ? (
        <Box marginBottom={7}>
          <GamesGrid data={gameProjects.items} />
        </Box>
      ) : areGameProjectsLoading ? (
        <GamesSkeletonGrid />
      ) : (
        <EmptyData dataType="games" />
      )}

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" marginTop={8} marginBottom={7}>
          {t("plaza.home.collections-title")}
        </Typography>
        <ViewAllButton link={collectionsLink} />
      </Box>
      <ChainFilter onChange={handleCollectionsChainChange} />
      <Box marginBottom={7}>
        {!!collections?.items.length ? (
          <CollectionsGrid items={collections.items} />
        ) : areCollectionsLoading ? (
          <CollectionsSkeletonGrid />
        ) : (
          <EmptyData dataType="collections" />
        )}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" marginTop={8} marginBottom={7}>
          {t("plaza.home.collectibles-title")}
        </Typography>
        <ViewAllButton link={collectiblesLink} />
      </Box>
      <ChainFilter onChange={handleItemsChainChange} />
      <Box marginBottom={7}>
        {!!collectibles?.items?.length ? (
          <CollectiblesGrid items={collectibles.items} />
        ) : areCollectiblesLoading ? (
          <CollectiblesSkeletonGrid />
        ) : (
          <EmptyData dataType="collectibles" />
        )}
      </Box>
    </Box>
  );
};
