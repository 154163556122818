import { Box, IconButton, Modal, Typography } from "@mui/material";
import { useGameDescriptionModalStyles } from "./useGameDescriptionModalStyles";
import { Close } from "uiKit/icons";
import { Button } from "uiKit/components/Button";
import { t } from "modules/utils/intl";

interface IGameDescriptionModal {
  open: boolean;
  onClose: () => void;
  gameData: {
    gameName: string;
    fullDescription: string | string[];
    logoUrl: string;
  };
}

export const GameDescriptionModal = ({
  open,
  onClose,
  gameData: { gameName, fullDescription, logoUrl },
}: IGameDescriptionModal) => {
  const { classes } = useGameDescriptionModalStyles();
  return (
    <Modal open={open} onClose={onClose} className={classes.root}>
      <Box className={classes.wrapper}>
        <Box className={classes.brand}>
          <Box display="flex">
            <Box className={classes.logoWrapper}>
              <img className={classes.logo} src={logoUrl} alt="game logo" />
            </Box>
            <Typography className={classes.heading} variant="h1">
              {gameName}
            </Typography>
          </Box>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        {Array.isArray(fullDescription) ? (
          fullDescription.map((paragraph, index) => (
            <Typography className={classes.description} key={index}>
              {paragraph}
            </Typography>
          ))
        ) : (
          <Typography className={classes.description}>
            {fullDescription}
          </Typography>
        )}
        <Button
          className={classes.btnCancel}
          variant="outlined"
          onClick={onClose}
        >
          {t("plaza.games.actions.cancel")}
        </Button>
      </Box>
    </Modal>
  );
};
