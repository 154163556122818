import { Box } from "@mui/material";
import { uid } from "react-uid";
import { COLLECTIBLES_PAGE_SIZE } from "modules/common/const";
import { CollectibleTileSkeleton } from "../CollectibleTileSkeleton";

export const CollectiblesSkeletonGrid = (): JSX.Element => {
  return (
    <Box display="flex" flexWrap="wrap" gap="40px 20px">
      {Array.from({ length: COLLECTIBLES_PAGE_SIZE }, (_, index) => {
        return <CollectibleTileSkeleton key={uid(index)} />;
      })}
    </Box>
  );
};
