import { Components, inputLabelClasses, Palette } from "@mui/material";

export function getMuiInputLabel(
  palette: Palette
): Components["MuiInputLabel"] {
  return {
    styleOverrides: {
      root: {
        color: palette.text.secondary,
        fontSize: 15,
        transform: "translate(20px, 22px)",

        [`&.${inputLabelClasses.shrink}`]: {
          fontSize: 13,
          transform: "translate(20px, 12px)",
          color: palette.text.secondary,
        },

        [`&.${inputLabelClasses.disabled}`]: {
          color: "#5C5C5C",
        },
      },
    },
  };
}
