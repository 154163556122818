import { makeStyles } from "tss-react/mui";

export const useWalletDescriptionStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    padding: theme.spacing(5),
    flexDirection: "column",
    alignItems: "center",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 52,
    height: 52,
    backgroundColor: theme.palette.grey[500],
    borderRadius: 6,
    marginRight: theme.spacing(6),
  },
}));
