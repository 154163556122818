import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useIconStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 32,
    maxHeight: 32,
    minHeight: 32,
    borderRadius: 12,
    color: theme.palette.common.white,
    backgroundColor: "#555555",
  },

  small: {
    minHeight: 24,
    maxHeight: 24,
    maxWidth: 24,
    minWidth: 24,
    borderRadius: 6,
  },

  defaultBackground: {
    backgroundColor: alpha(theme.palette.common.white, 0.28),
  },

  blackContent: {
    color: theme.palette.common.black,
  },

  whiteContent: {
    color: theme.palette.common.white,
  },

  darkBackground: {
    backgroundColor: theme.palette.grey[500],
  },
  badgeRoot: {
    borderRadius: 6,
  },
}));
