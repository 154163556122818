import { prepareUrl } from "modules/common/utils/prepareUrl";
import { makeStyles } from "tss-react/mui";

interface IHeaderStyles {
  bg: string;
  icon: string;
}

export const useHeaderStyles = makeStyles<IHeaderStyles>()(
  (theme, { bg, icon }) => ({
    root: {
      minHeight: 220,
      width: "100%",
      position: "relative",
      borderRadius: 20,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url(${prepareUrl(bg)})`,
      marginBottom: theme.spacing(24),
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 100,
      width: 100,
      borderRadius: "50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url(${prepareUrl(icon)})`,
      position: "absolute",
      bottom: theme.spacing(-14),
      zIndex: 1,
      left: theme.spacing(10),
      cursor: "pointer",

      "&:hover": {
        "& > *": {
          display: "block",
        },
      },
    },
    pen: {
      height: 20,
      width: 20,
      display: "none",
    },
  }),
);
