import { useState } from "react";

interface ISearchFieldData {
  search: string;
  searchDelay: boolean;
  setSearchDelay: (value: boolean) => void;
  setSearch: (value: string) => void;
}

export const useSearchField = (): ISearchFieldData => {
  const [search, setSearch] = useState<string>("");
  const [searchDelay, setSearchDelay] = useState(false);

  return {
    search,
    searchDelay,
    setSearchDelay,
    setSearch,
  };
};
