import { Session } from "@ory/client";
import { createContext, useContext } from "react";

interface IOrySessionContext {
  session?: Session;
  setSession: (newSession: Session) => void;
}

export const OrySessionContext = createContext<IOrySessionContext>({
  session: undefined,
  setSession: () => null,
});

export const useOrySession = () => useContext(OrySessionContext);
