import { makeStyles } from "tss-react/mui";

export const useWalletsListStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    padding: theme.spacing(5),
    flexDirection: "column",
    alignItems: "center",
  },
  arrowIcon: {
    marginRight: theme.spacing(1),
    transform: "rotate(90deg)",
    color: theme.palette.text.secondary,
  },
  logo: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(3),
  },
  itemRoot: {
    borderRadius: 12,
    border: `1px solid ${theme.palette.grey[500]}`,
  },
  getButton: {
    height: 32,
    minHeight: 32,
    width: 66,
  },
}));
