import { uid } from "react-uid";
import { format } from "date-fns";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { t } from "modules/utils/intl";
import { TableHeadCell } from "uiKit/components/Table/TableHeadCell";
import { ITxItem } from "../../useBalanceData";
import { DEFAULT_DATE_FORMAT } from "modules/common/const";
import { NetworkOrTokenIcon } from "uiKit/components/Icon";
import { TxStatus } from "../TxStatus";
import { Destination } from "../Destination";

interface ITransactionsTableProps {
  data: ITxItem[];
}

export const TransactionsTable = ({
  data,
}: ITransactionsTableProps): JSX.Element => {
  return (
    <Box mb={10}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell title={t("account.balance.date")} />
              <TableHeadCell title={t("account.balance.type")} />
              <TableHeadCell title={t("account.balance.asset")} />
              <TableHeadCell title={t("account.balance.amount")} />
              <TableHeadCell title={t("account.balance.destination")} />
              <TableHeadCell title={t("account.balance.status")} />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <TableRow key={uid(row)}>
                <TableCell style={{ width: 200 }}>
                  <Typography>
                    {format(new Date(row.date), DEFAULT_DATE_FORMAT)}
                  </Typography>
                </TableCell>
                <TableCell style={{ width: 100 }}>
                  <Typography>{row.type}</Typography>
                </TableCell>
                <TableCell style={{ width: 300 }}>
                  <Box display="flex" alignItems="center">
                    <NetworkOrTokenIcon name={row.asset} />
                    <Typography ml={2}>{row.asset}</Typography>
                  </Box>
                </TableCell>
                <TableCell style={{ width: 200 }}>
                  <Typography>+ {row.amount.toFormat()}</Typography>
                </TableCell>
                <TableCell style={{ width: 300 }}>
                  <Destination address={row.destination} />
                </TableCell>
                <TableCell style={{ width: 250 }}>
                  <TxStatus status={row.status} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
