import { Stack, Typography } from "@mui/material";
import { useBadgeStyles } from "./useBadgeStyles";

interface IBadgeProps {
  className?: string;
  text: string;
}

export const Badge = ({ className, text }: IBadgeProps): JSX.Element => {
  const { classes, cx } = useBadgeStyles();

  return (
    <Stack
      className={cx(classes.root, className)}
      direction={"row"}
      alignItems={"center"}
      gap={1}
    >
      <Typography variant="caption">{text}</Typography>
    </Stack>
  );
};
