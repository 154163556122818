import { Box, Typography } from "@mui/material";

export const PageNotFound = (): JSX.Element => {
  return (
    <Box
      height="calc(100vh - 165px)"
      width={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h1">404</Typography>
    </Box>
  );
};
