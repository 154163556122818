import { Box, Typography } from "@mui/material";
import { useGameData } from "./useGameData";
import { LoaderCentered } from "modules/common/components/LoaderCentered";
import { Hero } from "./components/Hero";
import { CollectiblesGrid } from "modules/common/components/CollectiblesGrid";
import { EmptyData } from "modules/common/components/EmptyData";
import { t } from "modules/utils";
import { CollectionsGrid } from "modules/common/components/CollectionsGrid";
import { CollectionsSkeletonGrid } from "modules/common/components/CollectionsSkeletonGrid";
import { CollectiblesSkeletonGrid } from "modules/common/components/CollectiblesSkeletonGrid";

export const Game = (): JSX.Element => {
  const gameData = useGameData();
  const {
    isLoading,
    areCollectionsLoading: isCollectionsLoading,
    areItemsLoading: isItemsLoading,
    collections,
    collectibles,
  } = gameData;

  if (isLoading) return <LoaderCentered />;

  return (
    <Box width={1} mb={25}>
      <Hero {...gameData} />

      <Typography variant="h2" marginBottom={5}>
        {t("plaza.collections.title")}
      </Typography>
      {isCollectionsLoading && <CollectionsSkeletonGrid />}
      {!isCollectionsLoading && !!collections && collections.length > 0 && (
        <CollectionsGrid items={collections} />
      )}
      {!isCollectionsLoading && (!collections || collections.length === 0) && (
        <EmptyData dataType="collections" />
      )}

      <Typography variant="h2" marginBottom={5} marginTop={17}>
        {t("plaza.collectibles.title")}
      </Typography>
      {isItemsLoading && <CollectiblesSkeletonGrid />}
      {!isItemsLoading && !!collectibles && collectibles.length > 0 && (
        <CollectiblesGrid items={collectibles} />
      )}
      {!isItemsLoading && (!collectibles || collectibles.length === 0) && (
        <EmptyData dataType="collectibles" />
      )}
    </Box>
  );
};
