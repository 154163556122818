import { HEADER_HEIGHT, SIDEBAR_WIDTH } from "modules/common/const";
import { makeStyles } from "tss-react/mui";
import { BREAKPOINTS } from "uiKit/const";

export const useLayoutStyles = makeStyles()((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "grid",
    gridTemplateAreas: `
      "header header"
      "sidebar content";
    `,
    gridTemplateRows: `${HEADER_HEIGHT}px 1fr`,
    gridTemplateColumns: `${SIDEBAR_WIDTH}px 1fr`,
    background: theme.palette.background.default,
  },

  header: {
    gridArea: "header",
    minWidth: BREAKPOINTS.sm,
    zIndex: 999,
  },

  sidebar: {
    gridArea: "sidebar",
    position: "sticky",
    top: 0,
    alignSelf: "start",
    width: SIDEBAR_WIDTH,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },

  content: {
    gridArea: "content",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    minWidth: BREAKPOINTS.xs,
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(10),

    "& > :last-child": {
      marginTop: "auto",
    },
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  link: {
    textDecoration: "unset",
  },
}));
