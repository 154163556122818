import { Stack, Typography } from "@mui/material";
import { useStatusBadgeStyles } from "./useStatusBadgeStyles";
import {
  COLLECTIBLE_STATUS_FULL_NAME_MAP,
  EListingStatus,
  ENftTokenStatus,
  ETxStatus,
} from "modules/common/types";

export type TStatusBadge = EListingStatus | ENftTokenStatus | ETxStatus;

interface IStatusBadgeProps {
  className?: string;
  type: TStatusBadge;
  lightBg?: boolean;
}

export const StatusBadge = ({
  className,
  type,
  lightBg = false,
}: IStatusBadgeProps): JSX.Element => {
  const { classes, cx } = useStatusBadgeStyles({ type });

  const isOwnedStatus = type === ENftTokenStatus.OWNED;

  return (
    <Stack
      className={cx(
        classes.root,
        lightBg && classes.lightBg,
        isOwnedStatus && classes.orangeRoot,
        className,
      )}
      direction={"row"}
      alignItems={"center"}
      gap={1}
    >
      <div className={cx(classes.dot)} />
      <Typography
        variant="caption"
        lineHeight="1em"
        className={cx(isOwnedStatus && classes.orangeText)}
      >
        {COLLECTIBLE_STATUS_FULL_NAME_MAP[type]}
      </Typography>
    </Stack>
  );
};
