import { Box, ButtonBase, Typography } from '@mui/material'
import { t } from 'modules/utils/intl'
import { Spinner } from 'uiKit/components/Spinner'
import { usePopularWalletsStyles } from './usePopularWalletsStyles'
import { cloneElement } from 'react'

interface IPopularWalletItemProps {
  name: string;
  logo: JSX.Element;
  isLoading: boolean;
  onClick: () => void;
}

export const PopularWalletItem = ({ isLoading, onClick, name, logo }: IPopularWalletItemProps) => {
  const { classes } = usePopularWalletsStyles()

  return <Box display="flex" alignItems="center" mb={1}>
    <ButtonBase
      className={classes.buttonWrapper}
      disabled={isLoading}
      onClick={onClick}
    >
      <Box display="flex" alignItems="center">
        {cloneElement(logo, {
          className: classes.popularWalletIcon,
        })}
        <Typography variant="body1Demibold">
          {t(name)}
        </Typography>
      </Box>
      {isLoading && (
        <Box>
          <Spinner className={classes.loader} />
        </Box>
      )}
    </ButtonBase>
  </Box>
}
