import { web3Api } from "modules/api";

import { EOrderListingStatus } from "modules/common/types";
import {
  RESERVOIR_ARBITRUM_NOVA_API,
  RESERVOIR_ETH_API,
  RESERVOIR_SEPOLIA_API,
  RESERVOIR_POLYGON_API,
} from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import {
  ARBITRUM_NOVA_MAINNET_CHAIN_ID,
  POLYGON_MAINNET_CHAIN_ID,
  SEPOLIA_CHAIN_ID,
} from "modules/common/const";

interface IOrderItem {
  id: string;
  status: EOrderListingStatus;
  maker: string;
}

interface IResponse {
  orders: IOrderItem[];
  continuation: unknown | null;
}

interface IGetOrderOnReservoirArgs {
  orderId: string;
  chainId: number;
}

export const { useGetOrderOnReservoirQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    getOrderOnReservoir: build.query<
      IResponse | null,
      IGetOrderOnReservoirArgs
    >({
      queryFn: queryFnNotifyWrapper<
        IGetOrderOnReservoirArgs,
        never,
        IResponse | null
      >(async ({ orderId, chainId }) => {
        if (!orderId) return { data: null };

        if (chainId === POLYGON_MAINNET_CHAIN_ID) {
          const data = await fetch(
            RESERVOIR_POLYGON_API + "/orders/asks/v4?ids=" + orderId,
            {
              method: "GET",
            },
          );
          return {
            data: await data.json(),
          };
        } else if (chainId === SEPOLIA_CHAIN_ID) {
          const data = await fetch(
            RESERVOIR_SEPOLIA_API + "/orders/asks/v4?ids=" + orderId,
            {
              method: "GET",
            },
          );
          return {
            data: await data.json(),
          };
        } else if (chainId === ARBITRUM_NOVA_MAINNET_CHAIN_ID) {
          const data = await fetch(
            RESERVOIR_ARBITRUM_NOVA_API + "/orders/asks/v4?ids=" + orderId,
            {
              method: "GET",
            },
          );
          return {
            data: await data.json(),
          };
        } else {
          const data = await fetch(
            RESERVOIR_ETH_API + "/orders/asks/v4?ids=" + orderId,
            {
              method: "GET",
            },
          );
          return {
            data: await data.json(),
          };
        }
      }),
    }),
  }),
});
