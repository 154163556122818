import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Grow, IconButton, Typography, Box, Modal } from "@mui/material";
import { TransitionGroup } from "react-transition-group";

import { useIsMounted } from "modules/common/hooks/useIsMounted";
import { t, createSlides } from "modules/utils";
import {
  // EnterFullscreen,
  // ExitFullscreen,
  ArrowPrev,
  ArrowNext,
  Close,
} from "uiKit/icons";

import { LightboxMedia, TLightboxContent } from "../../types";
import { Heading, Slide } from "./controls";
import { useLightboxStyles } from "./useLightboxStyles";
import { IMediaItem } from "../../../../types";

interface LightboxProps {
  mediaData: IMediaItem[];
  contentType: TLightboxContent;
  open: boolean;
  handleClose: () => void;
}

export const Lightbox = memo(
  ({ mediaData, contentType, open, handleClose }: LightboxProps) => {
    const slides = createSlides(mediaData, contentType);
    const [currentSlide, setCurrentSlide] = useState(() => slides[0]);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    // const [fullscreenEntered, setFullscreenEntered] = useState(false); TODO MM-116:
    const isMount = useIsMounted();
    const containerRef = useRef<HTMLDivElement>(null);
    const { classes } = useLightboxStyles();

    /* TODO MM-116: Design fullscreen mode for screenshots & videos in Lightbox component */
    // const handleToggleFullscreen = () => {
    //   const doc = document as any; // ❗️This is required because of TS errors with fullscreen API
    //   if (fullscreenEntered) {
    //     if (doc.exitFullscreen) {
    //       doc.exitFullscreen();
    //     } else if (doc.mozCancelFullScreen) {
    //       /* Firefox */
    //       doc?.mozCancelFullScreen();
    //     } else if (doc.webkitExitFullscreen) {
    //       /* Chrome, Safari and Opera */
    //       doc.webkitExitFullscreen();
    //     } else if (doc.msExitFullscreen) {
    //       /* IE/Edge */
    //       doc.msExitFullscreen();
    //     }
    //     setFullscreenEntered(false);
    //   } else {
    //     if (doc.documentElement.requestFullscreen) {
    //       doc.documentElement.requestFullscreen();
    //     } else if (doc.documentElement.mozRequestFullScreen) {
    //       /* Firefox */
    //       doc.documentElement.mozRequestFullScreen();
    //     } else if (doc.documentElement.webkitRequestFullscreen) {
    //       /* Chrome, Safari & Opera */
    //       doc.documentElement.webkitRequestFullscreen();
    //     } else if (doc.documentElement.msRequestFullscreen) {
    //       /* IE/Edge */
    //       doc.documentElement.msRequestFullscreen();
    //     }
    //     setFullscreenEntered(true);
    //   }
    // };

    const handleThumbnailClick = useCallback(
      (clickedSlide: LightboxMedia, idx: number) => {
        if (clickedSlide.id === currentSlide.id) return;
        setCurrentSlide(clickedSlide);
        setActiveSlideIndex(idx);
      },
      [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handlePrev = useCallback(() => {
      const currentIndex = slides.indexOf(currentSlide);
      const prevIndex =
        currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
      setActiveSlideIndex(prevIndex);
      setCurrentSlide(slides[prevIndex]);
    }, [currentSlide, slides]);

    const handleNext = useCallback(() => {
      const currentIndex = slides.indexOf(currentSlide);
      const nextIndex =
        currentIndex === slides.length - 1 ? 0 : currentIndex + 1;
      setActiveSlideIndex(nextIndex);
      setCurrentSlide(slides[nextIndex]);
    }, [currentSlide, slides]);

    useEffect(() => {
      if (isMount || !window) return;
      const activeSlide = document.querySelector('[data-id="active-slide"]');
      if (slides.length >= 4) {
        const container = containerRef.current;
        const containerRect = container!.getBoundingClientRect();
        const slideRect = activeSlide!.getBoundingClientRect();
        const slideHeight = slideRect.height;
        const slideOffsetTop = slideRect.top - containerRect.top;
        const scrollPosition =
          slideOffsetTop - (containerRect.height - slideHeight) / 2;
        container!.scrollTo({ top: scrollPosition, behavior: "smooth" });
      }
      // ❗️Adding isMount as dep causes issue with component not rendering.
    }, [currentSlide]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Modal open={open} onClose={handleClose} className={classes.root}>
        <Box className={classes.lightboxWrapper}>
          <Heading contentType={contentType} />
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
          <Box className={classes.content}>
            <Box className={classes.mainSlideWrapper}>
              <Box className={classes.mainSlide}>
                <TransitionGroup>
                  <Grow key={currentSlide.id} timeout={{ enter: 500, exit: 0 }}>
                    {currentSlide.contentType === "image" ? (
                      <img
                        style={{
                          maxWidth: 1036,
                          maxHeight: 600,
                          borderRadius: 12,
                        }}
                        alt="lightbox slide"
                        src={currentSlide.fileUri}
                      />
                    ) : (
                      <video
                        style={{
                          width: 1036,
                          maxHeight: 600,
                          borderRadius: 12,
                        }}
                        controls
                        src={currentSlide.fileUri}
                      />
                    )}
                  </Grow>
                </TransitionGroup>
                {/* TODO MM-116: Design fullscreen mode for screenshots & videos in Lightbox component */}
                {/*<IconButton className={classes.fullscreenButton} onClick={handleToggleFullscreen}>*/}
                {/*  {fullscreenEntered ? <ExitFullscreen /> : <EnterFullscreen />}*/}
                {/*</IconButton>*/}
              </Box>

              <Box className={classes.controls}>
                <IconButton
                  className={classes.controlButton}
                  onClick={handlePrev}
                >
                  <ArrowPrev />
                </IconButton>
                <Typography className={classes.counter}>
                  {activeSlideIndex + 1}{" "}
                  {t("plaza.games.game.screenshots.counter")} {slides.length}
                </Typography>
                <IconButton
                  className={classes.controlButton}
                  onClick={handleNext}
                >
                  <ArrowNext />
                </IconButton>
              </Box>
            </Box>

            <Box className={classes.thumbnails} ref={containerRef}>
              {slides.map((slide, idx) => {
                return (
                  <Slide
                    slide={slide}
                    isActive={activeSlideIndex === idx}
                    idx={idx}
                    key={slide.fileUri}
                    onClick={handleThumbnailClick}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  },
);
