import { Components, Palette } from "@mui/material";

export const getMuiButton = (palette: Palette): Components["MuiButton"] => ({
  variants: [
    {
      props: { variant: "text_navigate" },
      style: {
        background: "none",
        color: palette.yellow.main,
        "&:hover": {
          background: "none",
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      minWidth: "120px",
      minHeight: "40px",
      fontWeight: 600,
      backgroundColor: palette.yellow.main,
      color: palette.common.black,
      transition: "0.3s",
      textTransform: "initial",
      boxShadow: "none",

      "&:hover": {
        backgroundColor: palette.yellow.light,
        boxShadow: "none",
      },

      "&:disabled": {
        color: palette.grey[300],
        backgroundColor: palette.yellow.light,
      },
    },

    text: {
      color: palette.yellow.main,
      background: "none",

      "&:hover": {
        backgroundColor: palette.grey[500],
      },
    },

    outlined: {
      minWidth: 20,
      backgroundColor: "transparent",
      color: palette.yellow.main,
      boxShadow: "none",
      border: `1px solid ${palette.grey[500]}`,

      "&:hover": {
        border: `1px solid ${palette.grey[300]}`,
        backgroundColor: "transparent",
        boxShadow: "none",
      },

      "&:disabled": {
        border: `1px solid ${palette.grey[500]}`,
        backgroundColor: "transparent",
      },
    },

    sizeMedium: {
      fontSize: 17,
      height: 40,
      minHeight: 40,
      padding: "12px, 20px, 8px, 20px",
    },

    startIcon: {
      marginRight: 0,
    }
  },
});
