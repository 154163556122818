import { Box, Typography } from '@mui/material'
import { t, tHTML } from 'modules/utils/intl'
import { ReactComponent as WalletConnectIcon } from 'uiKit/icons/wallet-connect.svg'
import { ReactComponent as MetamaskIcon } from 'uiKit/icons/metamask.svg'
import { ReactComponent as MirageWalletIcon } from 'uiKit/icons/mirage-wallet.svg'
import { usePopularWalletsStyles } from './usePopularWalletsStyles'
import { getIsMetamaskInjected } from 'modules/common/utils/getIsMetamaskInjected'
import { DOWNLOAD_METAMASK, DOWNLOAD_MIRAGE } from 'modules/common/const'
import { useCallback } from 'react'
import { EWalletId } from 'modules/api/const'
import { getIsMirageInjected } from 'modules/common/utils/getIsMirageInjected'
import { PopularWalletItem } from './PopularWalletItem'

export interface IPopularWallet {
  walletId: EWalletId;
  name: string;
  logo: JSX.Element;
  link?: string,
  getIsWalletInjected: () => boolean,
}

const POPULAR_WALLETS: IPopularWallet[] = [
  {
    walletId: EWalletId.mirageWallet,
    name: 'wallet-connect.mrg',
    logo: <MirageWalletIcon />,
    link: DOWNLOAD_MIRAGE,
    getIsWalletInjected: getIsMirageInjected,
  },
  {
    walletId: EWalletId.metamask,
    name: 'wallet-connect.mtmsk',
    logo: <MetamaskIcon />,
    link: DOWNLOAD_METAMASK,
    getIsWalletInjected: getIsMetamaskInjected,
  },
  {
    walletId: EWalletId.walletConnect,
    name: 'wallet-connect.wallet-connect',
    logo: <WalletConnectIcon />,
    getIsWalletInjected: () => true,
  },
]

interface IPopularWalletsProps {
  walletInConnectProcess: EWalletId | null;
  handleClickConnect: (walletId: EWalletId) => void;
}

export const PopularWalletsList = (
  {
    handleClickConnect,
    walletInConnectProcess,
  }: IPopularWalletsProps): JSX.Element => {
  const { classes } = usePopularWalletsStyles()

  const handleConnectWallet = useCallback((idx: number) => () => {
    const walletToConnect = POPULAR_WALLETS[idx]
    const isWalletInjected = walletToConnect.getIsWalletInjected()

    if (isWalletInjected) {
      handleClickConnect(walletToConnect.walletId)
      return
    }

    if (walletToConnect.link) {
      window.open(walletToConnect.link, '_blank', 'noreferrer')
    }
  }, [ handleClickConnect ])

  return (
    <Box className={classes.root}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h3Demibold" mb={6}>
          {t('wallet-connect.connect-wallet')}
        </Typography>
        <Typography variant="caption" color="textSecondary" mb={5}>
          {t('wallet-connect.wallets')}
        </Typography>


        {POPULAR_WALLETS.map(({ name, logo, walletId }, idx) => (
          <PopularWalletItem
            key={walletId}
            name={name} logo={logo}
            onClick={handleConnectWallet(idx)}
            isLoading={walletInConnectProcess === walletId}
          />
        ))}

      </Box>
      <Typography variant="notes" color="textSecondary">
        {tHTML('wallet-connect.terms-policy', {
          terms: 'terms',
          policy: 'policy',
        })}
      </Typography>
    </Box>
  )
}
