import { CustomSvgIcon } from "../../modules/common/components/CustomSvgIcon";

interface IIcon {
  socialMedia: string;
  width?: number;
  height?: number;
}
export const SocialMedia = ({ socialMedia, width, height }: IIcon) => {
  if (socialMedia === "discord") {
    return (
      <CustomSvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{ width: width, height: height }}
      >
        <path
          d="M15.5535 5.03729C14.5178 4.55271 13.4104 4.20053 12.2526 4C12.1104 4.25707 11.9443 4.60284 11.8298 4.8779C10.599 4.69281 9.37956 4.69281 8.17144 4.8779C8.05693 4.60284 7.88704 4.25707 7.74357 4C6.58454 4.20053 5.47584 4.554 4.44013 5.03985C2.3511 8.19666 1.78479 11.2751 2.06794 14.3097C3.4535 15.3444 4.79627 15.973 6.11638 16.3843C6.44233 15.9357 6.73302 15.4588 6.98345 14.9563C6.5065 14.775 6.04969 14.5514 5.61805 14.2917C5.73256 14.2069 5.84457 14.1182 5.95279 14.027C8.58546 15.2583 11.4459 15.2583 14.0472 14.027C14.1566 14.1182 14.2686 14.2069 14.3819 14.2917C13.949 14.5527 13.4909 14.7763 13.014 14.9576C13.2644 15.4588 13.5538 15.937 13.881 16.3856C15.2024 15.9743 16.5464 15.3457 17.932 14.3097C18.2642 10.7918 17.3644 7.74164 15.5535 5.03729ZM7.34212 12.4434C6.55181 12.4434 5.9037 11.7056 5.9037 10.8072C5.9037 9.90873 6.53797 9.16966 7.34212 9.16966C8.14628 9.16966 8.79437 9.90743 8.78053 10.8072C8.78178 11.7056 8.14628 12.4434 7.34212 12.4434ZM12.6578 12.4434C11.8675 12.4434 11.2194 11.7056 11.2194 10.8072C11.2194 9.90873 11.8537 9.16966 12.6578 9.16966C13.462 9.16966 14.1101 9.90743 14.0962 10.8072C14.0962 11.7056 13.462 12.4434 12.6578 12.4434Z"
          fill="white"
        />
      </CustomSvgIcon>
    );
  }

  if (socialMedia === "website") {
    return (
      <CustomSvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{ width: width, height: height }}
      >
        <circle
          cx="10"
          cy="10"
          r="7"
          stroke="white"
          strokeWidth="2"
          fill="transparent"
        />
        <path
          d="M3 9.5C3 9.5 6.87581 11 10 11C13.1242 11 17 9.5 17 9.5"
          stroke="white"
          strokeWidth="2"
          fill="transparent"
        />
        <path
          d="M10.5 3C10.5 3 13 6.5 13 10C13 13.5 10.5 17 10.5 17"
          stroke="white"
          strokeWidth="2"
          fill="transparent"
        />
        <path
          d="M9.5 17C9.5 17 7 13.5 7 10C7 6.5 9.5 3 9.5 3"
          stroke="white"
          strokeWidth="2"
          fill="transparent"
        />
      </CustomSvgIcon>
    );
  }

  if (socialMedia === "discord") {
    return (
      <CustomSvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{ width: width, height: height }}
      >
        <path
          d="M15.5535 5.03729C14.5178 4.55271 13.4104 4.20053 12.2526 4C12.1104 4.25707 11.9443 4.60284 11.8298 4.8779C10.599 4.69281 9.37956 4.69281 8.17144 4.8779C8.05693 4.60284 7.88704 4.25707 7.74357 4C6.58454 4.20053 5.47584 4.554 4.44013 5.03985C2.3511 8.19666 1.78479 11.2751 2.06794 14.3097C3.4535 15.3444 4.79627 15.973 6.11638 16.3843C6.44233 15.9357 6.73302 15.4588 6.98345 14.9563C6.5065 14.775 6.04969 14.5514 5.61805 14.2917C5.73256 14.2069 5.84457 14.1182 5.95279 14.027C8.58546 15.2583 11.4459 15.2583 14.0472 14.027C14.1566 14.1182 14.2686 14.2069 14.3819 14.2917C13.949 14.5527 13.4909 14.7763 13.014 14.9576C13.2644 15.4588 13.5538 15.937 13.881 16.3856C15.2024 15.9743 16.5464 15.3457 17.932 14.3097C18.2642 10.7918 17.3644 7.74164 15.5535 5.03729ZM7.34212 12.4434C6.55181 12.4434 5.9037 11.7056 5.9037 10.8072C5.9037 9.90873 6.53797 9.16966 7.34212 9.16966C8.14628 9.16966 8.79437 9.90743 8.78053 10.8072C8.78178 11.7056 8.14628 12.4434 7.34212 12.4434ZM12.6578 12.4434C11.8675 12.4434 11.2194 11.7056 11.2194 10.8072C11.2194 9.90873 11.8537 9.16966 12.6578 9.16966C13.462 9.16966 14.1101 9.90743 14.0962 10.8072C14.0962 11.7056 13.462 12.4434 12.6578 12.4434Z"
          fill="currentColor"
        />
      </CustomSvgIcon>
    );
  }

  if (socialMedia === "youtube") {
    return (
      <CustomSvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{ width: width, height: height }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.33163 4.60784C3.09288 4.70519 2.15661 5.71239 2.09636 6.95349C2.04663 7.97607 2 9.15266 2 9.96493C2 10.7865 2.04774 11.981 2.09809 13.0115C2.15786 14.2367 3.07268 15.2372 4.29418 15.35C5.64627 15.4748 7.60249 15.6005 9.99998 15.6005C12.3912 15.6005 14.3435 15.4755 15.6953 15.351C16.9215 15.2381 17.8382 14.231 17.8971 13.0011C17.9492 11.916 18 10.6744 18 9.96493C18 9.26318 17.9503 8.04056 17.8988 6.96391C17.8393 5.71799 16.9015 4.70458 15.6581 4.60711C14.3272 4.50282 12.4076 4.40039 9.99991 4.40039C7.58606 4.40039 5.6627 4.50332 4.33157 4.60786L4.33163 4.60784ZM8.40025 7.59026L12.5248 9.96493L8.40025 12.3397V7.59026Z"
          fill="white"
        />
      </CustomSvgIcon>
    );
  }

  if (socialMedia === "twitter") {
    return (
      <CustomSvgIcon
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{ width: width, height: height }}
      >
        <path
          d="M17.5 5.45822C16.9422 5.70552 16.3478 5.86944 15.7281 5.94903C16.3656 5.56434 16.8522 4.95983 17.0809 4.23119C16.4866 4.58935 15.8303 4.84234 15.1309 4.98352C14.5666 4.37616 13.7622 4 12.8847 4C11.1822 4 9.81156 5.39663 9.81156 7.10878C9.81156 7.35514 9.83219 7.59202 9.88281 7.81752C7.32625 7.6915 5.06406 6.45311 3.54437 4.56661C3.27906 5.03184 3.12344 5.56434 3.12344 6.13759C3.12344 7.21396 3.67188 8.1681 4.48937 8.7205C3.99531 8.71103 3.51062 8.56606 3.1 8.33771C3.1 8.34718 3.1 8.3595 3.1 8.37182C3.1 9.88215 4.16594 11.1367 5.56375 11.4256C5.31344 11.4948 5.04062 11.528 4.7575 11.528C4.56062 11.528 4.36187 11.5166 4.17531 11.4749C4.57375 12.7057 5.70438 13.6106 7.04875 13.64C6.0025 14.4672 4.67406 14.9655 3.23594 14.9655C2.98375 14.9655 2.74187 14.9542 2.5 14.9229C3.86219 15.8107 5.47656 16.3176 7.2175 16.3176C12.8762 16.3176 15.97 11.5801 15.97 7.47358C15.97 7.33619 15.9653 7.20354 15.9587 7.07183C16.5691 6.63408 17.0819 6.08737 17.5 5.45822Z"
          fill="white"
        />
      </CustomSvgIcon>
    );
  }

  return (
    <CustomSvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: width, height: height }}
    >
      <circle
        cx="10"
        cy="10"
        r="7"
        stroke="white"
        strokeWidth="2"
        fill="transparent"
      />
      <path
        d="M3 9.5C3 9.5 6.87581 11 10 11C13.1242 11 17 9.5 17 9.5"
        stroke="white"
        strokeWidth="2"
        fill="transparent"
      />
      <path
        d="M10.5 3C10.5 3 13 6.5 13 10C13 13.5 10.5 17 10.5 17"
        stroke="white"
        strokeWidth="2"
        fill="transparent"
      />
      <path
        d="M9.5 17C9.5 17 7 13.5 7 10C7 6.5 9.5 3 9.5 3"
        stroke="white"
        strokeWidth="2"
        fill="transparent"
      />
    </CustomSvgIcon>
  );
};
