import { configFromEnv, web3Api } from "modules/api";
import { GET_POST_COLLECTION } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { ICollectionItem } from "modules/plaza/types";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

interface IGetCollectionDataArgs {
  id: string;
}

export const { useGetCollectionDataQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    getCollectionData: build.query<ICollectionItem, IGetCollectionDataArgs>({
      queryFn: queryFnNotifyWrapper<
        IGetCollectionDataArgs,
        never,
        ICollectionItem
      >(async ({ id }) => {
        try {
          const response = await fetch(
            nftManagerUrl + GET_POST_COLLECTION + id + "/tokens",
            {
              method: "GET",
              credentials: "include",
            },
          );

          const data = await processApiResponse(response);
          return { data };
        } catch (err) {
          console.error("Error in `useGetCollectionDataQuery.ts`", err);
          throw err;
        }
      }),
    }),
  }),
});
