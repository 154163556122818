import { makeStyles } from "tss-react/mui";

export const useInfoFormStyles = makeStyles()(theme => ({
  root: {
    width: "100%",
  },
  icon: {
    color: theme.palette.text.primary,
  },
  input: {
    width: 508,
    marginRight: theme.spacing(5),
    marginTop: "0",
  },
}));
