import { Network, Token } from "modules/common/types";

export interface IAddFundsFormData {
  address?: string;
  token?: Token;
  network?: Network;
}

interface IFormData {
  onSubmit: (data: IAddFundsFormData) => void;
}

export const useFormData = (): IFormData => {
  const onSubmit = (data: IAddFundsFormData) => {
    console.log(data);
  };

  return { onSubmit };
};
