import { makeStyles } from "tss-react/mui";

export const useGameDescriptionModalStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    alignSelf: "flex-start",
    width: 48,
    height: 48,
    padding: theme.spacing(5),
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderRadius: "6px",
  },
  wrapper: {
    position: "relative",
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(10, 8, 8),
    borderRadius: 12,
  },
  brand: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(6),
    maxWidth: 442,
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(180deg, #B4093A 0%, #071264 131.25%)",
    borderRadius: 10,
    height: 52,
    width: 52,
    marginRight: theme.spacing(4),
  },
  logo: {
    maxWidth: 60,
  },
  heading: {
    overflowWrap: "anywhere",
    marginRight: theme.spacing(12),
  },
  description: {
    maxWidth: 424,

    "&:not(:last-child)": {
      marginBottom: theme.spacing(4),
    },
  },
  btnCancel: {
    width: "100%",
  },
}));
