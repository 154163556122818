import { Components, Palette } from "@mui/material";

export const getMuiAlert = (palette: Palette): Components["MuiAlert"] => ({
  styleOverrides: {
    root: {
      display: 'block',
      textAlign: 'center',
      borderRadius: 6,
    },
    filled: {
      maxWidth: 284,
    },
    icon: {
      display: 'none',
    },
    standardSuccess: {
      backgroundColor: palette.alert?.bg?.success,
      color: palette.success.main,
    },
    standardInfo: {
      backgroundColor: palette.alert?.bg?.info,
      color: palette.alert?.text?.info,
    },
    standardWarning: {
      backgroundColor: palette.alert?.bg?.warning,
      color: palette.orange.main,
    },
    standardError: {
      backgroundColor: palette.alert?.bg?.error,
      color: palette.error.main,
    }
  }
})
