import { Components, Palette } from "@mui/material";

export function getMuiDivider(palette: Palette): Components["MuiDivider"] {
  return {
    styleOverrides: {
      root: {
        borderColor: palette.grey[500],
        backgroundColor: palette.grey[500],
      },
    },
  };
}
