import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as SwitchIcon } from "uiKit/icons/switch-icon.svg";
import { useNetworkGuardStyles } from "./useNetworkGuardStyles";
import { t, tHTML } from "modules/utils/intl";
import { useNetworkGuardData } from "./useNetworkGuardData";
import { NetworkOrTokenIcon } from "uiKit/components/Icon";
import { Network } from "modules/common/types";
import { getChainId } from "modules/common/utils/getChainId";
import { useCallback } from "react";

export const NetworkGuard = (): JSX.Element => {
  const { classes } = useNetworkGuardStyles();
  const { isLoading, handleSwitchNetwork } = useNetworkGuardData();

  const handleSwitchEth = useCallback(() => {
    handleSwitchNetwork(getChainId(Network.Eth));
  }, [handleSwitchNetwork]);

  const handleSwitchBsc = useCallback(() => {
    handleSwitchNetwork(getChainId(Network.Bsc));
  }, [handleSwitchNetwork]);

  const handleSwitchPolygon = useCallback(() => {
    handleSwitchNetwork(getChainId(Network.Matic));
  }, [handleSwitchNetwork]);

  const handleSwitchArbitrumNova = useCallback(() => {
    handleSwitchNetwork(getChainId(Network.ArbitrumNova));
  }, [handleSwitchNetwork]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <SwitchIcon className={classes.icon} />
      <Typography variant="h3Demibold" mb={3}>
        {t("network-guard.title")}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        textAlign="center"
        mb={7}
      >
        {tHTML("network-guard.description")}
      </Typography>

      <Box display="flex" flexDirection="column" width={1} gap={2}>
        <Button
          fullWidth
          disabled={isLoading}
          variant="outlined"
          onClick={handleSwitchEth}
        >
          <Typography variant="body1Demibold" className={classes.networkName}>
            {t("network-guard.ethereum")}
          </Typography>
          <NetworkOrTokenIcon
            name={Network.Eth}
            className={classes.networkIcon}
          />
        </Button>

        <Button
          fullWidth
          disabled={isLoading}
          variant="outlined"
          onClick={handleSwitchBsc}
        >
          <Typography variant="body1Demibold" className={classes.networkName}>
            {t("network-guard.bsc")}
          </Typography>
          <NetworkOrTokenIcon
            name={Network.Bsc}
            className={classes.networkIcon}
          />
        </Button>

        <Button
          fullWidth
          disabled={isLoading}
          variant="outlined"
          onClick={handleSwitchPolygon}
        >
          <Typography variant="body1Demibold" className={classes.networkName}>
            {t("network-guard.polygon")}
          </Typography>
          <NetworkOrTokenIcon
            name={Network.Matic}
            className={classes.networkIcon}
          />
        </Button>

        <Button
          fullWidth
          disabled={isLoading}
          variant="outlined"
          onClick={handleSwitchArbitrumNova}
        >
          <Typography variant="body1Demibold" className={classes.networkName}>
            {t("network-guard.arbitrum-nova")}
          </Typography>
          <NetworkOrTokenIcon
            name={Network.ArbitrumNova}
            className={classes.networkIcon}
          />
        </Button>
      </Box>
    </Box>
  );
};
