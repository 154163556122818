import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { MouseEvent, useCallback } from "react";
import { SearchInput } from "uiKit/components/SearchInput";
import { useCollectionFilterStyles } from "./useCollectionFilterStyles";
import { SimpleIcon } from "uiKit/components/Icon";
import { Button } from "uiKit/components/Button";
import { ReactComponent as GridIcon } from "uiKit/icons/grid-view.svg";
import { ReactComponent as ListIcon } from "uiKit/icons/list-view.svg";
import { Filters as FiltersIcon } from "uiKit/icons/Filters";
import { ViewMode } from "../../../plaza/screens/Games/types";

export type TViewMode = ViewMode.LIST | ViewMode.GRID;

interface ICollectionFilterProps {
  viewState: [TViewMode, (value: TViewMode) => void];
  filtersState: [boolean, (value: (prevState: boolean) => boolean) => void];
  search: string;
  isLoading: boolean;
  setSearch: (value: string) => void;
  hideViewMode?: boolean;
}

export const HeaderFilters = ({
  viewState: [viewMode, toggleViewMode],
  filtersState: [areFiltersOpen, toggleFilters],
  search,
  isLoading,
  setSearch,
  hideViewMode = false,
}: ICollectionFilterProps) => {
  const { classes } = useCollectionFilterStyles({
    viewMode,
    areFiltersOpen,
  });

  const handleViewChange = useCallback(
    (event: MouseEvent<HTMLElement>, value: TViewMode) => {
      toggleViewMode(value);
    },
    [toggleViewMode],
  );

  const handleToggleFilters = useCallback(() => {
    toggleFilters((prevState) => !prevState);
  }, [toggleFilters]);

  return (
    <Box className={classes.root}>
      <Box display="flex" flexBasis="center" marginRight={2}>
        <Box marginRight={2}>
          <Button
            className={classes.btnFilters}
            variant="outlined"
            size="small"
            onClick={handleToggleFilters}
          >
            <FiltersIcon />
          </Button>
        </Box>
        <SearchInput
          disabled={isLoading}
          value={search}
          setSearch={(value) => setSearch(value)}
        />
      </Box>

      {!hideViewMode && (
        <ToggleButtonGroup
          exclusive
          value={viewMode}
          onChange={handleViewChange}
        >
          <ToggleButton className={classes.btnToggle} value={"grid"}>
            <SimpleIcon className={classes.gridIcon} icon={<GridIcon />} />
          </ToggleButton>
          <ToggleButton className={classes.btnToggle} value={"list"}>
            <SimpleIcon
              className={classes.listIcon}
              icon={<ListIcon color="#fff" />}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
