import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { Chevron } from "../../../../../../../../uiKit/icons";
import { t } from "../../../../../../../utils";
import { useFiltersStyles } from "../../useFiltersStyles";
import { useCallback, useContext, useEffect, useState } from "react";
import { FiltersContext } from "../../../../../../../common/context/FiltersContext";
import { useGenresFilterStyles } from "./useGenresFilterStyles";
import { projectTypesMap } from "../../../../../../../common/const";
import { uid } from "react-uid";

interface ICheckbox {
  id: string;
  name: string;
  key: string;
  checked: boolean;
}

export const GenresFilter = () => {
  const { activeFilters, setActiveFilters } = useContext(FiltersContext);
  const [checkboxes, setCheckboxes] = useState<ICheckbox[]>(
    Object.entries(projectTypesMap).map(([key, value]) => ({
      id: uid(key),
      name: value,
      key: key,
      checked: false,
    })),
  );
  const { classes: filterClasses } = useFiltersStyles({});
  const { classes } = useGenresFilterStyles();

  useEffect(() => {
    if (activeFilters.genre) {
      const developersFilterState = new Set([
        ...activeFilters.genre.map((item) => item?.split("::")[0]),
      ]);

      const checkedCheckboxes = checkboxes?.map((checkbox) => {
        if (developersFilterState.has(checkbox.key)) {
          return { ...checkbox, checked: true };
        } else {
          return { ...checkbox, checked: false };
        }
      });

      setCheckboxes(checkedCheckboxes);
    }
  }, [activeFilters.genre, checkboxes]);

  const handleGenreFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { id, name } = event.target;

      setCheckboxes((prevCheckboxes) =>
        prevCheckboxes?.map((checkbox) =>
          checkbox.id === id
            ? { ...checkbox, checked: !checkbox.checked }
            : checkbox,
        ),
      );

      setActiveFilters({ type: "SET_GENRES", payload: `${id}::${name}` });
    },
    [setActiveFilters],
  );

  return (
    <Accordion className={filterClasses.accordion}>
      <AccordionSummary
        className={filterClasses.accordionSummary}
        expandIcon={<Chevron />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body2Demibold">
          {t("plaza.games.filters.genres")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={filterClasses.accordionDetails}>
        <FormControl sx={{ margin: 0 }} component="fieldset" variant="standard">
          <FormGroup>
            {checkboxes
              ?.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
              })
              .map((item: ICheckbox) => {
                return (
                  <FormControlLabel
                    className={classes.formControlLabel}
                    key={item.id}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        checked={item.checked}
                        onChange={handleGenreFilterChange}
                        name={item.name}
                        id={item.key}
                      />
                    }
                    label={item.name}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};
