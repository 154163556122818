import { makeStyles } from "tss-react/mui";

export const useBuyDialogStyles = makeStyles()(theme => ({
  root: {},
  image: {
    height: 100,
    width: 100,
    borderRadius: 12,
    marginRight: theme.spacing(5),
  },
  mainInfoRow: {
    "& > span > span": {
      color: theme.palette.yellow.main,
    },
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(3),
  },
}));
