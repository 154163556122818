import { SerializedError } from "@reduxjs/toolkit";

export enum Env {
  Stage = "stage",
  Prod = "prod",
  Local = "local",
}

export enum Locale {
  en = "en-US",
  zh = "zh-CN",
}
export enum Network {
  Eth = 0,
  Bsc = 1,
  Matic = 2,
  ArbitrumNova = 3,
}
export enum NetworkCode {
  GOERLI = "Goerli",
  SEPOLIA = "SepETH",
  ETH = "ETH",
  MATIC = "MATIC",
  BNB = "BNB",
  ARB = "ARB",
}

export enum Token {
  ETH = "ETH",
  BNB = "BNB",
  MATIC = "MATIC",
  ARB = "ARB",
}

export enum EListingStatus {
  "LISTED",
  "SOLD",
  "NotListed",
}

export enum ETxStatus {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
}

export enum ENftTokenStatus {
  OWNED = "OWNED",
  NotSupported = "NotSupported",
}

export const COLLECTIBLE_STATUS_FULL_NAME_MAP: Record<
  EListingStatus | ENftTokenStatus | ETxStatus,
  string
> = {
  [EListingStatus.LISTED]: "Listed",
  [EListingStatus.SOLD]: "Sold",
  [EListingStatus.NotListed]: "Not listed",
  [ENftTokenStatus.OWNED]: "Owned",
  [ETxStatus.COMPLETED]: "Completed",
  [ETxStatus.PENDING]: "Pending",
  [ENftTokenStatus.NotSupported]: "Not supported",
};

export enum EOrderListingStatus {
  ACTIVE = "active",
  FILLED = "filled",
}

export const TOKEN_FULL_NAME: Record<Token, string> = {
  [Token.ETH]: "Ethereum",
  [Token.BNB]: "Binance Coin",
  [Token.MATIC]: "Polygon",
  [Token.ARB]: "Arbitrum",
};

export enum RESPONSE_CODES {
  OK = 200,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  PRECONDITION_FAILED = 412,
}

export enum Platforms {
  PC = "PC",
  MOBILE = "MOBILE",
  CONSOLE = "CONSOLE",
}

export enum ViewType {
  GRID = "grid",
  LIST = "list",
}

export type ReactText = string | number;
export type Days = number;
export type Minutes = number;
export type Seconds = number;
export type Milliseconds = number;
export type Address = string;

export type TTokenStandart = "ERC721Template" | "ERC1155Template";

export enum EWalletErrorCode {
  ACTION_REJECTED = "ACTION_REJECTED",
}

export interface IMutationResponse<T> {
  data?: T;
  error?: SerializedError;
}
