import { generatePath, Route, Switch } from "react-router-dom";
import { Layout } from "../common/components/Layout";
import { createRouteConfig } from "../router";
// TODO: delete this file after changing main layout
// import { Sidebar } from "./components/Sidebar/Sidebar";
import { AddFunds } from "./screens/AddFunds";
import { Balance } from "./screens/Balance";
import { Inventory } from "./screens/Inventory";
import { Sidebar } from "modules/plaza/components/Sidebar/Sidebar";
import { Account } from "./screens/Account";
import { GuardRoute } from "modules/common/components/GuardRoute";
import { featuresConfig } from "modules/common/featuresConfig";
import { NoReactSnap } from "modules/common/components/NoReactSnap/NoReactSnap";
import { PageNotFound } from "../common/components/PageNotFound";

const ROOT_ACCOUNT_PATH = "/account";
const BALANCE_PATH = `${ROOT_ACCOUNT_PATH}/balance`;
const ADD_FUNDS_PATH = `${BALANCE_PATH}/add-funds`;
const INVENTORY_PATH = `${ROOT_ACCOUNT_PATH}/inventory`;

export const RoutesConfig = createRouteConfig(
  {
    account: {
      path: ROOT_ACCOUNT_PATH,
      generatePath: () => generatePath(ROOT_ACCOUNT_PATH),
    },

    balance: {
      path: BALANCE_PATH,
      generatePath: () => generatePath(BALANCE_PATH),
    },

    addFunds: {
      path: ADD_FUNDS_PATH,
      generatePath: () => generatePath(ADD_FUNDS_PATH),
    },

    inventory: {
      path: INVENTORY_PATH,
      generatePath: () => generatePath(INVENTORY_PATH),
    },
  },

  ROOT_ACCOUNT_PATH,
);

export function getRoutes(): JSX.Element {
  return (
    <Route
      path={[
        RoutesConfig.account.path,
        RoutesConfig.balance.path,
        RoutesConfig.addFunds.path,
        RoutesConfig.inventory.path,
      ]}
    >
      <Switch>
        {featuresConfig.isProfilePageEnabled && (
          <GuardRoute exact path={RoutesConfig.account.path}>
            <Layout sidebarSlot={<Sidebar />}>
              <NoReactSnap>
                <Account />
              </NoReactSnap>
            </Layout>
          </GuardRoute>
        )}

        <GuardRoute exact path={RoutesConfig.balance.path}>
          <Layout sidebarSlot={<Sidebar />}>
            <NoReactSnap>
              <Balance />
            </NoReactSnap>
          </Layout>
        </GuardRoute>

        <Route exact path={RoutesConfig.addFunds.path}>
          <Layout sidebarSlot={<Sidebar />}>
            <NoReactSnap>
              <AddFunds />
            </NoReactSnap>
          </Layout>
        </Route>

        <Route exact path={RoutesConfig.inventory.path}>
          <GuardRoute>
            <Layout sidebarSlot={<Sidebar />}>
              <NoReactSnap>
                <Inventory />
              </NoReactSnap>
            </Layout>
          </GuardRoute>
        </Route>

        <Route path="*">
          <Layout sidebarSlot={<Sidebar />}>
            <PageNotFound />
          </Layout>
        </Route>
      </Switch>
    </Route>
  );
}
