import { configFromEnv, web3Api } from "modules/api";
import { GET_IS_WALLET_CONNECTED } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { processApiResponse } from "modules/utils/processApiResponse";
import { RootState } from "store";
import { selectConnectionData } from "store/authSlice";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

export const { useLazyFetchCheckIsWalletConnectedQuery } =
  web3Api.injectEndpoints({
    endpoints: (build) => ({
      fetchCheckIsWalletConnected: build.query<boolean, void>({
        queryFn: queryFnNotifyWrapper<void, never, boolean>(
          async (args, { getState }) => {
            try {
              const { address } = selectConnectionData(getState() as RootState);

              const response = await fetch(
                nftManagerUrl +
                  GET_IS_WALLET_CONNECTED +
                  "?" +
                  new URLSearchParams({
                    address: address ?? "",
                  }),
                {
                  method: "GET",
                  credentials: "include",
                },
              );

              const data = await processApiResponse(response);

              return { data };
            } catch (err) {
              console.error(
                "Error in `useLazyFetchCheckIsWalletConnectedQuery.ts`",
                err,
              );
              throw err;
            }
          },
        ),
      }),
    }),
  });
