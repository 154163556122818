import { Components, Palette, toggleButtonClasses } from "@mui/material";

export const getMuiToggleButton = (palette: Palette): Components['MuiToggleButton'] => ({
  styleOverrides: {
    root: {
      [`&.${toggleButtonClasses.selected}`]: {
        backgroundColor: palette.yellow.main,
        color: palette.common.black,

        "&:hover": {
          backgroundColor: palette.yellow.main,
        }
      }
    },
    sizeSmall: {
      minWidth: 40,
      minHeight: 40,
      backgroundColor: palette.grey[700],
      color: palette.common.white,
    },
    sizeMedium: {
      backgroundColor: palette.grey[700],

      "&:hover": {
        backgroundColor: palette.yellow.main,
      }
    }
  }
})
