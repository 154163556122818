import { keyframes } from "tss-react";
import { makeStyles } from "tss-react/mui";

export const useLoaderCenteredStyles = makeStyles()(theme => ({
  root: {
    animation: `${keyframes`
      100% {
        transform: rotate(360deg);
      }`} 1s infinite linear`,
    willChange: "transform",
    height: 100,
    width: 100,
    color: "#FEFF8F", // because loader using before mui theme init
  },
}));
