import { Box, Button, Link, Typography } from "@mui/material";
import { useWalletsListStyles } from "./useWalletsListStyles";
import { cloneElement } from "react";
import { t } from "modules/utils/intl";

export interface IWalletItemProps {
  logo: JSX.Element;
  name: string;
  type: string;
  link: string;
}

export const WalletItem = ({
  logo,
  name,
  type,
  link,
}: IWalletItemProps): JSX.Element => {
  const { classes } = useWalletsListStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={5}
      height={60}
      width={384}
      className={classes.itemRoot}
    >
      <Box display="flex">
        {cloneElement(logo, {
          className: classes.logo,
        })}
        <Box display="flex" flexDirection="column">
          <Typography variant="body2Demibold">{t(name)}</Typography>
          <Typography variant="notes" color="textSecondary">
            {t(type)}
          </Typography>
        </Box>
      </Box>
      <Link href={link} target="_blank" rel="noopener noreferrer">
        <Button variant="outlined" className={classes.getButton}>
          {t("wallet-connect.get")}
        </Button>
      </Link>
    </Box>
  );
};
