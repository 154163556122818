import { queryFnWrapper } from "@ankr.com/utils";
import { uid } from "react-uid";
import { notify } from "uiKit/components/Notifications";

import { getErrMsg } from "./getErrMsg";

export const queryFnNotifyWrapper = queryFnWrapper({
  onNotification({ error, onError }) {
    const errMsg =
      typeof onError === "function" ? onError(error) : getErrMsg(error as any);

    notify({
      message: errMsg,
      type: "error",
      key: uid(errMsg),
    });
  },
});
