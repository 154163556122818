import { TableCell as MuiTableCell, Typography } from "@mui/material";
import { useTableStyles } from "./useTableStyles";

interface ITableCellProps {
  title?: string;
  tooltip?: string;
}

export const TableHeadCell = ({
  title,
  tooltip,
  ...props
}: ITableCellProps): JSX.Element => {
  const { classes } = useTableStyles();
  return (
    <MuiTableCell {...props} className={classes.root}>
      <Typography className={classes.headerCell} variant="notes">
        {title}
      </Typography>
    </MuiTableCell>
  );
};
