import CopyToClipboard from "react-copy-to-clipboard";
import { Box, IconButton, Link, Typography, useTheme } from "@mui/material";
import { Platform, SocialMedia, Copy } from "uiKit/icons";
import { capitalize } from "modules/utils/capitalize";
import { useCopyHook } from "modules/common/hooks/useCopyHook";
import { useGameLinksStyles } from "./useGameLinksStyles";
import { t } from "modules/utils/intl";
import { TGameSocialMedia } from "../../../../../../types";
import { TGamePlatformLinks } from "../../types";

interface IGameLinks {
  links: TGamePlatformLinks | TGameSocialMedia;
  metaType: string;
}

export const GameLinks = ({ links, metaType }: IGameLinks) => {
  const theme = useTheme();
  const { handleCopy } = useCopyHook();
  const { classes } = useGameLinksStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.links}>
        {Object.entries(links).map(([capitalizedKey, value]) => {
          const key = capitalizedKey.toLowerCase();
          return (
            <Link
              variant="body1Demibold"
              component="a"
              href={value}
              target={"_blank"}
              key={key}
              className={classes.link}
            >
              <Box className={classes.linkIcon}>
                {metaType === "play" && (
                  <Platform platform={key} width={20} height={20} />
                )}
                {metaType === "share" && (
                  <SocialMedia socialMedia={key} width={20} height={20} />
                )}
              </Box>
              <Box className={classes.linkTextWrapper}>
                <Typography className={classes.linkTitle} component="span">
                  {key.includes("_") ? capitalize(key, "_") : capitalize(key)}
                </Typography>
                {metaType === "play" && (
                  <Typography component="span" className={classes.linkText}>
                    {t("plaza.games.actions.play-now")}
                  </Typography>
                )}
              </Box>
            </Link>
          );
        })}
      </Box>
      {metaType === "share" && (
        <Box className={classes.copySection}>
          <CopyToClipboard text={window.location.href} onCopy={handleCopy}>
            <Box>
              {window.location.href.substring(0, 40) + "..."}
              <IconButton className={classes.btnCopy}>
                {t("plaza.games.actions.copy")}
                <Copy color={theme.palette.yellow.main} />
              </IconButton>
            </Box>
          </CopyToClipboard>
        </Box>
      )}
    </Box>
  );
};
