import { ThemeColors } from "web3modal";

export const METAMASK_ID = "injected";

export enum EWalletId {
  metamask = "injected",
  walletConnect = "walletconnect",
  mirageWallet = "custom-mirage",
}

export const web3ModalTheme: ThemeColors = {
  background: "rgb(255,255,255)",
  main: "rgb(137, 137, 137)",
  secondary: "rgb(137, 137, 137)",
  border: "rgba(195,195,195,0.14)",
  hover: "rgb(239, 239, 239)",
};
