import { useCallback, useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Chevron } from "uiKit/icons";
import { t } from "../../../../../../../utils";
import { EPlatformType, NO_FILTER_SPECIFIED } from "modules/common/const";
import { useFiltersStyles } from "../../useFiltersStyles";
import { FiltersContext } from "../../../../../../../common/context/FiltersContext";
import { platformsOptions } from "../../../../../Home/platformsOptions";

export const PlatformsFilter = () => {
  const { activeFilters, setActiveFilters } = useContext(FiltersContext);
  const [activePlatformFilter, setActivePlatformFilter] = useState<
    EPlatformType | "All"
  >(NO_FILTER_SPECIFIED);
  const { classes } = useFiltersStyles({});

  const handlePlatformFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setActivePlatformFilter(value as EPlatformType | "All");

      if (value === NO_FILTER_SPECIFIED) {
        setActiveFilters({ type: "CLEAR_FILTER", payload: "platforms" });
        return;
      }

      setActiveFilters({
        type: "SET_PLATFORM",
        payload: EPlatformType[value as keyof typeof EPlatformType],
      });
    },
    [setActiveFilters],
  );

  useEffect(() => {
    if (!activeFilters.platforms) {
      setActivePlatformFilter(NO_FILTER_SPECIFIED);
    }
  }, [activeFilters.platforms]);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<Chevron />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body2Demibold">
          {t("plaza.games.filters.platforms")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <FormControl>
          <RadioGroup
            className={classes.radioGroup}
            aria-labelledby="demo-radio-buttons-group-label"
            value={activePlatformFilter || NO_FILTER_SPECIFIED}
            name="radio-buttons-group"
            onChange={handlePlatformFilterChange}
          >
            {platformsOptions.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                className={classes.formControlLabel}
                control={<Radio className={classes.radio} id={label} />}
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};
