import { EWalletId } from "modules/api/const";
import { useEffect } from "react";
import { selectConnectionData } from "store/authSlice";
import { useAppSelector } from "store/useAppSelector";

import { useConnectMutation } from "../actions/useConnectMutations";
import { useConnectedData } from "./useConnectedData";
import { useDisconnectAll } from "./useDisconnectAll";

const NOTIFIER_TIMEOUT = 10_000;

export const useRestoreConnection = (): boolean => {
  const connectionStatus = useAppSelector(selectConnectionData);
  const disconnectAll = useDisconnectAll();

  const [connect] = useConnectMutation({
    fixedCacheKey: "useConnectMutation",
  });

  const { isConnected, isLoading, chainId, error } = useConnectedData();

  const isActiveAndNotConnected =
    connectionStatus?.isActive && !isConnected && !error;

  const isShouldBeRestored = isActiveAndNotConnected && !isLoading;

  useEffect(() => {
    if (isShouldBeRestored) {
      connect({
        wallet: connectionStatus.walletId as EWalletId,
        chainId: chainId ?? 1,
      });
    }
  }, [chainId, connect, connectionStatus, isShouldBeRestored]);

  useEffect(() => {
    if (!isActiveAndNotConnected) return undefined;

    const timeoutId = setTimeout(() => {
      disconnectAll();
    }, NOTIFIER_TIMEOUT);

    return () => clearInterval(timeoutId);
  }, [disconnectAll, isActiveAndNotConnected]);

  return isActiveAndNotConnected ?? false;
};
