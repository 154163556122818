import { Components, paginationItemClasses, Palette } from "@mui/material";

export const getMuiPaginationItem = (palette: Palette): Components["MuiPaginationItem"] => ({
  styleOverrides: {
    root: {
      [`&.${paginationItemClasses.selected}`]: {
        color: palette.grey[900],
        backgroundColor: palette.yellow.main
      },
    }
  }
});
