import { Button } from "@mui/material";
import { t } from "modules/utils/intl";
import { useConnectWalletData } from "./useConnectWalletData";
import { Dialog } from "../Dialog";
import { WalletConnectDialog } from "../WalletConnectDialog";

export const ConnectWallet = (): JSX.Element => {
  const {
    isOpenedDialog,
    walletInConnectProcess,
    handleCloseConnectWalletDialog,
    handleOpenConnectWalletDialog,
    handleConnectWallet,
  } = useConnectWalletData();

  return (
    <>
      <Button variant="outlined" onClick={handleOpenConnectWalletDialog}>
        {t("layout.header.connect-wallet")}
      </Button>

      <Dialog
        size="large"
        open={isOpenedDialog}
        onClose={handleCloseConnectWalletDialog}
      >
        <WalletConnectDialog
          walletInConnectProcess={walletInConnectProcess}
          handleClickConnect={handleConnectWallet}
        />
      </Dialog>
    </>
  );
};
