import { Typography } from "@mui/material";
import { TTxStatus } from "../../useBalanceData";
import { useTxStatusStyles } from "./useTxStatusStyles";

interface ITxStatusProps {
  status: TTxStatus;
}

export const TxStatus = ({ status }: ITxStatusProps): JSX.Element => {
  const { classes, cx } = useTxStatusStyles();

  return (
    <div className={classes.root}>
      {status === "completed" ? (
        <div className={cx(classes.dot, classes.green)} />
      ) : (
        <div className={cx(classes.dot, classes.orange)} />
      )}
      <Typography variant="caption" mt={1.25}>
        {status}
      </Typography>
    </div>
  );
};
