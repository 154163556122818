import { Box, Button, Typography } from "@mui/material";
import { t, tHTML } from "modules/utils/intl";
import { ReactComponent as AssetsHomeIcon } from "uiKit/icons/assets-home.svg";
import { ReactComponent as KeyIcon } from "uiKit/icons/key.svg";
import { useWalletDescriptionStyles } from "./useWalletDescriptionStyles";

interface IWalletDescriptionProps {
  handleClickGetWallet: () => void;
}

export const WalletDescription = ({
  handleClickGetWallet,
}: IWalletDescriptionProps): JSX.Element => {
  const { classes } = useWalletDescriptionStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h4_1" mb={10}>
        {t("wallet-connect.what-is-wallet")}
      </Typography>
      <Box display="flex" mb={8}>
        <Box className={classes.iconWrapper}>
          <AssetsHomeIcon />
        </Box>
        <Box>
          <Typography variant="body1Demibold">
            {t("wallet-connect.home-asset")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {tHTML("wallet-connect.home-asset-description")}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" mb={13}>
        <Box className={classes.iconWrapper}>
          <KeyIcon />
        </Box>
        <Box>
          <Typography variant="body1Demibold">
            {t("wallet-connect.login")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {tHTML("wallet-connect.login-description")}
          </Typography>
        </Box>
      </Box>
      <Button variant="contained" onClick={handleClickGetWallet}>
        {t("wallet-connect.get-wallet")}
      </Button>
    </Box>
  );
};
