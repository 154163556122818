import { GAME_TILE_WIDHT } from "modules/common/const";
import { makeStyles } from "tss-react/mui";

export const useGameTileStyles = makeStyles()((theme) => ({
  root: {
    background: "none",
    maxWidth: GAME_TILE_WIDHT,
  },
  media: {
    position: "relative",
    minWidth: GAME_TILE_WIDHT,
    minHeight: 156,
    borderRadius: 8,
  },
  networksWrapper: {
    position: "absolute",
    display: "flex",
    margin: theme.spacing(3, 1, 0, 3),
    gap: theme.spacing(1),
  },
  statusBadge: {
    display: "block",
    position: "absolute",
    top: 12,
    left: 12,
    maxWidth: 94,
    width: "100%",
  },
  content: {
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(3, 0, 0, 0),

    "&:last-child": {
      paddingBottom: theme.spacing(0),
    },
  },
  meta: {},
  overImage: {
    position: "absolute",
    bottom: -14,
    left: -3,
  },
  name: {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    whiteSpace: "pre-wrap",
    maxWidth: 212,
    marginBottom: theme.spacing(1),
  },
  developerName: {
    color: theme.palette.grey[300],
    marginBottom: theme.spacing(0.5),
  },
  tags: {
    display: "flex",
  },
  platformTag: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 20,
    height: 20,
    marginRight: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[700],
    borderRadius: 6,
  },
  gameTag: {
    display: "flex",
    alignItems: "center",
    maxHeight: 20,
    marginRight: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[700],
    borderRadius: 6,
    padding: theme.spacing(1, 1),
    fontSize: 11,
    fontWeight: 600,
    textAlign: "center",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
  reviewScoreWrapper: {
    position: "relative",
    display: "inline-flex",
  },
  reviewScoreTextWrapper: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reviewScore: {
    color: theme.palette.success.main,
    zIndex: 1,
  },
  reviewScoreBackground: {
    color: theme.palette.grey["300"],
    position: "absolute",
    top: 0,
    zIndex: 0,
    width: 26,
    height: 26,
  },
  reviewScoreText: {
    fontSize: 11,
    color: theme.palette.text.primary,
  },
}));
