import BigNumber from "bignumber.js";
import { ZERO } from "modules/common/const";
import { Token } from "modules/common/types";
import { useState } from "react";

export interface ICoinBalanceItem {
  token: Token;
  value: BigNumber;
  usdValue: BigNumber;
}

export type TTxStatus = "pending" | "completed";

export interface ITxItem {
  date: string;
  type: string;
  asset: Token;
  amount: BigNumber;
  destination: string;
  status: TTxStatus;
}

interface IBalanceData {
  userId: string;
  totalUsdBalance: BigNumber;
  coins: ICoinBalanceItem[];
  tx: ITxItem[];
  txGroup: ETxGroup;
  handleSetTxGroup: (newTxGroup: ETxGroup) => void;
}

const MOCK_COINS = [
  {
    token: Token.ETH,
    value: new BigNumber(1.43056),
    usdValue: new BigNumber(2549.6),
  },
  {
    token: Token.MATIC,
    value: new BigNumber(120.43),
    usdValue: new BigNumber(249.6),
  },
  {
    token: Token.BNB,
    value: new BigNumber(0.511),
    usdValue: new BigNumber(157.68),
  },
];

const MOCK_TX: ITxItem[] = [
  {
    date: new Date().toString(),
    type: "ADDED",
    asset: Token.BNB,
    amount: ZERO.plus(1_313),
    destination: "0x810831x6eq8w6asdsd78as7das897dadas79",
    status: "pending",
  },
  {
    date: new Date().toString(),
    type: "ADDED",
    asset: Token.ETH,
    amount: ZERO.plus(54),
    destination: "0xgjh687asd678a6s87da6s87d67a8sd",
    status: "completed",
  },
  {
    date: new Date().toString(),
    type: "ADDED",
    asset: Token.MATIC,
    amount: ZERO.plus(798_133),
    destination: "0x123nm123kjbjj1fgj3hk123",
    status: "completed",
  },
];

export enum ETxGroup {
  All = "All",
  Sent = "Sent",
  Added = "Added",
}

export const useBalanceData = (): IBalanceData => {
  const [txGroup, setTxGroup] = useState<ETxGroup>(ETxGroup.All);

  const handleSetTxGroup = (newTxGroup: ETxGroup) => {
    setTxGroup(newTxGroup);
  };

  return {
    userId: "345457",
    totalUsdBalance: new BigNumber(1278.053),
    coins: MOCK_COINS,
    tx: MOCK_TX,
    txGroup,
    handleSetTxGroup,
  };
};
