import { Components, Palette } from "@mui/material";

export function getMuiMenu(palette: Palette): Components["MuiMenu"] {
  return {
    styleOverrides: {
      paper: {
        padding: 12,
        backgroundColor: palette.grey[700],
        border: `1px solid ${palette.grey[500]}`,
      },
      list: {
        padding: 0,
      },
    },
  };
}
