import { configFromEnv, web3Api } from "modules/api";
import { GET_PROJECTS_PLAZA_LIST_URL } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { EPlatformType, EProjectType } from "modules/common/const";
import { NetworkCode } from "modules/common/types";
import { IImageData, IMediaItem, TSocialLink } from "modules/plaza/types";
import { TPlatformName } from "../components/Hero/types";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { gamingUrl },
} = configFromEnv();

interface IGetGameInfoArgs {
  id?: string;
}

export interface IMemberItem {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  role: string;
}

interface IClientSecret {
  clientId: string;
  clientSecret: string;
}

interface IGameScreenshot {
  domainEvents: unknown[];
  id: string;
  gameProjectId: string;
  fileName: string;
  fileUri: string;
}

// TODO: replace it to common types file
interface IResponse {
  id: string;
  userId: string;
  projectName: string;
  description: string;
  descriptionLong: string;
  gameDeveloperDescription?: string;
  gameProjectTypes: EProjectType[];
  platformTypes: EPlatformType[];
  groupId: string | null;
  contracts: unknown[];
  members: IMemberItem[] | null;
  clientSecret: IClientSecret | null;
  chainCodes?: NetworkCode[];
  image: IImageData;
  banner: IImageData;
  videos?: IMediaItem[];
  gameScreenshots?: IGameScreenshot[];
  gameProjectLinks: {
    platformLinks?: Record<TPlatformName, string>;
    socialLinks?: Record<TSocialLink, string>;
  };
}

export const { useGetGameInfoQuery } = web3Api.injectEndpoints({
  endpoints: (build) => ({
    getGameInfo: build.query<IResponse, IGetGameInfoArgs>({
      queryFn: queryFnNotifyWrapper<IGetGameInfoArgs, never, IResponse>(
        async ({ id }: IGetGameInfoArgs) => {
          try {
            const url = gamingUrl + GET_PROJECTS_PLAZA_LIST_URL + id;
            const response = await fetch(url, {
              method: "GET",
              credentials: "include",
            });

            const data = await processApiResponse(response);
            return { data };
          } catch (err) {
            console.error("Error in `useGetGameInfoQuery.ts`", err);
            throw err;
          }
        },
      ),
    }),
  }),
});
