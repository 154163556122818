import { CustomSvgIcon } from "../../modules/common/components/CustomSvgIcon";

export const Share = () => (
  <CustomSvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    style={{
      width: 16,
      height: 16,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7071 1.29289L10 0.585785L9.29289 1.29289L5.29289 5.29289L6.70711 6.70711L9 4.41421V13H11V4.41421L13.2929 6.70711L14.7071 5.29289L10.7071 1.29289ZM3 8H2V9V18V19H3H17H18V18V9V8H17H14V10H16V17H4V10H6V8H3Z"
      fill="currentColor"
    />
  </CustomSvgIcon>
);
