import BigNumber from "bignumber.js";
import { getNativeToken } from "./getNativeToken";
import { RoutesConfig } from "modules/plaza/Routes";
import { ICollectibleTileData } from "../components/CollectibleTile";
import { ICollectibleItem } from "modules/plaza/types";
import { EListingStatus } from "../types";

export const transformCollectiblesData = (
  data: ICollectibleItem[],
): ICollectibleTileData[] =>
  data.map((x) => ({
    id: x.id,
    name: x.name,
    tokenId: Number(x.tokenId) ?? 0,
    network: x.chainCode,
    token: getNativeToken(x.chainCode),
    collectionId: x.collectionId,
    collectionName: x.collectionName,
    price: x.price ? new BigNumber(x.price) : null,
    royalty: x.royalty,
    status: x.listingStatusEnum ?? EListingStatus.NotListed,
    createdBy: x.createdBy,
    lastModifiedBy: x.lastModifiedBy,
    orderId: x.orderId,
    image: !!x?.nftAsset ? x.nftAsset.fileUri : "",
    link: RoutesConfig.collectible.generatePath(x.id),
  }));
