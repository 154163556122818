import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { uid } from "react-uid";
import { useFilterTagsStyles } from "./useFilterTagsStyles";
import { Close } from "../../../../../../uiKit/icons";
import { Dispatch, useCallback } from "react";
import { IAction } from "../../reducer";
import { multiChoiceFilters, singleChoiceFilters } from "../../helpers";
import { filterToActionMap } from "../../../Games/components/Filters/reducer";

export type TTagTuple = [string, any];

interface IFilterTagsProps {
  tags: TTagTuple[];
  onFilterRemove: Dispatch<IAction>;
}

export const FilterTags = ({ tags, onFilterRemove }: IFilterTagsProps) => {
  const { classes } = useFilterTagsStyles({ areTagsEmpty: tags.length === 0 });
  const { palette } = useTheme();
  const handleRemoveFilter = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const target = event.currentTarget as HTMLButtonElement;
      const filterValue = target.getAttribute("data-value");
      const filterType = target.getAttribute("data-type");
      if (singleChoiceFilters.includes(filterType || "")) {
        onFilterRemove({ type: "CLEAR_FILTER", payload: filterType as string });
        return;
      }

      if (multiChoiceFilters.includes(filterType || "")) {
        onFilterRemove({
          type: filterToActionMap[filterType as string],
          payload: filterValue as string,
        });
      }
    },
    [onFilterRemove],
  );
  return (
    <Box className={classes.root}>
      {tags.map(([type, value]) => {
        const trimmedValue = value.includes("::")
          ? value.split("::")[1]
          : value;
        return (
          <Box className={classes.tag} key={uid(value)}>
            <Typography className={classes.filterText} variant="caption">
              {type}: {trimmedValue}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={handleRemoveFilter}
              data-value={value}
              data-type={type}
            >
              <Close color={palette.error.main} />
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};
