import { makeStyles } from "tss-react/mui";

export const useLightboxStyles = makeStyles()((theme) => ({
  root: {},
  content: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  lightboxWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(12.5, "auto", 0, "auto"),
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.grey["700"],
    borderRadius: "12px",
    maxWidth: 1276,
    maxHeight: 800,
    padding: theme.spacing(8, 10, 4.5, 10),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(4),
    top: theme.spacing(4),
    width: 48,
    height: 48,
    padding: theme.spacing(5),
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderRadius: "6px",
  },
  mainSlideWrapper: {
    marginRight: 5,
    width: "inherit",
    height: "inherit",
  },
  mainSlide: {
    position: "relative",
    minWidth: "inherit",
    minHeight: "inherit",
    maxWidth: 1036,
    maxHeight: 600,
    width: "100%",
    height: "100%",
    borderRadius: "12px",
    marginBottom: theme.spacing(4.5),
  },
  fullscreenButton: {
    position: "absolute",
    right: theme.spacing(4),
    top: theme.spacing(4),
    width: 40,
    height: 40,
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.grey["500"],
    borderRadius: "12px",
    "&:hover": {
      backgroundColor: "rgba(24, 24, 24, 0.8)",
    },
  },
  thumbnails: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "676px",
    overflow: "auto",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  controlButton: {
    width: 60,
    height: 60,
    padding: theme.spacing(5),
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderRadius: "6px",
  },
  counter: {
    color: theme.palette.grey["300"],
    lineHeight: "20px",
  },
}));
