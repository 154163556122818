import { toast, TypeOptions, ToastOptions } from "react-toastify";
import { Milliseconds, ReactText } from "modules/common/types";
import { Notification } from "./Notification";

interface INotifyArgs {
  key?: ReactText;
  title?: string;
  message: string;
  type: TypeOptions;
  toastOptions?: ToastOptions;
  autoClose?: Milliseconds | false;
  closeOnClick?: boolean;
}

export const notify = ({
  title,
  message,
  type,
  closeOnClick = true,
  autoClose = 5_000,
  toastOptions,
}: INotifyArgs): void => {
  const options: ToastOptions = {
    ...toastOptions,
    type,
    autoClose,
    closeOnClick,
    closeButton: false,
    position: "bottom-right",
  };

  toast(<Notification message={message} type={type} title={title} />, options);
};
