import { configFromEnv, web3Api } from "modules/api";
import { GET_POST_NFT_TOKEN_FILTERS } from "modules/api/urls";
import { queryFnNotifyWrapper } from "modules/api/utils/queryFnNotifyWrapper";
import { ICollectibleItem } from "modules/plaza/types";
import { GAMES_PAGE_SIZE } from "modules/common/const";
import { processApiResponse } from "modules/utils/processApiResponse";

const {
  gatewayConfig: { nftManagerUrl },
} = configFromEnv();

// TODO: make common interface for pagination
interface IResponse {
  items: ICollectibleItem[];
  pageNumber: number;
  totalCount: number;
  totalPages: number;
}

interface IGetCollectiblesArgs {
  collectionId: string;
  pageNumber: number;
}

export const { useLazyGetCollectiblesByCollectionQuery } =
  web3Api.injectEndpoints({
    endpoints: (build) => ({
      getCollectiblesByCollection: build.query<IResponse, IGetCollectiblesArgs>(
        {
          queryFn: queryFnNotifyWrapper<IGetCollectiblesArgs, never, IResponse>(
            async ({ collectionId, pageNumber }) => {
              try {
                const response = await fetch(
                  nftManagerUrl +
                    GET_POST_NFT_TOKEN_FILTERS +
                    "?" +
                    new URLSearchParams({
                      NftCollectionId: collectionId,
                      PageNumber: pageNumber.toString(),
                      PageSize: GAMES_PAGE_SIZE.toString(),
                    }),
                  {
                    method: "GET",
                    credentials: "include",
                  },
                );

                const data = await processApiResponse(response);
                return { data };
              } catch (err) {
                console.error(
                  "Error in `useLazyGetCollectiblesByCollectionQuery.ts`",
                  err,
                );
                throw err;
              }
            },
          ),
        },
      ),
    }),
  });
