import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Link, Skeleton, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { t } from "modules/utils/intl";
import { RoutesConfig } from "modules/plaza/Routes";
import { platformTypesMap, projectTypesMap } from "modules/common/const";
import { Platform } from "uiKit/icons";
import { ReactComponent as ArrowIcon } from "uiKit/icons/arrow-yellow-right.svg";
import { useHeroStyles } from "./useHeroStyles";
import { IGamesListItem } from "../../../../types";
import { useFetchFeaturedGamesQuery } from "../../../Games/actions/useFetchFeaturedGamesQuery";

export const Hero = (): JSX.Element => {
  const { data, isFetching } = useFetchFeaturedGamesQuery(
    {
      pageNumber: 1,
      pageSize: 4,
    },
    {
      refetchOnMountOrArgChange: false,
    },
  );
  const gamesData = useMemo(() => data?.items || [], [data]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentGame, setCurrentGame] = useState<IGamesListItem | null>();
  const { classes } = useHeroStyles({
    bannerUrl: currentGame?.image?.fileUri || "",
  });
  const totalPages = gamesData?.length;
  const platforms =
    currentGame?.platformTypes.map((platform) => platformTypesMap[platform]) ||
    [];
  const tags =
    currentGame?.gameProjectTypes.map((tag) => projectTypesMap[tag]) || [];

  useEffect(() => {
    if (gamesData.length) {
      setCurrentGame(gamesData[0]);
    }
  }, [gamesData]);

  const handleNextPage = useCallback(() => {
    if (currentPage === gamesData.length) {
      setCurrentPage(1);
      setCurrentGame(gamesData[0]);
    } else {
      setCurrentPage(currentPage + 1);
      setCurrentGame(gamesData[currentPage]);
    }
  }, [gamesData, currentPage]);

  const handlePrevPage = useCallback(() => {
    if (currentPage === 1) {
      setCurrentPage(gamesData.length);
      setCurrentGame(gamesData[gamesData.length - 1]);
    } else {
      setCurrentPage(currentPage - 1);
      setCurrentGame(gamesData[currentPage - 2]);
    }
  }, [gamesData, currentPage]);

  if (isFetching) {
    return (
      <Skeleton
        className={classes.skeleton}
        animation="pulse"
        variant="rounded"
        height={420}
        width="100%"
      />
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.banner} />
      <Box className={classes.content}>
        <Box className={classes.gameInfo}>
          <Box className={classes.badges}>
            {/*{!!chains?.length &&*/}
            {/*  chains?.map((chain) => (*/}
            {/*    <Link*/}
            {/*      className={classes.chainBadge}*/}
            {/*      component="button"*/}
            {/*      key={chain}*/}
            {/*    >*/}
            {/*      <SimpleNetworkIcon name={chain} />*/}
            {/*    </Link>*/}
            {/*  ))}*/}
            {platforms.map((platform) => (
              <Link
                className={classes.chainBadge}
                component="button"
                key={platform}
              >
                <Platform platform={platform} />
              </Link>
            ))}
            {!!tags.length &&
              tags.slice(0, 3).map((tag) => (
                <Typography component="span" key={tag} className={classes.tag}>
                  {tag}
                </Typography>
              ))}
          </Box>
          <Box className={classes.brand}>
            <Typography className={classes.heading} variant="h1">
              {currentGame?.projectName}
            </Typography>
          </Box>

          <Box className={classes.descriptionWrapper}>
            <Typography className={classes.descriptionText}>
              {currentGame?.description}
            </Typography>
          </Box>
          <NavLink to={RoutesConfig.game.generatePath(currentGame?.id)}>
            <Button variant="contained" className={classes.btnExplore}>
              {t("plaza.explore")}
            </Button>
          </NavLink>
        </Box>
        <Box className={classes.controlsWrapper}>
          <Box className={classes.controls}>
            <Typography variant="body2Demibold" marginRight={5}>
              {currentPage.toString().length === 1
                ? `0${currentPage}`
                : currentPage}
              /
              {totalPages.toString().length === 1
                ? `0${totalPages}`
                : totalPages}
            </Typography>
            <Button
              variant="outlined"
              className={classes.pageButton}
              onClick={handlePrevPage}
            >
              <ArrowIcon />
            </Button>
            <Button
              variant="outlined"
              className={classes.pageButton}
              onClick={handleNextPage}
            >
              <ArrowIcon />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
