import { Box, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { t } from "modules/utils/intl";

interface IValueWithUsdCellProps {
  value: BigNumber;
  usdValue: BigNumber;
}

export const ValueWithUsdCell = ({
  value,
  usdValue,
}: IValueWithUsdCellProps): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h4Numbers" mb={1}>
        {value.toFormat()}
      </Typography>
      <Typography variant="h4Numbers" color="textSecondary">
        {t("formats.estimated-usd-value", {
          value: value.toFormat(2),
        })}
      </Typography>
    </Box>
  );
};
